import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { InputAdornment } from '@mui/material'
import { CalendarIcon, MultiInputDateTimeRangeField } from '@mui/x-date-pickers-pro'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'

import { DateRangeShortcuts } from './DateRangeShortcuts'
import { useDateRangeShortcuts } from './useDateRangeShortcuts'
import { UserContext } from '../../../App'

function isValidDate(d) {
  return d instanceof Date && !isNaN(d)
}

export const CustomDateRangePicker = ({ initialDateRange, onChange }) => {
  const [value, setValue] = useState(() => {
    if (!initialDateRange.startDate) return null
    if (!initialDateRange.endDate) return null
    return [initialDateRange.startDate, initialDateRange.endDate]
  })

  useEffect(() => {
    if (!initialDateRange.startDate) return null
    if (!initialDateRange.endDate) return null
    setValue([initialDateRange.startDate, initialDateRange.endDate])
  }, [initialDateRange.endDate, initialDateRange.startDate])

  const selectedRangeRef = useRef(value)

  const user = useContext(UserContext)

  const shortcutsItems = useDateRangeShortcuts()

  const onSelect = useCallback(() => {
    const selectedRange = selectedRangeRef.current

    if (selectedRange?.length !== 2) return

    if (isValidDate(selectedRange[0]) && isValidDate(selectedRange[1])) {
      onChange({ startDate: selectedRange[0], endDate: selectedRange[1] })
    }
  }, [onChange])

  const onCancel = useCallback(() => {
    selectedRangeRef.current = [initialDateRange.startDate, initialDateRange.endDate]
    setValue([initialDateRange.startDate, initialDateRange.endDate])
  }, [initialDateRange.endDate, initialDateRange.startDate])

  return (
    <DateRangePicker
      localeText={{ start: user.translate('date_from'), end: user.translate('date_to') }}
      formatDensity="dense"
      format="dd.MM.yyyy HH:mm"
      value={value}
      closeOnSelect
      slots={{
        field: MultiInputDateTimeRangeField,
        shortcuts: DateRangeShortcuts,
      }}
      slotProps={{
        shortcuts: {
          items: shortcutsItems,
          isLandscape: false,
          value,
        },
        textField: {
          variant: 'standard',
          onKeyDown: (e) => {
            if (e.key === 'Enter') {
              onSelect()
            } else if (e.key === 'Escape') {
              onCancel()
            }
          },
          slotProps: {
            field: {
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarIcon />
                  </InputAdornment>
                ),
              },
            },
          },
        },
      }}
      onChange={([start, end]) => {
        if (start !== value[0] && end === value[1]) {
          selectedRangeRef.current = [start, start]
          setValue([start, start])
        } else if (start && end) {
          selectedRangeRef.current = [start, end]
        }
      }}
      onAccept={(range) => {
        selectedRangeRef.current = range
      }}
      onClose={onSelect}
    />
  )
}
