import { useContext, useState } from 'react'

import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom'

import { gql, useQuery } from '@apollo/client'

import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import { default as AssessmentIcon, default as InventoryIcon } from '@mui/icons-material/Assessment'
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import BlurLinearIcon from '@mui/icons-material/BlurLinear'
import CardMembershipIcon from '@mui/icons-material/CardMembership'
import InternalTransferIcon from '@mui/icons-material/CompareArrows'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import MeterIcon from '@mui/icons-material/DataUsage'
import PosIcon from '@mui/icons-material/DeviceHub'
import DomainIcon from '@mui/icons-material/Domain'
import FastfoodIcon from '@mui/icons-material/Fastfood'
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease'
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease'
import HomeWorkIcon from '@mui/icons-material/HomeWork'
import { default as WarehouseIcon, default as WarehousingIcon } from '@mui/icons-material/House'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import KitchenIcon from '@mui/icons-material/Kitchen'
import ListAltIcon from '@mui/icons-material/ListAlt'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import LocalConvenienceStoreIcon from '@mui/icons-material/LocalConvenienceStore'
import { default as LocalShippingIcon, default as SendingIcon } from '@mui/icons-material/LocalShipping'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import KomitentIcon from '@mui/icons-material/People'
import KomitentGroupIcon from '@mui/icons-material/PeopleAlt'
import PlayForWorkIcon from '@mui/icons-material/PlayForWork'
import ReceiptIcon from '@mui/icons-material/Receipt'
import SettingsIcon from '@mui/icons-material/Settings'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import StorageIcon from '@mui/icons-material/Storage'
import StaffIcon from '@mui/icons-material/SupervisorAccount'
import TableChartIcon from '@mui/icons-material/TableChart'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import makeStyles from '@mui/styles/makeStyles'

import { RestaurantContext, UserContext } from './App'
import './App.css'
import PrimarySearchAppBarDrawer from './app/AppNavigation'
import RestaurantConfig from './restaurant/config/RestaurantConfig'
import InventoryCounting from './restaurant/inventory/InventoryCounting'
import InventoryCountingList from './restaurant/inventory/InventoryCountingList'
import InvoiceInputItem from './restaurant/invoice/InvoiceInputItem'
import InvoiceInputList from './restaurant/invoice/InvoiceInputList'
import InvoiceOutputItem from './restaurant/invoice/InvoiceOutputItem'
import InvoiceOutputList, { CashInvoiceOutputList } from './restaurant/invoice/InvoiceOutputList'
import KomitentList from './restaurant/komitent/KomitentList'
import LoyaltyProgramIdentifications from './restaurant/komitent/LoyaltyProgramIdentifications'
import LoyaltyPrograms from './restaurant/komitent/LoyaltyPrograms'
import MenuList from './restaurant/menu/MenuList'
import RestaurantMenuList from './restaurant/menus/RestaurantMenuList'
import MeterList from './restaurant/meter/MeterList'
import AnaliticalCard from './restaurant/payment/AnaliticalCard'
import BankStatementList from './restaurant/payment/BankStatementList'
import FinancialDocumentList from './restaurant/payment/FinancialDocumentList'
import OpenItemsStatement from './restaurant/payment/OpenItemsStatement'
import PosList from './restaurant/pos/PosList'
import Normatives from './restaurant/product/Normatives'
import ProductList from './restaurant/product/ProductList'
import ProductionCategoryList from './restaurant/production/ProductionCategoryList'
import ProductionDepartmentList from './restaurant/production/ProductionDepartmentList'
import EtReport from './restaurant/report/EtReport'
import EtuReport from './restaurant/report/EtuReport'
import InventoryCategoryOrder from './restaurant/report/inventory/InventoryCategoryOrder'
import InventoryMain from './restaurant/report/inventory/InventoryMain'
import InventoryMenuItemOrder from './restaurant/report/inventory/InventoryMenuItemOrder'
import InventoryOrder from './restaurant/report/inventory/InventoryOrder'
import InventoryOutput from './restaurant/report/inventory/InventoryOutput'
import InventoryMeterReport from './restaurant/report/InventoryMeterReport'
import InventoryTurnoverReport from './restaurant/report/InventoryTurnoverReport'
import KdfiReport from './restaurant/report/KdfiReport'
import ReceiptsReport from './restaurant/report/ReceiptsReport'
import ReceivingReport from './restaurant/report/ReceivingReport'
import TurnoverReport from './restaurant/report/TurnoverReport'
import TurnoverReportDate from './restaurant/report/TurnoverReportDate'
import StaffList from './restaurant/staff/StaffList'
import { SendingDocumentPrint } from './restaurant/stock/DocumentPrint'
import PltReport from './restaurant/stock/PltReport'
import StockList from './restaurant/stock/StockList'
import StockReceivingItem from './restaurant/stock/StockReceivingItem'
import StockReceivingList from './restaurant/stock/StockReceivingList'
import StockReturningItem from './restaurant/stock/StockReturningItem'
import StockReturningList from './restaurant/stock/StockReturningList'
import StockSendingItem from './restaurant/stock/StockSendingItem'
import StockSendingList from './restaurant/stock/StockSendingList'
import StockTransferList from './restaurant/stock/StockTransferList'
import NewStockSupplingList from './restaurant/supply/NewStockSupplingList'
import SupplingList from './restaurant/supply/SupplingList'
import TableList from './restaurant/table/TableList'
import { RestaurantAdminTools } from './restaurant/tools/RestaurantAdminTools'
import WarehouseList from './restaurant/warehouse/WarehouseList'

const GET_NOTIFICATIONS = gql`
  query ($restaurantId: String!) {
    me {
      employeeRestaurantsCount
    }

    getInventoryCountingsByContextRestaurantId(restaurantId: $restaurantId) {
      state
    }
  }
`

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}))

export default function Main(props) {
  const classes = useStyles()

  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)
  const { data: notifications, loading: loadingNotifications } = useQuery(GET_NOTIFICATIONS, {
    pollInterval: 10000,
    variables: {
      restaurantId: restaurant.id,
    },
  })

  const notificationsCount = notifications ? notifications.me.employeeRestaurantsCount : 1

  const { restaurantId } = useParams()

  const drawerMenu = useLocation().pathname.split('/')[3]

  const [notifCount, setNotifCount] = useState(notificationsCount)
  const [openNotifications, setOpenNotifications] = useState(false)
  const handleCloseNotifications = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenNotifications(false)
  }

  const onNotifications = () => {
    setOpenNotifications(true)
    setNotifCount(0)
  }

  const alertInventoryCountingStates = ['COUNTING', 'REVIEWING', 'APPROVING']
  const pendingInventoryCountings = notifications?.getInventoryCountingsByContextRestaurantId?.reduce(
    (a, x) => (a += alertInventoryCountingStates.indexOf(x.state) > -1),
    0,
  )
  const pendingSupplies = 0

  const menus = [
    [
      user.me?.profile?.isAdmin
        ? {
            key: 'admin_tools',
            text: user.translate('admin_tools'),
            icon: <AdminPanelSettingsIcon />,
          }
        : {},
      user.me && user.role > 3
        ? {
            key: 'config',
            text: user.translate('config'),
            icon: <SettingsIcon />,
          }
        : {},
      { key: 'staff', text: user.translate('staff'), icon: <StaffIcon /> },
      user.me && user.role > 2
        ? {
            key: 'warehouse',
            text: user.translate('warehouse'),
            icon: <WarehouseIcon />,
          }
        : {},
      user.me && user.role > 2
        ? {
            key: 'meters',
            text: user.translate('meters'),
            icon: <MeterIcon />,
            children: [
              {
                key: 'meters_report',
                text: user.translate('report'),
                icon: <AssessmentIcon />,
              },
              {
                key: 'meter',
                text: user.translate('setup'),
                icon: <ListAltIcon />,
              },
            ],
          }
        : {},
      user.me && user.role > 2
        ? {
            key: 'production',
            text: user.translate('production'),
            icon: <HomeWorkIcon />,
            children: [
              {
                key: 'production_category',
                text: user.translate('category'),
                icon: <StorageIcon />,
              },
              {
                key: 'production_department',
                text: user.translate('department'),
                icon: <DomainIcon />,
              },
            ],
          }
        : {},
      {
        key: 'stock_types',
        text: user.translate('stock_items'),
        icon: <LocalConvenienceStoreIcon />,
      },
      user.me && user.role > 0
        ? {
            key: 'inventory',
            text: user.translate('inventory_counting'),
            icon: <InventoryIcon />,
            notifications: pendingInventoryCountings,
          }
        : {},
      user.me && user.role > 1
        ? {
            key: 'supplies',
            text: user.translate('supplies'),
            icon: <LocalShippingIcon />,
            notifications: pendingSupplies,
            children: [
              {
                key: 'new_supply',
                text: user.translate('new_supply'),
                icon: <OpenInNewIcon />,
              },
              {
                key: 'supplies',
                text: user.translate('supplies'),
                icon: <StorageIcon />,
              },
            ],
          }
        : {},
      {
        key: 'product',
        text: user.translate('products'),
        icon: <ShoppingBasketIcon />,
      },
      { key: 'menu', text: user.translate('menu'), icon: <MenuBookIcon /> },
      user.me && user.role > 2 ? { key: 'pos', text: user.translate('pos'), icon: <PosIcon /> } : {},
      {
        key: 'table',
        text: user.translate('tables'),
        icon: <TableChartIcon />,
      },
      user.me && user.role > 2
        ? {
            key: 'komitent',
            text: user.translate('komitents'),
            icon: <KomitentGroupIcon />,
            children: [
              {
                key: 'komitent',
                text: user.translate('komitents'),
                icon: <KomitentIcon />,
              },
              {
                key: 'loyalty',
                text: user.translate('loyalty'),
                icon: <CardMembershipIcon />,
              },
            ],
          }
        : {
            key: 'komitent',
            text: user.translate('komitents'),
            icon: <KomitentIcon />,
          },
      // { key: 'tax_type', text: user.translate("tax_type"), icon: <MergeTypeIcon /> },
      user.me && user.role > 2
        ? {
            key: 'warehousing',
            text: user.translate('warehouse_documents'),
            icon: <WarehousingIcon />,
            children: [
              {
                key: 'stock_receiving',
                text: user.translate('stock_receiving'),
                icon: <PlayForWorkIcon />,
              },
              {
                key: 'stock_sending',
                text: user.translate('stock_sending'),
                icon: <SendingIcon />,
              },
              {
                key: 'stock_returning',
                text: user.translate('stock_returning'),
                icon: <AssignmentReturnIcon />,
              },
              {
                key: 'stock_transfer',
                text: user.translate('stock_transfer'),
                icon: <InternalTransferIcon />,
              },
            ],
          }
        : {},
      user.me && user.role > 2
        ? {
            key: 'invoice_output',
            text: user.translate('output_invoices'),
            icon: <FormatIndentDecreaseIcon />,
          }
        : {},
      user.me && user.role > 2
        ? {
            key: 'cash_invoice_output',
            text: user.translate('cash_output_invoices'),
            icon: <FormatIndentDecreaseIcon />,
          }
        : {},
      user.me && user.role > 2
        ? {
            key: 'invoice_input',
            text: user.translate('input_invoices'),
            icon: <FormatIndentIncreaseIcon />,
          }
        : {},
      user.me && user.role > 2
        ? {
            key: 'finance',
            text: user.translate('finance'),
            icon: <AttachMoneyIcon />,
            notifications: 0,
            children: [
              {
                key: 'bank_statement',
                text: user.translate('bank_statements'),
                icon: <AccountBalanceIcon />,
              },
              {
                key: 'document',
                text: user.translate('documents'),
                icon: <InsertDriveFileIcon />,
              },
              {
                key: 'analytical_card',
                text: user.translate('analytical_card').substring(0, 15) + '.',
                icon: <CreditCardIcon />,
              },
              {
                key: 'open_items_statement',
                text: user.translate('open_items_statement'),
                icon: <CreditCardIcon />,
              },
            ],
          }
        : {},
    ],
    [
      {
        key: 'report',
        text: user.translate('reportsi'),
        icon: <AssessmentIcon />,
        children: [
          {
            key: 'kdfi_report',
            text: user.translate('kdfi'),
            icon: <BlurLinearIcon />,
          },
          {
            key: 'etu_report',
            text: user.translate('etu'),
            icon: <LowPriorityIcon />,
          },
          {
            key: 'et_report',
            text: user.translate('et'),
            icon: <LowPriorityIcon />,
          },
          {
            key: 'output_report',
            text: user.translate('outputs'),
            icon: <FormatIndentDecreaseIcon />,
          },
          {
            key: 'receiving_report',
            text: user.translate('receiving'),
            icon: <FormatIndentIncreaseIcon />,
          },
          {
            key: 'receipts_report',
            text: user.translate('receipts'),
            icon: <ReceiptIcon />,
          },
          {
            key: 'order',
            text: user.translate('orders'),
            icon: <FastfoodIcon />,
          },
          {
            key: 'category_order',
            text: user.translate('categoryOrders'),
            icon: <FastfoodIcon />,
          },
          {
            key: 'product_order',
            text: user.translate('productOrders'),
            icon: <FastfoodIcon />,
          },
          {
            key: 'stock',
            text: user.translate('inventory'),
            icon: <KitchenIcon />,
          },
          {
            key: 'turnover_report',
            text: user.translate('turnover'),
            icon: <LocalAtmIcon />,
          },
          {
            key: 'inventory_turnover_report',
            text: user.translate('inventory_turnover_report'),
            icon: <LocalAtmIcon />,
          },
        ],
      },
    ],
  ]

  if (!drawerMenu) {
    return <Navigate to={{ pathname: `/restaurant/${restaurantId}/staff` }} replace />
  }

  let title = restaurant.name[user.lang]
  if (user.me.profile && user.me.profile.name) {
    title += ', ' + user.me.profile.name
  }

  return (
    <div>
      <PrimarySearchAppBarDrawer
        title={title}
        notifications={notifCount}
        onNotifications={onNotifications}
        drawer={menus}
        home="/home"
        drawerSelected={drawerMenu}
        onSetLanguage={props.onSetLanguage}
        refetch={props.refetch}
      >
        <div className={classes.content}>
          <Routes>
            <Route path="/admin_tools" element={<RestaurantAdminTools />} />
            <Route path="/config" element={<RestaurantConfig />} />
            <Route path="/staff" element={<StaffList />} />
            <Route path="/komitent" element={<KomitentList />} />
            <Route path="/loyalty" element={<LoyaltyPrograms />} exact />
            {user.me && user.role > 2 && (
              <Route path="/loyalty/:userIdProgramId/identifications" element={<LoyaltyProgramIdentifications />} exact />
            )}
            <Route path="/table" element={<TableList />} />
            {user.me && user.role > 2 && <Route path="/warehouse" element={<WarehouseList />} />}
            {user.me && user.role > 2 && <Route path="/meter" element={<MeterList />} />}
            {user.me && user.role > 2 && <Route path="/meters_report/:counterId" element={<InventoryMeterReport />} />}
            {user.me && user.role > 2 && <Route path="/meters_report" element={<InventoryMeterReport />} />}
            <Route path="/inventory" exact element={<InventoryCountingList />} />
            <Route path="/inventory/:inventoryCountingId" exact element={<InventoryCounting />} />
            <Route path="/stock_types" element={<StockList />} />
            {user.me && user.role > 2 && <Route path="/supplies" exact element={<SupplingList />} />}
            {user.me && user.role > 2 && <Route path="/supplies/:stockSupplyId" exact element={<SupplingList />} />}
            {user.me && user.role > 2 && <Route path="/new_supply" element={<NewStockSupplingList />} />}
            {user.me && user.role > 2 && <Route path="/bank_statement" exact element={<BankStatementList />} />}
            {user.me && user.role > 2 && <Route path="/bank_statement/:statementId" exact element={<BankStatementList />} />}
            {user.me && user.role > 2 && (
              <Route path="/bank_statement/:statementId/payment/:paymentId" exact element={<BankStatementList />} />
            )}
            {user.me && user.role > 2 && <Route path="/bank_statement/new" element={<BankStatementList />} />}
            {user.me && user.role > 2 && <Route path="/analytical_card" exact element={<AnaliticalCard />} />}
            {user.me && user.role > 2 && <Route path="/open_items_statement" exact element={<OpenItemsStatement />} />}
            {user.me && user.role > 2 && <Route path="/open_items_statement/:komitentId" exact element={<OpenItemsStatement />} />}
            {user.me && user.role > 2 && <Route path="/document" exact element={<FinancialDocumentList />} />}
            {user.me && user.role > 2 && <Route path="/document/:documentType/:documentId" exact element={<FinancialDocumentList />} />}
            {user.me && user.role > 2 && <Route exact path="/stock_receiving" element={<StockReceivingList />} />}
            {user.me && user.role > 2 && <Route exact path="/stock_sending" element={<StockSendingList />} />}
            {user.me && user.role > 2 && <Route exact path="/stock_returning" element={<StockReturningList />} />}
            {user.me && user.role > 2 && <Route exact path="/stock_receiving/:stockReceivingId" element={<StockReceivingItem />} />}
            {user.me && user.role > 2 && <Route exact path="/stock_sending/:stockSendingId" element={<StockSendingItem />} />}
            {user.me && user.role > 2 && <Route exact path="/stock_returning/:stockReceivingId" element={<StockReturningItem />} />}
            {user.me && user.role > 2 && <Route exact path="/stock_transfer" element={<StockTransferList />} />}
            {user.me && user.role > 2 && <Route exact path="/stock_transfer/:stockTransferId" element={<StockTransferList />} />}
            {user.me && user.role > 2 && <Route exact path="/sending_document/:documentId" element={<SendingDocumentPrint />} />}
            <Route path="/product" element={<ProductList />} />
            <Route path="/normatives" element={<Normatives />} />
            {user.me && user.role > 2 && <Route path="/production_category" element={<ProductionCategoryList />} />}
            {user.me && user.role > 2 && <Route path="/production_department" element={<ProductionDepartmentList />} />}
            {/* <Route path="/tax_type" element={<TaxTypeList />} /> */}
            {user.me && user.role > 2 && <Route path="/pos" element={<PosList />} />}
            <Route path="/menu" element={<MenuList />} />
            <Route path="/menus" element={<RestaurantMenuList />} />

            {user.me && user.role > 2 && <Route exact path="/invoice_output" element={<InvoiceOutputList />} />}
            {user.me && user.role > 2 && <Route path="/invoice_output/:invoiceId" element={<InvoiceOutputItem />} />}
            {user.me && user.role > 2 && <Route exact path="/cash_invoice_output" element={<CashInvoiceOutputList />} />}
            {user.me && user.role > 2 && <Route path="/cash_invoice_output/:invoiceId" element={<InvoiceOutputItem />} />}
            {user.me && user.role > 2 && <Route exact path="/invoice_input" element={<InvoiceInputList />} />}
            {user.me && user.role > 2 && <Route path="/invoice_input/:invoiceId" element={<InvoiceInputItem />} />}

            <Route path="/inventory_turnover_report" element={<InventoryTurnoverReport />} />
            <Route path="/turnover_report/" element={<TurnoverReport />} exact />
            <Route path="/turnover_report/:date" element={<TurnoverReportDate />} />
            <Route path="/kdfi_report/" element={<KdfiReport />} />
            <Route path="/etu_report/" element={<EtuReport />} />
            <Route path="/et_report/" element={<EtReport />} />
            <Route path="/output_report" element={<InventoryOutput />} />
            <Route path="/receiving_report" element={<ReceivingReport />} />
            <Route path="/receipts_report" element={<ReceiptsReport />} />
            <Route path="/order" element={<InventoryOrder />} />
            <Route path="/category_order" element={<InventoryCategoryOrder />} />
            <Route path="/product_order" element={<InventoryMenuItemOrder />} />
            <Route path="/stock" element={<InventoryMain />} />
            <Route path="/plt_report/:receivingId" element={<PltReport />} />
            <Route index element={<p>Not implemented {drawerMenu}!</p>} />
          </Routes>
        </div>
      </PrimarySearchAppBarDrawer>

      <Snackbar
        open={openNotifications}
        autoHideDuration={5000}
        onClose={handleCloseNotifications}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert elevation={6} variant="filled" onClose={handleCloseNotifications} severity="info">
          Welcome to eBar!
        </Alert>
      </Snackbar>
    </div>
  )
}
