import React, { useContext, useState } from 'react'

import PropTypes from 'prop-types'

import CheckIcon from '@mui/icons-material/Check'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'


import { RestaurantContext, UserContext } from '../../App'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    '& [class*="MuiFormHelperText-root"]': {
      marginTop: 0,
      fontSize: 11,
    },
  },
}))

export default function AmountInputFieldToggle(props) {
  const classes = useStyles()

  const user = useContext(UserContext)
  const restaurant = useContext(RestaurantContext)

  const [inputValue, setInputValue] = useState()

  const propsValue = props.value && user.formatQuantity(props.value)
  const quantityValue = inputValue !== undefined ? inputValue : propsValue

  const inputQuantityVal = quantityValue && user.parseStringNumber(quantityValue)

  const clearOrSameInput = (inputValue === undefined || inputValue.length === 0 || inputValue === propsValue) && propsValue !== undefined

  const valueChanged = inputValue !== undefined && inputValue.length > 0 && inputValue !== propsValue && propsValue !== undefined

  const onInputValueChanged = (e) => {
    const value = e.target.value
    if (value.length === 0 || value.endsWith(',') || value.endsWith('.')) {
      return setInputValue(value)
    }

    const numValue = user.parseStringNumber(value)
    const formatedValue = numValue === undefined || isNaN(numValue) ? value : user.formatQuantity(numValue)
    setInputValue(formatedValue)

    const quantityRawVal = (numValue === undefined || isNaN(numValue)) && numValue
    if (props.onChange && quantityRawVal) {
      props.onChange(quantityRawVal)
    }
  }

  if (props.readOnly) {
    return `${quantityValue}`
  }

  return (
    <div className={[props.className, classes.root].join(' ')}>
      <TextField
        helperText={props.label}
        type="text"
        inputMode="numeric"
        value={quantityValue}
        margin="none"
        variant="standard"
        className={classes.input}
        onChange={onInputValueChanged}
        onBlur={() => setInputValue()}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (clearOrSameInput) {
              props.onSubmit(0)
            } else if (valueChanged) {
              props.onSubmit(inputQuantityVal)
            } else if (inputQuantityVal) {
              props.onSubmit(inputQuantityVal)
            }
          }
        }}
        InputProps={{
          style: {
            fontSize: 14,
          },
          classes: {
            input: classes.input,
          },
          startAdornment: props.uom && (
            <Typography variant="caption" style={{ fontSize: 10, paddingRight: 4, paddingTop: 4 }}>
              {props.uom}
            </Typography>
          ),
          endAdornment: clearOrSameInput ? (
            <InputAdornment position="end" className="no_print">
              <IconButton
                aria-label="remove"
                onClick={() => props.onSubmit(0)}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
                size="large"
              >
                <DeleteOutlineIcon />
              </IconButton>
            </InputAdornment>
          ) : (
            valueChanged && (
              <InputAdornment position="end" className="no_print">
                <IconButton
                  aria-label="order"
                  onClick={() => {
                    props.onSubmit(inputQuantityVal)
                  }}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                  size="large"
                >
                  <CheckIcon />
                </IconButton>
              </InputAdornment>
            )
          ),
        }}
      />
    </div>
  )
}

AmountInputFieldToggle.propTypes = {
  value: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  enableClear: PropTypes.bool,
  enableAccept: PropTypes.bool,
}
