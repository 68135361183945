import React, { useContext, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { gql, useMutation } from '@apollo/client'

import DeleteIcon from '@mui/icons-material/Delete'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import makeStyles from '@mui/styles/makeStyles'

import InventoryCountingItemDialog from './InventoryCountingItemDialog'
import { RestaurantContext, UserContext } from '../../App'
import Delete from '../common/Delete'

const useStyles = makeStyles((theme) => ({
  roots: {
    '& .MuiTableCell-root .MuiIconButton-root': {
      padding: theme.spacing(1),
    },
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'wrap',
    padding: 0,
    justifyContent: 'space-evenly',
  },
  item: {
    width: '100%',
    maxWidth: 320,
    minWidth: 280,
    margin: theme.spacing(1),
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '& > *': {
      height: 22,
      display: 'flex',
      alignItems: 'flex-end',
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  iconInactive: {
    marginRight: theme.spacing(1),
    opacity: 0.4,
  },
  checkedLabel: {
    marginRight: 0,
    '& .MuiFormControlLabel-label': {
      fontSize: 12,
    },
  },
  progress: {
    // paddingLeft: theme.spacing(1),
  },
  stateSection: {
    marginLeft: theme.spacing(2),
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  loading: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    margin: theme.spacing(2),
  },
  dialogContent: {
    minWidth: 330,
    [theme.breakpoints.down('md')]: {
      minWidth: 220,
    },
  },
  table: {
    minWidth: 220,
  },
}))

const DELETE_ITEM = `
    mutation ($id: String!) {
        deleteInventoryCountingItem(id: $id)
    }
`

const GQL_INVENTORY_COUNTING_CREATE = gql`
  mutation ($id: String!, $countingId: String!, $stockType: String!, $quantity: Float!) {
    createInventoryCountingItem(input: { id: $id, countingId: $countingId, stockTypeId: $stockType, quantity: $quantity, uomOpts: 0 }) {
      id
    }
  }
`

// React Function Component
export default function InventoryCountingItemCountingsDialog(props) {
  const classes = useStyles()

  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const navigate = useNavigate()

  const { restaurantId, inventoryCountingId } = useParams()
  const [openItem, setOpenItem] = useState(false)
  const [deleteState, setDeleteState] = useState(false)

  const [saveItem, { loading: loadingSave }] = useMutation(GQL_INVENTORY_COUNTING_CREATE)

  if (!props.item) return <></>

  const handleClose = () => {
    props.onClose(false)
  }

  const uomIndexes = {
    LITER: 0,
    KILOGRAM: 1,
    PIECE: 2,
  }

  const uom = uomIndexes[props.item.uom]

  const uomUnit = ['L', 'Kg', 'p']

  const uomOptionPiece = [user.translate('dose'), user.translate('dose'), user.translate('dose')]

  const uomOptionPackage = [
    [user.translate('bottle'), user.translate('bottles')],
    [user.translate('bag'), user.translate('bags')],
    [user.translate('box'), user.translate('boxes')],
  ]

  const uomName = uomUnit[uom]

  const calcNormalizedQuantity = (quantity, uomOption) => {
    if (uomOption === 1) {
      const normQuantity = quantity / props.item.piece
      if (normQuantity === 1) {
        return `${user.formatQuantity(normQuantity)} ${user.translate('dose')}`
      } else {
        return `${user.formatQuantity(normQuantity)} ${user.translate('doses')}`
      }
    }
    if (uomOption === 2) {
      const normQuantity = quantity / props.item.package
      if (normQuantity === 1) {
        return `${user.formatQuantity(normQuantity)} ${uomOptionPackage[uom][0]}`
      } else {
        return `${user.formatQuantity(normQuantity)} ${uomOptionPackage[uom][1]}`
      }
    }
    return ''
  }

  const onAddClick = () => {
    setOpenItem(true)
  }

  const onRowClick = (item) => {
    setOpenItem(item.id)
  }

  const showItem = Boolean(openItem) && {
    item: props.item,
    counting: props.item.counting.find((x) => x.id === openItem),
  }

  const onItemDeleteClick = (e, item) => {
    e.stopPropagation()
    setDeleteState({
      name: item.quantity.toString(),
      variables: { id: item.id },
    })
  }

  return (
    <>
      <Dialog
        open={Boolean(props.item)}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.item.stockName.toUpperCase()}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell size="small" padding="none" />
                  <TableCell size="small">{user.translate('entered')}</TableCell>
                  {/* <TableCell size="small">{user.translate("time")}</TableCell> */}
                  <TableCell size="small" align="right">
                    {user.translate('quantity').slice(0, 3)} ({uomName})
                  </TableCell>
                  <TableCell size="small" align="right">
                    {user.translate('price').slice(0, 4)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.item.counting.map((row) => (
                  <TableRow key={row.id} onClick={() => onRowClick(row)}>
                    <TableCell size="small" padding="none">
                      <IconButton size="small" onClick={(e) => onItemDeleteClick(e, row)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                    {/* <TableCell size="small" component="th" scope="row">
                                        <Typography variant="caption">
                                            {new Date(parseInt(row.created)).toLocaleString()}
                                        </Typography>
                                    </TableCell> */}
                    <TableCell size="small" align="right">
                      {calcNormalizedQuantity(row.quantity, row.uomOpts)}
                    </TableCell>
                    <TableCell size="small" align="right">
                      {user.formatQuantity(row.quantity)}
                    </TableCell>
                    <TableCell size="small" align="right">
                      {user.formatQuantity(row.price)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          {Boolean(loadingSave) && <CircularProgress size={24} className={classes.loading} />}
          <Button onClick={handleClose} color="primary" disabled={loadingSave}>
            {user.translate('cancel')}
          </Button>
          <Button onClick={onAddClick} color="primary" autoFocus disabled={loadingSave}>
            {user.translate('add')}
          </Button>
        </DialogActions>
      </Dialog>
      {Boolean(showItem) && (
        <InventoryCountingItemDialog
          open={Boolean(showItem)}
          onClose={setOpenItem}
          onSuccess={props.onSuccess}
          item={showItem.item}
          counting={showItem.counting}
        />
      )}
      {Boolean(deleteState) && (
        <Delete
          name={user.translate('inventory_counting') + ' ' + deleteState.name}
          variables={deleteState.variables}
          setOpenDeleteBox={setDeleteState}
          deleteItem={DELETE_ITEM}
          onSuccess={props.onSuccess}
        />
      )}
    </>
  )
}
