import React, { useContext, useState } from 'react'

import { UserContext } from '../../App'
import Report from '../common/Report'

export default function KdfiReport(props) {
  const user = useContext(UserContext)
  const [page, setPage] = useState(0)

  const startDate = new Date();
    const endDate = new Date()
  startDate.setMonth(startDate.getMonth(), 1) // the first of this month
  // endDate.setMonth(endDate.getMonth(), 0); //- the last day of last month
  startDate.setHours('00')
  startDate.setMinutes('00')
  startDate.setSeconds('00')
  endDate.setHours('23')
  endDate.setMinutes('59')
  endDate.setSeconds('59')

  const params = {
    query: {
      name: 'report_dailyFinancialReport',
      dateInit: '$dateFrom: Long!, $dateTo: Long!',
      date: 'dateFrom: $dateFrom, dateTo: $dateTo',
      dateVars: {
        dateFrom: startDate.getTime(),
        dateTo: endDate.getTime(),
      },
      results: `
                date
                pos
                taxAmountDomesticA
                taxAmountDomesticB
                taxAmountDomesticC
                taxAmountDomesticD
                taxAmountDomesticE
                totalIncome
                totalIncomeByTaxA
                totalIncomeByTaxB
                totalIncomeByTaxC
                totalIncomeByTaxD
                totalIncomeByTaxE
                totalIncomeDomestic
                totalTax
                totalTaxByRateA
                totalTaxByRateB
                totalTaxByRateC
                totalTaxByRateD
                totalTaxByRateE
                totalTaxDomestic
            `,
      columnsPush: ['id'],
    },
    title: user.translate('kdfi_report'),
    titleName: user.translate('kdfireport'),
    key: 'kdfi_report_',
    page,
    setPage,
    datetime: true,
    header: true,
    headerNumbers: true,
    exportOrientation: 'landscape',
    mapData: (items) => {
      let taxAmountDomesticA = 0
      let taxAmountDomesticB = 0
      let taxAmountDomesticC = 0
      let taxAmountDomesticD = 0
      let totalIncomeByTaxA = 0
      let totalIncomeByTaxB = 0
      let totalIncomeByTaxC = 0
      let totalIncomeByTaxD = 0
      let totalTaxByRateA = 0
      let totalTaxByRateB = 0
      let totalTaxByRateC = 0
      let totalTaxByRateD = 0
      let totalIncomeDomestic = 0
      let totalIncome = 0
      let totalTax = 0
      let totalTaxDomestic = 0

      items.forEach((item) => {
        taxAmountDomesticA += user.parseStringNumber(item.taxAmountDomesticA)
        taxAmountDomesticB += user.parseStringNumber(item.taxAmountDomesticB)
        taxAmountDomesticC += user.parseStringNumber(item.taxAmountDomesticC)
        taxAmountDomesticD += user.parseStringNumber(item.taxAmountDomesticD)
        totalIncomeByTaxA += user.parseStringNumber(item.totalIncomeByTaxA)
        totalIncomeByTaxB += user.parseStringNumber(item.totalIncomeByTaxB)
        totalIncomeByTaxC += user.parseStringNumber(item.totalIncomeByTaxC)
        totalIncomeByTaxD += user.parseStringNumber(item.totalIncomeByTaxD)
        totalTaxByRateA += user.parseStringNumber(item.totalTaxByRateA)
        totalTaxByRateB += user.parseStringNumber(item.totalTaxByRateB)
        totalTaxByRateC += user.parseStringNumber(item.totalTaxByRateC)
        totalTaxByRateD += user.parseStringNumber(item.totalTaxByRateD)
        totalIncomeDomestic += user.parseStringNumber(item.totalIncomeDomestic)
        totalIncome += user.parseStringNumber(item.totalIncome)
        totalTax += user.parseStringNumber(item.totalTax)
        totalTaxDomestic += user.parseStringNumber(item.totalTaxDomestic)
      })
      const total = {
        isTotal: true,
        date: user.translate('total'),
        pos: '',
        taxAmountDomesticA: user.formatNumber(taxAmountDomesticA),
        taxAmountDomesticB: user.formatNumber(taxAmountDomesticB),
        taxAmountDomesticC: user.formatNumber(taxAmountDomesticC),
        taxAmountDomesticD: user.formatNumber(taxAmountDomesticD),
        totalIncomeByTaxA: user.formatNumber(totalIncomeByTaxA),
        totalIncomeByTaxB: user.formatNumber(totalIncomeByTaxB),
        totalIncomeByTaxC: user.formatNumber(totalIncomeByTaxC),
        totalIncomeByTaxD: user.formatNumber(totalIncomeByTaxD),
        totalTaxByRateA: user.formatNumber(totalTaxByRateA),
        totalTaxByRateB: user.formatNumber(totalTaxByRateB),
        totalTaxByRateC: user.formatNumber(totalTaxByRateC),
        totalTaxByRateD: user.formatNumber(totalTaxByRateD),
        totalIncomeDomestic: user.formatNumber(totalIncomeDomestic),
        totalIncome: user.formatNumber(totalIncome),
        totalTax: user.formatNumber(totalTax),
        totalTaxDomestic: user.formatNumber(totalTaxDomestic),
      }
      return [...items, total]
    },
    rowStyle: (row) => {
      if (row.isTotal) {
        return {
          background: '#CCC',
          fontWeight: 600,
          height: 24,
          borderTop: '2px solid #333',
          borderBottom: '2px solid #333',
        }
      }

      return undefined
    },
  }

  return <Report {...params} />
}
