import { useContext, useState } from 'react'

import { gql, useMutation } from '@apollo/client'

import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'

import { UserContext } from '../App'

export default function RequestPassword(props) {
  const user = useContext(UserContext)

  const [requestIt, setRequestIt] = useState(false)

  const REQUEST = gql`
    mutation {
      auth_requestResetPassword {
        message
        ok
      }
    }
  `

  const [requestPassword, { loading: loading_requestPass, data: data_requestPass }] = useMutation(REQUEST)

  const handleClose = () => {
    props.refetch()
    props.setOpenPassword(false)
  }

  const handleRequestAccept = () => {
    requestPassword()
    setRequestIt(true)
  }

  let message = ''

  if (requestIt && data_requestPass) {
    message = data_requestPass.auth_requestResetPassword.message
  }

  return (
    <>
      <Dialog open={!requestIt} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {props.message} {props.name}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {user.translate('cancel')}
          </Button>
          <Button onClick={handleRequestAccept} color="primary" autoFocus>
            {user.translate('accept')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={requestIt && data_requestPass}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {user.translate('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
