import React from 'react'

import EditText from './EditText'

export default function EditTextInt(props) {
  return <EditText {...props} intNumber />
}

EditTextInt.gql = 'Long'

EditTextInt.validate = (props, value) => {
  if (props.minValue !== undefined && value < props.minValue) return true
  if (props.maxValue !== undefined && value > props.maxValue) return true
  return false
}
