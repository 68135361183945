import React, { useContext, useState } from 'react'

import { UserContext } from '../../../App'
import Report from '../../common/Report'

// React Function Component
export default function InventoryOutput(props) {
  const user = useContext(UserContext)
  const [page, setPage] = useState(0)

  const startDate = new Date();
    const endDate = new Date()
  startDate.setMonth(startDate.getMonth(), 1) // the first of this month
  // endDate.setMonth(endDate.getMonth(), 0); //- the last day of last month
  startDate.setHours('00')
  startDate.setMinutes('00')
  startDate.setSeconds('00')
  endDate.setHours('23')
  endDate.setMinutes('59')
  endDate.setSeconds('59')

  const params = {
    query: {
      name: 'report_stockItemsSells',
      dateInit: '$dateFrom: Long!, $dateTo: Long!',
      date: 'dateFrom: $dateFrom, dateTo: $dateTo',
      dateVars: {
        dateFrom: startDate.getTime(),
        dateTo: endDate.getTime(),
      },
      results: `
                pos
                posId
                quantity
                stockType
                stockTypeId
                taxType
                taxTypeId
                uom
                warehouse
                warehouseId
                prodDepartment
            `,
      columnsPush: ['posId', 'stockTypeId', 'taxTypeId', 'warehouseId'],
    },
    title: user.translate('inventory_output'),
    titleName: user.translate('inventoryoutput'),
    InventoryAdjustments: user.role > 2,
    key: 'inventory_output_',
    page,
    setPage,
    datetime: true,
  }

  return <Report {...params} />
}
