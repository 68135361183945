import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'

export default function SnackBarAdd(props) {
  const handleClose = () => {
    props.setOpenSnackBar(false)
  }

  return (
    <Snackbar
      open={props.openSnackBar}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={handleClose}
      TransitionProps={{
        onExited: handleClose,
      }}
    >
      <Alert elevation={6} variant="filled" onClose={handleClose} severity="success">
        {props.message}
      </Alert>
    </Snackbar>
  )
}
