import { useContext, useState } from 'react'

import { Link, useLocation, useNavigate } from 'react-router-dom'

import { gql, useMutation, useQuery } from '@apollo/client'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import DescriptionIcon from '@mui/icons-material/Description'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import PrintIcon from '@mui/icons-material/Print'
import { CircularProgress, IconButton, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { RestaurantContext, UserContext } from '../../App'
import helper from '../common/Helper'
import ProgressDialog from '../common/ProgressDialog'
import ReceivingHeader from '../common/ReceivingHeader'
import SnackBarAdd from '../common/SnackBarAdd'
import SnackBarDelete from '../common/SnackBarDelete'
import Table from '../common/Table'
import tableComponents from '../common/TableComponents'
import useImperativeQuery from '../config/useImperativeQuery'

const useStyles = makeStyles((theme) => ({
  roots: {
    position: 'relative',
    '& .MuiTableCell-root .MuiIconButton-root.MuiIconButton-colorInherit, & .MuiTableCell-root .MuiIconButton-root.MuiIconButton-colorPrimary':
      {
        padding: 4,
      },
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  extraTableName: {
    color: 'inherit',
    position: 'relative',
    zIndex: 99,
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
}))

// React Function Component
export default function StockSendingList(props) {
  const classes = useStyles()

  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const location = useLocation()
  const navigate = useNavigate()

  const path = new URLSearchParams(location.search)

  const _rowDataItem = {}
  path.forEach(function (value, key) {
    if (key === 'stockReceivingId') _rowDataItem.stockReceivingId = value
    if (key === 'invoiceId') _rowDataItem.invoiceId = value
    if (key === 'sendingNumber') _rowDataItem.sendingNumber = value
    if (key === 'invoiceNumber') _rowDataItem.invoiceNumber = value
    if (key === 'receivingNumber') _rowDataItem.receivingNumber = value
  })

  const [rowDataItem, setRowDataItem] = useState(_rowDataItem.stockReceivingId ? _rowDataItem : false)
  if (rowDataItem && !_rowDataItem.stockReceivingId) {
    setRowDataItem(false)
  }

  const [page, setPage] = useState(0)
  const [openAdd, setOpenAdd] = useState(false)

  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [openSnackBarAdd, setOpenSnackBarAdd] = useState(false)
  const [deleteState, setDeleteState] = useState({
    variables: {},
    name: '',
    state: {},
  })

  const [progress, setProgress] = useState(-1)

  const startDate = new Date()
  const endDate = new Date()
  startDate.setMonth(startDate.getMonth() - 1, 1) // the first of last month
  // endDate.setMonth(endDate.getMonth(), 0); //- the last day of last month

  startDate.setHours(0)
  startDate.setMinutes(0)
  startDate.setSeconds(0)
  startDate.setMilliseconds(0)
  endDate.setHours(23)
  endDate.setMinutes(59)
  endDate.setSeconds(59)
  endDate.setMilliseconds(999)

  const filterState = location.state || {
    selectedDateFrom: startDate,
    selectedDateTo: endDate,
  }
  const setFilterState = (state) => {
    navigate('', { state })
  }

  const EDIT_ITEM = gql`
    mutation (
      $id: String!
      $companyId: String!
      $senderId: String!
      $employeeId: String!
      $sendingNumber: String!
      $invoiceNumber: String!
      $created: Long!
      $updated: Long!
    ) {
      createReceivingStock(
        input: {
          id: $id
          companyId: $companyId
          senderId: $senderId
          employeeId: $employeeId
          sendingNumber: $sendingNumber
          invoiceNumber: $invoiceNumber
          created: $created
          updated: $updated
          direction: "SND"
        }
      ) {
        id
      }
    }
  `
  const [editItem, { loading: loading_edit }] = useMutation(EDIT_ITEM)

  const FINISH_RECEIVING_STOCK = gql`
    mutation ($receivingStockId: String!) {
      extras_finishReceivingStock(receivingStockId: $receivingStockId)
    }
  `
  const [finishReceivingStock, { loading: loading_finish }] = useMutation(FINISH_RECEIVING_STOCK)

  const DELETE_ITEM = gql`
    mutation ($id: String!) {
      deleteReceivingStock(id: $id)
    }
  `
  const [deleteItem, { loading: loading_delete }] = useMutation(DELETE_ITEM)

  const DELETE_INVOICE = gql`
    mutation ($id: String!, $invoiceId: String!) {
      deleteReceivingStock(id: $id)
      deleteInvoice(id: $invoiceId)
    }
  `
  const [deleteInvoice, { loading: loading_delete_invoice }] = useMutation(DELETE_INVOICE)

  const UNDELETE_ITEM = `
    mutation ($id: String!) {
        undeleteReceivingStock(id: $id)
    }`

  const ADD_INVOICE = gql`
    mutation (
      $restaurantId: String!
      $payeeCompanyId: String!
      $invoiceNumber: String
      $issuingDate: Long
      $dueToDate: Long
      $bookDate: Long
      $amount: Float!
      $description: String
      $status: String!
      $isMaterial: Boolean
    ) {
      extras_createInvoice(
        fromRestaurantId: $restaurantId
        toRestaurantId: $payeeCompanyId
        invoiceNumber: $invoiceNumber
        amount: $amount
        description: $description
        issuingDate: $issuingDate
        dueToDate: $dueToDate
        bookDate: $bookDate
        status: $status
        isMaterial: $isMaterial
      )
    }
  `
  const [addInvoice, { data: data_add_invoice, loading: loading_add_invoice }] = useMutation(ADD_INVOICE)

  const EDIT_STOCK_INVOICE = gql`
    mutation ($id: String!, $invoiceId: String!) {
      editReceivingStock(input: { id: $id, invoiceId: $invoiceId }) {
        id
      }
    }
  `
  const [editStock, { loading: loading_edit_stock }] = useMutation(EDIT_STOCK_INVOICE)

  const EDIT_ITEM_INVOICE = gql`
        mutation (
            $receivingItemId: String!, 
            $invoiceItemId: String!, 
            $invoiceId: String!, 
            $article: String!, 
            $description: String!, 
            $quantity: Float!, 
            $price: Float!, 
            $tax: Float!, 
            $discount: Float! 
            ) {
            createInvoiceItem(input: {
                id: $invoiceItemId
                ${user.gqlCreateName('$article', false, 'Article')}
                ${user.gqlCreateName('$description', false, 'Description')}
                quantity: $quantity
                price: $price
                tax: $tax
                discount: $discount
                invoiceId: $invoiceId
            }){
                id
            }
            createInvoiceItemReceivingItem(input: {
                invoiceItemId: $invoiceItemId,
                receivingItemId: $receivingItemId
            }) {
                invoiceItemId
            }
        }
    `
  const [editItemInvoice, { loading: loading_edit_invoice }] = useMutation(EDIT_ITEM_INVOICE)

  // GraphQL API request definition (local variables: restaurantId)
  const GET_RECEIVING_ITEMS = gql`
	query ($receivingStockId: String!) {
        getReceivingStockItemsByReceivingStockId(receivingStockId: $receivingStockId) {
            id
            quantity
            price
            tax
            discount
            uomOpts
            stockType {
                id
                name {
                    ${user.gqlFetchName()}
                }
                ingredient
                uom
            }
            receivingStockId
        }
	}`

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const {
    data: data_stockReceivingItems,
    loading: loading_stockReceivingItems,
    get: getReceivingItems,
  } = useImperativeQuery(GET_RECEIVING_ITEMS)

  const GET_COMMISSIONERS_BY_RESTAURANT = gql`
    query ($restaurantId: String!){
        getCommissionersByRestaurantId(restaurantId: $restaurantId) {
            commissioner {
                id
                name {
                    ${user.gqlFetchName()}
                }
            }
        }
        getRestaurantEmployeesByContextRestaurantId(restaurantId: $restaurantId) {
			employee {
				id
				profile {
                    _id
					name
					email
				}
			}
      	}
    }`

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const { data: data_commissioners, loading: loading_commissioners } = useQuery(GET_COMMISSIONERS_BY_RESTAURANT, {
    variables: { restaurantId: restaurant.id },
    pollInterval: 30000,
  })

  const { data, loading, error, refetch } = useQuery(
    gql`
        query ($restaurantId: String!){
            getReceivingStocksByContextRestaurantId(restaurantId: $restaurantId, filter: {
                by: "created"
                gte: ${filterState.selectedDateFrom?.getTime() || startDate.getTime()}
                lte: ${filterState.selectedDateTo?.getTime() || endDate.getTime()}
                and: { by: "direction", eqStr: "SND" }
            }) {
                id
                company {
                    id
                    name {
                        ${user.gqlFetchName()}
                    }
                }
                sender {
                    id
                    name {
                        ${user.gqlFetchName()}
                    }
                }
                employee {
                    id
                    profile {
                        _id
                        name
                        email
                    }
                }
                sendingNumber
                invoiceNumber
                receivingNumber
                invoiceId
                created
                updated
                lastTimePrinted
                issuingDate
            }
        }
    `,
    {
      variables: { restaurantId: restaurant.id },
      pollInterval: 5000,
    },
  )

  // If it is loading, show progress bar
  if (loading && !data) {
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    )
  }

  // In case there is an error, just show it for now
  if (!data) {
    user.consoleLog(error)
    return <p>Error</p>
  }

  const fieldList = {}
  let restaurants
  let employees
  const restaurantsFieldOptions = []
  const tempRestaurants = []
  const employeesFieldOptions = []
  const tempEmployees = []

  const stockReceiving = data.getReceivingStocksByContextRestaurantId

  if (data_commissioners) {
    restaurants = data_commissioners.getCommissionersByRestaurantId
    employees = data_commissioners.getRestaurantEmployeesByContextRestaurantId
  }

  for (const index in restaurants) {
    const commissioner = restaurants[index].commissioner
    if (commissioner && tempRestaurants.indexOf(commissioner.id) === -1) {
      restaurantsFieldOptions.push({
        id: commissioner.id,
        name: commissioner.name[user.lang],
      })
      tempRestaurants.push(commissioner.id)
    }
  }

  for (const indexx in employees) {
    if (employees[indexx]?.employee) {
      if (tempEmployees.indexOf(employees[indexx].employee.id) === -1) {
        employeesFieldOptions.push({
          id: employees[indexx].employee.id,
          name: employees[indexx].employee.profile ? employees[indexx].employee.profile.name : '',
        })
        tempEmployees.push(employees[indexx].employee.id)
      }
    }
  }

  const firstRestaurantId = restaurantsFieldOptions.slice(0, 1)[0]

  const onCreateInvoiceClick = (rowData) => {
    setProgress(0)
    return getReceivingItems({ receivingStockId: rowData.id }).then((receivingData) => {
      setProgress(2)
      const receivingItems = receivingData.getReceivingStockItemsByReceivingStockId
      const totalAmount = receivingItems
        .sumOf((item) => {
          const quantity = -(item.stockType.uom === 2 ? item.quantity || 0 : item.quantity / 1000)
          const tax = item.tax
          const price = item.price * (1 - item.discount)
          const subTotal = price * quantity
          const totalVat = tax * price * quantity
          return subTotal + totalVat
        })
        .round(0)

      return addInvoice({
        variables: {
          restaurantId: restaurant.id,
          payeeCompanyId: rowData.senderId,
          invoiceNumber: rowData.invoiceNumber,
          issuingDate: new Date(rowData.created).getTime(),
          dueToDate: new Date(rowData.created).getTime(),
          bookDate: new Date(rowData.updated).getTime(),
          amount: totalAmount,
          description: ' ',
          status: 'CREATE',
        },
      })
        .then(({ data: invoiceData }) => {
          const invoiceId = invoiceData.extras_createInvoice

          if (receivingItems.length === 0) {
            setProgress(50)
            return editStock({
              variables: {
                id: rowData.id,
                invoiceId,
              },
            }).then((value) => {
              setProgress(-1)
              return value
            })
          }

          setProgress(5)
          let count = 1
          return receivingItems
            .reducePromise((item) =>
              editItemInvoice({
                variables: {
                  invoiceItemId: helper.uid(),
                  receivingItemId: item.id,
                  invoiceId,
                  article: item.stockType.name[user.lang],
                  description: '',
                  quantity: -(item.stockType.uom === 2 ? item.quantity || 0 : item.quantity / 1000),
                  price: item.price,
                  tax: item.tax,
                  discount: item.discount ? item.discount / 100 : 0,
                },
              }).then((value) => {
                setProgress(5 + (count++ * 95) / receivingItems.length)
                return value
              }),
            )
            .then(() => {
              return editStock({
                variables: {
                  id: rowData.id,
                  invoiceId,
                },
              })
            })
        })
        .then(() => setProgress(100))
        .then(refetch)
        .then(() => setProgress(-1))
    })
  }

  const onRowClick = (rowData) => {
    navigate(`/restaurant/${restaurant._id}/stock_sending/${rowData.id}`, { state: { from: 'sendingList' } })
  }

  const onSaveItem = (newData, state) =>
    editItem({
      variables: {
        ...newData,
        companyId: restaurant.id,
        id: newData.id ? newData.id : helper.uid(),
        senderId: newData.senderId ? newData.senderId : firstRestaurantId,
        employeeId: newData.employeeId ? newData.employeeId : user.me.id,
        created: newData.created ? new Date(newData.created).getTime() : new Date().getTime(),
        updated: newData.updated ? new Date(newData.updated).getTime() : new Date().getTime(),
        sendingNumber: '',
        invoiceNumber: '',
      },
    })
      .then(refetch)
      .then(() => {
        if (newData.id === undefined) {
          setPage(Math.round((stockReceiving.length + 1) / 50))
        }
      })

  const onDeleteItem = (oldData, state) => {
    const vars = {
      id: oldData.id,
      invoiceId: oldData.invoiceId,
    }

    const mutation = oldData.invoiceId != null ? deleteInvoice : deleteItem

    return mutation({ variables: vars }).then(() => {
      setDeleteState({
        variables: vars,
        name: `${oldData.sender} ${oldData.sendingNumber}`,
        state,
      })
      setOpenSnackBar(true)
    })
  }

  const onClosePlt = (id) => {
    return finishReceivingStock({
      variables: {
        receivingStockId: id,
      },
    })
      .then(refetch)
      .catch(console.log)
  }

  fieldList.columns = [
    { width: '1%', cellStyle: { whiteSpace: 'nowrap' }, title: 'Id', field: 'id', hidden: true },
    {
      width: '1%',
      cellStyle: { whiteSpace: 'nowrap' },
      title: 'companyId',
      field: 'companyId',
      hidden: true,
      export: false,
    },
    {
      width: '1%',
      cellStyle: { whiteSpace: 'nowrap' },
      title: 'senderId',
      field: 'senderId',
      hidden: true,
      export: false,
    },
    {
      width: '1%',
      cellStyle: { whiteSpace: 'nowrap' },
      title: 'employeeId',
      field: 'employeeId',
      hidden: true,
      export: false,
    },
    {
      width: '1%',
      cellStyle: { whiteSpace: 'nowrap' },
      title: 'invoiceId',
      field: 'invoiceId',
      hidden: true,
      export: false,
    },
    {
      width: '1%',
      cellStyle: { whiteSpace: 'nowrap' },
      title: 'created',
      field: 'created',
      hidden: true,
      export: false,
    },
    {
      width: '1%',
      cellStyle: { whiteSpace: 'nowrap' },
      title: 'updated',
      field: 'updated',
      hidden: true,
      export: false,
    },
    {
      title: '',
      field: 'count',
      editable: 'never',
      cellStyle: { width: '1%', padding: `0 5px 0 0`, fontSize: 12, whiteSpace: 'pre-line' },
    },
    {
      title: user.translate('komitent'),
      field: 'sender',
      cellStyle: { width: '10%', padding: 0, fontSize: 14, whiteSpace: 'pre-line' },
      editComponent: (props) => tableComponents.AutoComplete(props, restaurantsFieldOptions, 'senderId', 'sender'),
      customFilterAndSearch: (filterValue, row) => {
        return (
          (row.sender && row.sender.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
          (row.receiver && row.receiver.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
          (row.invoiceId && user.translate('invoice').cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1)
        )
      },
      customSort: (a, b) => a.sender.cirilicLatinCompare(user.lang).localeCompare(b.sender.cirilicLatinCompare(user.lang), user.lang),
    },
    {
      title: user.translate('sending_no'),
      field: 'receivingNumber',
      editable: 'never',
      cellStyle: { width: '5%', padding: 0, fontSize: 12, whiteSpace: 'nowrap' },
    },
    {
      title: user.translate('date'),
      field: 'issuingDate',
      cellStyle: { width: '5%', padding: `0`, fontSize: 12, whiteSpace: 'nowrap' },
      editable: 'never',
    },
    { title: user.translate('invoice_no'), field: 'invoiceNumber', value: '', hidden: true },
    {
      title: user.translate('issued_byl'),
      field: 'employee',
      editable: 'never',
      cellStyle: { width: '5%', padding: 0, fontSize: 12, whiteSpace: 'nowrap' },
    },
    {
      title: user.translate('created'),
      field: 'createdLabel',
      editComponent: (props) => tableComponents.DateTimeEditComponent(props, 'created'),
      cellStyle: { width: '5%', padding: `0`, fontSize: 12, whiteSpace: 'nowrap' },
    },
    {
      title: user.translate('updated'),
      field: 'updatedLabel',
      editComponent: (props) => tableComponents.DateTimeEditComponent(props, 'updated'),
      cellStyle: { width: '5%', padding: `0`, fontSize: 12, whiteSpace: 'nowrap' },
    },
    {
      title: user.translate('invoice'),
      field: 'hasInvoice',
      render: (rowData) => {
        if (rowData.invoiceId)
          return (
            <Tooltip title={`${user.translate('open_invoice')}`} placement="top">
              <IconButton
                color="primary"
                to={`/restaurant/${restaurant._id}/invoice_output/${rowData.invoiceId}/`}
                component={Link}
                size="large"
              >
                <DescriptionIcon />
              </IconButton>
            </Tooltip>
          )
        else
          return (
            <Tooltip title={`${user.translate('create_invoice')}`} placement="top">
              <IconButton color="primary" onClick={(e) => onCreateInvoiceClick(rowData)} size="large">
                <NoteAddIcon />
              </IconButton>
            </Tooltip>
          )
      },
      editable: 'never',
      export: false,
      disableClick: true,
      cellStyle: { width: '5%', padding: 0, fontSize: 12, whiteSpace: 'pre-line' },
    },
    {
      title: user.translate('plt_report'),
      field: 'plt',
      render: (rowData) =>
        rowData.receivingNumber ? (
          <Tooltip title={`${user.translate('plt_report')}`} placement="top">
            <IconButton
              color="primary"
              to={`/restaurant/${restaurant._id}/plt_report/${rowData.id}`}
              /// ${stockReceiving.id}/
              component={Link}
              size="large"
            >
              <PrintIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={`${user.translate('close')}`} placement="top">
            <IconButton color="primary" onClick={(e) => onClosePlt(rowData.id)} size="large">
              <CancelPresentationIcon />
            </IconButton>
          </Tooltip>
        ),
      editable: 'never',
      export: false,
      disableClick: true,
      cellStyle: { width: '5%', padding: 0, fontSize: 12, whiteSpace: 'pre-line' },
    },
    {
      title: user.translate('items'),
      field: 'items',
      editable: 'never',
      render: (rowData) => (
        <Tooltip title={`${user.translate('items')}`} placement="top">
          <IconButton color="primary" onClick={(event) => onRowClick(rowData)} size="large">
            <ArrowForwardIcon />
          </IconButton>
        </Tooltip>
      ),
      sorting: false,
      export: false,
      disableClick: true,
      cellStyle: { width: '5%', padding: 0, fontSize: 12, whiteSpace: 'pre-line' },
    },
  ]

  fieldList.data = stockReceiving
    .orderBy((item) => item.created)
    .map((result, index) => ({
      ...result,
      count: index + 1 + '.',
      id: result.id,
      invoiceId: result.invoiceId,
      senderId: result.sender.id,
      sender: result.sender.name[user.lang],
      companyId: result.company.id,
      employeeId: user.me.id,
      employee: result.employee.profile.name,
      receivingNumber: result.receivingNumber ? result.receivingNumber : undefined,
      lastTimePrinted: result.lastTimePrinted ? user.formatDate(parseInt(result.lastTimePrinted), true) : undefined,
      issuingDate: result.issuingDate ? user.formatDate(parseInt(result.issuingDate), true) : undefined,
      createdLabel: result.created ? user.formatDate(result.created, true) : '',
      updatedLabel: result.updated ? user.formatDate(result.updated, true) : '',
    }))

  const tableName = user.translate('stock_sending')

  return (
    <div className={classes.roots}>
      <div className={classes.table}>
        <ReceivingHeader filterState={filterState} setFilterState={setFilterState} setQuery={setFilterState} />

        <Table
          key={helper.hash(user.lang + page + tableName)}
          tableName={tableName}
          exportFileName={user.translate('stockreceiving')}
          fieldList={fieldList}
          pageSize="50"
          pageSizeOptions={[50, 100, 200]}
          page={page}
          onAddItem={onSaveItem}
          onEditItem={onSaveItem}
          onDeleteItem={onDeleteItem}
          onRowClick={onRowClick}
          openAdd={openAdd}
          setOpenAdd={setOpenAdd}
          receivingList
          hidden={rowDataItem}
        />
      </div>

      {openSnackBar && (
        <SnackBarDelete
          hideUndo
          message={`${deleteState.name} 
                    ${user.translate('deleted')}!`}
          variables={deleteState.variables}
          openSnackBar={openSnackBar}
          setOpenSnackBar={setOpenSnackBar}
          unDeleteItem={UNDELETE_ITEM}
        />
      )}

      {openSnackBarAdd && (
        <SnackBarAdd
          message={`${user.translate('invoice')} ${user.translate('saved')}!`}
          openSnackBar={openSnackBarAdd}
          setOpenSnackBar={setOpenSnackBarAdd}
        />
      )}
      <ProgressDialog show={progress >= 0} progress={progress} />
    </div>
  )
}
