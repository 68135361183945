const configs = {
  local: {
    apiUrl: 'http://192.168.100.112:9080',
    apiImagesUrl: 'http://192.168.100.112:9080/image',
    scanQrUrl: 'http://192.168.100.112:9080/qr',
    fbAppId: '1436570349884735',
    googleClientId: '941778011187-cdegm98n6t45m4uobjp8ga7olti3m04h.apps.googleusercontent.com',
    isTest: true,
  },
  localSSL: {
    apiUrl: 'https://local-qr.ebar.mk:9083',
    apiImagesUrl: 'https://local-qr.ebar.mk:9083/image',
    scanQrUrl: 'https://192.168.100.112:9083/qr',
    fbAppId: '1436570349884735',
    googleClientId: '941778011187-cdegm98n6t45m4uobjp8ga7olti3m04h.apps.googleusercontent.com',
    isTest: true,
  },
  dev: {
    apiUrl: 'https://dev-api.eBar.mk',
    apiImagesUrl: 'https://dev-images.eBar.mk',
    scanQrUrl: 'https://dev-q.eBar.mk',
    fbAppId: '1436570349884735',
    googleClientId: '941778011187-cdegm98n6t45m4uobjp8ga7olti3m04h.apps.googleusercontent.com',
    isTest: true,
  },
  test: {
    apiUrl: 'https://test-api.eBar.mk',
    apiImagesUrl: 'https://test-images.eBar.mk',
    scanQrUrl: 'https://test-q.eBar.mk',
    fbAppId: '1436570349884735',
    googleClientId: '941778011187-cdegm98n6t45m4uobjp8ga7olti3m04h.apps.googleusercontent.com',
    isTest: true,
  },
  production: {
    apiUrl: 'https://api.ebar.mk',
    apiImagesUrl: 'https://images.ebar.mk',
    scanQrUrl: 'https://q.ebar.mk',
    fbAppId: '1436570349884735',
    googleClientId: '941778011187-cdegm98n6t45m4uobjp8ga7olti3m04h.apps.googleusercontent.com',
    isTest: false,
  },
}

const config = configs[process.env.REACT_APP_STAGE]

export default config
