import React, { useContext, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import makeStyles from '@mui/styles/makeStyles'

import { UserContext } from '../../App'
import Report from '../common/Report'

const useStyles = makeStyles((theme) => ({
  root: {
    // '& #table-title': {
    //     maxHeight: 34
    // }
  },
}))
export default function InventoryMeterReport(props) {
  const classes = useStyles()
  const user = useContext(UserContext)
  const { counterId } = useParams()
  const [page, setPage] = useState(0)

  const navigate = useNavigate()

  const startDate = new Date()
  const endDate = new Date()
  startDate.setMonth(startDate.getMonth(), 1)
  startDate.setHours(0, 0, 0, 0)
  endDate.setHours(23, 59, 59)

  const params = {
    query: {
      name: 'report_inventoryCounterReport',
      dateInit: '$dateFrom: Long!, $dateTo: Long!',
      date: 'dateFrom: $dateFrom, dateTo: $dateTo',
      dateVars: {
        dateFrom: startDate.getTime(),
        dateTo: endDate.getTime(),
      },
      results: `
                id
                timeFrom
                timeTo
                fromTo
                name
                info
                counterDiff
                orderQuantity
                diff
                amount
            `,
      columnsPush: ['id'],
    },
    title: user.translate('turnover_report'),
    titleName: user.translate('turnoverreport'),
    key: 'turnover_report_',
    page,
    setPage,
    datetime: false,
    headerNumbers: false,
    exportOrientation: 'portrait',
    handleSelectAccept: (item) => navigate(`./${new Date(parseInt((item.timeFrom + item.timeTo) / 2)).toISODate()}`),
    rowStyle: (row) => {
      if (row.isTotal) {
        return {
          background: '#CCC',
          fontWeight: 600,
          height: 24,
          borderTop: '2px solid #333',
          borderBottom: '2px solid #333',
        }
      }

      return undefined
    },
    filter: { counter: counterId, grouping: 'daily' },
  }

  return (
    <div className={classes.root}>
      <Report {...params} noRowNum />
    </div>
  )
}
