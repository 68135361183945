import React, { useContext, useState } from 'react'

import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import InvoiceApplyBillTo from './InvoiceApplyBillTo'
import { UserContext } from '../../../App'
import FormInvoice from '../../common/FormInvoice'
import helper from '../../common/Helper'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    flexDirection: 'column',
    position: 'relative',
    top: -28,
    right: theme.spacing(4),
    alignItems: 'end',
  },
  billingStyles: {
    minWidth: 60,
  },
}))

export default function InvoiceBillTo(props) {
  const classes = useStyles()
  // Get current user preferences (language)
  const user = useContext(UserContext)
  const invoice = props.invoice

  const [fieldListBillTo, setFieldListBillTo] = useState([
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'id',
      fieldLabel: 'id',
      fieldValue: invoice.id,
    },
    {
      required: true,
      type: 'text',
      fieldType: 'text',
      fieldName: 'billTo',
      fieldLabel: user.translate('to'),
      fieldValue: invoice.billTo ? invoice.billTo[user.lang] : '',
      error: !invoice.billTo,
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'billToLangId',
      fieldLabel: 'billToLangId',
      fieldValue: invoice.billTo ? invoice.billTo.id : '',
    },
    {
      required: false,
      type: 'text',
      fieldType: 'text',
      fieldName: 'billToAddress',
      fieldLabel: user.translate('address'),
      fieldValue: invoice.billToAddress ? invoice.billToAddress[user.lang] : '',
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'billToAddressLangId',
      fieldLabel: 'billToAddressLangId',
      fieldValue: invoice.billToAddress ? invoice.billToAddress.id : '',
    },
    {
      required: false,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'billToRegNumber',
      fieldLabel: user.translate('billing_reg_no'),
      fieldValue: invoice.billToRegNumber ? invoice.billToRegNumber : '',
    },
  ])

  const setItems = (event, fieldName, fieldValue) => {
    let items = {};
      let field = {}
    const langId = fieldListBillTo.find((c) => c.fieldName === `${fieldName}LangId`)

    field = {
      fieldType: `$${fieldName}: String!`,
      field: fieldName,
    }

    if (typeof langId !== 'undefined') {
      items = {
        id: langId.fieldValue,
        [fieldName]: fieldValue,
      }
      props.onEditInvoice(items, field, true)
    } else {
      items = {
        id: invoice.id,
        [fieldName]: fieldValue,
      }
      props.onEditInvoice(items, field, false)
    }

    user.consoleLog(items)
  }

  const tabIndex = 16

  // styles={classes.billingStyles}

  return (
    <div className={classes.root}>
      <Typography component="span">
        {/* {user.translate("client")}: {invoice.billTo ? invoice.billTo[user.lang] : ""} &nbsp;&nbsp;&nbsp; */}
        {props.typeOutput && (
          <InvoiceApplyBillTo
            key={helper.hash('invoice_apply_billto_billing_' + JSON.stringify(fieldListBillTo))}
            invoice={invoice}
            fieldListBillTo={fieldListBillTo}
            setFieldListBillTo={setFieldListBillTo}
            onEditInvoiceBillTo={props.onEditInvoiceBillTo}
          />
        )}
      </Typography>

      {fieldListBillTo.map((item, index) => (
        <FormInvoice
          key={helper.hash('invoice_billing_' + item.fieldValue + index)}
          fieldList={fieldListBillTo}
          item={index}
          tabIndex={tabIndex}
          setFieldList={setFieldListBillTo}
          setItems={setItems}
          left
          styles={classes.billingStyles}
        />
      ))}
    </div>
  )
}
