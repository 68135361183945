import React, { useContext } from 'react'

import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material'
import Button from '@mui/material/Button'
import makeStyles from '@mui/styles/makeStyles'

import { UserContext } from '../../App'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      width: '90vw',
      maxWidth: 360,
    },
  },
}))

export default function ProgressDialog(props) {
  const user = useContext(UserContext)

  const classes = useStyles()

  const onCancel = (e) => {
    if (props.onCancel) {
      props.onCancel()
    }
    e.stopPropagation()
  }

  const variant = props.variant || (props.progress < 0 ? 'indeterminate' : 'determinate')

  return (
    <Dialog
      open={Boolean(props.show)}
      onClose={onCancel}
      className={classes.root}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <LinearProgress value={props.progress} variant={variant} color={props.color || 'primary'} />
      </DialogContent>
      <DialogActions>{props.cancelButton && <Button onClick={onCancel}>{user.translate('cancel')}</Button>}</DialogActions>
    </Dialog>
  )
}
