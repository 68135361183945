import React, { useContext, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { gql, useMutation } from '@apollo/client'
import DateFnsUtils from '@date-io/date-fns'

import {
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  TextField,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import makeStyles from '@mui/styles/makeStyles'
import { DateTimePicker } from '@mui/x-date-pickers'

import { RestaurantContext, UserContext } from '../../App'
import helper from '../common/Helper'


const useStyles = makeStyles((theme) => ({
  roots: {
    '& .MuiTableCell-root .MuiIconButton-root': {
      padding: theme.spacing(1),
    },
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'wrap',
    padding: 0,
    justifyContent: 'space-evenly',
  },
  item: {
    width: '100%',
    maxWidth: 320,
    minWidth: 280,
    margin: theme.spacing(1),
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '& > *': {
      height: 22,
      display: 'flex',
      alignItems: 'flex-end',
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  iconInactive: {
    marginRight: theme.spacing(1),
    opacity: 0.4,
  },
  checkedLabel: {
    marginRight: 0,
    '& .MuiFormControlLabel-label': {
      fontSize: 12,
    },
  },
  progress: {
    // paddingLeft: theme.spacing(1),
  },
  stateSection: {
    marginLeft: theme.spacing(2),
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  loading: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    margin: theme.spacing(2),
  },
  dialogContent: {
    minWidth: 330,
    [theme.breakpoints.down('md')]: {
      minWidth: 220,
    },
  },
}))

const DELETE_ITEM = `
mutation ($id: String!) {
    deleteInventoryCounting(id: $id)
}`

const GQL_INVENTORY_COUNTING_CREATE = gql`
  mutation (
    $id: String!
    $countingId: String!
    $stockType: String!
    $quantity: Float!
    $price: Float!
    $uomOpts: Long!
    $created: String!
  ) {
    createInventoryCountingItem(
      input: {
        id: $id
        countingId: $countingId
        stockTypeId: $stockType
        quantity: $quantity
        price: $price
        uomOpts: $uomOpts
        created: $created
      }
    ) {
      id
    }
  }
`

// React Function Component
export default function InventoryCountingItemDialog(props) {
  const classes = useStyles()

  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const navigate = useNavigate()

  const propsUom = props.counting?.uomOpts || 0
  const propsPrice = props.counting?.price || 0
  const propsTax = props.item?.tax || 0
  const propsQuantity = props.counting?.quantity || 0
  const propsQuantityNormalized =
    props.counting !== undefined
      ? (propsUom === 0 ? propsQuantity : propsUom === 1 ? propsQuantity / props.item.piece : propsQuantity / props.item.package)
          .round(3)
          .toString()
          .replace(',', '')
          .replace('.', ',')
      : ''
  console.log('propsPrice', propsPrice)
  const { restaurantId, inventoryCountingId } = useParams()
  const [priceVal, setPriceVal] = useState(propsPrice)
  const [quantityVal, setQuantityVal] = useState(propsQuantityNormalized)
  const [uomOption, setUomOption] = useState(propsUom)
  const [time, setTime] = useState(parseInt(props.counting?.created) || new Date().getTime())
  const [saveItem, { loading: loadingSave }] = useMutation(GQL_INVENTORY_COUNTING_CREATE)

  if (!props.item) return <></>

  const handleClose = () => {
    props.onClose(false)
  }

  const inputQuantity = user.parseStringNumber(quantityVal.trim().split(' ')[0])
  const quantity =
    !isNaN(inputQuantity) &&
    inputQuantity !== 0 &&
    (uomOption === 0 ? inputQuantity : uomOption === 1 ? inputQuantity * props.item.piece : inputQuantity * props.item.package)
      .round(3)
      .toString()

  const onUomOptionClick = (option) => {
    if (option === 0) setQuantityVal(quantity.toString())
    else if (option === 1) setQuantityVal((quantity / props.item.piece).round(3).toString())
    else setQuantityVal((quantity / props.item.package).round(3).toString())

    setUomOption(option)
  }

  const uomIndexes = {
    LITER: 0,
    KILOGRAM: 1,
    PIECE: 2,
  }

  const uom = uomIndexes[props.item.uom]

  const uomUnit = ['L', 'Kg', 'p']

  const uomOptions = [user.translate('liter'), user.translate('kilogram'), user.translate('piece')]

  const pieceQuantity = `${props.item.piece} ${uomUnit[uom]}`
  const uomOptionPiece = [
    { label: `${user.translate('dose')} (${pieceQuantity})` },
    { label: `${user.translate('dose')} (${pieceQuantity})` },
    { label: `${user.translate('dose')} (${pieceQuantity})` },
  ]

  const packageQuantity = `${props.item.package} ${uomUnit[uom]}`
  const uomOptionPackage = [
    { label: `${user.translate('package')} / ${user.translate('bottle')} (${packageQuantity})` },
    { label: `${user.translate('package')} / ${user.translate('bag')} (${packageQuantity})` },
    { label: `${user.translate('package')} / ${user.translate('box')} (${packageQuantity})` },
  ]

  const uomTitle = uomOptions[uom]
  const uomName = uomUnit[uom]
  const uomPiece = uomOptionPiece[uom]
  const uomPackage = uomOptionPackage[uom]

  const optionUomQuantity = [uomName, `(${pieceQuantity})`, `(${packageQuantity})`]
  const optionUomQuantityUom = [uomTitle, pieceQuantity.toString(), packageQuantity.toString()]

  const uomOptionName = optionUomQuantity[uomOption]
  const uomOptionNameUom = optionUomQuantityUom[uomOption]

  // let quantityNum = parseFloat(quantityVal);
  // let quantity = !isNaN(quantityNum) && quantityNum !== 0 && (
  //     uomOption === 0
  //         ? quantityNum
  //         : (uomOption === 1
  //             ? quantityNum / props.item.piece
  //             : quantityNum / props.item.package)).toString()

  const onSaveClick = () => {
    saveItem({
      variables: {
        id: props.counting?.id || helper.uid(),
        countingId: inventoryCountingId,
        stockType: props.item.id,
        quantity: quantity || 0,
        price: priceVal || 0,
        uomOpts: uomOption,
        created: time.toString(),
      },
    }).then((e) => {
      props.onSuccess()
      props.onClose(false)
    })
  }

  const dateTime = new Date(time)

  console.log('quantityVal', typeof quantityVal, quantityVal)
  return (
    <Dialog
      open={Boolean(props.item)}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.item.stockName.toUpperCase()}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <ButtonGroup size="small" color="primary" aria-label="outlined primary button group" disabled={loadingSave}>
          <Button variant={uomOption === 0 && 'contained'} onClick={() => onUomOptionClick(0)}>
            {uomName}
          </Button>
          <Button variant={uomOption === 1 && 'contained'} onClick={() => onUomOptionClick(1)}>
            {uomPiece.label}
          </Button>
          <Button variant={uomOption === 2 && 'contained'} onClick={() => onUomOptionClick(2)}>
            {uomPackage.label}
          </Button>
        </ButtonGroup>
        <Autocomplete
          fullWidth
          disabled={loadingSave}
          id="input-quantity"
          freeSolo
          options={[...Array(50).keys()].map((option) => `${option + 1} ${uomOptionName}`)}
          inputValue={quantityVal}
          onInputChange={(event, newInputValue, reason) => {
            if (reason === 'reset' && newInputValue.length === 0) return
            if (newInputValue.indexOf(uomOptionName) > -1) {
              setQuantityVal(parseFloat(newInputValue.split(' ')[0]).toString())
            } else {
              setQuantityVal(newInputValue)
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${user.translate('quantity')} (${uomOptionNameUom})`}
              margin="normal"
              variant="outlined"
              value={quantityVal}
              type="text"
            />
          )}
        />
        <DateTimePicker
          animateYearScrolling
          ampm={false}
          autoOk
          variant="inline"
          format={user.dateTimeFormat}
          id="datetime-local"
          value={new Date(time)}
          label={user.translate('time')}
          onKeyPress={(e) => {
            if (e.key === 'Enter') setTime(e.target.value.getTime())
          }}
          onChange={(date) => setTime(date.getTime())}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <br />
        <FormControl style={{ marginTop: 8 }} fullWidth>
          <InputLabel htmlFor="inputPrice">
            {user.translate('price_with_vat')} ({user.formatQuantity(propsTax * 100)}%)
          </InputLabel>
          <Input
            id="inputPrice"
            type="number"
            aria-describedby="price"
            defaultValue={priceVal}
            onChange={(e) => setPriceVal(parseFloat(e.target.value))}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        {Boolean(loadingSave) && <CircularProgress size={24} className={classes.loading} />}
        <Button onClick={handleClose} color="primary" disabled={loadingSave}>
          {user.translate('cancel')}
        </Button>
        <Button onClick={onSaveClick} color="primary" autoFocus disabled={loadingSave}>
          {user.translate('save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
