import React, { useContext, useMemo } from 'react'

import { EditText, GqlForm, SelectOption, FormField, GqlComponent, FilterField, FormSwitch } from '@zeroapi-io/zeroapireact'

import { Box, Divider, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { RestaurantContext, UserContext } from '../../App'
import { ImageUpload } from '../common/ImageUpload'

interface FormFieldProps extends FormField<string> {
  value?: string
}

export const LegalInfoField: GqlComponent<FormFieldProps, string> = ({ value, onChange, ...props }: FormFieldProps) => {
  const user = useContext(UserContext) as any

  const legalInfo = useMemo(() => {
    if (!value?.length) return {}

    return JSON.parse(value)
  }, [value])

  const onUpdate = (field: string) => (value: string) => {
    onChange?.(JSON.stringify({ ...legalInfo, [field]: value }))
  }

  return (
    <>
      <EditText {...props} gql="String" name="name" label={user.translate('name')} value={legalInfo.name} onChange={onUpdate('name')} />
      <EditText
        {...props}
        gql="String"
        name="vatin"
        label={user.translate('vat_no')}
        value={legalInfo.vatin}
        onChange={onUpdate('vatin')}
      />
      <EditText
        {...props}
        gql="String"
        name="address"
        label={user.translate('address')}
        value={legalInfo.address}
        onChange={onUpdate('address')}
      />
    </>
  )
}

export const ImageUploadField: GqlComponent<FormFieldProps, string> = ({ value, onChange, ...props }: FormFieldProps) => {
  return (
    <Box pt={1} display="flex" flexDirection="column" alignItems="center">
      <ImageUpload
        {...props}
        id={props.item?.id}
        style={{ width: 320, height: 320 }}
        imageStyle={{ width: '100%', height: 320 }}
        imageContainerStyle={{ borderWidth: 1, borderColor: 'black', borderStyle: 'solid' }}
        imageUrl={value}
        onImageUploaded={onChange}
        onRemove={() => onChange?.('')}
        alwaysShowActions
      />
    </Box>
  )
}

const DividerWithText = ({ label }: { label: string }) => {
  const user = useContext(UserContext) as any
  return (
    <Divider sx={{ m: 1, fontSize: 12 }} textAlign="center">
      {user.translate(label)}
    </Divider>
  )
}

const useStyles = makeStyles((theme) => ({
  form: {
    alignItems: 'stretch',
    flexDirection: 'column',
    '& > div': {
        display: 'inline-flex'
    }
  },
}))

const SUBSCRIPTIONS = ['NONE', 'FREE', 'BASIC', 'PREMIUM', 'CUSTOM']

export function RestaurantAdminTools(props: any) {
  const restaurant = useContext(RestaurantContext) as any
  const user = useContext(UserContext) as any

  const styles = useStyles()

  const subscriptions = useMemo(
    () =>
      SUBSCRIPTIONS.toMapBy(
        (it) => it,
        (it) => (it === 'NONE' ? user.translate('inactive') : user.translate(it)),
      ),
    [user],
  )

  return (
    <Box sx={{ p: 2 }} display="flex" flexDirection="row" alignItems="stretch" justifyContent="center">
      <Paper sx={{ maxWidth: 640, flex: 1, p: 1 }}>
        <GqlForm entity="Restaurant" className={styles.form} dense="dense" noDelete>
          <FilterField name="id" value={restaurant.id} />

          <SelectOption gql="String" name="subscription" label={user.translate('subscription')} options={subscriptions} />

          <DividerWithText label="legal_entity_info" />
          <SelectOption gql="String" name="lang" label={user.translate('language')} options={{ MK: 'Македонски', EN: 'English' }} />
          <SelectOption gql="String" name="currency" label={user.translate('currency')} options={{ MKD: 'Денари', EUR: 'Евра' }} />

          <LegalInfoField gql="String" name="legalInfo" />

          <DividerWithText label="logo" />
          <ImageUploadField gql="String" name="imageLogo" />

          <DividerWithText label="parameterizing" />
          <FormSwitch name="pltSalePrice" label={user.translate('plt_sale_price')} />
        </GqlForm>
      </Paper>
    </Box>
  )
}
