import React, { useContext } from 'react'

import { CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import MenuCategory from './MenuCategory'
import { RestaurantContext, UserContext } from '../../App'
import { useEntityRelationQuery } from '../common/useEntityQuery'

// CSS
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'start',
    padding: theme.spacing(1),
    // backgroundColor: theme.palette.background.paper,
  },
  children: {
    padding: 24,
  },
  tabs: {
    display: `grid`,
  },
  gridList: {
    flexGrow: 1,
    width: `100%`,
  },
}))

export default function MenuCategoriesList(props) {
  const classes = useStyles()

  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  if (props.isLoading) {
    return <CircularProgress />
  }

  const items = props.items

  if (!items) {
    return <div>No items</div>
  }

  return (
    <div className={classes.root}>
      {items.map((item) => (
        <MenuCategory key={item.id} item={item} />
      ))}
    </div>
  )
}
