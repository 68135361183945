import { useCallback, useContext, useMemo } from 'react'

import { Box } from '@mui/material'
import {
  EditText,
  FilterField,
  FormCountInfo,
  FormCreators,
  FormField,
  FormSwitch,
  GqlComponent,
  GqlTable,
  HiddenField,
  NestedItemForm,
  SelectOption,
  useZeroApiContext,
} from '@zeroapi-io/zeroapireact'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../App'

interface FormFieldProps extends FormField<string> {
  value?: string
}

export const LoyaltyProgramConfigField: GqlComponent<FormFieldProps, string> = ({ value, onChange, ...props }: FormFieldProps) => {
  const config = useMemo(() => {
    if (!value?.length) return {}

    return JSON.parse(value)
  }, [value])

  const onDiscountUpdate = (value: string) => {
    onChange?.(JSON.stringify({ ...config, discount: value ? parseFloat(value) / 100 : 0 }))
  }

  return (
    <>
      <EditText {...props} gql="String" name="discount" value={(config.discount || 0) * 100} onChange={onDiscountUpdate} />
    </>
  )
}

LoyaltyProgramConfigField.render = (props: FormFieldProps, rowValue?: string) => {
  const config = rowValue ? JSON.parse(rowValue) : {}

  return (config.discount ?? 0) * 100 + '%'
}

export default function LoyaltyPrograms() {
  const context = useZeroApiContext()

  const navigate = useNavigate()
  const user = useContext(UserContext) as any

  const onItemClick = useCallback((_: any, item: any) => {
    navigate(`${item.id}/identifications`)
  }, [])

  return (
    <Box m={2}>
      <GqlTable entity="UserIdProgram" onItemClick={onItemClick} title={user.translate('user_id_program')}>
        <HiddenField name="restaurantId" value={context.id} />
        <FilterField name="type" value={0} filterType="not-equal" />
        <NestedItemForm name="name">
          <EditText name={context.lang} label={user.translate('name')} />
          <HiddenField name={context.lang === 'mk' ? 'en' : 'mk'} value={(row: any) => row?.[`name_${context.lang}`] ?? ''} />
        </NestedItemForm>
        <SelectOption gql="String" name="type" options={{ 1: 'Кредит', 2: 'Членство', 3: 'Лојалти' }} label={user.translate('type')} />
        <LoyaltyProgramConfigField name="config" gql="String" label={user.translate('config')} />
        <FormSwitch name="enabled" label={user.translate('active')} align="right" />
        <FormCreators name="id" label={user.translate('changes')} align="right" />
        <FormCountInfo name="userProgramIdentificationsCount" label={user.translate('users')} align="right" readOnly />
      </GqlTable>
    </Box>
  )
}
