import { useContext, useEffect, useState } from 'react'

import { gql, useMutation } from '@apollo/client'

import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { Button, CircularProgress, IconButton, Snackbar } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { UserContext } from '../../../App'
import generateGql from '../generateGql'
import Show from '../Show'

const useStyles = makeStyles((theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
  loading: {
    position: 'absolute',
    marginLeft: 24,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default function SnackBarDelete(props) {
  const classes = useStyles()

  const user = useContext(UserContext)

  const [open, setOpen] = useState(true)

  const entity = props.entityName
  const keys = props.entityKeys || { id: props.itemId }

  const [undeleteItem, { loading: loadingUndelete, called }] = useMutation(
    gql(
      generateGql(
        {
          [`undelete${entity}`]: {
            args: Object.keys(keys).toMapBy(
              (key) => '$' + key,
              () => 'String!',
            ),
          },
        },
        'mutation',
      ),
    ),
  )

  const message = props.name
    ? `${user.translate(entity)} '${props.name}' ${user.translate('is_deleted')}`
    : `${user.translate(entity)} ${user.translate('is_deleted')}`

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return

    setTimeout(() => {
      setOpen(false)
    }, 800)
  }

  const finished = called && !loadingUndelete && !open

  useEffect(() => {
    if (finished) {
      const timer = setTimeout(() => {
        if (props.onClose) props.onClose()
      }, 500)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [finished])

  const handleUndelete = () => {
    undeleteItem({ variables: keys }).then(() => {
      if (props.refresh) {
        props.refresh().then(() => {
          handleClose()
        })
      } else {
        handleClose()
      }
    })
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={called ? 10000 : null}
      onClose={handleClose}
      message={message}
      action={
        <>
          <Button color="secondary" size="small" disabled={called} onClick={handleUndelete}>
            {user.translate('undo')}
          </Button>
          <Show if={loadingUndelete}>
            <CircularProgress size={18} color="secondary" className={classes.loading} />
          </Show>
          <Show if={called && !loadingUndelete}>
            <CheckIcon size={14} color="secondary" className={classes.loading} />
          </Show>
          <IconButton aria-label="close" color="inherit" className={classes.close} onClick={handleClose} size="large">
            <CloseIcon />
          </IconButton>
        </>
      }
    />
  )
}
