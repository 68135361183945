import XLSX from 'sheetjs-style'

const CellTypes = {
  int: {
    parse: (value: any) => (typeof value === 'string' && value != null ? parseInt(value) : value),
    format: '#,##0',
  },
  quantity: {
    parse: (value: any) => (typeof value === 'string' && value ? parseFloat(value?.replace?.('.', '')?.replace?.(',', '.')) : value),
    format: '#,##0.000',
  },
  currency: {
    parse: (value: any) => (typeof value === 'string' && value ? parseFloat(value?.replace?.('.', '')?.replace?.(',', '.')) : value),
    format: '#,##0.00',
  },
  percent: {
    parse: (value: any) => (typeof value === 'string' && value ? parseFloat(value?.replace?.('.', '')?.replace?.(',', '.')) / 100 : value),
    format: '0.00%',
  },
}

const FieldCellTypes: Record<string, keyof typeof CellTypes> = {
  receiptNum: 'int',

  price: 'currency',
  totalTax: 'currency',
  totalIncome: 'currency',
  totalTaxByRateA: 'currency',
  totalTaxByRateB: 'currency',
  totalTaxByRateC: 'currency',
  totalTaxByRateD: 'currency',
  totalIncomeByTaxD: 'currency',
  totalIncomeByTaxB: 'currency',
  totalIncomeByTaxC: 'currency',
  totalIncomeByTaxA: 'currency',
  totalTaxDomestic: 'currency',
  totalIncomeDomestic: 'currency',
  taxAmountDomesticB: 'currency',
  taxAmountDomesticC: 'currency',
  taxAmountDomesticD: 'currency',

  initialTotalValue: 'currency',
  receivingTotalValue: 'currency',
  closingTotalValue: 'currency',
  expenditurePrice: 'currency',
  usedQuantityPrice: 'currency',
  unitSalePrice: 'currency',
  totalSalePrice: 'currency',
  profitAmount: 'currency',

  initialCountingQuantity: 'quantity',
  receivingQuantity: 'quantity',
  closingCountingQuantity: 'quantity',
  expenditureQuantity: 'quantity',
  usedQuantity: 'quantity',
  ordersQuantity: 'quantity',

  discount: 'percent',
  profitPercent: 'percent',
}

export const ExportExcel = (columns: any[], data: any[], fileName: string) => {
//   console.log('ExportExcel', columns)
  const workbook = XLSX.utils.book_new()

  const headers = columns.map((col) => col.title)
  const worksheet = XLSX.utils.aoa_to_sheet([headers])

  XLSX.utils.sheet_add_json(worksheet, data, { origin: 'A2', header: columns.map((col) => col.field), skipHeader: true })

  columns.forEach((col, index) => {
    const columnLetter = String.fromCharCode(65 + index)

    worksheet[`${columnLetter}1`].s = { font: { sz: 10, bold: true } }

    const cellType = FieldCellTypes[col.field]
    if (cellType) {
      const { parse, format } = CellTypes[cellType]

      for (let i = 0; i < data.length; i++) {
        const address = `${columnLetter}${i + 2}`

        XLSX.utils.sheet_add_aoa(worksheet, [[parse(data[i][col.field])]], { origin: address })

        XLSX.utils.cell_set_number_format(worksheet[address], format)

        worksheet[address].s = { font: { sz: 10, name: 'Arial' }, alignment: { horizontal: 'right' } }
      }
    } else {
      for (let i = 0; i < data.length; i++) {
        worksheet[`${columnLetter}${i + 2}`].s = { font: { sz: 10, name: 'Arial' } }
      }
    }
  })

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  XLSX.writeFile(workbook, fileName, { bookType: 'xlsx', bookSST: false, compression: true })
}
