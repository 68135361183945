import { forwardRef, useContext, useLayoutEffect, useState } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import MaterialTable, { MTableHeader } from '@material-table/core'

import AddBox from '@mui/icons-material/AddBox'
import ArrowDownward from '@mui/icons-material/ArrowDownward'
import Check from '@mui/icons-material/Check'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import Clear from '@mui/icons-material/Clear'
import DeleteOutline from '@mui/icons-material/DeleteOutline'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import Edit from '@mui/icons-material/Edit'
import FilterList from '@mui/icons-material/FilterList'
import FirstPage from '@mui/icons-material/FirstPage'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import LastPage from '@mui/icons-material/LastPage'
import Remove from '@mui/icons-material/Remove'
import SaveAlt from '@mui/icons-material/SaveAlt'
import Search from '@mui/icons-material/Search'
import ViewColumn from '@mui/icons-material/ViewColumn'
import { IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { useExportOptions } from './useExportOptions'
import useWindowSize from './useWindowSize'
import { UserContext } from '../../App'
import Show from '../common/Show'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <KeyboardArrowDown {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const useStyles = makeStyles((theme) => ({
  tableFix: {
    '& .MuiToolbar-root > div:nth-child(2)': {
      [theme.breakpoints.only('xs')]: {
        flex: 1,
      },
    },
    '& .MuiToolbar-root > div:last-child > div > div': {
      display: 'flex',
    },
    '& .MuiTableSortLabel-icon': {
      [theme.breakpoints.only('xs')]: {
        margin: 0,
      },
    },
  },
}))

export default function Table(props) {
  const classes = useStyles()

  const user = useContext(UserContext)
  const location = useLocation()
  const navigate = useNavigate()

  const filterState = location.state || {
    search: '',
  }

  const onSearchChange = (query) => navigate('', { state: { ...filterState, search: query } })

  const tableData = props.fieldList

  const pageSize = parseInt(props.pageSize)
  const pageSizeOptions = props.pageSizeOptions
  const tableLayout = 'auto'
  let fixedColumns = {}

  if (typeof props.fixedColumns !== 'undefined') {
    fixedColumns = props.fixedColumns
  }

  const exportFileName = `${props.exportFileName}_${user.formatDate(new Date())}`

  const exportOptions = useExportOptions(props.exportFileName)

  const size = useWindowSize()

  const [expanded, setExpanded] = useState(!size.isMobile)

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const hideOnMobile = size.isMobile && !expanded
  const hideOnTablet = size.isCompact && !expanded
  const columns = tableData.columns.map((column) => ({
    ...column,
    hidden: column.hidden || (column.mobile === false && hideOnMobile) || (column.tablet === false && hideOnTablet),
  }))
  const canExpand =
    columns.find((column) => (column.mobile === false && size.isMobile) || (column.tablet === false && size.isMobile)) !== undefined

  const countColumns = columns.filter((x) => !x.hidden).length - 1
  const paddingColumns = props.paddingColumns || 0

  useLayoutEffect(() => {
    if (props.openAdd) {
      const addRow = document.querySelector("[mode='add']")
      if (addRow === null) {
        const add = document.querySelectorAll(`[title=${user.translate('add')}]`)
        if (add != null) {
          for (const i in add) {
            if (typeof add[i] === 'object') {
              if (i === add.length - 1) {
                add[i].click()
                props.setOpenAdd(false)
              }
            }
          }
        }
      }
    }
  }, [props, user])

  window.columnParents = props.columnParents || []
  window.columnNumbers = props.columnNumbers || []

  const getPromiseOrCreate = function (callback, creator) {
    if (!callback) return undefined
    if (callback instanceof Promise) return callback
    if (callback instanceof Function) {
      return (a, b) => {
        try {
          const result = callback(a, b)
          if (result instanceof Promise) return result
          return new Promise((resolve, reject) => {
            resolve()
          })
        } catch (error) {
          console.log(error)
          return new Promise((resolve, reject) => {
            reject(error)
          })
        }
      }
    }
    return creator
  }

  const renderHeader = () => {
    if (!canExpand) return props.header && props.header()

    return (
      <>
        <IconButton onClick={toggleExpanded} size="large">
          <DoubleArrowIcon style={{ transform: expanded && 'rotate(180deg)', transition: 'transform 0.3s ease 0s' }} />
        </IconButton>
        {expanded && props.header && props.header()}
      </>
    )
  }

  return (
    <div className={classes.tableFix}>
      {props.printTitle && (
        <div
          id={props.tableTitleId ? props.tableTitleId : 'table-title'}
          style={{
            lineHeight: 1.3,
            fontSize: 10,
            fontStyle: 'normal',
            marginLeft: 10,
            marginTop: 10,
            fontFamily: 'Roboto',
            display: 'flex',
            textAlign: 'left',
          }}
        >
          {props.printTitle}
        </div>
      )}
      <MaterialTable
        style={{
          display: props.hidden && 'none',
          backgroundColor: props.backgroundColor,
          minWidth: expanded && 510,
        }}
        icons={tableIcons}
        title={
          <div
            id={!props.printTitle ? (props.tableTitleId ? props.tableTitleId : 'table-title') : undefined}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              lineHeight: 1,
              fontSize: '14px',
              fontWeight: 'bold',
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              marginLeft: !props.printTitle ? (props.tableTitleId ? 32 : 0) : 0,
              paddingBottom: 0,
              textAlign: 'left',
              minWidth: 220,
            }}
          >
            {props.tableName} {renderHeader()}
          </div>
        }
        columns={columns}
        data={[...tableData.data]}
        localization={{
          header: {
            actions: user.translate('actions'),
          },
          body: {
            emptyDataSourceMessage: user.translate('no_records_display'),
            addTooltip: user.translate('add'),
            deleteTooltip: user.translate('delete'),
            editTooltip: user.translate('edit'),
            editRow: {
              deleteText: user.translate('delete_text_row'),
              cancelTooltip: user.translate('cancel'),
              saveTooltip: user.translate('save'),
            },
          },
          toolbar: {
            page: user.translate('page'),
            of: user.translate('of'),
            seal: user.translate('seal'),
            authorized_person_signature: user.translate('authorized_person_signature'),
            exportCSVName: user.translate('export_csv_name'),
            exportPDFName: user.translate('export_pdf_name'),
            exportTitle: user.translate('export'),
            searchAriaLabel: user.translate('search'),
            searchTooltip: user.translate('search'),
            searchPlaceholder: user.translate('search'),
          },
          pagination: {
            firstTooltip: user.translate('first_page'),
            lastTooltip: user.translate('last_page'),
            previousTooltip: user.translate('previous_page'),
            nextTooltip: user.translate('next_page'),
          },
        }}
        options={{
          ...(tableData.options || {}),
          showTitle: !props.noTitle,
          toolbar: !props.noTitle,
          searchText: props.search === false ? '' : filterState.search,
          search: expanded && (props.search !== undefined ? props.search : true),
          addRowPosition: 'first',
          tableLayout,
          fixedColumns,
          emptyRowsWhenPaging: false,
          initialPage: props.page,
          paging: !props.noPaging,
          pageSize,
          pageSizeOptions,
          paginationPosition: props.paginationPosition !== undefined ? props.paginationPosition : 'bottom',
          exportMenu: exportOptions,
          exportButton: typeof props.export !== 'undefined' ? !!props.export : true,
          exportFileName,
          exportAllData: props.exportAllData !== undefined ? props.exportAllData : true,
          exportFontName: 'Roboto',
          exportFontSize: 7,
          exportFontOptions: {
            styles: { font: 'Roboto', fontStyle: 'normal', fontSize: 8 },
            headStyles: {
              font: 'RobotoBold',
              fontStyle: 'normal',
              fontSize: 8,
              fillColor: 227,
              textColor: 32,
              lineWidth: 1,
            },
          },
          exportOrientation: 'portrait',
          cellStyle: (rowData) => ({
            padding: rowData?.cellPadding !== undefined ? rowData.cellPadding : paddingColumns,
            fontSize: 12,
            width: `${100 / countColumns}%`,
            whiteSpace: 'pre-line',
          }),
          headerStyle: {
            padding: props.headerPadding !== undefined ? props.headerPadding : `5px 0`,
            lineHeight: 1.2,
            position: 'sticky',
            top: 0,
            backgroundColor: props.headerBackgroundColor || '#e3e3e3',
            whiteSpace: tableData.options?.nowrap && 'nowrap',
          },
          actionsCellStyle: {
            padding: 0,
            width: '5%',
          },
          rowStyle: (rowData) => {
            if (rowData.rowBackgroundColor) {
              return { backgroundColor: rowData.rowBackgroundColor }
            }
            if (rowData.active === 0) {
              // deactivated
              return { backgroundColor: 'rgba(0, 0, 0, 0.05)' }
            }
            if (props.receivingList && typeof rowData.receivingNumber === 'undefined') {
              return { backgroundColor: '#d0d0d0' }
            }

            return { backgroundColor: props.itemBackgroundColor }
          },
        }}
        onRowClick={props.onRowClick ? (event, rowData, togglePanel) => props.onRowClick(rowData, togglePanel, event) : undefined}
        isLoading={props.isLoading}
        editable={{
          onRowAdd: getPromiseOrCreate(props.onAddItem),
          onRowUpdate: getPromiseOrCreate(props.onEditItem),
          onRowDelete: getPromiseOrCreate(props.onDeleteItem),
        }}
        components={{
          Header: (_props) => (
            <>
              <Show if={Boolean(props.headlines)}>{props.headlines}</Show>
              <MTableHeader {..._props} />
            </>
          ),
        }}
        onSearchChange={props.search !== false ? onSearchChange : undefined}
        detailPanel={props.detailPanel ? ({ rowData }) => props.detailPanel(rowData) : undefined}
        showDetailPanel={props.showDetailPanel}
        onPageChange={(page) => console.log('onPageChange', page)}
        onChangePage={(page) => console.log('onChangePage', page)}
        onChangeRowsPerPage={(page) => console.log('onChangeRowsPerPage', page)}
      />
    </div>
  )
}
