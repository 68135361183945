import { useEffect, useRef, useState } from 'react'

import * as htmlToImage from 'html-to-image'

import { Box, Button, FormControl, FormHelperText, Input, InputLabel, LinearProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import config from '../../config'

const bigInt = require('big-integer')
const QRCode = require('qrcode.react')

let qrLink = 'https://dev-qr.e-bar.mk/'
let qrCode = false


// eslint-disable-next-line no-extend-native
String.prototype.hashCode = function () {
  let hash = 0
  let i
  let chr
  if (this.length === 0) return hash
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i)
    hash = bigInt(hash).shiftLeft(5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

function bigIntHash(number) {
  let hash = 0
  let value = bigInt(number)
  while (value > 0) {
    hash = hash ^ value.mod(256 * 256 * 256)
    value = value.shiftRight(24)
  }
  return hash
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  options: {
    display: 'block',
    '& > *': {
      display: 'inline-block',
      margin: 'auto 12px',
      '& > *': {
        display: 'flex',
        marginBottom: 10,
      },
    },
  },
  previewContainer: {
    maxWidth: 640,
    position: 'relative',
  },
  imageQrCode: {
    position: 'absolute',
    left: 0,
    top: 0,
  },
  imageTemlate: {
    width: '100%',
    position: 'relative',
  },
  progress: {
    margin: 12,
  },
}))

let zip

function QrCodeLogo(props) {
  const classes = useStyles()
  if (props.generating === -1) return <div />

  return (
    <QRCode
      className={classes.imageQrCode}
      key={`qr_code_generator_${props.generating}_${props.qrSize}_${props.value.hashCode()}`}
      style={{ left: props.x, top: props.y }}
      {...props}
    />
  )
}

export default function QrGenerator(props) {
  const classes = useStyles()

  let storedPrefs = { qrExcavate: true, qrIncludeMargin: true }
  try {
    storedPrefs = JSON.parse(localStorage._qrGenerator_prefs)
  } catch (error) {
    console.log('parse stored prefs error')
  }

  const levels = ['L', 'M', 'H', 'Q']

  const [generating, setGenerating] = useState(false)
  const [qrBaseCode, setQrBaseCode] = useState(false)
  const [templateFile, setTemplateFile] = useState(false)
  const [logoFile, setLogoFile] = useState('e-bar_logo_white_circle_shadow.png')
  const [templateSize, setTemplateSize] = useState(false)
  const [qrExcavate, setQrExcavate] = useState(Boolean(storedPrefs.qrExcavate))
  const [qrIncludeMargin, setQrIncludeMargin] = useState(Boolean(storedPrefs.qrIncludeMargin))
  const [qrLevel, setQrLevel] = useState(levels.indexOf(storedPrefs.qrLevel) > -1 ? storedPrefs.qrLevel : 'L')
  const [qrCount, setQrCount] = useState(parseFloat(storedPrefs.qrCount) || 100)
  const [qrSize, setQrSize] = useState(parseFloat(storedPrefs.qrSize) || 128)
  const [qrLogoSize, setQrLogoSize] = useState(parseFloat(storedPrefs.qrLogoSize) || 32)
  const [qrPosition, setQrPosition] = useState({
    x: parseFloat(storedPrefs.qrPosition?.x) || 0,
    y: parseFloat(storedPrefs.qrPosition?.y) || 0,
  })
  const elPreviewRef = useRef(null)

  localStorage._qrGenerator_prefs = JSON.stringify({
    qrPosition,
    qrSize,
    qrLogoSize,
    qrCount,
    qrExcavate,
    qrIncludeMargin,
    qrLevel,
  })

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })

  const saveQrCodeAndContinue = () => {
    if (generating === -1) {
      setGenerating(0)
      return
    }
    if (generating === qrCount) {
      let csv = ''
      for (let i = 0; i < qrCount; i++) {
        let code = qrBaseCode.shiftLeft(8).plus(bigInt(i))
        const checkSum = bigIntHash(code) % 16
        code = code.plus(bigInt(checkSum).shiftLeft(80))
        csv += `${code.toString()}, ${config.scanQrUrl}/${code.toString()}\n`
      }
      zip.file(`qr_${qrBaseCode}.csv`, csv)

      zip.generateAsync({ type: 'blob' }).then((content) => {
        const link = document.createElement('a')
        link.download = `eBar_qr_codes_${new Date().toISOString().split('T')[0]}_${qrBaseCode}.zip`
        link.href = URL.createObjectURL(content)
        link.click()
        setGenerating(false)
      })
    } else {
      htmlToImage.toBlob(elPreviewRef.current).then(function (dataUrl) {
        zip.file(`qr_${generating}-${qrCode}.png`, dataUrl)

        setGenerating(generating + 1)
      })
    }
  }

  const setTemplateImage = (url) => {
    setTemplateFile(url)
    setTemplateSize(false)
    const image = new Image()
    image.onload = (e) => {
      setTemplateSize({ width: image.width, height: image.height })
    }
    image.onerror = () => {
      alert('Failed to load image')
    }
    image.src = url
  }

  const handleTemplateFile = (event) => {
    const file = event.target.files[0]
    if (!file) {
      return
    }
    const fileUrl = URL.createObjectURL(file)
    setTemplateImage(fileUrl)
  }

  useEffect(() => {
    if (templateFile === false) {
      setTemplateImage('e-menu-template.png')
    } else if (generating !== false) {
      if (generating <= 3) {
        setTimeout(() => {
          requestAnimationFrame(saveQrCodeAndContinue)
        }, 500)
      } else {
        requestAnimationFrame(saveQrCodeAndContinue)
      }
    }
  })

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }

    window.addEventListener('resize', handleResize)

    return (_) => {
      window.removeEventListener('resize', handleResize)
    }
  })


  if (generating !== false) {
    if (qrBaseCode === false) {
      setGenerating(false)
      return <div />
    }

    let code = qrBaseCode.shiftLeft(8).plus(bigInt(generating))
    const checkSum = bigIntHash(code) % 16
    code = code.plus(bigInt(checkSum).shiftLeft(80))

    qrCode = code.toString()
    qrLink = `${config.scanQrUrl}/${qrCode}`
  }

  const handleLogoFile = (event) => {
    setLogoFile(URL.createObjectURL(event.target.files[0]))
  }

  const handleScaleChange = (e) => {
    setQrSize(e.target.value)
  }

  const templateRect = elPreviewRef.current?.getBoundingClientRect() || {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  }
  const x = qrPosition.x + (templateRect.width - qrSize) / 2
  const y = qrPosition.y + (templateRect.height - qrSize) / 2

  const onGenerateCodesClick = (e) => {
    zip = require('jszip')()

    const version = 1
    const instance = 1
    const userData = 0

    let baseCode = bigInt(version)
    baseCode = baseCode.shiftLeft(4) // check sum
    baseCode = baseCode.shiftLeft(48).plus(bigInt(new Date().getTime()))
    baseCode = baseCode.shiftLeft(8).plus(bigInt(instance))

    baseCode = baseCode.shiftLeft(16).plus(bigInt(userData))

    setQrBaseCode(baseCode)
    setGenerating(0)
  }

  const onActivateClick = (e) => {
    // TODO: activate last generated codes for the current? restaurant
  }

  return (
    <Box className={classes.root}>
      <div className={classes.options}>
        <div>
          <FormControl>
            <Input id="inputImageTemplate" type="file" aria-describedby="template image" onChange={handleTemplateFile} />
            <FormHelperText id="my-helper-text">Template Image</FormHelperText>
          </FormControl>
          <FormControl>
            <Input id="inputImageTemplate" type="file" aria-describedby="template image" onChange={handleLogoFile} />
            <FormHelperText id="my-helper-text">Logo Image</FormHelperText>
          </FormControl>
        </div>
        <div>
          <FormControl>
            <InputLabel htmlFor="inputQrSize">QR Size</InputLabel>
            <Input id="inputQrSize" type="number" value={qrSize} aria-describedby="template image" onChange={handleScaleChange} />
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="inputQrLogoSize">Logo Size</InputLabel>
            <Input
              id="inputQrLogoSize"
              type="number"
              value={qrLogoSize}
              aria-describedby="template image"
              onChange={(e) => setQrLogoSize(parseFloat(e.target.value))}
            />
          </FormControl>
        </div>
        <div>
          <FormControl>
            <InputLabel htmlFor="inputQrPositionX">QR X</InputLabel>
            <Input
              id="inputQrPositionX"
              type="number"
              value={qrPosition.x}
              aria-describedby="template image"
              onChange={(e) =>
                setQrPosition({
                  x: parseFloat(e.target.value),
                  y: qrPosition.y,
                })
              }
            />
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="inputQrPositionY">QR Y</InputLabel>
            <Input
              id="inputQrPositionY"
              type="number"
              value={qrPosition.y}
              aria-describedby="template image"
              onChange={(e) =>
                setQrPosition({
                  x: qrPosition.x,
                  y: parseFloat(e.target.value),
                })
              }
            />
          </FormControl>
        </div>
        <div>
          <div>
            <input
              id="inputQrExcavate"
              type="checkbox"
              defaultChecked={qrExcavate}
              onChange={(e) => setQrExcavate(Boolean(e.target.checked))}
            />
            <label htmlFor="scales">Excavate</label>
          </div>
          <div>
            <input
              id="inputQrIncludeMargin"
              type="checkbox"
              defaultChecked={qrIncludeMargin}
              onChange={(e) => setQrIncludeMargin(Boolean(e.target.checked))}
            />
            <label htmlFor="scales">Include-Margin</label>
          </div>
          <div>
            <label htmlFor="scales">Level</label>
            <select
              value={qrLevel}
              onChange={(e) => {
                setQrLevel(e.target.value)
              }}
            >
              <option value="L">Low</option>
              <option value="M">Medium</option>
              <option value="Q">Quality</option>
              <option value="H">High</option>
            </select>
          </div>
        </div>
        <div>
          <FormControl>
            <InputLabel htmlFor="inputQrCount">QR Count</InputLabel>
            <Input
              id="inputQrPositionX"
              type="number"
              value={qrCount}
              aria-describedby="template image"
              onChange={(e) => setQrCount(parseInt(e.target.value))}
            />
          </FormControl>
          <Button variant="outlined" color="secondary" onClick={onGenerateCodesClick}>
            Generate Flyers
          </Button>
        </div>
      </div>

      {generating !== false && (
        <LinearProgress
          className={classes.progress}
          color="secondary"
          variant={generating === -1 || generating === qrCount ? 'indeterminate' : 'determinate'}
          value={(generating * 100) / qrCount}
        />
      )}

      <div style={{ margin: 'auto' }}>
        <div className={classes.previewContainer} ref={elPreviewRef}>
          <img className={classes.imageTemlate} src={templateFile || undefined} />
          <QrCodeLogo
            x={x}
            y={y}
            generating={generating}
            value={qrLink}
            size={qrSize}
            bgColor="#ffffff"
            fgColor="#000000"
            level={qrLevel}
            includeMargin={qrIncludeMargin}
            renderAs={generating === false ? 'svg' : 'canvas'}
            imageSettings={
              logoFile
                ? {
                    src: logoFile,
                    x: null,
                    y: null,
                    height: qrLogoSize,
                    width: qrLogoSize,
                    excavate: qrExcavate,
                  }
                : undefined
            }
          />
        </div>
      </div>
    </Box>
  )
}
