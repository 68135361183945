import { useCallback, useContext, useMemo } from 'react'

import { Box } from '@mui/material'
import {
  EditText,
  EntityAutoComplete,
  FilterField,
  FormField,
  FormSwitch,
  GqlComponent,
  GqlTable,
  HiddenField,
  NestedItemForm,
  useZeroApiContext,
} from '@zeroapi-io/zeroapireact'
import { useNavigate, useParams } from 'react-router-dom'
import { UserContext } from '../../App'

interface FormFieldProps extends FormField<string> {
  value?: string
}

export const LoyaltyProgramConfigField: GqlComponent<FormFieldProps, string> = ({ value, onChange, ...props }: FormFieldProps) => {
  const config = useMemo(() => {
    if (!value?.length) return {}

    return JSON.parse(value)
  }, [value])

  const onDiscountUpdate = (value: string) => {
    onChange?.(JSON.stringify({ ...config, discount: parseFloat(value) / 100 }))
  }

  return (
    <>
      <EditText {...props} gql="String" name="discount" value={config.discount * 100} onChange={onDiscountUpdate} />
    </>
  )
}

LoyaltyProgramConfigField.render = (props: FormFieldProps, rowValue?: string) => {
  const config = rowValue ? JSON.parse(rowValue) : {}

  return (config.discount ?? 0) * 100 + '%'
}

export default function LoyaltyProgramIdentifications() {
  const context = useZeroApiContext()
  const user = useContext(UserContext) as any

  const navigate = useNavigate()

  const { userIdProgramId } = useParams()

  const onItemClick = useCallback((_: any, item: any) => {
    navigate(`${item.id}/identification`)
  }, [])

  return (
    <Box m={2}>
      <GqlTable entity="Identification" onItemClick={onItemClick} backPathsCount={2} hasBack title={user.translate('identifications')}>
        <HiddenField name="id" gql="String" id />
        <HiddenField name="userIdProgramId" value={userIdProgramId} />
        <FilterField name="userIdProgramId" value={userIdProgramId} filterType="equal" />
        <EditText name="amount" intNumber label={user.translate('amount')} default={0} />

        <EditText name="rfid" label={user.translate('serial')} group="RFID" />
        <EditText name="sector" group="RFID" label={user.translate('sector')} default="0" />
        <EditText name="verifier" group="RFID" label={user.translate('verifier')} default="" />

        <NestedItemForm name="identificationUser" keys={['identificationId']} dependent>
          <HiddenField name="identificationId" valueFromParent="id" id />
          <EntityAutoComplete
            name="commissionerId"
            entity="Restaurant"
            group={user.translate('costumer')}
            label={user.translate('komitent')}
          >
            <NestedItemForm name="name">
              <HiddenField name="mk" />
            </NestedItemForm>
          </EntityAutoComplete>
          <EntityAutoComplete name="userId" entity="Profile" pk="userId" group={user.translate('costumer')} label={user.translate('user')}>
            <HiddenField name="name" />
          </EntityAutoComplete>
        </NestedItemForm>

        <FormSwitch name="active" label={user.translate('active')} />
      </GqlTable>
    </Box>
  )
}
