import React from 'react'

import EditTextDateEpoch from './EditTextDateEpoch'

export default function EditTextDate(props) {
  let srcDate = new Date(props.value)
  if (srcDate === 'Invalid Date') srcDate = new Date(props.default)
  if (srcDate === 'Invalid Date') srcDate = new Date()

  const onChange = (value) => {
    const date = new Date(parseInt(value))
    props.onChange(date.toISODate())
  }

  return <EditTextDateEpoch {...props} value={srcDate.getTime()} onChange={onChange} />
}
EditTextDate.gql = 'String'
