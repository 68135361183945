import { useCallback } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

export default function useOnLogin(props) {
  const navigate = useNavigate()

  const location = useLocation()
  const locationHash = location.hash.replace('#', '')

  return useCallback(async () => {
    props.onLogin?.()

    if (props.refreshOnLogin) {
      window.location.reload(false)
    } else if (locationHash?.length) {
      navigate(locationHash)
    } else {
      window.location = '/home'
    }
  }, [navigate, locationHash, props])
}
