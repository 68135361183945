import { useContext, useRef, useState } from 'react'

import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { gql, useMutation } from '@apollo/client'

import Check from '@mui/icons-material/Check'
import Print from '@mui/icons-material/Print'
import { Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import makeStyles from '@mui/styles/makeStyles'

import { RestaurantContext, UserContext } from '../../App'
import useDateRangeFilter from '../common/DateRangeHeader'
import GqlSimpleTable from '../common/GqlSimpleTable'
import MobileDialog from '../common/MobileDialog'
import Show from '../common/Show'
import SnackBarWarning from '../common/SnackBarWarning'
import { useEntityRelationQuery } from '../common/useEntityQuery'

const useStyles = makeStyles((theme) => ({
  roots: {
    '& .MuiTableCell-root .MuiIconButton-root': {
      padding: theme.spacing(1),
    },
  },
  root: {},
  checkedLabel: {
    marginRight: 0,
    '& .MuiFormControlLabel-label': {
      fontSize: 12,
    },
  },
}))

export default function StockTransferList() {
  const classes = useStyles()

  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const navigate = useNavigate()
  const location = useLocation()

  const { stockTransferId: selectedStockTransferId } = useParams()

  const [openSnackBarWarning, setOpenSnackBarWarning] = useState(false)

  const FIELDS = [
    { name: 'id', gql: 'String!', id: true, hidden: true },
    { name: 'restaurantId', gql: 'String!', hidden: true, default: restaurant.id },
    { name: 'year', gql: 'Long!', width: 0.7, default: new Date().getFullYear() },
    { name: 'number', gql: 'String!', width: 0.5, default: ' ' },
    { name: 'date', gql: 'String!', edit: 'DateMilis' },
    { name: 'createdById', gql: 'String', default: user.me.id, hidden: true },
    { name: 'updatedById', gql: 'String', value: user.me.id, hidden: true },
    { name: 'created', gql: 'Long!', edit: 'DateTime', default: new Date().getTime() },
    { name: 'updated', gql: 'Long!', default: new Date().getTime(), hidden: true },
    { name: 'seen', gql: 'Long', hidden: true },
  ]

  const dateRangeFilter = useDateRangeFilter('last_and_this_month')

  const apiControler = useRef()

  const closeItem = () => {
    if (location.state?.from === 'list-stock_transfer') {
      navigate(-1)
    } else {
      navigate(`/restaurant/${restaurant._id}/stock_transfer`)
    }
  }

  return (
    <div className={classes.roots}>
      <GqlSimpleTable
        apiRef={apiControler}
        entityName="StockTransferDocument"
        pathName="stock_transfer"
        fields={FIELDS}
        dateRangeFilter={dateRangeFilter}
        dateRangeField="created"
        title="stock_transfer"
      />

      {openSnackBarWarning && (
        <SnackBarWarning
          message={openSnackBarWarning.alertMessage}
          linkTo={openSnackBarWarning.linkTo}
          linkText={openSnackBarWarning.linkText}
          autoHideDuration={openSnackBarWarning.autoHideDuration || 3000}
          openSnackBar
          setOpenSnackBar={setOpenSnackBarWarning}
        />
      )}

      <Show if={selectedStockTransferId}>
        <StockTransferItem itemId={selectedStockTransferId} fields={FIELDS} onClose={closeItem} />
      </Show>
    </div>
  )
}

const FINISH_DOCUMENT = gql`
  mutation ($documentId: String!) {
    extras_finishStockTransferDocument(documentId: $documentId)
  }
`

export function StockTransferItem(props) {
  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const apiControler = useRef()

  const itemId = props.itemId

  const query = useEntityRelationQuery('StockTransferDocument', props.fields, 'id', itemId, {
    single: true,
  })

  const item = query.item

  const [finishDocument, { loading: loadingFinish }] = useMutation(FINISH_DOCUMENT)

  const uomOptions = [user.translate('liter'), user.translate('kilogram'), user.translate('piece')]

  const FIELDS = [
    { name: 'id', gql: 'String!', id: true, hidden: true },
    { name: 'documentId', gql: 'String!', hidden: true, value: itemId },
    { name: 'created', gql: 'Long!', title: 'date', edit: 'DateMilis' },
    {
      name: 'sendingWarehouseId',
      title: 'sendingWarehouse',
      gql: 'String!',
      edit: 'EntityAutoComplete',
      entity: 'Warehouse',
      editName: 'sendingWarehouse',
      render: (item) => item.sendingWarehouse.name[user.lang],
    },
    {
      name: 'receivingWarehouseId',
      title: 'receivingWarehouse',
      gql: 'String!',
      edit: 'EntityAutoComplete',
      entity: 'Warehouse',
      editName: 'receivingWarehouse',
      render: (item) => item.receivingWarehouse.name[user.lang],
    },
    {
      name: 'stockType',
      gql: 'String!',
      title: 'stock',
      width: 2,
      edit: 'EntityAutoComplete',
      entity: 'StockType',
      editField: 'id',
      editName: 'stockTypeName',
      render: (item) => `${item.stockType.name[user.lang]} (${uomOptions[item.stockType?.uom]})`,
      subSelection: { id: true, name: { [user.lang]: true }, uom: true, sellPrice: true },
    },
    {
      name: 'quantity',
      gql: 'Float!',
      width: 0.7,
      edit: 'Numeric',
      converter: (item, value, inverse) =>
        (item.stockType?.uom ?? item._stockType?.uom) === 2 ? value : inverse ? value * 1000 : value / 1000,
      render: (item) => user.formatQuantity(item.quantity / (item.stockType?.uom === 2 ? 1 : 1000)),
      decimals: 3,
    },
    { name: 'price', gql: 'Float!', width: 0.7, edit: 'StockTypeCostInput' },
    { name: 'countingId', gql: 'String', hidden: true },
    { name: 'updated', gql: 'Long!', default: new Date().getTime(), hidden: true },
    {
      name: 'sendingWarehouse',
      gql: 'Warehouse',
      hidden: true,
      subSelection: { name: { [user.lang]: true } },
      readOnly: true,
    },
    {
      name: 'receivingWarehouse',
      gql: 'Warehouse',
      hidden: true,
      subSelection: { name: { [user.lang]: true } },
      readOnly: true,
    },
  ]

  const isClosed = !isNaN(parseInt(item?.number))

  const onAction = () => {
    if (loadingFinish) return true
    if (isClosed) return false
    finishDocument({ variables: { documentId: itemId } }).then(() => {
      query.refresh()
    })
    return true
  }
  return (
    <MobileDialog
      maxWidth="md"
      open
      onClose={props.onClose}
      title={`${user.translate('transfer_doc')} ${isClosed ? item?.number : '-'}/${item?.year}`}
      onAction={onAction}
      actionIcon={isClosed ? <Print /> : <Check />}
    >
      <Show if={Boolean(item)}>
        <Typography variant="body1">
          {user.translate('date')} {user.formatDate(parseInt(item?.date))}
        </Typography>
        {/* <Typography variant="body1">{user.translate("date")} {user.formatDate(parseInt(item?.date))}</Typography> */}
        <Typography variant="h5">
          {user.translate('transfer_doc')} {isClosed ? item?.number : '-'}/{item?.year}
        </Typography>
        <GqlSimpleTable
          apiRef={apiControler}
          entityName="StockTransfer"
          relFieldName="documentId"
          relFieldValue={itemId}
          pathName="stock_transfer"
          noEdit={isClosed}
          fields={FIELDS}
          title="stock_transfer"
          onRowClick={() => {}}
        />
      </Show>
      <Show if={itemId === undefined || itemId === null}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </div>
      </Show>
    </MobileDialog>
  )
}
