import { useContext, useState } from 'react'

import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import { default as Edit } from '@mui/icons-material/Edit'
import { Box, Button, CircularProgress, IconButton, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import EditNameDialog from './EditNameDialog'
import config from '../../../config'
import { RestaurantContext, UserContext } from '../../App'
import useConfirmDialog from '../common/ConfirmDialog'
import helper from '../common/Helper'
import { ImageUpload } from '../common/ImageUpload'
import { useEntityQuery } from '../common/useEntityQuery'

const useStyles = makeStyles((theme) => ({
  itemCard: {
    display: 'block',
    overflow: 'unset',
    textAlign: 'left',
  },
  itemCardDisabled: {
    display: 'block',
    overflow: 'unset',
    textAlign: 'left',
    backgroundColor: theme.palette.primary.light, // 'rgba(0, 0, 0, 0.05)'
  },
  cardHeader: {
    width: `inherit`,
    padding: `30px 10px`,
    marginTop: '-30px',
    borderRadius: `10px`,
    boxShadow: `0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)`,
    backgroundColor: theme.palette.primary.main, // `rgb(0, 51, 102, 0.8)`,
    color: `#fff`,
  },
  cardHeaderTitle: {
    width: `inherit`,
    padding: `30px`,
    margin: '-30px',
  },
  cardHeaderIcons: {
    float: `right`,
    padding: '7px',
    marginTop: '-7px',
    width: '35px',
  },
  cardMenuIcons1: {
    right: `40px`,
    top: `-5px`,
    position: `absolute`,
    zIndex: '99',
    padding: '7px',
    width: '35px',
  },
  cardMenuIcons2: {
    right: `0`,
    top: `-5px`,
    position: `absolute`,
    zIndex: '99',
    padding: '7px',
    width: '35px',
  },
  posName: {
    position: 'relative',
    flexGrow: 1,
  },
  menuTitle: {
    padding: theme.spacing(1),
    display: 'block',
    fontSize: 12,
    width: `75%`,
  },
  itemName: {
    margin: `${theme.spacing(2)} 0 0 0`,
    padding: theme.spacing(1),
    flexGrow: 1,
  },
  enablePos: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  input: {
    display: 'none',
  },
  formControlUpload: {
    width: '100%',
    marginTop: theme.spacing(2),
    clear: `both`,
    display: `inline-block`,
    position: 'relative',
  },
  image: {
    maxWidth: 150,
    maxHeight: 150,
    float: `left`,
    marginRight: theme.spacing(3),
  },
  img: {
    width: '100%',
    height: 200,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  labelUpload: {
    width: '100%',
  },
}))

export default function WebPosSettings(props) {
  const classes = useStyles()
  // Get current user preferences (language)
  const user = useContext(UserContext)
  const restaurant = useContext(RestaurantContext)

  const confirmDialog = useConfirmDialog()

  const [openEditWebName, setOpenEditWebName] = useState(false)

  const LANGS = Array.from(new Set([user.lang, 'en', 'mk']))
  const langFields = LANGS.map((lang) => ({
    name: lang,
    gql: 'String!',
  }))
  langFields.push({ name: 'id', gql: 'String!' })

  const createNameQuery = useEntityQuery('Translation', langFields, 'id', undefined, {
    single: true,
  })

  const webPosQuery = useEntityQuery(
    'WebPos',
    [
      { name: 'posId', gql: 'String!' },
      { name: 'name', gql: 'String!', subSelection: { id: true, en: true, mk: true, [user.lang]: true } },
      { name: 'active', gql: 'Long!' },
      { name: 'webOrders', gql: 'Long!' },
      { name: 'tableOrders', gql: 'Long!' },
      { name: 'memOrders', gql: 'Long!' },
      { name: 'webMenu', gql: 'Long!' },
      { name: 'image', gql: 'String!' },
      { name: 'workingHours', gql: 'String!' },
    ],
    'posId',
    props.posId,
    {
      single: true,
      keys: ['posId'],
    },
  )

  const webPos = webPosQuery.item

  const editWebPos = (prop) => {
    if (webPos === undefined) return

    webPosQuery.saveItem({
      ...webPos,
      name: webPos.name.id,
      [prop]: webPos[prop] === 0 ? 1 : 0,
    })
  }

  const onEnableWebMenu = () => {
    confirmDialog.showConfirm('create', 'webMenu', () => {
      createNameQuery
        .saveItem(
          {
            ...LANGS.toMapBy(
              (lang) => lang,
              (lang) => props.posName[lang],
            ),
            id: helper.uid(),
          },
          false,
        )
        .then((item) => {
          webPosQuery.saveItem({
            posId: props.posId,
            name: item.id,
            active: 0,
            webOrders: 0,
            tableOrders: 0,
            memOrders: 0,
            webMenu: 0,
            image: '',
            workingHours: '',
          })
        })
    })
  }

  const onImageSelect = () => {}

  const onImageDelete = () => {
    confirmDialog.showConfirm('delete', 'image', () => {
      webPosQuery.saveItem({
        ...webPos,
        name: webPos.name.id,
        image: '',
      })
    })
  }

  const onImageUploaded = (imageUrl) => {
    return webPosQuery.saveItem({
      ...webPos,
      name: webPos.name.id,
      image: imageUrl,
    })
  }

  if (webPos === undefined && (createNameQuery.isLoadingAction || webPosQuery.isLoadingAction)) {
    return <CircularProgress />
  }

  if (webPos === undefined) {
    return (
      <>
        <Button variant="outlined" className={classes.enablePos} onClick={onEnableWebMenu}>
          {user.translate('enableWebMenu')}
        </Button>
        {confirmDialog.render()}
      </>
    )
  }

  const imageUrl =
    webPos?.image != null &&
    webPos.image.length > 5 &&
    `${webPos.image.indexOf('http') === -1 ? config.apiImagesUrl + '/' : ''}${webPos?.image}`

  return (
    <>
      <Box component="h6" className={classes.itemName}>
        {user.translate('webMenu') + ' ' + user.translate('title')}
      </Box>

      <div className={classes.posName} key="webMenuTitle">
        <Tooltip
          title={`${user.translate('edit')} ${user.translate('webMenu')}`}
          placement="bottom-start"
          className={classes.menuTitle}
          onClick={(e) => setOpenEditWebName(true)}
        >
          <Button color="inherit" component="p">
            {webPos?.name && webPos.name[user.lang]}
          </Button>
        </Tooltip>

        <Tooltip
          title={`${user.translate('edit')}  ${user.translate('webMenu')}`}
          placement="bottom-start"
          className={classes.cardMenuIcons2}
          onClick={(e) => setOpenEditWebName(true)}
        >
          <IconButton size="small" color="primary">
            <Edit />
          </IconButton>
        </Tooltip>
      </div>

      <Box component="h6" className={classes.itemName}>
        {user.translate('webMenu')}
      </Box>

      <div className={classes.posName} key="webMenuActive">
        <Tooltip
          title={`${user.translate('edit')} ${user.translate('active')}`}
          placement="bottom-start"
          className={classes.menuTitle}
          onClick={(e) => editWebPos('active')}
        >
          <Button color="inherit" component="p">
            {user.translate('active')}
          </Button>
        </Tooltip>

        <Tooltip
          title={`${user.translate('edit')}  ${user.translate('webMenu')}`}
          placement="bottom-start"
          className={classes.cardMenuIcons2}
          onClick={(e) => editWebPos('active')}
        >
          <IconButton size="small" color="primary">
            {webPos?.active ? <CheckIcon /> : <ClearIcon />}
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes.posName} key="webMenu">
        <Tooltip
          title={`${user.translate('edit')} ${user.translate('webMenu')}`}
          placement="bottom-start"
          className={classes.menuTitle}
          onClick={(e) => editWebPos('webMenu')}
        >
          <Button color="inherit" component="p">
            {user.translate('webMenu')}
          </Button>
        </Tooltip>

        <Tooltip
          title={`${user.translate('edit')}  ${user.translate('webMenu')}`}
          placement="bottom-start"
          className={classes.cardMenuIcons2}
          onClick={(e) => editWebPos('webMenu')}
        >
          <IconButton size="small" color="primary">
            {webPos?.webMenu ? <CheckIcon /> : <ClearIcon />}
          </IconButton>
        </Tooltip>
      </div>

      <div className={classes.posName} key="webOrders">
        <Tooltip
          title={`${user.translate('edit')} ${user.translate('webOrders')}`}
          placement="bottom-start"
          className={classes.menuTitle}
          onClick={(e) => editWebPos('webOrders')}
        >
          <Button color="inherit" component="p">
            {user.translate('webOrders')}
          </Button>
        </Tooltip>

        <Tooltip
          title={`${user.translate('edit')}  ${user.translate('webOrders')}`}
          placement="bottom-start"
          className={classes.cardMenuIcons2}
          onClick={(e) => editWebPos('webOrders')}
        >
          <IconButton size="small" color="primary">
            {webPos?.webOrders ? <CheckIcon /> : <ClearIcon />}
          </IconButton>
        </Tooltip>
      </div>

      <div className={classes.posName} key="tableOrders">
        <Tooltip
          title={`${user.translate('edit')} ${user.translate('tableOrders')}`}
          placement="bottom-start"
          className={classes.menuTitle}
          onClick={(e) => editWebPos('tableOrders')}
        >
          <Button color="inherit" component="p">
            {user.translate('tableOrders')}
          </Button>
        </Tooltip>

        <Tooltip
          title={`${user.translate('edit')}  ${user.translate('tableOrders')}`}
          placement="bottom-start"
          className={classes.cardMenuIcons2}
          onClick={(e) => editWebPos('tableOrders')}
        >
          <IconButton size="small" color="primary">
            {webPos?.tableOrders ? <CheckIcon /> : <ClearIcon />}
          </IconButton>
        </Tooltip>
      </div>

      <div className={classes.posName} key="memOrders">
        <Tooltip
          title={`${user.translate('edit')} ${user.translate('memOrders')}`}
          placement="bottom-start"
          className={classes.menuTitle}
          onClick={(e) => editWebPos('memOrders')}
        >
          <Button color="inherit" component="p">
            {user.translate('memOrders')}
          </Button>
        </Tooltip>

        <Tooltip
          title={`${user.translate('edit')}  ${user.translate('memOrders')}`}
          placement="bottom-start"
          className={classes.cardMenuIcons2}
          onClick={(e) => editWebPos('memOrders')}
        >
          <IconButton size="small" color="primary">
            {webPos?.memOrders ? <CheckIcon /> : <ClearIcon />}
          </IconButton>
        </Tooltip>
      </div>

      <div className={classes.posName} key="webImage">
        <Tooltip title={`${user.translate('edit')} ${user.translate('webImage')}`} placement="bottom-start" className={classes.enablePos}>
          <ImageUpload
            className={classes.formControlUpload}
            id={props.posId}
            imageUrl={imageUrl}
            onImageUploaded={onImageUploaded}
            onRemove={onImageDelete}
          />
        </Tooltip>
      </div>

      {openEditWebName && <EditNameDialog id={webPos?.name?.id} refresh={webPosQuery.refresh} onClose={setOpenEditWebName} />}
      {confirmDialog.render()}
    </>
  )
}
