import { Snackbar } from '@mui/material'
import Alert from '@mui/material/Alert'

export default function SnackBarDeactivate(props) {
  const handleClose = () => {
    props.setOpenDeactivateSnackBar(false)
  }

  return (
    <Snackbar
      open={props.openDeactivateSnackBar}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={handleClose}
      TransitionProps={{
        onExited: handleClose,
      }}
    >
      <Alert elevation={6} variant="filled" onClose={handleClose} severity={props.deactivated ? 'warning' : 'success'}>
        {props.message}
      </Alert>
    </Snackbar>
  )
}
