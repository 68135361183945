import { useContext, useState } from 'react'

import { GoogleLogin } from '@react-oauth/google'

import LoadingBox from './common/LoadingBox'
import useOnLogin from './common/useOnLogin'
import useLoginWithGoogleToken from './useLoginWithGoogle'
import { UserContext } from '../App'
import useWindowSize from '../restaurant/common/useWindowSize'

// React Function Component
export default function LoginGoogle(props) {
  const user = useContext(UserContext)

  const [isLoading, setIsLoading] = useState(false)

  const onLogin = useOnLogin(props)

  const { loginWithGoogleToken, loading } = useLoginWithGoogleToken(onLogin)

  const size = useWindowSize()

  return (
    <LoadingBox loading={loading || isLoading} sx={{ pt: 2 }}>
      <GoogleLogin
        cookiePolicy="single_host_origin"
        onSuccess={(credentialResponse) => {
          setIsLoading(false)
          loginWithGoogleToken(credentialResponse.credential)
        }}
        onError={() => {
          setIsLoading(false)
          console.log('Login Failed')
        }}
        responseType="id_token"
        logo_alignment="left"
        locale={user.lang}
        onLogin={() => {
          setIsLoading(true)
        }}
        width={props.widthAuto ? null : Math.min(size.width - 32, 400)}
      />
    </LoadingBox>
  )
}
