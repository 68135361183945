import { useContext, useState } from 'react'

import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import PrintIcon from '@mui/icons-material/Print'
import { Button, Checkbox, FormControl, FormGroup, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import makeStyles from '@mui/styles/makeStyles'
import { addDays, startOfISOWeek, endOfISOWeek, subHours } from 'date-fns'

import { DateRangePickerComponent } from './DateRangePickerComponent'
import Show from './Show'
import { UserContext } from '../../App'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const useStyles = makeStyles((theme) => ({
  gridItem: {
    flexGrow: 0,
    marginBottom: 25,
    textAlign: `center`,
    marginTop: 25,
    fontSize: 14,
    justifyContent: `center`,
  },
  formContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
  },
  formRow: {
    flexGrow: 0,
    marginBottom: 16,
    textAlign: `center`,
    marginTop: theme.spacing(1),
    fontSize: 14,
    justifyContent: `center`,
    // display: `block`,
  },
  formControl: {
    minWidth: 150,
    width: `auto`,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  formControlDate: {
    minWidth: 150,
    width: `auto`,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    float: 'left',
    paddingLeft: theme.spacing(1),
  },
  formControlLabel: {
    minWidth: 150,
    width: `auto`,
    margin: `-${theme.spacing(2)} ${theme.spacing(3)} 0`,
  },
  formControlAutocomplete: {
    minWidth: 250,
    width: `auto`,
    margin: `-13px ${theme.spacing(3)} 0`,
  },
  formControlPrint: {
    minWidth: 250,
    width: `auto`,
    margin: `0`,
  },
  dateRange: {
    position: `absolute`,
    marginTop: `58px`,
    zIndex: `999`,
  },
}))

export default function ReportHeader(props) {
  const classes = useStyles()
  const user = useContext(UserContext)

  const [filterState, setFilterState] = useState({
    filters: props.filters,
    group: props.group,
    plt: props.plt,
    subTotal: props.subTotal ? props.subTotal : [],
    selectedDate: new Date(props.vars.date),
    selectedDateFrom: new Date(props.vars.dateFrom),
    selectedDateTo: new Date(props.vars.dateTo),
  })

  const dateRange = {
    startDate: filterState.selectedDateFrom,
    endDate:
      filterState.selectedDateTo &&
      filterState.selectedDateFrom &&
      new Date(Math.max(filterState.selectedDateTo.getTime(), filterState.selectedDateFrom.getTime())),
  }

  let filters = []

  if (props.tableInfo.filters.length > 0) {
    filters = Object.assign(props.tableInfo.filters)
    filters = filters.map((filter) => {
      const options = Object.assign([], filter.options)
      if (options.length > 0 && options.length < 50) {
        options.unshift({
          __typename: 'ReportFilterOptions',
          _id: `null_${user.translate('all')}`,
          id: 'null',
          title: user.translate('all'),
        })
      }
      return {
        ...filter,
        options,
      }
    })
  }

  const filterOptions = (options, params) =>
    options.filter((option) => option.title && option.title.cirilicToLatin().indexOf(params.inputValue.cirilicToLatin()) > -1)

  const handleChangeFilter = (event, value, name) => {
    if (value.length > 0) {
      const _filters = Object.assign(filterState.filters)
      const all = value.find((c) => c.id === 'null')

      if (all) {
        const _filter = filters.find((f) => f.name === name)
        _filters[name] = _filter.options.filter((f) => f.id !== 'null')
      } else {
        _filters[name] = value
      }

      setFilterState({ ...filterState, filters: _filters })
      props.setQuery(filterState)
    } else {
      const _filters = Object.assign(filterState.filters)
      _filters[name] = []
      setFilterState({ ...filterState, filters: _filters })
      props.setQuery(filterState)
    }
  }

  const setGroups = (event) => {
    if (event.target.value !== 0) {
      setFilterState({ ...filterState, group: event.target.value })
      props.setQuery(filterState, 'group', event.target.value)
    } else {
      setFilterState({ ...filterState, group: '' })
      props.setQuery(filterState, 'group', '')
    }
  }

  const setSubTotals = (event, value) => {
    if (value.length > 0) {
      let subTotals = []
      subTotals = value

      setFilterState({ ...filterState, subTotal: subTotals })
      props.setQuery(filterState, 'subTotal', subTotals)
    } else {
      setFilterState({ ...filterState, subTotal: [] })
      props.setQuery(filterState, 'subTotal', '')
    }
  }

  const handleDateRangeAccept = (range) => {
    const filState = Object.assign(filterState)
    const dateFrom = new Date(range.startDate)
    const dateTo = addDays(range.endDate, 1)
    console.log('handleDateRangeAccept', dateFrom, dateTo)
    dateFrom.setHours(5, 0, 0, 0)
    dateTo.setHours(5, 0, 0, 0)
    filState.selectedDateFrom = dateFrom
    filState.selectedDateTo = dateTo
    setFilterState(filState)
    // setDateRange({
    //     startDate: dateFrom,
    //     endDate: dateTo
    // });
    props.setQuery(filState, 'dateRange')
  }

  if (props.receivingId) {
    return (
      <div className="no_print">
        <FormGroup row className={classes.formRow}>
          {props.displayPrint && props.plt && (
            <FormControl className={classes.formControlPrint}>
              <label htmlFor="contained-button-file" className={classes.labelUpload}>
                <Button variant="contained" color="primary" component="span" onClick={props.onPrintPlt} startIcon={<PrintIcon />}>
                  {user.translate('print')}
                </Button>
              </label>
            </FormControl>
          )}
        </FormGroup>
      </div>
    )
  }

  return (
    <div className="no_print">
      <div className={classes.formContainer}>
        <Show if={filters.length > 0}>
          <FormGroup row className={classes.formRow}>
            {filters.map((filter) => {
              return (
                <FormControl className={classes.formControl} key={'filter_' + filter.name}>
                  <Autocomplete
                    selectOnFocus
                    handleHomeEndKeys
                    multiple
                    limitTags={2}
                    size="small"
                    options={filter.options}
                    filterOptions={filterOptions}
                    name={filter.name}
                    value={filterState.filters[filter.name]}
                    onChange={(event, value) => handleChangeFilter(event, value, filter.name)}
                    isOptionEqualToValue={(option, value) => option.title === value.title}
                    getOptionLabel={(option) => option.title}
                    renderOption={(props, option, { selected }) => {
                      const selectFilmIndex =
                        filterState.filters[filter.name]?.findIndex(
                          (filter) => filter.title.toLowerCase() === user.translate('all').toLowerCase(),
                        ) ?? -1
                      if (selectFilmIndex > -1) {
                        selected = true
                      }
                      return (
                        <li {...props} style={{ fontSize: 12, padding: 0, minWidth: 180 }}>
                          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} size="small" />
                          {option.title}
                        </li>
                      )
                    }}
                    renderInput={(params) => {
                      params.inputProps.autoComplete = 'off'
                      return <TextField {...params} fullWidth label={filter.title} variant="standard" />
                    }}
                  />
                </FormControl>
              )
            })}
          </FormGroup>
        </Show>
        <Show if={Object.keys(props.tableInfo.group).length > 0 || Object.keys(props.tableInfo.subTotals).length > 0}>
          <FormGroup row className={classes.formRow}>
            {Object.keys(props.tableInfo.group).length > 0 && (
              <FormControl variant="standard" className={classes.formControlLabel}>
                <InputLabel id="group">{user.translate('group')}</InputLabel>
                <Select id="filter_group" name="filter_group" fullWidth onChange={setGroups} value={filterState.group}>
                  <MenuItem key="none" value={0}>
                    <em>None</em>
                  </MenuItem>
                  {props.tableInfo.group.map((group) => {
                    return (
                      group.title !== '' && (
                        <MenuItem key={group.name} value={group.name}>
                          {group.title}
                        </MenuItem>
                      )
                    )
                  })}
                </Select>
              </FormControl>
            )}

            {Object.keys(props.tableInfo.subTotals).length > 0 && (
              <FormControl className={classes.formControlAutocomplete}>
                <Autocomplete
                  selectOnFocus
                  handleHomeEndKeys
                  multiple
                  limitTags={4}
                  size="small"
                  options={props.tableInfo.subTotals}
                  value={filterState.subTotal}
                  filterOptions={filterOptions}
                  onChange={(event, value) => setSubTotals(event, value)}
                  getOptionLabel={(option) => option.title}
                  isOptionEqualToValue={(option, value) => option.title === value.title}
                  renderOption={(props, option, { selected }) =>
                    option.title !== '' && (
                      <div {...props}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.title}
                      </div>
                    )
                  }
                  renderInput={(params) => {
                    params.inputProps.autoComplete = 'off'
                    return <TextField {...params} fullWidth label={user.translate('sub_total')} variant="standard" />
                  }}
                />
              </FormControl>
            )}
          </FormGroup>
        </Show>

        <FormGroup row className={classes.formRow}>
          <Grid container justifyContent="flex-start" className={classes.formControlDate}>
            <DateRangePickerComponent initialDateRange={dateRange} onChange={(range) => handleDateRangeAccept(range)} />
          </Grid>
        </FormGroup>
      </div>
    </div>
  )
}
