import React, { useContext, useMemo, useState } from 'react'

import { gql, useMutation } from '@apollo/client'
import DateFnsUtils from '@date-io/date-fns'

import Check from '@mui/icons-material/Check'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { green } from '@mui/material/colors'
import makeStyles from '@mui/styles/makeStyles'
import { DateTimePicker } from '@mui/x-date-pickers'

import { UserContext } from '../../App'
import CustomTheme from '../common/CustomTheme'
import helper from '../common/Helper'

const hash = require('object-hash')

const useStyles = makeStyles((theme) => ({
  roots: {
    '& .MuiTableCell-root .MuiIconButton-root': {
      padding: theme.spacing(1),
    },
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'wrap',
    padding: 0,
    justifyContent: 'space-evenly',
  },
  item: {
    width: '100%',
    maxWidth: 320,
    minWidth: 280,
    margin: theme.spacing(1),
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '& > *': {
      height: 22,
      display: 'flex',
      alignItems: 'flex-end',
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  iconInactive: {
    marginRight: theme.spacing(1),
    opacity: 0.4,
  },
  checkedLabel: {
    marginRight: 0,
    '& .MuiFormControlLabel-label': {
      fontSize: 12,
    },
  },
  progress: {
    // paddingLeft: theme.spacing(1),
  },
  stateSection: {
    marginLeft: theme.spacing(2),
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  loading: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: theme.spacing(2),
  },
  dialogContent: {
    minWidth: 330,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      minWidth: 240,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  margin: {
    marginTop: theme.spacing(1),
  },
}))

const GQL_DELETE_RECEIVING_ITEM = gql`
  mutation ($id: String!) {
    deleteReceivingStockItem(id: $id)
  }
`
const GQL_DELETE_RECEIVING_INVOICE_ITEM = gql`
  mutation ($id: String!, $invoiceItemId: String!) {
    deleteReceivingStockItem(id: $id)
    deleteInvoiceItemReceivingItem(receivingItemId: $id)
    deleteInvoiceItem(id: $invoiceItemId)
  }
`

// React Function Component
export default function StockReceivingItemDialog(props) {
  const classes = useStyles()

  const user = useContext(UserContext)

  const [warehouseId, setWarehouseId] = useState(props.item?.warehouseId || props.warehouses.get(0)?.id || 0)
  const [stockTypeId, setStockTypeId] = useState(props.item.stockTypeId || props.stockTypes.get(0)?.id || 0)

  const stockTypesHash = hash(props.stockTypes)

  const indexedStockTypes = useMemo(
    () =>
      props.stockTypes.map((stockType) => ({
        ...stockType,
        search: `${stockType.name} ${stockType.name.cirilicToLatin()}`,
      })),
    [stockTypesHash],
  )

  const stockType = useMemo(() => indexedStockTypes.find((item) => item.id === stockTypeId), [stockTypeId, stockTypesHash])

  const unitQuantities = stockType ? [1, stockType.piece, stockType.package] : []
  const convertUom = (value, fromUnit, toUnit = 0, roundTo = 3) => {
    if (unitQuantities[fromUnit] === undefined) return undefined
    if (unitQuantities[toUnit] === undefined) return undefined
    return ((value * unitQuantities[fromUnit]) / unitQuantities[toUnit]).round(roundTo)
  }

  const propsUom = props?.item?.uomOpts || 0

  const [uomOption, setUomOption] = useState(propsUom)

  const propsQuantity = props.item?.quantity || 0
  const propsQuantityNormalized = convertUom(propsQuantity, 0, propsUom) || 0

  const [quantityVal, setQuantityVal] = useState(user.formatQuantity(propsQuantityNormalized))
  const [tax, setTax] = useState(props.item?.tax || 0)

  const propsPrice = props.item?.price_with_vat || 0 // ((props.item?.price_with_vat || 0) / (1 + tax)).round(3, 0)
  const propsPriceNormalized = convertUom(propsPrice, propsUom, 0) || 0

  const [price, setPrice] = useState(propsPriceNormalized)
  const [discount, setDiscount] = useState(props.item?.discount || 0)
  const [time, setTime] = useState(parseInt(props.item?.created) || new Date().getTime())

  const EDIT_INVOICE_ITEM = gql`
        mutation (
            $id: String!, 
            $invoiceItemId: String!, 
            $invoiceId: String!, 
            $receivingStockId: String!, 
            $warehouseId: String!, 
            $stockTypeId: String!, 
            $article: String!, 
            $description: String!, 
            $quantity: Float!, 
            $uomOpts: Long!,
            $invoiceQuantity: Float!,
            $price: Float!, 
            $tax: Float!, 
            $discount: Float!, 
            $created: Long!, 
            $updated: Long!
            ) {
            createReceivingStockItem(input: {
                id: $id
                receivingStockId: $receivingStockId
                warehouseId: $warehouseId
                stockType: $stockTypeId
                quantity: $quantity
                uomOpts: $uomOpts
                price: $price
                tax: $tax
                discount: $discount
                created: $created
                updated: $updated
            }) {
                id
            }
            createInvoiceItem(input: {
                id: $invoiceItemId
                ${user.gqlCreateName('$article', false, 'Article')}
                ${user.gqlCreateName('$description', false, 'Description')}
                quantity: $invoiceQuantity
                price: $price
                tax: $tax
                discount: $discount
                invoiceId: $invoiceId
            }){
                id
            }
            createInvoiceItemReceivingItem(input: {
                invoiceItemId: $invoiceItemId,
                receivingItemId: $id
            }) {
                invoiceItemId
            }
    }`
  const EDIT_ITEM = gql`
    mutation (
      $id: String!
      $receivingStockId: String!
      $warehouseId: String!
      $stockTypeId: String!
      $quantity: Float!
      $uomOpts: Long!
      $price: Float!
      $tax: Float!
      $discount: Float!
      $created: Long!
      $updated: Long!
    ) {
      createReceivingStockItem(
        input: {
          id: $id
          receivingStockId: $receivingStockId
          warehouseId: $warehouseId
          stockType: $stockTypeId
          quantity: $quantity
          uomOpts: $uomOpts
          price: $price
          tax: $tax
          discount: $discount
          created: $created
          updated: $updated
        }
      ) {
        id
      }
    }
  `
  const [saveItem, { loading: loadingSave }] = useMutation(EDIT_ITEM)
  const [saveInvoiceItem, { loading: loadingInvoiceSave }] = useMutation(EDIT_INVOICE_ITEM)

  const [deleteItem, { loading: loadingDeleteItem }] = useMutation(GQL_DELETE_RECEIVING_ITEM)
  const [deleteInvoiceItem, { loading: loadingDeleteInvoiceItem }] = useMutation(GQL_DELETE_RECEIVING_INVOICE_ITEM)

  const isLoading = loadingSave || loadingInvoiceSave || loadingDeleteItem || loadingDeleteInvoiceItem

  if (!props.item) return <></>

  const onCloseClick = () => {
    props.onClose(false)
  }

  const inputQuantity = user.parseStringNumber(quantityVal.toString().trim().split(' ')[0])
  const quantity = !isNaN(inputQuantity) && inputQuantity !== 0 ? convertUom(inputQuantity, uomOption, 0) : ''

  const uom = stockType.uom

  const uomUnit = ['L', 'Kg', 'p']

  const uomOptions = [user.translate('liter'), user.translate('kilogram'), user.translate('piece')]

  const pieceQuantity = `${stockType.piece} ${uomUnit[uom]}`
  const uomOptionPiece = [
    { label: `${user.translate('dose')} (${pieceQuantity})` },
    { label: `${user.translate('dose')} (${pieceQuantity})` },
    { label: `${user.translate('dose')} (${pieceQuantity})` },
  ]

  const packageQuantity = `${stockType.package} ${uomUnit[uom]}`
  const uomOptionPackage = [
    { label: `${user.translate('package')} / ${user.translate('bottle')} (${packageQuantity})` },
    { label: `${user.translate('package')} / ${user.translate('bag')} (${packageQuantity})` },
    { label: `${user.translate('package')} / ${user.translate('box')} (${packageQuantity})` },
  ]

  const uomTitle = uomOptions[uom]
  const uomTitleCap = uomTitle.capitalize(true)
  const uomName = uomUnit[uom]
  const uomPiece = uomOptionPiece[uom]
  const uomPackage = uomOptionPackage[uom]

  const optionUomQuantity = [uomName, `(${pieceQuantity})`, `(${packageQuantity})`]
  const optionUomQuantityUom = [uomTitleCap, pieceQuantity.toString(), packageQuantity.toString()]

  const uomOptionName = optionUomQuantity[uomOption]
  const uomOptionNameUom = optionUomQuantityUom[uomOption]

  const pricePerUnit = convertUom(price, 0, uomOption)

  const pricePerUnitWithoutTax = (pricePerUnit / (1 + tax)).round(3, 0)

  const taxOptions = [
    { val: 0, label: '0%' },
    { val: 0.05, label: '5%' },
    // { val: 0.1, label: "10%" },
    { val: 0.18, label: '18%' },
  ]

  const systemQuantity = uom === 2 ? quantity : quantity * 1000

  const onUomOptionClick = (option) => {
    if (quantityVal.length > 0) {
      setQuantityVal(user.formatQuantity(convertUom(quantity, 0, option)))
    }
    setPrice(convertUom(price, option, uomOption, 3))

    setUomOption(option)
  }

  const onSaveClick = () => {
    const variables = {
      id: props.item?.id || helper.uid(),
      receivingStockId: props.item.receivingStockId,
      warehouseId,
      stockTypeId,
      quantity: systemQuantity,
      uomOpts: uomOption,
      price: pricePerUnitWithoutTax,
      tax,
      discount: (discount / 100).round(4, 0),
      created: time.toString(),
      updated: new Date().getTime().toString(),
    }

    let invoiceItemIds = props.item.receivingItemInvoiceItems?.map((receivingInvoiceItem) => receivingInvoiceItem.invoiceItemId) || []
    if (invoiceItemIds.length === 0 && props.invoiceId) {
      invoiceItemIds = [helper.uid()]
    }

    if (invoiceItemIds.length === 0) {
      saveItem({ variables }).then((e) => {
        props.onSuccess()
        props.onClose(false)
      })
    } else {
      variables.invoiceId = props.invoiceId
      variables.article = stockType.name
      variables.description = ''
      variables.invoiceQuantity = quantity
      invoiceItemIds
        .reduce((acc, invoiceItemId) => {
          const opts = { variables: { ...variables, invoiceItemId, invoiceId: props.invoiceId } }
          if (acc === false) return saveInvoiceItem(opts)
          return acc.then(() => saveInvoiceItem(opts))
        }, false)
        .then((e) => {
          props.onSuccess()
          props.onClose(false)
        })
    }
  }

  const onDeleteClick = () => {
    const invoiceItemIds = props.item.receivingItemInvoiceItems?.map((receivingInvoiceItem) => receivingInvoiceItem.invoiceItemId) || []

    if (invoiceItemIds.length === 0) {
      deleteItem({
        variables: { id: props.item.id },
      }).then((e) => {
        props.onSuccess()
        props.onClose(false)
        props.onDelete(props.item)
      })
    } else {
      invoiceItemIds
        .reduce((acc, invoiceItemId) => {
          const opts = { variables: { id: props.item.id, invoiceItemId } }
          if (acc === false) return deleteInvoiceItem(opts)
          return acc.then(() => deleteInvoiceItem(opts))
        }, false)
        .then((e) => {
          props.onSuccess()
          props.onClose(false)
          props.onDelete(props.item)
        })
    }
  }

  const priceInfo =
    tax === 0
      ? `${pricePerUnit} ден/${uomTitleCap}`
      : `${pricePerUnit} (${pricePerUnitWithoutTax} ${user.translate('without_tax')}) ден/${uomTitleCap}`

  const discountMultiplier = (1 - discount / 100).round(4, 1)
  const discountInfo =
    tax === 0
      ? `${(pricePerUnit * discountMultiplier).round(2)} ден/${uomName}`
      : `${(pricePerUnit * discountMultiplier).round(2)} (${(pricePerUnitWithoutTax * discountMultiplier).round(2)} ${user.translate(
          'without_tax',
        )}) ден/${uomName}`

  const dateTime = new Date(time)

  const buttonWarehouseTheme = {
    primary: {
      main: green[700],
      contrastText: '#fff',
    },
    secondary: {
      main: green[700],
      contrastText: '#fff',
    },
  }

  return (
    <Dialog
      open={Boolean(props.item)}
      onClose={onCloseClick}
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{user.translate('receiving')}</DialogTitle>
      {Boolean(isLoading) && <CircularProgress size={24} className={classes.loading} />}
      <DialogContent className={classes.dialogContent}>
        <CustomTheme {...buttonWarehouseTheme}>
          <ButtonGroup fullWidth="true" size="small" color="secondary" aria-label="outlined primary button group" disabled={isLoading}>
            {props.warehouses.map((warehouse) => (
              <Button
                key={warehouse.id}
                endIcon={warehouse.id === warehouseId && <Check />}
                variant={warehouse.id !== warehouseId && 'contained'}
                onClick={() => setWarehouseId(warehouse.id)}
              >
                {warehouse.name}
              </Button>
            ))}
          </ButtonGroup>
        </CustomTheme>

        <Autocomplete
          fullWidth
          disabled={isLoading}
          id="input-stockType"
          options={indexedStockTypes}
          value={stockType}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          filterOptions={(options, state) => {
            const query = state.inputValue.cirilicToLatin()
            return options.filter((item) => item.search.indexOf(query) > -1)
          }}
          onChange={(event, value, reason) => {
            if (reason === 'select-option') {
              setStockTypeId(value?.id)
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label={user.translate('stock_type')} margin="normal" variant="outlined" value={stockType.name} />
          )}
        />

        <ButtonGroup fullWidth size="small" color="primary" aria-label="outlined primary button group" disabled={isLoading}>
          <Button style={{ fontSize: 12 }} variant={uomOption !== 0 && 'contained'} onClick={() => onUomOptionClick(0)}>
            {uomTitleCap}
          </Button>
          {/* <Button style={{ fontSize: 12 }} variant={uomOption !== 1 && "contained"} onClick={() => onUomOptionClick(1)}>{uomPiece.label}</Button> */}
          <Button style={{ fontSize: 12 }} variant={uomOption !== 2 && 'contained'} onClick={() => onUomOptionClick(2)}>
            {uomPackage.label}
          </Button>
        </ButtonGroup>
        <Autocomplete
          disabled={isLoading}
          id="input-quantity"
          freeSolo
          options={[...Array(50).keys()].map((option) => `${option + 1} ${uomOptionName}`)}
          inputValue={quantityVal}
          onInputChange={(event, newInputValue, reason) => {
            if (reason === 'reset' && newInputValue.length === 0) return

            const filteredInputValue = newInputValue.indexOf(uomOptionName) > -1 ? newInputValue.split(' ')[0] : newInputValue.trim()
            const value = user.parseStringNumber(filteredInputValue)
            if (isNaN(value)) {
              setQuantityVal('')
            } else {
              const formatedVal = user.formatQuantity(value)
              if (newInputValue !== formatedVal + ',' && newInputValue !== formatedVal + '.') {
                setQuantityVal(formatedVal)
              } else {
                setQuantityVal(newInputValue)
              }
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${user.translate('quantity')} (${uomOptionNameUom})`}
              margin="normal"
              variant="outlined"
              value={quantityVal}
              type="text"
            />
          )}
        />
        <TextField
          id={`input_price_${uomOption}`}
          key={`input_price_${uomOption}`}
          label={`${user.translate('price_with_vat')} - ${uomOptionName}`}
          helperText={<div style={{ fontSize: 10 }}>{priceInfo}</div>}
          type="text"
          defaultValue={isNaN(price) || price === 0 ? '' : user.formatQuantity(price)}
          style={{ marginRight: 8, whiteSpace: 'nowrap' }}
          className={classes.margin}
          onChange={(e) => setPrice(user.parseStringNumber(e.target.value))}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControl className={classes.margin}>
          <InputLabel shrink id="demo-customized-select-label">
            {user.translate('tax')}
          </InputLabel>
          <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={tax}
            onChange={(event, value, reason) => {
              setTax(event.target.value)
            }}
          >
            {taxOptions.map((option) => (
              <MenuItem key={option.label} value={option.val}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          id="input_discount"
          label={`${user.translate('discount')} %`}
          helperText={<div style={{ fontSize: 10 }}>{discountInfo}</div>}
          type="number"
          defaultValue={discount.toString()}
          style={{ marginRight: 8, whiteSpace: 'nowrap' }}
          className={classes.margin}
          onChange={(e) => setDiscount(user.parseStringNumber(e.target.value))}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <DateTimePicker
          animateYearScrolling
          ampm={false}
          autoOk
          variant="inline"
          format={user.dateTimeFormat}
          id="datetime-local"
          value={new Date(time)}
          label={user.translate('time')}
          onKeyPress={(e) => {
            if (e.key === 'Enter') setTime(e.target.value.getTime())
          }}
          onChange={(date) => setTime(date.getTime())}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <IconButton onClick={onDeleteClick} color="primary" disabled={isLoading} style={{ float: 'left' }} size="large">
          <DeleteIcon style={{ color: '#F00' }} />
        </IconButton>
        <div style={{ flex: 1 }} />
        <Button onClick={onCloseClick} color="primary" disabled={isLoading}>
          {user.translate('cancel')}
        </Button>
        <Button onClick={onSaveClick} color="primary" autoFocus disabled={isLoading}>
          {user.translate('save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
