import React, { useState, useContext, useLayoutEffect } from 'react'

import { useMutation, gql } from '@apollo/client'

import { UserContext } from '../../../App'
import Report from '../../common/Report'
import SnackBarDelete from '../../common/SnackBarDelete'

// React Function Component
export default function InventoryHistory(props) {
  const user = useContext(UserContext)

  const startDate = new Date()
  const endDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000)
  startDate.setHours(0, 0, 0, 0)
  endDate.setHours(5, 0, 0, 0)

  const [refetchIt, setRefetchIt] = useState(false)
  const [page, setPage] = useState(0)
  const [deleteIt, setDeleteIt] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [deleteState, setDeleteState] = useState({
    variables: {},
    name: '',
    state: {},
    unDeleteItem: ``,
  })

  const DELETE_STOCK_TRANSFER = gql`
    mutation ($id: String!) {
      deleteStockTransfer(id: $id)
    }
  `
  const [deleteStockTransfer, { loading: loading_stockTransfer }] = useMutation(DELETE_STOCK_TRANSFER)

  const DELETE_INVENTORY = gql`
    mutation ($id: String!) {
      deleteInventory(id: $id)
    }
  `
  const [deleteInventory, { loading: loading_inventory }] = useMutation(DELETE_INVENTORY)

  const UNDELETE_STOCK_TRANSFER = `
        mutation ($id: String!) {
            undeleteStockTransfer(id: $id)
    }`

  const UNDELETE_INVENTORY = `
        mutation ($id: String!) {
            undeleteInventory(id: $id)
    }`

  const onDeleteItem = (oldData, state) => {
    const items = {}
    items.id = oldData.sourceId

    if (oldData.type === 'TRANSFER_IN' || oldData.type === 'TRANSFER_OUT') {
      deleteStockTransfer({ variables: items })

      setDeleteState({
        variables: items,
        name: oldData.stockType,
        state,
        unDeleteItem: UNDELETE_STOCK_TRANSFER,
      })
      setDeleteIt(true)
      setOpenSnackBar(true)
    }
    if (oldData.type === 'INVENTORY') {
      deleteInventory({ variables: items })

      setDeleteState({
        variables: items,
        name: oldData.stockType,
        state,
        unDeleteItem: UNDELETE_INVENTORY,
      })
      setDeleteIt(true)
      setOpenSnackBar(true)
    }
  }

  useLayoutEffect(() => {
    const style = document.createElement('style')
    style.innerHTML = `
            .MuiTableCell-root .MuiIconButton-root {
                padding: 3px;
            }
        `
    document.head.appendChild(style)
  }, [])

  if (!loading_inventory && !loading_stockTransfer && deleteIt) {
    setDeleteIt(false)
    setRefetchIt(true)
  }

  const params = {
    query: {
      name: 'report_inventoryHistory',
      dateInit: '$dateFrom: Long!, $dateTo: Long!',
      date: 'dateFrom: $dateFrom, dateTo: $dateTo',
      dateVars: {
        dateFrom: startDate.getTime(),
        dateTo: endDate.getTime(),
      },
      results: `
                stockTypeId
                stockType
                employee
                warehouseId
                warehouse
                uom
                type
                typeText
                quantity
                comment
                created
                sourceId
                avgTotalPrice
                avgSellPrice
            `,
      columnsPush: ['stockTypeId', 'warehouseId', 'sourceId'],
    },
    title: user.translate('inventory_history'),
    titleName: user.translate('inventoryhistory'),
    InventoryAdjustments: user.role > 2,
    editable: user.role > 2,
    onDeleteItem,
    refetchIt,
    setRefetchIt,
    key: 'inventory_history_' + refetchIt,
    page,
    setPage,
    datetime: true,
  }

  return (
    <>
      {!loading_stockTransfer && !loading_inventory && <Report {...params} />}

      {openSnackBar && (
        <SnackBarDelete
          message={`${deleteState.name} ${user.translate('deleted')}!`}
          variables={deleteState.variables}
          openSnackBar={openSnackBar}
          setOpenSnackBar={setOpenSnackBar}
          unDeleteItem={deleteState.unDeleteItem}
        />
      )}
    </>
  )
}
