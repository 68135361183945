import React, { useContext, useState } from 'react'

import { gql, useQuery } from '@apollo/client'

import { Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import InvoiceApplyReceipt from './InvoiceApplyReceipt'
import InvoiceItemVatView from './InvoiceItemVatView'
import InvoiceItemView from './InvoiceItemView'
import { RestaurantContext, UserContext } from '../../../App'
import Add from '../../common/Add'
import Delete from '../../common/Delete'
import FormInvoice from '../../common/FormInvoice'
import helper from '../../common/Helper'
import ProductDialog from '../../common/ProductDialog'
import Show from '../../common/Show'
import SnackBarDelete from '../../common/SnackBarDelete'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    top: theme.spacing(2),
  },
  itemName: {
    position: 'absolute',
    right: 0,
    top: -32,
  },
  table: {
    '& .MuiTableCell-root': {
      fontSize: 12,
      padding: 2,
    },
  },
  billingStyles: {
    float: `right`,
  },
  total: {
    fontSize: 14,
    fontWeight: '700',
    '&.MuiTableCell-root': {
      fontSize: 12,
      padding: 2,
    },
  },
}))

export default function InvoiceItemTable(props) {
  const classes = useStyles()
  // Get current user preferences (language)
  const user = useContext(UserContext)
  const restaurant = useContext(RestaurantContext)
  const invoice = props.invoice

  const [updatedAmount, setUpdatedAmount] = useState(false)

  const [openAddBox, setOpenAddBox] = useState(false)
  const [selectedItem, setSelectedItem] = useState({
    product: {},
  })
  const [openAddRow, setOpenAddRow] = useState(false)
  const [openAddReceipt, setOpenAddReceipt] = useState(false)
  const [openAddProduct, setOpenAddProduct] = useState(false)
  const [openDeleteBox, setOpenDeleteBox] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [deleteState, setDeleteState] = useState({
    name: '',
    variables: {},
  })

  const cancelFieldListItems = [
    {
      required: true,
      type: 'text',
      fieldType: 'text',
      fieldName: 'article',
      fieldLabel: user.translate('article'),
      fieldValue: '',
      error: true,
    },
    {
      required: false,
      type: 'text',
      fieldType: 'text',
      fieldName: 'description',
      fieldLabel: user.translate('description'),
      fieldValue: '',
    },
    {
      required: true,
      type: 'number',
      fieldType: 'text',
      fieldName: 'quantity',
      fieldLabel: user.translate('quantity'),
      fieldValue: 0,
      decimalScale: 3,
    },
    {
      required: true,
      type: 'number',
      fieldType: 'text',
      fieldName: 'price',
      fieldLabel: user.translate('price'),
      fieldValue: 0,
    },
    {
      required: true,
      type: 'number',
      fieldType: 'text',
      fieldName: 'discount',
      fieldLabel: user.translate('discount'),
      fieldValue: invoice.discount ? invoice.discount * 100 : 0,
      decimalScale: 4,
    },
    {
      required: true,
      type: props.devizna ? 'hidden' : 'text',
      fieldType: props.devizna ? 'text' : 'select',
      fieldName: 'tax',
      fieldLabel: user.translate('vat'),
      fieldValue: '0',
      fieldOptions: props.taxTypes,
      noNone: true,
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'id',
      fieldLabel: 'id',
      fieldValue: helper.uid(),
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'invoiceId',
      fieldLabel: 'invoiceId',
      fieldValue: invoice.id,
    },
  ]
  const [fieldListItems, setFieldListItems] = useState([...cancelFieldListItems])

  const setReturnItemsCancel = () => {
    let _items = {};
      const returnObj = {}
    for (const idx in cancelFieldListItems) {
      returnObj[cancelFieldListItems[idx].fieldName] = cancelFieldListItems[idx].fieldValue
    }
    _items = {
      ...returnObj,
      discount: returnObj.discount / 100,
      tax: returnObj.tax / 100,
    }
    return _items
  }

  const [returnItems, setReturnItems] = useState(setReturnItemsCancel())

  const DELETE_ITEM = `
        mutation ($id: String!) {
            deleteInvoiceItem(id: $id)
    }`

  const UNDELETE_ITEM = `
        mutation ($id: String!) {
            undeleteInvoiceItem(id: $id)
    }`

  const ADD_INVOICE_ITEM_PRODUCT = `
        mutation ($id: String!, $invoiceId: String!, $productId: String!, $warehouseId: String!, $article: String!, $description: String!, $quantity: Float!, $price: Float!, $tax: Float!, $discount: Float! ) {
            createInvoiceItem(input: {
                id: $id
                invoiceId: $invoiceId
                quantity: $quantity
                ${user.gqlCreateName('$article', false, 'Article')}
                ${user.gqlCreateName('$description', false, 'Description')}
                price: $price
                tax: $tax
                discount: $discount
            }) {
                id
            }
            createInvoiceItemProduct( input: {
                invoiceItemId: $id,
                productId: $productId
                warehouseId: $warehouseId
            }) {
                invoiceItemId
                productId
                warehouseId
            }
        }`

  // GraphQL API request definition (local variables: restaurantId)
  const GET_DATA_BY_RESTAURANT = gql`
    query ($restaurantId: String!){
        getWarehousesByContextRestaurantId(restaurantId: $restaurantId) {
			id
			name {
				${user.gqlFetchName()}
			}
        }
    }`

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const { data, loading } = useQuery(GET_DATA_BY_RESTAURANT, {
    variables: { restaurantId: restaurant.id },
    skip: !openAddBox,
    pollInterval: 3000,
  })

  // If it is loading, show progress bar
  // if (loading) return <CircularProgress />
  if (loading) {
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    )
  }

  let warehouses;
    const warehousesFieldOptions = [];
    const tempWarehouses = []

  if (data) {
    warehouses = data.getWarehousesByContextRestaurantId
  }

  for (const index in warehouses) {
    if (tempWarehouses.indexOf(warehouses[index].id) === -1) {
      warehousesFieldOptions.push({
        val: warehouses[index].id,
        label: warehouses[index].name[user.lang],
      })
      tempWarehouses.push(warehouses[index].id)
    }
  }

  const firstWarehouseId = warehousesFieldOptions.slice(0, 1)[0]

  const fieldListItemProduct = [
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'id',
      fieldLabel: 'id',
      fieldValue: helper.uid(),
    },
    {
      required: true,
      type: 'text',
      fieldType: 'text',
      fieldName: 'article',
      fieldLabel: user.translate('article'),
      fieldValue: selectedItem.product.id ? selectedItem.product.name : '',
    },
    {
      required: false,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'productId',
      fieldLabel: 'productId',
      fieldValue: selectedItem.product.id ? selectedItem.product.id : '',
    },
    {
      required: false,
      type: 'text',
      fieldType: 'text',
      fieldName: 'description',
      fieldLabel: user.translate('description'),
      fieldValue: ' ', // selectedItem.product.id ? selectedItem.product.productionCategory.name[user.lang] : "",
    },
    {
      required: true,
      type: 'autocomplete',
      fieldType: 'autocomplete',
      fieldName: 'warehouseId',
      fieldLabel: user.translate('warehouse'),
      fieldValue: firstWarehouseId,
      fieldOptions: warehousesFieldOptions,
    },
    {
      required: true,
      type: 'number',
      fieldType: 'text',
      fieldName: 'quantity',
      fieldLabel: user.translate('quantity'),
      fieldValue: 1,
      decimalScale: 3,
    },
    {
      required: true,
      type: 'number',
      fieldType: 'text',
      fieldName: 'price',
      fieldLabel: user.translate('price'),
      fieldValue: selectedItem.product.id ? selectedItem.product.price : '',
    },
    {
      required: true,
      type: 'number',
      fieldType: 'text',
      fieldName: 'discount',
      fieldLabel: user.translate('discount'),
      fieldValue: selectedItem.product.discountable && invoice.discount ? invoice.discount * 100 : 0,
    },
    {
      required: true,
      type: 'text',
      fieldType: 'select',
      fieldName: 'tax',
      fieldLabel: user.translate('vat'),
      fieldValue: selectedItem.product.taxType ? '' + selectedItem.product.taxType.rate * 100 : '0',
      fieldOptions: props.taxTypes,
      noNone: true,
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'invoiceId',
      fieldLabel: 'invoiceId',
      fieldValue: invoice.id,
    },
  ]

  const toggleAddRow = () => {
    if (!openAddRow) {
      setFieldListItems([...cancelFieldListItems])
      setReturnItems(setReturnItemsCancel())
    }
    setOpenAddRow(!openAddRow)
    setOpenAddReceipt(false)
  }
  const closeAddRow = () => {
    setFieldListItems([...cancelFieldListItems])
    setReturnItems(setReturnItemsCancel())
    setOpenAddRow(false)
    setOpenAddReceipt(false)
  }

  const onAddReceipt = () => {
    setOpenAddReceipt(true)
  }

  const onAddProduct = () => {
    setOpenAddProduct(true)
  }

  let total = 0;
    let subTotal = 0;
    let totalVat = 0
  const basis = [];
    const vatVal = [];
    const _total = [];
    const totalVatView = []

  invoice.invoiceItems.forEach((invoiceItem) => {
    const taxVal = props.devizna ? 0 : invoiceItem.tax * 100;
      const price = invoiceItem.price * (1 - invoiceItem.discount);
      const quantity = invoiceItem.quantity;
      const tax = props.devizna ? 0 : invoiceItem.tax

    if (typeof basis[taxVal] === 'undefined') basis[taxVal] = price * quantity
    else basis[taxVal] += price * quantity

    if (typeof vatVal[taxVal] === 'undefined') vatVal[taxVal] = tax * price * quantity
    else vatVal[taxVal] += tax * price * quantity

    if (typeof _total[taxVal] === 'undefined') _total[taxVal] = (1 + tax) * price * quantity
    else _total[taxVal] += (1 + tax) * price * quantity

    totalVatView[taxVal] = {
      tax: taxVal,
      basis: user.formatNumber(basis[taxVal]),
      vatVal: user.formatNumber(vatVal[taxVal]),
      total: user.formatNumber(Math.round(_total[taxVal])),
    }

    subTotal += price * quantity
    totalVat += tax * price * quantity
  })

  const _totalAmount = (subTotal + totalVat).round(props.devizna ? 2 : 0, 0)
  subTotal = user.formatNumber(subTotal)
  totalVat = user.formatNumber(totalVat) // amount
  total = user.formatNumber(_totalAmount)

  if (props.setTotal && (!props.total || (props.total && props.total !== total))) {
    props.setTotal(total)
  }

  if (invoice.amount !== _totalAmount && !updatedAmount) {
    const field = {
      fieldType: `$amount: Float`,
      field: 'amount',
    }
    const _items = {
      id: invoice.id,
      amount: _totalAmount,
    }
    props.onEditInvoice(_items, field, false)
    setUpdatedAmount(true)
    user.consoleLog(_items)
  }

  const setItemsAdd = (event, name, value) => {
    let items = Object.assign(returnItems)

    items = function () {
      items[name] = name === 'discount' || name === 'tax' ? value / 100 : value
      return items
    }.bind(name, value)()

    user.consoleLog(items)

    setReturnItems({ ...items })
  }

  const checkErrorFunction = () => {
    for (const idx in fieldListItems) {
      if (fieldListItems[idx].required && returnItems[fieldListItems[idx].fieldName] === '') {
        return true
      }
    }

    return false
  }

  const onAddRowAccept = () => {
    toggleAddRow()
    props.onAddInvoiceItem(returnItems)
    setUpdatedAmount(false)
  }

  const onAddRowSubmit = (event) => {
    switch (event.key) {
      case 'Enter':
        if (checkErrorFunction()) {
          break
        } else {
          onAddRowAccept()
          break
        }
      case 'Escape':
        closeAddRow()
        break
      default:
        break
    }
  }

  const onEditInvoiceItemDiscount = (event, name, value) => {
    const _items = {
      invoiceId: invoice.id,
      discount: value / 100,
    }
    user.consoleLog(_items)
    props.onEditInvoiceDiscount(_items)
    setUpdatedAmount(false)
  }

  // styles={classes.billingStyles}

  // tabIndex = 26

  return (
    <>
      <div
        className="no_print"
        style={{
          float: 'left',
          clear: 'both',
          marginTop: 30,
          display: 'inline-flex',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <FormInvoice
          fieldList={fieldListItems}
          item={4}
          tabIndex={22}
          setFieldList={setFieldListItems}
          setItems={onEditInvoiceItemDiscount}
        />
      </div>

      <div
        style={{
          float: 'left',
          clear: 'both',
          marginTop: 30,
          display: 'inline-flex',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width="32" className="no_print" />
                <TableCell>{user.translate('article')}</TableCell>
                <TableCell>{user.translate('description')}</TableCell>
                <TableCell align="right">{user.translate('quant')}</TableCell>
                <TableCell align="right">{!props.devizna ? user.translate('price_without_vat') : user.translate('price')}</TableCell>
                <TableCell align="right">{user.translate('discount')}</TableCell>
                <TableCell align={!props.devizna ? 'right' : 'right'}>{user.translate('price_with_discount')}</TableCell>
                {!props.devizna && <TableCell align="right">{user.translate('vat')}</TableCell>}
                <TableCell align="right">{!props.devizna ? user.translate('total_without_vat') : user.translate('total')}</TableCell>
                <TableCell width="32" className="no_print" />
              </TableRow>
            </TableHead>
            <TableBody>
              {invoice.invoiceItems.map((invoiceItem) => (
                <InvoiceItemView
                  key={'invoice_item_table_' + invoiceItem.id + invoiceItem.discount}
                  invoice={invoice}
                  devizna={props.devizna}
                  invoiceItem={invoiceItem}
                  invoiceReceivingItems={props.invoiceReceivingItems}
                  onEditInvoiceItem={props.onEditInvoiceItem}
                  taxTypes={props.taxTypes}
                  firstTaxType={props.firstTaxType}
                  setDeleteState={setDeleteState}
                  setOpenDeleteBox={setOpenDeleteBox}
                  setUpdatedAmount={setUpdatedAmount}
                />
              ))}

              <Show if={openAddRow === true}>
                <TableRow key={'invoice_item_open_add_row' + openAddRow} onKeyDown={onAddRowSubmit}>
                  <TableCell />
                  {Object.keys(fieldListItems).map((item, index) =>
                    index === 0 ? (
                      <TableCell key={'invoice_item_open_add_row_' + item.fieldValue + index} component="th" scope="row">
                        <FormInvoice
                          key={'invoice_item_open_add_row_' + item.fieldValue + index}
                          fieldList={fieldListItems}
                          item={index}
                          setFieldList={setFieldListItems}
                          setItems={setItemsAdd}
                          width="100%"
                          left
                          addRow
                        />
                      </TableCell>
                    ) : (
                      <TableCell key={'invoice_item_open_add_row_' + item.fieldValue + index} align={index === 1 ? 'inherit' : 'right'}>
                        <FormInvoice
                          key={'invoice_item_open_add_row_' + item.fieldValue + index}
                          fieldList={fieldListItems}
                          item={index}
                          setFieldList={setFieldListItems}
                          setItems={setItemsAdd}
                          width="100%"
                          left
                          addRow
                        />
                      </TableCell>
                    ),
                  )}
                  <TableCell />
                </TableRow>
              </Show>

              <Show if={openAddReceipt === true}>
                <InvoiceApplyReceipt
                  invoice={invoice}
                  openApply={openAddReceipt}
                  setOpenAddReceipt={setOpenAddReceipt}
                  setUpdatedAmount={setUpdatedAmount}
                  onAddInvoiceItemReceipt={props.onAddInvoiceItemReceipt}
                />
              </Show>

              <Show if={openAddProduct === true}>
                <ProductDialog
                  restaurant={restaurant}
                  setOpenSelectBox={setOpenAddProduct}
                  setSelectedItem={setSelectedItem}
                  action={setOpenAddBox}
                />
              </Show>

              <Show if={openAddBox}>
                <Add
                  fieldList={fieldListItemProduct}
                  openManipulateBox={setOpenAddBox}
                  actionType={user.translate('add')}
                  restaurant={restaurant}
                  name={user.translate('invoice_item_product')}
                  manipulateItem={ADD_INVOICE_ITEM_PRODUCT}
                  product
                  invoice={invoice}
                  onSuccess={props.onSuccess}
                />
              </Show>

              <Show if={openAddRow === true}>
                <>
                  {!props.devizna && (
                    <>
                      <TableRow key="invoice_item_row_total">
                        <TableCell className="no_print" />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell align="right">{user.translate('total')}</TableCell>
                        <TableCell align="right">{subTotal}</TableCell>
                        <TableCell />
                      </TableRow>
                      <TableRow key="invoice_item_row_total1">
                        <TableCell className="no_print" />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell align="right">{user.translate('vat')}</TableCell>
                        <TableCell align="right">{totalVat}</TableCell>
                        <TableCell />
                      </TableRow>
                    </>
                  )}
                  <TableRow key="invoice_item_row_total2">
                    <TableCell className="no_print" />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    {!props.devizna && <TableCell />}
                    <TableCell colSpan={2} align="right" className={total !== 0 ? classes.total : ''}>
                      {user.translate('to_pay')}
                    </TableCell>
                    <TableCell align="right" className={total !== 0 ? classes.total : ''}>
                      {total}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </>
              </Show>

              <TableRow key="invoice_item_close_add_row" className="no_print">
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                {!props.devizna && <TableCell />}
                <Show if={!openAddRow}>
                  <>
                    <TableCell align="right">
                      <Button onClick={onAddProduct} color="primary">
                        {user.translate('add_product')}
                      </Button>
                    </TableCell>
                    <TableCell align="right">
                      <Button onClick={onAddReceipt} color="primary">
                        {user.translate('add_receipt')}
                      </Button>
                    </TableCell>
                    <TableCell align="right">
                      <Button onClick={toggleAddRow} color="primary">
                        {user.translate('add_invoice_item')}
                      </Button>
                    </TableCell>
                  </>
                </Show>
                <Show if={openAddRow}>
                  <>
                    <TableCell align="right">
                      <Button onClick={closeAddRow} color="primary">
                        {user.translate('cancel')}
                      </Button>
                    </TableCell>
                    <TableCell align="right">
                      <Button onClick={onAddRowAccept} autoFocus color="primary" disabled={checkErrorFunction()}>
                        {user.translate('save')}
                      </Button>
                    </TableCell>
                  </>
                </Show>
                <TableCell />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Show if={!props.devizna}>
        <div
          style={{
            float: 'left',
            clear: 'both',
            marginTop: 30,
            display: 'inline-flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <TableContainer component={Paper} style={{ width: 600 }}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" width="110">
                    {user.translate('tax_rate')}
                  </TableCell>
                  <TableCell align="right">{user.translate('base')}</TableCell>
                  <TableCell align="right">{user.translate('vat')}</TableCell>
                  <TableCell align="right">{user.translate('value')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {totalVatView.map((taxItem) => (
                  <InvoiceItemVatView key={'invoice_item_tax_' + taxItem.tax} invoice={invoice} taxItem={taxItem} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Show>

      <Show if={openDeleteBox}>
        <Delete
          name={user.translate('invoice_item') + ' ' + deleteState.name}
          variables={deleteState.variables}
          setOpenSnackBar={setOpenSnackBar}
          setOpenDeleteBox={setOpenDeleteBox}
          deleteItem={DELETE_ITEM}
          onSuccess={props.onSuccess}
          setUpdatedAmount={setUpdatedAmount}
        />
      </Show>

      <Show if={openSnackBar}>
        <SnackBarDelete
          hideUndo
          message={user.translate('invoice_item') + ' ' + deleteState.name + ` ${user.translate('deleted')}!`}
          variables={deleteState.variables}
          openSnackBar={openSnackBar}
          setOpenSnackBar={setOpenSnackBar}
          unDeleteItem={UNDELETE_ITEM}
          onSuccess={props.onSuccess}
        />
      </Show>
    </>
  )
}
