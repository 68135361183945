import React from 'react'

import PropTypes from 'prop-types'
import Routes, { Case, Default } from 'react-switch-case'

import DoneIcon from '@mui/icons-material/Done'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

export default function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" size={34} value={100} style={{ color: '#CACACA', position: 'absolute' }} />
      <CircularProgress variant="determinate" size={34} {...props} />

      <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
        <Routes condition={props.value}>
          <Case value={100}>
            <DoneIcon />
          </Case>
          <Default>
            <Typography variant="caption" component="div" color={props.textColor || 'textSecondary'}>{`${Math.round(
              props.value,
            )}%`}</Typography>
          </Default>
        </Routes>
      </Box>
    </Box>
  )
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
}
