import { green, red } from '@mui/material/colors'
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles'

export default function CustomTheme(props) {
  const customTheme = createTheme({
    palette: {
      primary: props.primary || {
        main: green[500],
        contrastText: '#fff',
        dark: green[900],
      },
      secondary: props.secondary || {
        main: red[500],
        contrastText: '#fff',
      },
    },
  })

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={customTheme}>{props.children}</ThemeProvider>
    </StyledEngineProvider>
  )
}
