import { Box, Button, Checkbox, Stack, Typography } from '@mui/material'
import { useDynamicGqlMutation } from '@zeroapi-io/zeroapireact'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const ForgetMe: React.FC = () => {
  const [delLoginCredentials, setDelLoginCredetials] = useState(false)

  const [mutate, { data, loading, error }] = useDynamicGqlMutation('privacy_requestDataDeletion', { delLoginCredentials })

  const navigate = useNavigate()

  const onClick = useCallback(() => {
    mutate().then(() => {
      location.href = '/'
    })
  }, [])

  return (
    <Box padding={8} display="flex" alignItems="center" flexDirection="column">
      <Typography variant="h3">Hello,</Typography>
      <Typography variant="body1">It appears that you want us to delete your personal data.</Typography>
      <Typography variant="body1">You are in the right place to do that, just press the button bellow.</Typography>

      <Stack direction="column" maxWidth={320} mt={2}>
        <Stack direction="row" m={3} alignItems="center">
          <Checkbox
            disabled={loading}
            title="Delete my login credentials"
            checked={delLoginCredentials}
            onChange={(e) => setDelLoginCredetials(!delLoginCredentials)}
          />
          <Typography>Delete my login credentials</Typography>
        </Stack>
        <Button color="error" variant="contained" disabled={loading} onClick={onClick}>
          Forget Me!
        </Button>
        <br />
        <Typography variant="caption" color="error">
          {error && error.message}
        </Typography>
        <Typography variant="caption" color="info">
          {data && data}
        </Typography>
      </Stack>
    </Box>
  )
}
