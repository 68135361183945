import React, { useCallback, useContext, useState } from 'react'

import { HideScroll } from 'react-hide-on-scroll'
import { useNavigate } from 'react-router-dom'
import Routes, { Case } from 'react-switch-case'

import { gql, NetworkStatus, useMutation, useQuery } from '@apollo/client'

import AddIcon from '@mui/icons-material/Add'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import CheckCloseIcon from '@mui/icons-material/BlockOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import ExposureZeroIcon from '@mui/icons-material/Exposure'
import PersonIcon from '@mui/icons-material/Person'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import ReplayIcon from '@mui/icons-material/Replay'
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CircularProgress,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { green, red } from '@mui/material/colors'
import SpeedDial from '@mui/material/SpeedDial'
import makeStyles from '@mui/styles/makeStyles'

import InventoryCountingActionDialog from './InventoryCountingActionDialog'
import InventoryCountingDialog from './InventoryCountingDialog'
import { RestaurantContext, UserContext } from '../../App'
import CircularProgressWithLabel from '../common/CircularProgressWithLabel'
import CustomTheme from '../common/CustomTheme'
import Delete from '../common/Delete'

const useStyles = makeStyles((theme) => ({
  roots: {
    '& .MuiTableCell-root .MuiIconButton-root': {
      padding: theme.spacing(1),
    },
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'wrap',
    padding: 0,
    justifyContent: 'space-evenly',
  },
  item: {
    width: '100%',
    maxWidth: 320,
    minWidth: 280,
    margin: theme.spacing(1),
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '& > *': {
      height: 22,
      display: 'flex',
      alignItems: 'flex-end',
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  iconInactive: {
    marginRight: theme.spacing(1),
    opacity: 0.4,
  },
  checkedLabel: {
    marginRight: 0,
    '& .MuiFormControlLabel-label': {
      fontSize: 12,
    },
  },
  progress: {
    // paddingLeft: theme.spacing(1),
  },
  stateSection: {
    marginLeft: theme.spacing(2),
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  loading: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: theme.spacing(1),
  },
}))

const DELETE_ITEM = `
mutation ($id: String!) {
    deleteInventoryCounting(id: $id)
}`

const GQL_INVENTORY_COUNTING_ZERO = gql`
  mutation ($id: String!) {
    inventoryCounting_addZeroForNoEntry(inventoryCountingId: $id)
  }
`

// React Function Component
export default function InventoryCountingList() {
  const classes = useStyles()

  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const navigate = useNavigate()

  const buttonTheme = {
    primary: {
      main: green[500],
      contrastText: '#fff',
    },
    secondary: {
      main: red[500],
      contrastText: '#fff',
    },
  }

  // const { restaurantId, invoiceId } = useParams();
  const [openInventoryCounting, setOpenInventoryCounting] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [deleteState, setDeleteState] = useState(false)
  const [changeState, setChangeState] = useState(false)

  const [applyZero, { loading: loadingZero }] = useMutation(GQL_INVENTORY_COUNTING_ZERO)

  const GQL_INVENTORY_COUNTING_LIST = gql`
        query($restaurantId: String!) {
            getInventoryCountingsByContextRestaurantId(restaurantId: $restaurantId) {
                id
                state
                date
                counted
                created
                updated
                warehouse {
                    name {
                        ${user.lang}
                    }
                }
                employee {
                    profile {
                        _id
                        name
                    }
                }
                reviewed
                reviewer {
                    profile {
                        _id
                        name
                    }
                }
                approve
                approver {
                    profile {
                        _id
                        name
                    }
                }
            }
            inventoryCounting_getProgress(restaurantId: $restaurantId) {
                countingId
                stockTypesCount
                stockTypesCounted
            }
        }  
    `

  const { loading, error, data, refetch, networkStatus } = useQuery(GQL_INVENTORY_COUNTING_LIST, {
    pollInterval: 5000,
    variables: {
      restaurantId: restaurant.id,
    },
  })

  const applyZeroCounting = useCallback(
    (item) => {
      applyZero({
        variables: {
          id: item.id,
        },
      }).then(() => refetch())
    },
    [applyZero, refetch],
  )

  if (loading && data === undefined) {
    return <CircularProgress />
  }

  const byState = data?.getInventoryCountingsByContextRestaurantId?.groupBy((x) => x.state) || {}
  const progressByCountingId = data?.inventoryCounting_getProgress?.toMapBy((x) => x.countingId) || {}

  const onAddClick = () => {
    setOpenInventoryCounting(true)
  }

  const onDeleteClick = (item) => {
    setDeleteState({
      name: item.date,
      variables: { id: item.id },
    })
  }

  const onItemClick = (item) => {
    navigate(`${item.id}`)
  }

  const states = ['COUNTING', 'REVIEWING', 'APPROVING', 'APPLIED', 'REJECTED'].filter((x) => byState[x] !== undefined)

  return (
    <div className={classes.root}>
      {Boolean(loading) || (networkStatus === NetworkStatus.refetch && <CircularProgress size={24} className={classes.loading} />)}
      {states.map((state) => (
        <div key={`key_${state}`}>
          <Typography key={`inventory_counting_state_${state}`} align="left" variant="h6" component="h2" className={classes.stateSection}>
            {user.translate(state)}
          </Typography>
          <ul className={classes.list} key={`inventory_counting_list_${state}`}>
            {byState[state]
              .orderByDesc((item) => item.updated)
              .map((item) => {
                const progressInfo = progressByCountingId[item.id]
                const stockTypesCounted = progressInfo?.stockTypesCounted || 0
                const stockTypesCount = progressInfo?.stockTypesCount || 1
                const progress = ((stockTypesCounted * 100) / stockTypesCount).roundDownBy(1)
                return (
                  <Card className={classes.item} key={`inventory_counting_list_${item.id}`}>
                    <CardActionArea onClick={() => onItemClick(item)}>
                      <CardContent>
                        <div className={classes.title}>
                          <Typography align="left" gutterBottom variant="h5" component="h2">
                            {new Date(item.date).toLocaleDateString()}
                          </Typography>
                          <Typography align="left" gutterBottom variant="subtitle2" component="h2">
                            {item.warehouse.name[user.lang]}
                          </Typography>
                        </div>
                        <div className={classes.content}>
                          <PersonIcon size="small" color="secondary" className={classes.icon} />
                          <Typography variant="body2" color="textSecondary" component="p" style={{ flexGrow: 1 }}>
                            {item.employee.profile.name}
                          </Typography>
                          <Typography variant="caption" color="textSecondary" component="p">
                            {new Date(parseInt(item.created)).toLocaleString()}
                          </Typography>
                        </div>
                        <div className={classes.content}>
                          {item.reviewed ? (
                            <CheckCircleIcon size="small" color="primary" className={classes.icon} />
                          ) : (
                            <RadioButtonUncheckedIcon size="small" color="disabled" className={classes.icon} />
                          )}
                          <Typography variant="body2" color="textSecondary" component="p" style={{ flexGrow: 1 }}>
                            {item.reviewer?.profile?.name || user.translate('not_reviewed')}
                          </Typography>
                          <Typography variant="caption" color="textSecondary" component="p">
                            {item.reviewed ? new Date(parseInt(item.reviewed)).toLocaleString() : ''}
                          </Typography>
                        </div>
                        <div className={classes.content}>
                          {item.approve ? (
                            state === 'REJECTED' ? (
                              <CheckCloseIcon size="small" color="primary" className={classes.icon} />
                            ) : (
                              <CheckCircleIcon size="small" color="primary" className={classes.icon} />
                            )
                          ) : (
                            <RadioButtonUncheckedIcon size="small" color="disabled" className={classes.icon} />
                          )}
                          <Typography variant="body2" color="textSecondary" component="p" style={{ flexGrow: 1 }}>
                            {item.approver?.profile?.name || user.translate('not_approved')}
                          </Typography>
                          <Typography variant="caption" color="textSecondary" component="p">
                            {item.approve ? new Date(parseInt(item.approve)).toLocaleString() : ''}
                          </Typography>
                        </div>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      &nbsp;&nbsp;
                      <Tooltip
                        title={`Попишани се ${stockTypesCounted} артикли од вкупно ${stockTypesCount}`}
                        aria-label="Inventory"
                        className={classes.progress}
                      >
                        <CircularProgressWithLabel value={progress} color="secondary" />
                      </Tooltip>
                      <CustomTheme {...buttonTheme}>
                        <Routes condition={state}>
                          <Case value="COUNTING">
                            <Button
                              onClick={() => setChangeState({ ...item, newState: 'REVIEWING' })}
                              size="small"
                              variant="contained"
                              color="primary"
                              endIcon={<AssignmentTurnedInIcon />}
                              disabled={!user.me.profile.isAdmin && stockTypesCounted < stockTypesCount}
                            >
                              {user.translate('finish')}
                            </Button>
                            <Button
                              onClick={() => applyZeroCounting(item)}
                              size="small"
                              variant="contained"
                              color="primary"
                              endIcon={<ExposureZeroIcon />}
                              disabled={stockTypesCounted === stockTypesCount}
                            >
                              {loadingZero ? '...' : user.translate('zero')}
                            </Button>
                          </Case>
                          <Case value="REVIEWING">
                            <Button
                              onClick={() => setChangeState({ ...item, newState: 'APPROVING' })}
                              size="small"
                              variant="contained"
                              color="primary"
                              endIcon={<DoneIcon />}
                            >
                              {user.translate('approve')}
                            </Button>
                            <Button
                              onClick={() => setChangeState({ ...item, newState: 'REJECTED' })}
                              size="small"
                              variant="contained"
                              color="secondary"
                              endIcon={<CloseIcon />}
                            >
                              {user.translate('reject')}
                            </Button>
                          </Case>
                          <Case value="APPROVING">
                            <Button
                              onClick={() => setChangeState({ ...item, newState: 'APPLIED' })}
                              size="small"
                              variant="contained"
                              color="primary"
                              startIcon={<DoneAllIcon />}
                            >
                              {user.translate('apply')}
                            </Button>
                            <Button
                              onClick={() => setChangeState({ ...item, newState: 'REJECTED' })}
                              size="small"
                              variant="contained"
                              color="secondary"
                              startIcon={<CloseIcon />}
                            >
                              {user.translate('reject')}
                            </Button>
                          </Case>
                          <Case value="REJECTED">
                            <Button
                              onClick={() => setChangeState({ ...item, newState: 'COUNTING' })}
                              size="small"
                              variant="contained"
                              color="secondary"
                              startIcon={<ReplayIcon />}
                            >
                              {user.translate('restart')}
                            </Button>
                          </Case>
                        </Routes>
                      </CustomTheme>
                      <IconButton style={{ marginLeft: 'auto' }} onClick={() => onDeleteClick(item)} size="large">
                        <DeleteIcon />
                      </IconButton>
                    </CardActions>
                  </Card>
                )
              })}
          </ul>
        </div>
      ))}

      <HideScroll variant="down">
        <SpeedDial ariaLabel="Add Inventory Counting" className={classes.speedDial} open={false} onClick={onAddClick} icon={<AddIcon />} />
      </HideScroll>

      {Boolean(deleteState) && (
        <Delete
          name={user.translate('invoice_item') + ' ' + deleteState.name}
          variables={deleteState.variables}
          setOpenSnackBar={setOpenSnackBar}
          setOpenDeleteBox={setDeleteState}
          deleteItem={DELETE_ITEM}
          onSuccess={refetch}
        />
      )}

      {Boolean(openInventoryCounting) && (
        <InventoryCountingDialog item={openInventoryCounting} onClose={setOpenInventoryCounting} onSuccess={refetch} />
      )}

      {Boolean(changeState) && <InventoryCountingActionDialog item={changeState} onClose={setChangeState} onSuccess={refetch} />}

      <Snackbar
        key="item_deleted"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
        message="Пописот е избришан"
        action={
          <>
            <IconButton aria-label="close" color="inherit" className={classes.close} onClick={() => setOpenSnackBar(false)} size="large">
              <CloseIcon />
            </IconButton>
          </>
        }
        TransitionProps={{
          onExited: () => setOpenSnackBar(false),
        }}
      />
    </div>
  )
}
