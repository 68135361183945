import React, { useContext } from 'react'

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { callOrGet, ifNull, preventPropagation } from './utils'
import { UserContext } from '../../../App'
import Show from '../Show'

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: 'inline-grid',
    margin: theme.spacing(1),
    flexGrow: 1,
    minWidth: 80,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

export default function SelectPaymentDirection(props) {
  const classes = useStyles()
  const user = useContext(UserContext)

  const options =
    props.options ||
    (props.showAll
      ? [
          { val: 'all', name: user.translate('all') },
          { val: 'CREDIT', name: user.translate('credit') },
          { val: 'DEBIT', name: user.translate('debit') },
        ]
      : [
          { val: 'CREDIT', name: user.translate('credit') },
          { val: 'DEBIT', name: user.translate('debit') },
        ])

  const value = ifNull(props.value, callOrGet(props.default, props.item))

  const propsStyle = props.style || {}
  return (
    <FormControl
      className={props.showLabel && classes.formControl}
      style={{ ...propsStyle, flexGrow: props.shrink ? 0 : 1 }}
      size={props.size}
    >
      <Show if={props.showLabel}>
        <InputLabel htmlFor={`select-${props.name}`} variant={props.variant || 'outlined'}>
          {props.label || user.translate(props.name)}
        </InputLabel>
      </Show>
      <Select
        fullWidth={props.fullWidth}
        style={{ fontSize: props.size === 'small' ? 12 : 14 }}
        value={value}
        label={(props.label || user.translate(props.name)) + '='}
        variant={props.variant || 'outlined'}
        onKeyDown={preventPropagation}
        inputProps={{
          id: `select-${props.name}`,
        }}
        error={props.error}
        disabled={props.disabled}
        onChange={(event) => {
          const option = options.find((opts) => opts.val === event.target.value)
          return props.onChange(option?.val, option?.name)
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.val} value={option.val} onKeyDown={preventPropagation}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
SelectPaymentDirection.gql = 'String'

SelectPaymentDirection.renderView = function (props, user) {
  const options = [
    { val: 'CREDIT', name: user.translate('credit') },
    { val: 'DEBIT', name: user.translate('debit') },
  ]

  const value = ifNull(props.value, callOrGet(props.default, props.item))
  const option = options.find((opts) => opts.val.toLowerCase() === value.toLowerCase()) || options[0]

  return option?.name
}
