import React, { useContext, useState } from 'react'

import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import InvoiceApplyBillFrom from './InvoiceApplyBillFrom'
import { UserContext } from '../../../App'
import FormInvoice from '../../common/FormInvoice'
import helper from '../../common/Helper'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    flexDirection: 'column',
    position: 'relative',
    top: theme.spacing(2),
    alignItems: 'end',
  },
  itemName: {
    position: 'relative',
    right: 0,
    top: -20,
    textAlign: 'right',
  },
  billingStyles: {
    // display: `inline-flex`
  },
}))

export default function InvoiceBilling(props) {
  const classes = useStyles()
  // Get current user preferences (language)
  const user = useContext(UserContext)
  // const restaurant = useContext(RestaurantContext);
  const invoice = props.invoice

  const _fieldListBilling = [
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'id',
      fieldLabel: 'id',
      fieldValue: invoice.id,
    },
    {
      required: true,
      type: 'text',
      fieldType: 'text',
      fieldName: 'billingFrom',
      fieldLabel: user.translate('from'),
      fieldValue: invoice.billingFrom ? invoice.billingFrom[user.lang] : '',
      error: !invoice.billingFrom,
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'billingFromLangId',
      fieldLabel: 'billingFromLangId',
      fieldValue: invoice.billingFrom ? invoice.billingFrom.id : '',
    },
    {
      required: false,
      type: 'text',
      fieldType: 'text',
      fieldName: 'billingVAT',
      fieldLabel: user.translate('billing_vat'),
      fieldValue: invoice.billingVAT ? invoice.billingVAT : '',
    },
    {
      required: false,
      type: 'text',
      fieldType: 'text',
      fieldName: 'billingBankName',
      fieldLabel: user.translate('bank_name'),
      fieldValue: invoice.billingBankName ? invoice.billingBankName[user.lang] : '',
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'billingBankNameLangId',
      fieldLabel: 'billingBankNameLangId',
      fieldValue: invoice.billingBankName ? invoice.billingBankName.id : '',
    },
    {
      required: false,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'billingBankAddress',
      fieldLabel: user.translate('bank_address'),
      fieldValue: invoice.billingBankAddress ? invoice.billingBankAddress[user.lang] : '',
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'billingBankAddressLangId',
      fieldLabel: 'billingBankAddressLangId',
      fieldValue: invoice.billingBankAddress ? invoice.billingBankAddress.id : '',
    },
    {
      required: false,
      type: 'text',
      fieldType: 'text',
      fieldName: 'billingBankAccount',
      fieldLabel: user.translate('iban'),
      fieldValue: invoice.billingBankAccount ? invoice.billingBankAccount : '',
    },
    {
      required: false,
      type: 'text',
      fieldType: 'text',
      fieldName: 'billingSwift',
      fieldLabel: user.translate('swift'),
      fieldValue: invoice.billingSwift ? invoice.billingSwift : '',
    },
    {
      required: false,
      type: 'text',
      fieldType: 'text',
      fieldName: 'billingAddress',
      fieldLabel: user.translate('address'),
      fieldValue: invoice.billingAddress ? invoice.billingAddress[user.lang] : '',
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'billingAddressLangId',
      fieldLabel: 'billingAddressLangId',
      fieldValue: invoice.billingAddress ? invoice.billingAddress.id : '',
    },
    {
      required: false,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'billingContactPhone',
      fieldLabel: user.translate('contact_phone'),
      fieldValue: invoice.billingContactPhone ? invoice.billingContactPhone : '',
    },
    {
      required: false,
      type: 'text',
      fieldType: 'text',
      fieldName: 'billingContactEmail',
      fieldLabel: user.translate('contact_email'),
      fieldValue: invoice.billingContactEmail ? invoice.billingContactEmail : '',
    },
    {
      required: false,
      type: 'text',
      fieldType: 'text',
      fieldName: 'billingContactPerson',
      fieldLabel: user.translate('contact_person'),
      fieldValue: invoice.billingContactPerson ? invoice.billingContactPerson[user.lang] : '',
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'billingContactPersonLangId',
      fieldLabel: 'billingContactPersonLangId',
      fieldValue: invoice.billingContactPerson ? invoice.billingContactPerson.id : '',
    },
  ]

  if (!props.devizna) {
    _fieldListBilling.splice(9, 1)
  }

  const [fieldListBilling, setFieldListBilling] = useState([..._fieldListBilling])

  const setItems = (event, fieldName, fieldValue) => {
    let items = {};
      let field = {}
    const langId = fieldListBilling.find((c) => c.fieldName === `${fieldName}LangId`)

    field = {
      fieldType: `$${fieldName}: String!`,
      field: fieldName,
    }

    if (typeof langId !== 'undefined') {
      items = {
        id: langId.fieldValue,
        [fieldName]: fieldValue,
      }
      props.onEditInvoice(items, field, true)
    } else {
      items = {
        id: invoice.id,
        [fieldName]: fieldValue,
      }
      props.onEditInvoice(items, field, false)
    }

    user.consoleLog(items)
  }

  const tabIndex = 0

  return (
    <div className={classes.root}>
      <Typography component="span" className={classes.itemName}>
        {!props.typeOutput && (
          <InvoiceApplyBillFrom
            key={helper.hash('invoice_apply_billto_billing_' + JSON.stringify(fieldListBilling))}
            invoice={invoice}
            fieldListBilling={fieldListBilling}
            setFieldListBilling={setFieldListBilling}
            onEditInvoiceBillFrom={props.onEditInvoiceBillFrom}
          />
        )}
        {invoice.payeeCompany.name[user.lang]}
      </Typography>

      {fieldListBilling.map((item, index) => (
        <FormInvoice
          key={'invoice_billing_' + item.fieldValue + index}
          fieldList={fieldListBilling}
          item={index}
          tabIndex={tabIndex}
          setFieldList={setFieldListBilling}
          setItems={setItems}
          styles={classes.billingStyles}
          left
        />
      ))}
    </div>
  )
}
