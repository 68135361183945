import React, { useContext, useState } from 'react'

import { useLazyQuery, gql } from '@apollo/client'

import CloseIcon from '@mui/icons-material/Close'
import { Dialog, AppBar, Toolbar, IconButton, DialogContent, DialogActions, Button, Slide, alpha, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { UserContext } from '../../../App'
import helper from '../../common/Helper'
import ReceiptsReport from '../../report/ReceiptsReport'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
  },
  appBar: {
    position: 'relative',
  },
  gridItem: {
    maxWidth: 290,
    minWidth: 240,
    flexGrow: 1,
  },
  itemName: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.05),
      cursor: `pointer`,
    },
  },
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 600,
    height: 40,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function InvoiceApplyReceipt(props) {
  const classes = useStyles()
  // Get current user preferences (language)
  const user = useContext(UserContext)
  const invoice = props.invoice
  let receipt = false

  const [openApply, setOpenApply] = useState(props.openApply)
  const [getIt, setGetIt] = useState(false)

  const onToggleClose = () => {
    setOpenApply(!openApply)
    props.setOpenAddReceipt(!props.openApply)
  }

  // GraphQL API request definition (local variables: restaurantId)
  const GET_RECEIPT_BY_ID = gql`
    query ($id: String) {
      getReceiptById(id: $id) {
        id
        receiptNum
        totalPrice
      }
    }
  `

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const [getReceipt, { data }] = useLazyQuery(GET_RECEIPT_BY_ID, {
    skip: !getIt,
  })

  if (data && getIt) {
    receipt = data.getReceiptById
    if (receipt) {
      const items = {
        invoiceId: invoice.id,
        receiptId: receipt.id,
      }

      props.onAddInvoiceItemReceipt(items)
      props.setUpdatedAmount(false)
      setGetIt(false)
      onToggleClose()
    }
  }

  const handleSelectAccept = (rowData) => {
    getReceipt({ variables: { id: rowData.receiptId } })
    setGetIt(true)
  }

  return (
    <div className={`${classes.root} no_print`}>
      {openApply && (
        <Dialog
          open={openApply}
          fullScreen
          onClose={onToggleClose}
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          aria-describedby="form-dialog-description"
          fullWidth
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={onToggleClose} aria-label="close" size="large">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                {user.translate('receipts_report')}
              </Typography>
            </Toolbar>
          </AppBar>

          <DialogContent>
            <ReceiptsReport handleSelectAccept={handleSelectAccept} invoiceId={invoice.id} notEditItem />
          </DialogContent>
          <DialogActions>
            <Button onClick={onToggleClose} color="primary">
              {user.translate('cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}
