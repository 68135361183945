import React, { useContext, useMemo } from 'react'

import { RestaurantContext, UserContext } from '../../../App'
import { DynamicTable } from '../../common/DynamicTable'
import { useCustomQuery } from '../../common/useEntityQuery'

export default function GenericReport(props) {
  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const { date, name, title, columns, totalBy } = props
  const startDate = new Date(date)
  const endDate = new Date(date.getTime() + 24 * 60 * 60 * 1000)
  startDate.setHours(5, 0, 0, 0)
  endDate.setHours(5, 0, 0, 0)

  const FIELDS = useMemo(
    () => [
      { name: 'title', gql: 'String!' },
      { name: 'headers', gql: 'Headers', subSelection: { name: true, title: true, hidden: true, format: true } },
      {
        name: 'results',
        gql: 'Results',
        subSelection: columns.toMapBy(
          (it) => it,
          () => true,
        ),
      },
    ],
    [columns],
  )

  const { item } = useCustomQuery(name, FIELDS, 'restaurantId', restaurant.id, {
    args: {
      dateFrom: startDate.getTime(),
      dateTo: endDate.getTime(),
      filters: [{ name: 'grouping', selection: ['byPos'] }],
      lang: user.lang,
    },
    single: true,
  })

  const [tableColumns, tableItems] = useMemo(() => {
    if (!item) return [[], []]

    const { results, headers } = item

    const tableItems = totalBy
      ? [...results, { [headers[0].name]: user.translate('total'), [totalBy]: results.sumOf((it) => it[totalBy]) }]
      : results

    const getItemRenderer = (column) => {
      if (column.format === 'PRICE')
        return (item) => {
          const value = item[column.name]
          if (value === undefined || value === null) return ''
          return user.formatNumber(value)
        }
      return undefined
    }

    const tableColumns =
      headers?.map((it) => ({
        ...it,
        gql: 'String!',
        render: getItemRenderer(it),
        width: '50px',
        cellStyle: { whiteSpace: 'nowrap' },
      })) ?? []

    return [tableColumns, tableItems]
  }, [item, totalBy, user])

  return (
    <DynamicTable
      name={user.translate(title ?? item?.title ?? name)}
      items={tableItems}
      columns={tableColumns}
      search={false}
      nowrap={props.nowrap}
      onRowClick={props.onRowClick}
      paging={false}
      noPaging
      noEdit
    />
  )
}
