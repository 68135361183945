import { useContext, useMemo } from 'react'

import { endOfDay, endOfMonth, endOfISOWeek, startOfDay, startOfMonth, startOfISOWeek, subDays } from 'date-fns'

import { UserContext } from '../../../App'

export const useDateRangeShortcuts = () => {
  const user = useContext(UserContext)

  return useMemo(() => {
    const today = startOfDay(new Date())
    return [
      {
        label: user.translate('current_day_short'),
        help: user.translate('current_day'),
        getValue: () => {
          return [startOfDay(today), endOfDay(today)]
        },
      },
      {
        label: user.translate('previous_day_short'),
        help: user.translate('previous_day'),
        getValue: () => {
          const yesterday = subDays(today, 1)
          return [startOfDay(yesterday), endOfDay(yesterday)]
        },
      },
      {
        label: user.translate('current_week_short'),
        help: user.translate('current_week'),
        getValue: () => {
          return [startOfISOWeek(today), endOfISOWeek(today)]
        },
      },
      {
        label: user.translate('previous_week_short'),
        help: user.translate('previous_week'),
        getValue: () => {
          const prevWeek = subDays(today, 7)
          return [startOfISOWeek(prevWeek), endOfISOWeek(prevWeek)]
        },
      },
      {
        label: user.translate('last_7_days_short'),
        help: user.translate('last_7_days'),
        getValue: () => {
          return [subDays(today, 7), today]
        },
      },
      {
        label: user.translate('current_month_short'),
        help: user.translate('current_month'),
        getValue: () => {
          return [startOfMonth(today), endOfMonth(today)]
        },
      },
      {
        label: user.translate('previous_month_short'),
        help: user.translate('previous_month'),
        getValue: () => {
          const prevMonth = subDays(startOfMonth(today), 1)
          return [startOfMonth(prevMonth), endOfMonth(prevMonth)]
        },
      },
    ]
  }, [user])
}
