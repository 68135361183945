import React, { useContext, useState } from 'react'

import { UserContext } from '../../../App'
import Report from '../../common/Report'

// React Function Component
export default function InventoryCategoryOrder(props) {
  const user = useContext(UserContext)
  const [page, setPage] = useState(0)

  const startDate = new Date()
  const endDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000)
  startDate.setHours(0, 0, 0, 0)
  endDate.setHours(5, 0, 0, 0)

  const params = {
    query: {
      name: 'report_ordersByProduct',
      dateInit: '$dateFrom: Long!, $dateTo: Long!',
      date: 'dateFrom: $dateFrom, dateTo: $dateTo',
      dateVars: {
        dateFrom: startDate.getTime(),
        dateTo: endDate.getTime(),
      },
      results: `
                pos
                prodDep
                warehouse
                category
                menuItemName
                products
                quantity
                amount
                productCost
                employee
            `,
      columnsPush: [],
    },
    title: user.translate('ordersByProduct'),
    titleName: user.translate('ordersByProduct'),
    // InventoryAdjustments: user.role > 2 ? true : false,
    key: 'orders_by_product',
    page,
    setPage,
    datetime: true,
  }

  return <Report {...params} />
}
