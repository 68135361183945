import React, { useContext } from 'react'

import { UserContext } from '../App'
import Add from '../restaurant/common/Add'

// React Function Component
export default function Profile(props) {
  const user = useContext(UserContext)

  const me = user.me

  const EDIT_ITEM = `
    mutation ($userId: String!, $name: String!, $picture: String!, $birthday: String, $phone: String) {
        editProfile(input: {
          userId: $userId
          name: $name
          picture: $picture
          birthday: $birthday
          phone: $phone
        }){
          userId
        }
    }`

  const fieldList = [
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'userId',
      fieldLabel: 'userId',
      fieldValue: me.profile.userId,
      error: false,
    },
    {
      required: false,
      type: 'upload',
      fieldType: 'upload',
      fieldName: 'picture',
      fieldLabel: user.translate('image'),
      fieldValue: me.profile.picture,
    },
    {
      required: true,
      type: 'text',
      fieldType: 'text',
      fieldName: 'name',
      fieldLabel: user.translate('name'),
      fieldValue: me.profile.name,
    },
    {
      required: false,
      type: 'dateyear',
      fieldType: 'date',
      fieldName: 'birthday',
      fieldLabel: user.translate('birthday'),
      fieldValue: me.profile.birthday,
      formatDate: true,
    },
    {
      required: false,
      type: 'text',
      fieldType: 'text',
      fieldName: 'phone',
      fieldLabel: user.translate('phone'),
      fieldValue: me.profile.phone,
    },
  ]

  return (
    <Add
      fieldList={fieldList}
      openManipulateBox={props.setOpenProfile}
      actionType={user.translate('edit')}
      name={user.translate('profile')}
      manipulateItem={EDIT_ITEM}
      onSuccess={props.refetch}
    />
  )
}
