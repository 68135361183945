import { Link } from 'react-router-dom'

import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'

export default function SnackBarWarning(props) {
  const handleClose = () => {
    if (props.setOpenSnackBar) {
      props.setOpenSnackBar(false)
    }
  }

  return (
    <Snackbar
      autoHideDuration={props.autoHideDuration}
      open={props.openSnackBar}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={handleClose}
      TransitionProps={{
        onExited: handleClose,
      }}
    >
      <Alert elevation={6} variant="filled" onClose={handleClose} severity="warning">
        {props.message}
        {props.linkTo && props.linkText && (
          <Link style={{ color: 'inherit' }} to={props.linkTo}>
            {props.linkText}
          </Link>
        )}
      </Alert>
    </Snackbar>
  )
}
