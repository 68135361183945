import React, { useCallback, useContext, useMemo, useState } from 'react'

import { FontAwesomeIcon, FontAwesomeIcons } from '@zeroapi-io/zeroapireact'

import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Slide,
  Toolbar,
  alpha,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { UserContext } from '../../App'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  gridItem: {
    maxWidth: 60,
    minWidth: 60,
    flexGrow: 1,
  },
  itemName: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.05),
      cursor: `pointer`,
    },
  },
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 600,
    height: 40,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function IconDialog(props) {
  const classes = useStyles()
  const user = useContext(UserContext)

  const [selectIt, setSelectIt] = useState(false)

  // Add Dialog Close
  const handleClose = useCallback(
    (icon) => {
      setSelectIt(true)
      props.setOpenSelectBox(false)
      if (typeof icon !== 'undefined') props.action(icon)
    },
    [props],
  )

  const handleSelectAccept = useCallback(
    (icon) => {
      if (props.setSelectedItem) props.setSelectedItem(icon)

      handleClose(icon)
    },
    [props, handleClose],
  )

  const [search, setSearch] = useState('')

  const onSearchChange = (event) => {
    setSearch(event.target.value.cirilicToLatin())
  }

  const filteredIcons = useMemo(() => {
    return FontAwesomeIcons?.filter((faIcon) => {
      return faIcon.icon.toLowerCase().includes(search.toLowerCase()) || faIcon.search.terms.includes(search.toLowerCase())
    })?.map(({ icon }) => icon)
  }, [search])

  return (
    <Dialog
      open={!selectIt}
      fullScreen
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="form-dialog-title"
      aria-describedby="form-dialog-description"
      fullWidth
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" size="large">
            <CloseIcon />
          </IconButton>

          <Paper component="form" className={classes.search}>
            <IconButton className={classes.iconButton} aria-label="menu" size="large">
              <MenuIcon />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder={user.translate('search_icon')}
              inputProps={{ 'aria-label': 'search icon' }}
              onChange={onSearchChange}
            />
            <IconButton type="submit" className={classes.iconButton} aria-label="search" size="large">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid xs={12} container spacing={2} direction="row" justifyContent="center" alignItems="center">
            {filteredIcons.map((icon) => (
              <Grid item xs={4} key={icon} className={`${classes.gridItem} ${icon}`} onClick={(e) => handleSelectAccept(icon)}>
                <Paper elevation={2} className={classes.itemName}>
                  <FontAwesomeIcon icon={icon} />
                </Paper>
              </Grid>
            ))}
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {user.translate('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
