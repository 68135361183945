import { useContext, useMemo, useRef, useState } from 'react'

import { gql, useQuery } from '@apollo/client'

import Close from '@mui/icons-material/Close'
import LocalShipping from '@mui/icons-material/LocalShipping'
import { Badge, CircularProgress, Fab, IconButton, Typography, useTheme, Zoom } from '@mui/material'
import { brown, green } from '@mui/material/colors'
import makeStyles from '@mui/styles/makeStyles'

import NewStockSupplingOrderItem from './NewStockSupplingOrderItem'
import NewStockSupplingStockItem from './NewStockSupplingStockItem'
import { RestaurantContext, UserContext } from '../../App'
import CustomTheme from '../common/CustomTheme'
import useDateRangeFilter from '../common/DateRangeHeader'
import MobileDialog from '../common/MobileDialog'
import SearchBar from '../common/SearchBar'
import useWindowSize from '../common/useWindowSize'
import VirtualList from '../common/VirtualList'

const hash = require('object-hash')

const useStyles = makeStyles((theme) => ({
  roots: {
    display: 'flex',
    flexDirection: 'row',
    '& .MuiTableCell-root .MuiIconButton-root': {
      padding: theme.spacing(1),
    },
  },
  inventoryList: {
    height: '100%',
    padding: theme.spacing(0.5),
    flexGrow: 3,
  },
  supplierList: {
    position: 'sticky',
    top: 0,
    bottom: 20,
    height: '100%',
    maxHeight: '100vh',
    overflowY: 'scroll',
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    flexGrow: 1,
    backgroundColor: '#e5e5e5',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 360,
    },
  },
  listItem: {
    display: 'block',
    padding: theme.spacing(1),
  },
  stockTypeOptions: {
    display: 'block',
    margin: theme.spacing(0.5),
  },
  supplyOrders: {
    display: 'block',
    margin: theme.spacing(1),
  },
  itemLoading: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: '#ffffffad',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stockSupplyBar: {
    display: 'flex',
    height: 35,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    justifyContent: 'space-between',
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  noSupplyOrders: {
    padding: theme.spacing(1),
  },
}))

const GQL_GET_KOMITENTS_AND_EMPLOYEES = function (user) {
  return gql`
        query ($restaurantId: String!) {
            getCommissionersByRestaurantId(restaurantId: $restaurantId) {
                commissioner {
                    id
                    name {
                        ${user.gqlFetchName()}
                    }
                }
            }
            getRestaurantEmployeesByContextRestaurantId(restaurantId: $restaurantId) {
                employee {
                    id
                    profile {
                        _id
                        name
                    }
                }
                role
                lang
            }
        }
    `
}

const GQL_GET_INVENTORY_STATE_AND_SUPPLY_OPTIONS = gql`
  query ($restaurantId: String!, $dateFrom: Long!, $dateTo: Long!, $lang: String!) {
    report_inventoryState(restaurantId: $restaurantId, dateFrom: $dateFrom, dateTo: $dateTo, lang: $lang, filters: []) {
      results {
        stockTypeId
        stockType
        uom
        unit
        quantity
        ordersQuantity
        receivingQuantity
        internalTransferQuantity
        inventoryQuantity
        invoiceQuantity
        avgPrice
        avgNetPrice
        avgTotalPrice
      }
    }
    getStockTypeSupplyOptionsByContextRestaurantId(restaurantId: $restaurantId) {
      id
      stockTypeId
      minStock
      quantity
      uomOpts
      period
      supplierId
      stockType {
        package
      }
    }
  }
`

// React Function Component
export default function NewStockSupplingList() {
  const classes = useStyles()
  const theme = useTheme()

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  }

  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)
  const size = useWindowSize()

  const fabTheme = {
    primary: {
      main: brown[700],
      contrastText: '#fff',
    },
    secondary: {
      main: green[600],
      contrastText: '#fff',
    },
  }

  const [search, setSearch] = useState('')
  const [minimizeOrders, setMinimizeOrders] = useState(size.isCompact)

  const dateRangeFilter = useDateRangeFilter('past_week')

  const GET_SUPPLINGS_BY_RESTAURANT = gql`
        query($restaurantId: String!) {
            getStockSupplysByContextRestaurantId(restaurantId: $restaurantId, filter: {
                by: "created"
                gte: ${dateRangeFilter.startDate.getTime() || 0}
                lte: ${dateRangeFilter.endDate.getTime() || new Date().getTime()}
                and: {
                    by: "state",
                    eqStr: "OPEN",
                    or: {
                        by: "state",
                        eqStr: "CLOSED"
                    }
                }
            }) {
                id
                supplierId
                employeeId
                supplyingNumber
                date
                emailSent
                comment
                created
                updated
                state
                stockSupplyItems {
                    _id
                    id
                    stockTypeId
                    quantity
                    uomOpts
                    comment
                }
            }
        }
    `

  const date = new Date()
  date.setHours(23)
  date.setMinutes(59)
  date.setSeconds(59)
  date.setMilliseconds(999)

  const {
    data: dataInventoryAndOptions,
    loading: loadingInventory,
    error: errorInventory,
  } = useQuery(GQL_GET_INVENTORY_STATE_AND_SUPPLY_OPTIONS, {
    variables: { restaurantId: restaurant.id, lang: user.lang, dateFrom: 0, dateTo: date.getTime() },
    pollInterval: 15000,
    errorPolicy: 'ignore',
  })

  const {
    data: dataSupplies,
    loading: loadingSupplies,
    error: errorSupplies,
    refetch: refetchSupplies,
  } = useQuery(GET_SUPPLINGS_BY_RESTAURANT, {
    variables: { restaurantId: restaurant.id },
    pollInterval: 5000,
    errorPolicy: 'ignore',
    skip: loadingInventory,
  })

  const {
    data: dataKomitents,
    loading: loadingKomitents,
    error: errorKomitents,
    refetch: refetchKomitents,
  } = useQuery(GQL_GET_KOMITENTS_AND_EMPLOYEES(user), {
    variables: { restaurantId: restaurant.id },
    // pollInterval: 30000,
    errorPolicy: 'ignore',
    skip: loadingSupplies || loadingInventory,
  })

  // if (!dataInventoryAndOptions) return <div className="App AppLoading"><CircularProgress /></div>;
  const inventoryStateReport = dataInventoryAndOptions?.report_inventoryState?.results || []
  const stockTypeSupplyOptions = dataInventoryAndOptions?.getStockTypeSupplyOptionsByContextRestaurantId || []
  const restaurantKomitents = dataKomitents?.getCommissionersByRestaurantId || []
  const restaurantStockSupplyOrder = dataSupplies?.getStockSupplysByContextRestaurantId || []

  const hashRestaurantKomitents = hash(restaurantKomitents.map((x) => x.commissioner.id))
  const hashInventoryStateReport = hash(inventoryStateReport.map((x) => x.id + x.quantity))
  const hashStockTypeSupplyOptions = hash(stockTypeSupplyOptions.map((x) => x.id + x.minStock))
  const hashRestaurantStockSupplyOrder = hash(
    restaurantStockSupplyOrder.map((x) => x.id + x.state + x.stockSupplyItems.map((y) => y.quantity + '_' + y.uomOpts)),
  )

  const komitents = useMemo(
    () =>
      restaurantKomitents
        .map((item) => ({
          id: item.commissioner.id,
          name: item.commissioner.name[user.lang],
        }))
        .toMapBy((item) => item.id),
    [hashRestaurantKomitents],
  )

  const stockTypeOptions = useMemo(() => stockTypeSupplyOptions.groupBy((item) => item.stockTypeId), [hashStockTypeSupplyOptions])

  const stockTypesById = useMemo(() => inventoryStateReport.toMapBy((item) => item.stockTypeId), [hashInventoryStateReport])

  const stockTypeData = useMemo(
    () =>
      inventoryStateReport
        .map((item) => {
          const options = stockTypeOptions[item.stockTypeId] || []
          const itemBaseQuantity = item.unit < 2 ? item.quantity * 1000 : item.quantity

          const optionsBySupplier = options.groupBy((option) => option.supplierId)
          const orderOptions = Object.keys(optionsBySupplier).map((supplierId) => {
            const supplierOptions = optionsBySupplier[supplierId]

            const maxOption = supplierOptions.maxBy((item) => item.minStock)
            const minStock = maxOption.minStock

            if (minStock <= itemBaseQuantity)
              return {
                ...supplierOptions[0],
                quantities: [],
                neededQuantity: minStock - itemBaseQuantity,
              }

            const minStockNorm = item.unit < 2 ? minStock / 1000 : minStock

            const options = supplierOptions
              .flatMap((option) => {
                if (option.minStock <= itemBaseQuantity) return []
                const optimalQuantity = (minStockNorm - item.quantity).roundUpBy(option.stockType.package)
                const defaultQuantity = item.unit < 2 ? option.quantity / 1000 : option.quantity
                if (optimalQuantity === defaultQuantity) return [optimalQuantity]
                return [optimalQuantity, defaultQuantity]
              })
              .distinct()
              .orderBy((option) => option)

            return {
              ...supplierOptions[0],
              neededQuantity: minStock - itemBaseQuantity,
              quantities: options.map((quantity) => ({
                quantity,
                label: user.formatQuantity(quantity),
              })),
            }
          })
          return {
            ...item,
            id: item.stockTypeId,
            rawQuantity: itemBaseQuantity,
            orderOptions,
            bestCandidate: orderOptions?.maxBy((option) => option.neededQuantity),
          }
        })
        .orderByDesc((a) => a.bestCandidate?.neededQuantity || 0),
    [hashInventoryStateReport, hashStockTypeSupplyOptions],
  )

  const stockSupplyOrders = useMemo(() => restaurantStockSupplyOrder.orderByDesc((item) => item.created), [hashRestaurantStockSupplyOrder])

  const stockSupplyOrdersByStockId = useMemo(() => {
    return stockSupplyOrders
      .flatMap((order) => order.stockSupplyItems.map((item) => ({ ...item, order })))
      .groupBy((item) => item.stockTypeId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashRestaurantStockSupplyOrder])

  const stockTypeDataWithOrders = useMemo(() => {
    if (dataSupplies === undefined) return []
    return stockTypeData.map((item) => ({
      ...item,
      supplyOrders: stockSupplyOrdersByStockId[item.stockTypeId] || [],
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSupplies && hashRestaurantStockSupplyOrder, hashInventoryStateReport, hashStockTypeSupplyOptions])

  const itemsExpandedRef = useRef({})

  const isItemExpanded = (item) => {
    const stateVal = itemsExpandedRef.current[item.id]
    if (stateVal !== undefined) return stateVal

    if ((item?.bestCandidate?.neededQuantity || 0) <= 0) return false

    return item.supplyOrders.length === 0
  }

  const setItemExpanded = (item, value) => {
    itemsExpandedRef.current[item.id] = value
  }

  return (
    <>
      <div className={classes.roots}>
        <div className={classes.inventoryList}>
          <div className={classes.stockSupplyBar} style={{ opacity: stockTypeDataWithOrders.length ? 1 : 0 }}>
            <div />
            <SearchBar onChange={(value) => setSearch(value)} />
          </div>
          <VirtualList
            items={stockTypeDataWithOrders}
            onRefresh={refetchSupplies}
            renderWhenEmpty={() => (
              <div>
                <CircularProgress />
              </div>
            )}
            searchWarmup={(item) => {
              item._search = item.stockType.cirilicToLatin()
            }}
            searchMatch={(item, search) => item._search.indexOf(search) > -1}
            search={search.cirilicToLatin()}
            dependencies={[hashRestaurantKomitents]}
            renderItem={(stockTypeItem, idx, windowSize) => (
              <NewStockSupplingStockItem
                key={stockTypeItem.id}
                className={classes.stockTypeOptions}
                komitents={komitents}
                expanded={isItemExpanded(stockTypeItem)}
                setExpanded={(expanded) => setItemExpanded(stockTypeItem, expanded)}
                supplies={dataSupplies}
                refresh={refetchSupplies}
                item={stockTypeItem}
                windowSize={windowSize}
              />
            )}
          />
        </div>
        <MobileDialog
          inlineOnDesktop
          open={!minimizeOrders}
          onClose={() => {
            setMinimizeOrders(true)
          }}
          title={user.translate('supplies')}
        >
          <div className={classes.supplierList}>
            {!size.isCompact && (
              <div className={classes.stockSupplyBar}>
                <div />
                <IconButton
                  size="small"
                  onClick={() => {
                    setMinimizeOrders(true)
                  }}
                >
                  <Close />
                </IconButton>
              </div>
            )}
            {stockSupplyOrders.length === 0 && !(loadingSupplies || loadingInventory) && (
              <div className={classes.noSupplyOrders}>
                <Typography variant="h6" color="textSecondary">
                  {user.translate('alertNoSupplyOrders')}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {user.translate('messageNoSupplyOrders')}
                </Typography>
              </div>
            )}
            {stockSupplyOrders.length === 0 && loadingSupplies && <CircularProgress />}
            {stockSupplyOrders.map((supplyOrder) => (
              <NewStockSupplingOrderItem
                key={supplyOrder.id}
                className={classes.supplyOrders}
                komitents={komitents}
                supplies={dataSupplies}
                stockTypesById={stockTypesById}
                refresh={refetchSupplies}
                item={supplyOrder}
              />
            ))}
          </div>
        </MobileDialog>

        <Zoom key="fab_orders" in={minimizeOrders} timeout={transitionDuration} unmountOnExit>
          <CustomTheme {...fabTheme}>
            <Badge
              showZero={false}
              className={classes.speedDial}
              badgeContent={stockSupplyOrders.length}
              color="secondary"
              overlap="circular"
            >
              <Fab color="primary" aria-label="orders" onClick={() => setMinimizeOrders(false)}>
                <LocalShipping />
              </Fab>
            </Badge>
          </CustomTheme>
        </Zoom>
      </div>
      {(loadingInventory || loadingSupplies) && (
        <div className={classes.itemLoading}>
          <CircularProgress />
        </div>
      )}
    </>
  )
}
