import { useContext } from 'react'

import { Navigate, useLocation, useParams } from 'react-router-dom'

import { ZeroApiContextProvider } from '@zeroapi-io/zeroapireact'

import { RestaurantContext, UserContext } from './App'
import './App.css'
import Main from './Main'
import config from '../config'

export const Restaurant = ({ theme, ...props }) => {
  const user = useContext(UserContext)

  const { restaurantId } = useParams()

  const location = useLocation()

  if (!props.restLang && location.pathname.indexOf('invoice_output/') === -1 && location.pathname.indexOf('invoice_input/') === -1) {
    props.setRestLang('mk')
  }

  if (!props.restaurants) {
    return <Navigate to={{ pathname: '/login', state: { from: location } }} />
  }

  const item = props.restaurants.find((it) => it.restaurant._id === restaurantId)

  const currencyOptions = [
    { val: 'MKD', label: user.translate('mkd') },
    { val: 'EUR', label: user.translate('eur') },
    { val: 'USD', label: user.translate('usd') },
  ]

  if (item) {
    const value = { ...item.restaurant, currencies: currencyOptions }
    return (
      <ZeroApiContextProvider
        id={item.restaurant.id}
        name="Restaurant"
        lang={user.lang}
        apiUrl={`${config.apiUrl}/graphql/restaurant`}
        theme={theme}
      >
        <RestaurantContext.Provider value={value}>
          <Main onSetLanguage={props.onSetLanguage} refetch={props.refetch} />
        </RestaurantContext.Provider>
      </ZeroApiContextProvider>
    )
  }

  return <Navigate to={{ pathname: '/restaurant' }} />
}
