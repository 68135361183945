import { useContext } from 'react'

import { gql, useMutation } from '@apollo/client'

import { Button, CircularProgress, Dialog, DialogActions, DialogTitle } from '@mui/material'

import { UserContext } from '../../App'

export function Update(props) {
  const user = useContext(UserContext)

  const [update, { loading }] = useMutation(gql`
    ${props.gql}
  `)

  // Delete Dialog
  const handleClose = () => {
    props.onClose(false)
  }

  if (loading) {
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    )
  }

  const handleAccept = () => {
    if (props.setOpenSnackBar) props.setOpenSnackBar(true)
    update({
      variables: props.variables,
    })
      .then((e) => {
        props.onSuccess()
      })
      .finally(() => {
        props.onClose(false)
      })
    user.consoleLog(props.variables)
  }

  return (
    <>
      <Dialog open={!loading} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-title">
          {user.translate(props.title)} {props.name}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {user.translate('cancel')}
          </Button>
          <Button onClick={handleAccept} color="primary" autoFocus>
            {user.translate('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
