const ElseIf = (prevClause) => (predicate, elsIfVal) => {
  const elseClause = (elsVal) => prevClause(predicate ? elsIfVal : elsVal)
  return {
    else: elseClause,
    end: elseClause,
    elseIf: ElseIf(elseClause),
  }
}
export const If = (predicate, ifValue) => {
  const elseClause = (elsVal) => (predicate ? ifValue : elsVal)
  return {
    else: elseClause,
    end: elseClause,
    elseIf: ElseIf(elseClause),
  }
}
