import React, { useContext, useState } from 'react'

import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'
import { Button, Card, CardActions, CardContent, IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { InventoryCounterState } from './InventoryCounterState'
import { RestaurantContext, UserContext } from '../../App'
import Add from '../common/Add'
import Delete from '../common/Delete'
import Show from '../common/Show'
import { useEntityRelationQuery } from '../common/useEntityQuery'

const useStyles = makeStyles((theme) => ({
  warehouseItem: {
    display: 'flex',
    padding: 0,
  },
  warehouseItemDisabled: {
    display: 'flex',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    padding: 0,
  },
  itemName: {
    margin: theme.spacing(1),
    flexGrow: 1,
    whiteSpace: 'nowrap',
  },
}))

export default function Warehouse(props) {
  const classes = useStyles()
  // Get current user preferences (language)
  const user = useContext(UserContext)
  const restaurant = useContext(RestaurantContext)
  const warehouse = props.warehouse

  let fieldList = [
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'id',
      fieldLabel: 'id',
      fieldValue: warehouse.id,
    },
    {
      required: true,
      type: 'text',
      fieldType: 'text',
      fieldName: 'name',
      fieldLabel: user.translate('title'),
      fieldValue: warehouse.name[user.lang],
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'langId',
      fieldLabel: 'langId',
      fieldValue: warehouse.name.id,
    },
  ]

  if (user.me.profile && user.me.profile.isAdmin) {
    fieldList = [
      {
        required: true,
        type: 'hidden',
        fieldType: 'text',
        fieldName: 'id',
        fieldLabel: 'id',
        fieldValue: warehouse.id,
      },
      {
        required: true,
        type: 'text',
        fieldType: 'text',
        fieldName: 'nameMk',
        fieldLabel: user.translate('titleMk'),
        fieldValue: warehouse.name.mk,
      },
      {
        required: true,
        type: 'text',
        fieldType: 'text',
        fieldName: 'nameEn',
        fieldLabel: user.translate('titleEn'),
        fieldValue: warehouse.name.en,
      },
      {
        required: true,
        type: 'hidden',
        fieldType: 'text',
        fieldName: 'langId',
        fieldLabel: 'langId',
        fieldValue: warehouse.name.id,
      },
    ]
  }

  const EDIT_ITEM = `mutation ($restaurantId: String!, $id: String!, $langId: String!, ${
    user.me.profile && user.me.profile.isAdmin ? '$nameMk: String!, $nameEn: String!' : '$name: String!'
  }) {
		createWarehouse(input: {
			id: $id,
			${user.me.profile && user.me.profile.isAdmin ? user.gqlEditNameTr('$nameMk', '$nameEn', '$langId') : user.gqlEditName('$name', '$langId')}
			restaurantId: $restaurantId
		}) {
			id
		}
    }`

  const DEACTIVATE_ITEM = `
        mutation ($id: String!) {
            editWarehouse(input: {
                id: $id,
                active: 0
            }) {
                id
            }
        }`
  const UNDEACTIVATE_ITEM = `
        mutation ($id: String!) {
            editWarehouse(input: {
                id: $id,
                active: 1
            }) {
                id
            }
        }`

  const [openManipulateBox, setOpenManipulateBox] = React.useState(false)

  const onManipulateClick = () => {
    setOpenManipulateBox(true)
  }

  const onDeleteClick = () => {
    if (
      warehouse.productionDepartmentsCount === 0 &&
      warehouse.orderItemsCount === 0 &&
      warehouse.inventorysCount === 0 &&
      warehouse.receivingStockItemsCount === 0 &&
      warehouse.invoiceItemProductsCount === 0 &&
      warehouse.receivingWarehouseStockTransfersCount === 0 &&
      warehouse.sendingWarehouseStockTransfersCount === 0
    ) {
      props.setDeleteState({
        name: warehouse.name[user.lang],
        variables: { id: warehouse.id },
      })
      props.setOpenDeleteBox(true)
    } else {
      props.setOpenSnackBarWarning(true)
    }
  }

  const warehouseCountersQuery = useEntityRelationQuery(
    'InventoryCounter',
    [
      { name: 'id', gql: 'String!' },
      { name: 'name', gql: 'String!', subSelection: { id: true, [user.lang]: true } },
      { name: 'stockTypeId', gql: 'String!' },
      { name: 'stockType', gql: 'StockType!', subSelection: { name: { [user.lang]: true } } },
      { name: 'quantity', gql: 'Float!' },
    ],
    'warehouseId',
    warehouse.id,
  )
  const stockTypesQuery = useEntityRelationQuery(
    'StockType',
    [
      { name: 'id', gql: 'String!' },
      { name: 'name', gql: 'String!', subSelection: { [user.lang]: true } },
      { name: 'uom', gql: 'Long!' },
    ],
    'restaurantId',
    restaurant.id,
  )

  const unitOfMeasures = {
    0: user.translate('liter'),
    1: user.translate('kilogram'),
    2: user.translate('piece'),
  }

  const stockTypeOptions =
    stockTypesQuery.items?.map((it) => ({
      val: it.id,
      label: it.name[user.lang] + ' (' + unitOfMeasures[it.uom] + ')',
    })) ?? []

  const [showCounter, setShowCounter] = useState()
  const [deleteCounter, setDeleteCounter] = useState()

  const ADD_COUNTER_ITEM = `mutation ($restaurantId: String!, $warehouseId: String!, $name: String!, $stockTypeId: String!, $quantity: Float!) {
    createInventoryCounter(input: {
		${user.gqlCreateName('$name')}
		restaurantId: $restaurantId
		warehouseId: $warehouseId
        stockTypeId: $stockTypeId
        quantity: $quantity
	}) {
		id
	}
}`
  const EDIT_COUNTER_ITEM = `mutation ($restaurantId: String!, $warehouseId: String!, $id: String, $langId: String!, $name: String!, $stockTypeId: String!, $quantity: Float!) {
    createInventoryCounter(input: {
		id: $id,
		restaurantId: $restaurantId
		${user.gqlEditName('$name', '$langId')}
		warehouseId: $warehouseId
        stockTypeId: $stockTypeId
        quantity: $quantity
	}) {
		id
	}
}`

  const DELETE_COUNTER = `mutation ($id: String!) {
        deleteInventoryCounter(id: $id)
    }`

  const counterFieldList = [
    {
      required: false,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'id',
      fieldLabel: 'id',
      fieldValue: showCounter?.id,
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'restaurantId',
      fieldLabel: 'restaurantId',
      fieldValue: restaurant.id,
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'warehouseId',
      fieldLabel: 'warehouseId',
      fieldValue: warehouse.id,
    },
    {
      required: false,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'langId',
      fieldLabel: 'langId',
      fieldValue: showCounter?.name ? showCounter.name.id : undefined,
    },
    {
      required: true,
      type: 'text',
      fieldType: 'text',
      fieldName: 'name',
      fieldLabel: user.translate('title'),
      fieldValue: showCounter?.name ? showCounter.name[user.lang] : '',
    },
    {
      required: true,
      type: 'autocomplete',
      fieldType: 'autocomplete',
      fieldName: 'stockTypeId',
      fieldLabel: user.translate('stock_type'),
      fieldValue: stockTypeOptions.find((it) => it.val === showCounter?.stockTypeId) ?? stockTypeOptions[0] ?? {},
      fieldOptions: stockTypeOptions,
    },
    {
      required: true,
      type: 'number',
      fieldType: 'text',
      fieldName: 'quantity',
      fieldLabel: user.translate('quantity'),
      fieldValue: showCounter?.quantity || 1,
    },
  ]

  return (
    <React.Fragment>
      <Card>
        <CardContent className={warehouse.active ? classes.warehouseItem : classes.warehouseItemDisabled}>
          <CardActions className={classes.itemName}>
            <Button onClick={onManipulateClick}>
              <b>{warehouse.name[user.lang]}</b>
            </Button>
          </CardActions>
          <CardActions>
            <IconButton size="small" color="primary" onClick={onManipulateClick}>
              <IconEdit />
            </IconButton>
            <IconButton size="small" color="primary" onClick={onDeleteClick}>
              <IconDelete />
            </IconButton>
          </CardActions>
        </CardContent>
        <CardContent style={{ padding: 0, display: 'flex', flexDirection: 'column' }}>
          <Show if={warehouseCountersQuery.items?.length}>
            <Typography style={{ alignSelf: 'start', paddingLeft: 12 }} color="secondary" variant="subtitle2">
              {user.translate('inventory_counters')}:
            </Typography>
            {warehouseCountersQuery.items?.map((counter) => (
              <CardContent key={counter.id} style={{ display: 'flex', padding: 0 }}>
                <CardActions className={classes.itemName} style={{ padding: 0, marginTop: 0, marginBottom: 0 }}>
                  <Button startIcon={<IconEdit />} onClick={() => setShowCounter(counter)}>
                    {counter.name[user.lang]} (<InventoryCounterState counterId={counter.id} />)
                  </Button>
                </CardActions>
                <CardActions style={{ padding: 0 }}>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => setDeleteCounter({ variables: { id: counter.id }, name: counter.name[user.lang] })}
                  >
                    <IconDelete />
                  </IconButton>
                </CardActions>
              </CardContent>
            ))}
          </Show>
        </CardContent>
        <CardActions>
          <Button size="small" color="secondary" onClick={() => setShowCounter({})}>
            {user.translate('add_inventory_counter')}
          </Button>
        </CardActions>
      </Card>

      {showCounter && (
        <Add
          fieldList={counterFieldList}
          openManipulateBox={setShowCounter}
          actionType={user.translate('edit')}
          restaurant={restaurant}
          name={showCounter?.name ? showCounter?.name[user.lang] : undefined}
          manipulateItem={showCounter?.id ? EDIT_COUNTER_ITEM : ADD_COUNTER_ITEM}
          onSuccess={warehouseCountersQuery.refresh}
        />
      )}

      {openManipulateBox && (
        <Add
          fieldList={fieldList}
          openManipulateBox={setOpenManipulateBox}
          actionType={user.translate('edit')}
          restaurant={restaurant}
          name={warehouse.name[user.lang]}
          manipulateItem={EDIT_ITEM}
          deactivate={!!warehouse.active}
          deactivateItem={DEACTIVATE_ITEM}
          unDeactivateItem={UNDEACTIVATE_ITEM}
          onSuccess={props.refetch}
        />
      )}
      {Boolean(deleteCounter) && (
        <Delete
          name={deleteCounter.name}
          variables={deleteCounter.variables}
          setOpenDeleteBox={setDeleteCounter}
          deleteItem={DELETE_COUNTER}
          onSuccess={warehouseCountersQuery.refresh}
        />
      )}
    </React.Fragment>
  )
}
