import React, { useContext, useLayoutEffect, useState } from 'react'

import $ from 'jquery'

import { gql, useMutation, useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@zeroapi-io/zeroapireact'

import 'jquery-ui-bundle'
import 'jquery-ui-bundle/jquery-ui.min.css'

import IconAdd from '@mui/icons-material/Add'
import IconDuplicate from '@mui/icons-material/ContentCopy'
import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'
import { Button, Card, CardContent, Fab, IconButton, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { RestaurantContext, UserContext } from '../../App'
import Add from '../common/Add'
import Delete from '../common/Delete'
import helper from '../common/Helper'
import IconDialog from '../common/IconDialog'
import SnackBarDelete from '../common/SnackBarDelete'
import { Update } from '../common/Update'
import './style.menu.css'

// CSS
const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  itemCard: {
    display: 'block',
    overflow: 'unset',
    textAlign: 'left',
  },
  cardContent: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  cardHeader: {
    width: `inherit`,
    padding: `30px`,
    marginTop: '-30px',
    borderRadius: `10px`,
    boxShadow: `0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)`,
    backgroundColor: `#3f51b5`,
    color: `#fff`,
  },
  cardHeaderTitle: {
    width: `inherit`,
    padding: `30px`,
    margin: '-30px',
  },
  menuTitle: {
    padding: `5px 0`,
    fontSize: 12,
    display: 'block',
    flex: 1,
  },
  fontIcon: {
    fontSize: 14,
    width: '23px !important',
  },
  dragIcon: {
    float: `left`,
    paddingRight: theme.spacing(1),
    marginTop: `-2px`,
  },
  cardHeaderIcons: {
    float: `right`,
    padding: '10px',
    marginTop: '-10px',
    width: '40px',
  },
  cardMenuIcons1: {
    // right: `32px`,
    // // position: `absolute`,
    // zIndex: '99',
    // padding: '3px',
    // marginTop: '-33px',
    // width: '33px',
  },
  cardMenuIcons2: {
    // right: `0`,
    // // position: `absolute`,
    // zIndex: '99',
    // padding: '3px',
    // marginTop: '-33px',
    // width: '33px',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
  },
  itemName: {
    margin: 0,
    position: 'relative',
    flexGrow: 1,
  },
  itemNameDisabled: {
    margin: 0,
    position: 'relative',
    flexGrow: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  titleName: {
    fontVariant: 'all-small-caps',
    fontSize: 18,
    marginBottom: theme.spacing(2),
  },
}))

// React Function Component
export default function MenuCategories(props) {
  const classes = useStyles()

  // Get current restaurant from context
  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const [openAddBox, setOpenAddBox] = useState(false)
  const [openEditBox, setOpenEditBox] = useState(false)
  const [openDeleteBox, setOpenDeleteBox] = useState(false)
  const [openDuplicateBox, setOpenDuplicateBox] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [openSelectBox, setOpenSelectBox] = useState(false)
  const [selectedItem, setSelectedItem] = useState('')

  const [deleteState, setDeleteState] = useState({
    name: '',
    deleteItem: ``,
    undeleteItem: ``,
    variables: {},
  })

  const [editState, setEditState] = useState({
    menuCategory: {},
  })

  const onAddClick = () => {
    setEditState({
      menuCategory: {},
    })

    setOpenSelectBox(true)
  }

  const onEditClick = (event, menuId, menuCategory) => {
    setEditState({
      menuCategory,
    })

    setOpenEditBox(true)
  }
  const onDeleteClick = (event, menuCategory) => {
    const variables = { id: menuCategory.id }

    setDeleteState({
      name: menuCategory.name[user.lang],
      variables,
    })

    setOpenDeleteBox(true)
  }

  let maxDisplayIndex = 0

  let fieldList = [
    {
      required: true,
      type: 'text',
      fieldType: 'text',
      fieldName: 'name',
      fieldLabel: user.translate('title'),
      fieldValue: typeof editState.menuCategory.id !== 'undefined' ? editState.menuCategory.name[user.lang] : '',
    },
    {
      required: true,
      type: 'text',
      fieldType: 'text',
      fieldName: 'icon',
      fieldLabel: user.translate('icon'),
      fieldValue: '',
      fieldIcon: '',
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'menuId',
      fieldLabel: 'menuId',
      fieldValue: props.menuId,
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'displayIndex',
      fieldLabel: 'displayIndex',
      fieldValue: '',
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'id',
      fieldLabel: 'id',
      fieldValue: typeof editState.menuCategory.id !== 'undefined' ? editState.menuCategory.id : helper.uid(),
    },
    {
      required: false,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'langId',
      fieldLabel: 'langId',
      fieldValue: typeof editState.menuCategory.name !== 'undefined' ? editState.menuCategory.name.id : '',
    },
  ]

  if (user.me.profile && user.me.profile.isAdmin) {
    fieldList = [
      {
        required: true,
        type: 'text',
        fieldType: 'text',
        fieldName: 'nameMk',
        fieldLabel: user.translate('titleMk'),
        fieldValue: typeof editState.menuCategory.id !== 'undefined' ? editState.menuCategory.name.mk : '',
      },
      {
        required: true,
        type: 'text',
        fieldType: 'text',
        fieldName: 'nameEn',
        fieldLabel: user.translate('titleEn'),
        fieldValue: typeof editState.menuCategory.id !== 'undefined' ? editState.menuCategory.name.en : '',
      },
      {
        required: true,
        type: 'text',
        fieldType: 'text',
        fieldName: 'icon',
        fieldLabel: user.translate('icon'),
        fieldValue: '',
        fieldIcon: '',
      },
      {
        required: true,
        type: 'hidden',
        fieldType: 'text',
        fieldName: 'menuId',
        fieldLabel: 'menuId',
        fieldValue: props.menuId,
      },
      {
        required: true,
        type: 'hidden',
        fieldType: 'text',
        fieldName: 'displayIndex',
        fieldLabel: 'displayIndex',
        fieldValue: '',
      },
      {
        required: true,
        type: 'hidden',
        fieldType: 'text',
        fieldName: 'id',
        fieldLabel: 'id',
        fieldValue: typeof editState.menuCategory.id !== 'undefined' ? editState.menuCategory.id : helper.uid(),
      },
      {
        required: false,
        type: 'hidden',
        fieldType: 'text',
        fieldName: 'langId',
        fieldLabel: 'langId',
        fieldValue: typeof editState.menuCategory.name !== 'undefined' ? editState.menuCategory.name.id : '',
      },
    ]
  }

  const ADD_ITEM = `
    mutation ($id: String!, $menuId: String!, ${
      user.me.profile && user.me.profile.isAdmin ? '$nameMk: String!, $nameEn: String!' : '$name: String!'
    }, $icon: String!, $displayIndex: Long!) {
        createMenuCategory(input:{
            id: $id
            ${user.me.profile && user.me.profile.isAdmin ? user.gqlCreateNameTr('$nameMk', '$nameEn') : user.gqlCreateName('$name')}
            menuId: $menuId
            displayIndex: $displayIndex
            icon: $icon
        }){
            id
        }
    }`

  const EDIT_ITEM = `
    mutation ($id: String!, $menuId: String!, $langId: String!, ${
      user.me.profile && user.me.profile.isAdmin ? '$nameMk: String!, $nameEn: String!' : '$name: String!'
    }, $icon: String!, $displayIndex: Long!) {
        createMenuCategory(input:{
            id: $id
            ${
              user.me.profile && user.me.profile.isAdmin
                ? user.gqlEditNameTr('$nameMk', '$nameEn', '$langId')
                : user.gqlEditName('$name', '$langId')
            }
            menuId: $menuId
            displayIndex: $displayIndex
            icon: $icon
        }){
            id
        }
    }`

  const BUNDLE_EDIT = gql`
    mutation ($indices: [InputDisplayIndex!]!) {
      extras_updateMenuCategoriesDisplayIndex(indices: $indices)
    }
  `

  const [bundleEditItem] = useMutation(BUNDLE_EDIT)

  const DELETE_ITEM = `
    mutation ($id: String!) {
        deleteMenuCategory(id: $id)
    }`

  const UNDELETE_ITEM = `
    mutation ($id: String!) {
        undeleteMenuCategory(id: $id)
    }`

  const DUPLICATE_ITEM = `
    mutation ($categoryId: String!) {
        extras_duplicateMenuCategory(categoryId: $categoryId)
    }`

  const DEACTIVATE_ITEM = `
    mutation ($id: String!) {
        editMenuCategory(input: {
            id: $id,
            active: 0
        }) {
            id
        }
    }`
  const UNDEACTIVATE_ITEM = `
    mutation ($id: String!) {
        editMenuCategory(input: {
            id: $id,
            active: 1
        }) {
            id
        }
    }`

  // GraphQL API request definition (local variables: restaurantId)
  const GET_MENU_CATEGORY_BY_RESTAURANT = gql`
    query($menuId: String!) {
        getRestaurantMenuById(id: $menuId) {
          name {
            ${user.gqlFetchName()}
          }
          menuCategorys {
            id
            name {
                ${user.gqlFetchName()}
            }
            icon
            displayIndex
            active
          }
        }
    }`

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const { data, loading, error, refetch } = useQuery(GET_MENU_CATEGORY_BY_RESTAURANT, {
    variables: { menuId: props.menuId },
    pollInterval: 3000,
    errorPolicy: 'ignore',
  })

  const onDuplicateClick = (event, menuCategory) => {
    setOpenDuplicateBox({
      name: menuCategory.name[user.lang],
      variables: {
        categoryId: menuCategory.id,
      },
    })
  }

  useLayoutEffect(() => {
    let isMounted = true // note this flag denote mount status

    let placeholderHeight, placeholderAnimatorHeight

    const slides = $('.slides.slidesMenuCategorys')
    if (isMounted) {
      $(slides).sortable({
        placeholder: 'slide-placeholder',
        axis: 'y',
        revert: 150,
        // handle: "handle",
        start: function (event, ui) {
          placeholderHeight = ui.item.outerHeight()
          ui.placeholder.height(placeholderHeight + 15)
          $('<div class="slide-placeholder-animator" data-height="' + placeholderHeight + '"></div>').insertAfter(ui.placeholder)
        },
        change: function (event, ui) {
          ui.placeholder
            .stop()
            .height(0)
            .animate(
              {
                height: ui.item.outerHeight() + 15,
              },
              300,
            )

          placeholderAnimatorHeight = parseInt($('.slide-placeholder-animator').attr('data-height'))

          $('.slide-placeholder-animator')
            .stop()
            .height(placeholderAnimatorHeight + 15)
            .animate(
              {
                height: 0,
              },
              300,
              function () {
                $(this).remove()
                placeholderHeight = ui.item.outerHeight()
                $('<div class="slide-placeholder-animator" data-height="' + placeholderHeight + '"></div>').insertAfter(ui.placeholder)
              },
            )
        },
        stop: function (event, ui) {
          const indices = []
          const sortable = $('.slides div.ui-sortable-handle.menuCategorys')
          const count = sortable.length

          let min = 0
          for (let i = 0; i <= count - 1; i++) {
            min = min > i ? min : i
            sortable[i].setAttribute('displayIndex', min)

            indices.push({
              id: sortable[i].getAttribute('id'),
              index: min,
            })
          }
          bundleEditItem({ variables: { indices } })

          $('.slide-placeholder-animator').remove()
        },
      })
    }

    return () => {
      isMounted = false
    } // use effect cleanup to set flag false, if unmounted
  })

  // If it is loading, show progress bar
  // if (loading) return <CircularProgress />
  // if (loading) {
  //     return (<div className="App AppLoading"><CircularProgress /></div>);
  // }

  // In case there is an error, just show it for now
  if (!data) {
    user.consoleLog(error)
    return <p>&nbsp;</p>
  }

  const menu = data.getRestaurantMenuById
  const categories = menu.menuCategorys.slice().sort((a, b) => a.displayIndex - b.displayIndex)
  maxDisplayIndex = categories.length

  const selectedCategory = categories.find((c) => c.id === props.menuCategoryId)

  if (!selectedCategory) {
    if (categories.length > 0) {
      props.setMenuCategoryId(categories[0].id)
      props.setMenuItemId(false)
    } else if (props.menuCategoryId) {
      props.setMenuCategoryId(false)
      props.setMenuItemId(false)
    }
  }

  const renderAdd = (fieldList, maxDisplayIndex) => {
    fieldList[0].fieldValue = ''
    if (user.me.profile && user.me.profile.isAdmin) {
      fieldList[2].fieldValue = selectedItem
      fieldList[2].fieldIcon = helper.renderFontIcon(selectedItem)
      fieldList[4].fieldValue = maxDisplayIndex
    } else {
      fieldList[1].fieldValue = selectedItem
      fieldList[1].fieldIcon = helper.renderFontIcon(selectedItem)
      fieldList[3].fieldValue = maxDisplayIndex
    }

    return (
      <Add
        fieldList={fieldList}
        openManipulateBox={setOpenAddBox}
        actionType={user.translate('add')}
        name={user.translate('category_item')}
        restaurant={restaurant}
        manipulateItem={ADD_ITEM}
        categoryIcon
        onSuccess={refetch}
      />
    )
  }

  const renderEdit = (fieldList) => {
    if (user.me.profile && user.me.profile.isAdmin) {
      fieldList[2].fieldValue = editState.menuCategory.icon
      fieldList[2].fieldIcon = helper.renderFontIcon(editState.menuCategory.icon)
      fieldList[4].fieldValue = editState.menuCategory.displayIndex
    } else {
      fieldList[1].fieldValue = editState.menuCategory.icon
      fieldList[1].fieldIcon = helper.renderFontIcon(editState.menuCategory.icon)
      fieldList[3].fieldValue = editState.menuCategory.displayIndex
    }

    return (
      <Add
        fieldList={fieldList}
        openManipulateBox={setOpenEditBox}
        actionType={user.translate('edit')}
        categoryIcon
        restaurant={restaurant}
        name={editState.menuCategory.name[user.lang]}
        manipulateItem={EDIT_ITEM}
        deactivate={!!editState.menuCategory.active}
        deactivateItem={DEACTIVATE_ITEM}
        unDeactivateItem={UNDEACTIVATE_ITEM}
        onSuccess={refetch}
      />
    )
  }

  return (
    <React.Fragment>
      <Card className={classes.itemCard}>
        <CardContent className={classes.cardContent}>
          <div className={classes.titleName}>
            {user.translate('category_items')}:
            <Fab ariaLabel="Add Menu Category" color="primary" className={classes.speedDial} onClick={onAddClick}>
              <IconAdd />
            </Fab>
          </div>

          <div className="slides slidesMenuCategorys">
            {/* {loading && (
                            <div className="App AppLoading"><CircularProgress /></div>
                        )} */}
            {!loading &&
              categories.length > 0 &&
              categories.map((menuCategory, index) => {
                const fontIconItem = helper.renderFontIcon(menuCategory.icon)

                return (
                  <div
                    className={`${classes.item} ${menuCategory.active ? classes.itemName : classes.itemNameDisabled} menuCategorys`}
                    // displayIndex={menuCategory.displayIndex}
                    id={menuCategory.id}
                    key={menuCategory.id}
                  >
                    <Tooltip
                      title={`${user.translate('click_to_open_drag')} ` + menuCategory.name[user.lang]}
                      placement="bottom-start"
                      className={classes.menuTitle}
                      onClick={(event) => props.setMenuCategoryId(menuCategory.id)}
                    >
                      <Button color="inherit" component="subtitle1">
                        {/* <DragHandleIcon className={`${classes.dragIcon} handle`} /> */}
                        <div>
                          {fontIconItem && <FontAwesomeIcon icon={fontIconItem} className={classes.fontIcon} />}{' '}
                          {menuCategory.name[user.lang]}
                        </div>
                      </Button>
                    </Tooltip>

                    <Tooltip
                      title={`${user.translate('edit')} ` + menuCategory.name[user.lang]}
                      placement="bottom-start"
                      className={classes.cardMenuIcons1}
                    >
                      <IconButton size="small" color="primary" onClick={(e) => onEditClick(e, props.menuId, menuCategory)}>
                        <IconEdit fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={`${user.translate('duplicate')} ` + menuCategory.name[user.lang]}
                      placement="bottom-start"
                      className={classes.cardMenuIcons1}
                    >
                      <IconButton size="small" color="primary" onClick={(e) => onDuplicateClick(e, menuCategory)}>
                        <IconDuplicate fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={`${user.translate('delete')} ` + menuCategory.name[user.lang]}
                      placement="bottom-start"
                      className={classes.cardMenuIcons1}
                    >
                      <IconButton size="small" color="primary" onClick={(e) => onDeleteClick(e, menuCategory)}>
                        <IconDelete fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </div>
                )
              })}
          </div>
        </CardContent>
      </Card>

      {openDeleteBox && (
        <Delete
          name={deleteState.name}
          variables={deleteState.variables}
          setOpenSnackBar={setOpenSnackBar}
          setOpenDeleteBox={setOpenDeleteBox}
          deleteItem={DELETE_ITEM}
          onSuccess={refetch}
        />
      )}
      {openDuplicateBox && (
        <Update
          name={openDuplicateBox.name}
          variables={openDuplicateBox.variables}
          setOpenSnackBar={setOpenSnackBar}
          onClose={setOpenDuplicateBox}
          onSuccess={refetch}
          title="duplicate_category"
          gql={DUPLICATE_ITEM}
        />
      )}

      {openSnackBar && (
        <SnackBarDelete
          message={deleteState.name + ` ${user.translate('deleted')}!`}
          variables={deleteState.variables}
          openSnackBar={openSnackBar}
          setOpenSnackBar={setOpenSnackBar}
          unDeleteItem={UNDELETE_ITEM}
          onSuccess={refetch}
        />
      )}

      {openAddBox && renderAdd(fieldList, maxDisplayIndex)}

      {openEditBox && renderEdit(fieldList)}

      {openSelectBox && (
        <IconDialog restaurant={restaurant} setOpenSelectBox={setOpenSelectBox} setSelectedItem={setSelectedItem} action={setOpenAddBox} />
      )}
    </React.Fragment>
  )
}
