import React, { useContext, useMemo, useState } from 'react'

import PropTypes from 'prop-types'

import { gql, useQuery } from '@apollo/client'

import CheckIcon from '@mui/icons-material/Check'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { IconButton, InputAdornment, MenuItem, Select, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { RestaurantContext, UserContext } from '../../App'

const uomDropDownSize = 70

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    '& [class*="MuiFormHelperText-root"]': {
      marginTop: 0,
      fontSize: 11,
    },
  },
  uomOptions: {
    position: 'absolute',
    width: uomDropDownSize,
  },
  select: {},
}))

const GQL_GET_STOCK_TYPE = gql`
  query ($restaurantId: String!) {
    getStockTypesByContextRestaurantId(restaurantId: $restaurantId) {
      _id
      id
      uom
      piece
      package
    }
  }
`

export function QuantityInputFieldToggle(props) {
  const classes = useStyles()

  const user = useContext(UserContext)
  const restaurant = useContext(RestaurantContext)

  const [inputValue, setInputValue] = useState()
  const [uomOption, setUomOption] = useState()

  const { data: dataStockTypes } = useQuery(GQL_GET_STOCK_TYPE, {
    variables: { restaurantId: restaurant.id },
  })

  const stockTypes = dataStockTypes?.getStockTypesByContextRestaurantId || []
  const stockTypesById = useMemo(() => stockTypes.toMapBy((item) => item.id), [stockTypes])
  const stockType = stockTypesById[props.stockTypeId]

  if (stockType === undefined) return '...'

  const uom = stockType.unit !== undefined ? stockType.unit : stockType.uom

  const propsUom = props.uomOpts || 0
  const uomValue = uomOption !== undefined ? uomOption : propsUom

  const unitQuantities = stockType ? [1, stockType.piece, stockType.package] : []
  const convertUom = (value, fromUnit, toUnit = 0, roundTo = 3) => {
    if (unitQuantities[fromUnit] === undefined) return undefined
    if (unitQuantities[toUnit] === undefined) return undefined
    return ((value * unitQuantities[fromUnit]) / unitQuantities[toUnit]).round(roundTo)
  }

  const propsValue = props.value && user.formatQuantity(convertUom(props.value, 0, propsUom))
  const quantityValue = inputValue !== undefined ? inputValue : propsValue

  const inputQuantityVal = quantityValue && user.parseStringNumber(quantityValue)
  const inputQuantityRawVal = quantityValue && convertUom(inputQuantityVal, uomValue, 0)

  const uomUnit = ['L', 'Kg', 'p']
  const uomOptions = [user.translate('liter'), user.translate('kilogram'), user.translate('piece')]

  const pieceQuantity = `${stockType.piece} ${uomUnit[uom]}`
  const uomOptionPiece = [
    { label: `${user.translate('dose')} ${pieceQuantity}` },
    { label: `${user.translate('dose')} ${pieceQuantity}` },
    { label: `${user.translate('dose')} ${pieceQuantity}` },
  ]

  const packageQuantity = `${stockType.package} ${uomUnit[uom]}`
  const uomOptionPackage = [
    { label: `${user.translate('bottle')} ${packageQuantity}`, val: 2 },
    { label: `${user.translate('bag')} ${packageQuantity}`, val: 2 },
    { label: `${user.translate('box')} ${packageQuantity}`, val: 2 },
  ]

  const uomTitle = uomOptions[uom]
  const uomPiece = uomOptionPiece[uom]
  const uomPackage = uomOptionPackage[uom]

  const menuUomOptions = [
    { value: 0, label: uomTitle },
    { value: 1, label: uomPiece.label },
    { value: 2, label: uomPackage.label },
  ]

  const clearOrSameInputQuantity =
    (inputValue === undefined || inputValue.length === 0 || inputValue === propsValue) && propsValue !== undefined
  const clearOrSameInputUom = (uomOption === undefined || uomOption.length === 0 || uomOption === propsUom) && propsUom !== undefined
  const clearOrSameInput = props.enableClear && clearOrSameInputQuantity && clearOrSameInputUom

  const quantityValueChanged = inputValue !== undefined && inputValue.length > 0 && inputValue !== propsValue && propsValue !== undefined
  const uomValueChanged = uomOption !== undefined && uomOption !== propsUom && propsUom !== undefined
  const valueChanged = props.enableAccept && (quantityValueChanged || uomValueChanged)

  const onInputValueChanged = (e) => {
    const value = e.target.value
    if (value.length === 0 || value.endsWith(',') || value.endsWith('.')) {
      return setInputValue(value)
    }

    const numValue = user.parseStringNumber(value)
    const formatedValue = numValue === undefined || isNaN(numValue) ? value : user.formatQuantity(numValue)
    setInputValue(formatedValue)

    const quantityRawVal = (numValue === undefined || isNaN(numValue)) && convertUom(numValue, uomValue, 0)
    if (props.onChange && quantityRawVal) {
      props.onChange(quantityRawVal, uomOption)
    }
  }

  const onInputUomChanged = (e) => {
    const newUom = e.target.value

    const inputQuantityRawVal = inputQuantityVal && convertUom(inputQuantityVal, uomValue, newUom)

    setInputValue(inputQuantityRawVal && user.formatQuantity(inputQuantityRawVal))
    setUomOption(newUom)

    if (props.onChange && inputQuantityRawVal) {
      props.onChange(inputQuantityRawVal, uomOption)
    }
  }

  if (props.readOnly) {
    const uomLabel = menuUomOptions.find((option) => option.value === uomValue)?.label || uomTitle
    return `${quantityValue} ${uomLabel}`
  }

  return (
    <div className={[props.className, classes.root].join(' ')}>
      <TextField
        helperText={props.label}
        type="text"
        inputMode="numeric"
        value={quantityValue}
        margin="none"
        variant="standard"
        className={classes.input}
        onChange={onInputValueChanged}
        onBlur={() => setInputValue()}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (clearOrSameInput) {
              props.onSubmit(0, uomOption)
            } else if (valueChanged) {
              props.onSubmit(inputQuantityRawVal, uomOption)
            } else if (inputQuantityRawVal) {
              props.onSubmit(inputQuantityRawVal, uomOption)
            }
          }
        }}
        InputProps={{
          style: {
            paddingLeft: uomDropDownSize + 8,
            fontSize: 14,
          },
          classes: {
            input: classes.input,
          },
          endAdornment: clearOrSameInput ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="remove"
                onClick={() => props.onSubmit(0, uomOption)}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
                size="large"
              >
                <DeleteOutlineIcon />
              </IconButton>
            </InputAdornment>
          ) : (
            valueChanged && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="order"
                  onClick={() => {
                    props.onSubmit(inputQuantityRawVal, uomOption)
                  }}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                  size="large"
                >
                  <CheckIcon />
                </IconButton>
              </InputAdornment>
            )
          ),
        }}
      />
      <Select
        value={uomValue}
        onChange={onInputUomChanged}
        disableUnderline
        variant="standard"
        size="small"
        className={classes.uomOptions}
        IconComponent={() => null}
        inputProps={{ 'aria-label': 'Without label', fontSize: 10 }}
        SelectDisplayProps={{
          style: { fontSize: 12, height: 17, paddingRight: 0, color: '#666' },
        }}
      >
        {menuUomOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

QuantityInputFieldToggle.propTypes = {
  stockTypeId: PropTypes.string.isRequired,
  uomOpts: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  enableClear: PropTypes.bool,
  enableAccept: PropTypes.bool,
}
