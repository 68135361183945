import React, { useContext, useState } from 'react'

import { useQuery, gql } from '@apollo/client'

import IconAdd from '@mui/icons-material/Add'
import IconClose from '@mui/icons-material/Close'
import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'
import { CircularProgress, Grid, AppBar, Tabs, Tab } from '@mui/material'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import makeStyles from '@mui/styles/makeStyles'

import MenuCategories from './MenuCategories'
import MenuItemProducts from './MenuItemProducts'
import MenuItems from './MenuItems'
import { RestaurantContext, UserContext } from '../../App'
import Add from '../common/Add'
import Delete from '../common/Delete'
import helper from '../common/Helper'
import MenuTabPanel from '../common/MenuTabPanel.js'
import SnackBarDelete from '../common/SnackBarDelete'

// CSS
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    // backgroundColor: theme.palette.background.paper,
  },
  children: {
    padding: 24,
  },
  tabs: {
    display: `grid`,
  },
  gridList: {
    flexGrow: 1,
    width: `100%`,
  },
  gridItem: {
    maxWidth: 600,
    minWidth: 300,
    flexGrow: 1,
    float: `left`,
    position: `relative`,
  },
  speedDialAction: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

// React Function Component
export default function MenuList() {
  const classes = useStyles()

  // Get current restaurant from context
  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const [selectedMenu, setSelectedMenu] = useState(0)
  const [menuCategoryId, setMenuCategoryId] = useState(false)
  const [menuItemId, setMenuItemId] = useState(false)

  const [openSpeedDial, setOpenSpeedDial] = useState(false)

  const [manipulateState, setManipulateState] = useState(false)
  const [openDeleteBox, setOpenDeleteBox] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false)

  const ADD_ITEM = `
        mutation ($restaurantId: String!, $id: String!, ${
          user.me.profile && user.me.profile.isAdmin ? '$nameMk: String!, $nameEn: String!' : '$name: String!'
        }) {
          createRestaurantMenu(input: {
            id: $id
            ${user.me.profile && user.me.profile.isAdmin ? user.gqlCreateNameTr('$nameMk', '$nameEn') : user.gqlCreateName('$name')}
            restaurantId: $restaurantId
          }) {
              id
          }
      }`

  const EDIT_ITEM = `
        mutation ($restaurantId: String!, $id: String!, $langId: String!, ${
          user.me.profile && user.me.profile.isAdmin ? '$nameMk: String!, $nameEn: String!' : '$name: String!'
        }) {
          createRestaurantMenu(input: {
            id: $id
            ${
              user.me.profile && user.me.profile.isAdmin
                ? user.gqlEditNameTr('$nameMk', '$nameEn', '$langId')
                : user.gqlEditName('$name', '$langId')
            }
            restaurantId: $restaurantId
          }) {
              id
          }
    }`

  const DELETE_ITEM = `
      mutation ($id: String!) {
        deleteRestaurantMenu(id: $id)
      }`

  const UNDELETE_ITEM = `
      mutation ($id: String!) {
        undeleteRestaurantMenu(id: $id)
      }`

  const DEACTIVATE_ITEM = `
        mutation ($id: String!) {
            editRestaurantMenu(input: {
                id: $id,
                active: 0
            }) {
                id
            }
        }`
  const UNDEACTIVATE_ITEM = `
        mutation ($id: String!) {
            editRestaurantMenu(input: {
                id: $id,
                active: 1
            }) {
                id
            }
        }`

  // GraphQL API request definition (local variables: restaurantId)
  const GET_MENU_BY_RESTAURANT = gql`
      query ($restaurantId: String!){
        getRestaurantMenusByRestaurantId(restaurantId: $restaurantId) {
              id
              name {
                ${user.gqlFetchName()}
              }
              active
          }
      }`

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const { data, loading, error, refetch } = useQuery(GET_MENU_BY_RESTAURANT, {
    variables: { restaurantId: restaurant.id },
    pollInterval: 3000,
  })

  // If it is loading, show progress bar
  // if (loading) return <CircularProgress />
  if (loading) {
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    )
  }

  // In case there is an error, just show it for now
  if (!data) {
    user.consoleLog(error)
    return <p>Error</p>
  }

  const menus = data.getRestaurantMenusByRestaurantId

  if (selectedMenu >= menus.length) {
    setSelectedMenu(menus.length - 1)
    setMenuCategoryId(false)
    setMenuItemId(false)
    return
  }

  const menu = menus[selectedMenu]

  const handleOpenSpeedDial = () => {
    setOpenSpeedDial(true)
  }

  const handleCloseSpeedDial = () => {
    setOpenSpeedDial(false)
  }

  const handleChangeMenu = (event, newValue) => {
    setSelectedMenu(newValue)
    setMenuCategoryId(false)
    setMenuItemId(false)
  }

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }

  function onAddClick() {
    setManipulateState({
      isAdd: true,
      menu: {},
      actionType: user.translate('add'),
      editItem: ADD_ITEM,
      fieldList:
        user.me.profile && user.me.profile.isAdmin
          ? [
              {
                required: true,
                type: 'text',
                fieldType: 'text',
                fieldName: 'nameMk',
                fieldLabel: user.translate('titleMk'),
                fieldValue: '',
              },
              {
                required: true,
                type: 'text',
                fieldType: 'text',
                fieldName: 'nameEn',
                fieldLabel: user.translate('titleEn'),
                fieldValue: '',
              },
              {
                required: true,
                type: 'hidden',
                fieldType: 'text',
                fieldName: 'id',
                fieldLabel: 'id',
                fieldValue: helper.uid(),
              },
            ]
          : [
              {
                required: true,
                type: 'text',
                fieldType: 'text',
                fieldName: 'name',
                fieldLabel: user.translate('title'),
                fieldValue: '',
              },
              {
                required: true,
                type: 'hidden',
                fieldType: 'text',
                fieldName: 'id',
                fieldLabel: 'id',
                fieldValue: helper.uid(),
              },
            ],
    })
  }

  function onEditClick() {
    setManipulateState({
      isAdd: true,
      menu,
      actionType: user.translate('edit'),
      editItem: EDIT_ITEM,
      fieldList:
        user.me.profile && user.me.profile.isAdmin
          ? [
              {
                required: true,
                type: 'text',
                fieldType: 'text',
                fieldName: 'nameMk',
                fieldLabel: user.translate('titleMk'),
                fieldValue: menu.name.mk,
              },
              {
                required: true,
                type: 'text',
                fieldType: 'text',
                fieldName: 'nameEn',
                fieldLabel: user.translate('titleEn'),
                fieldValue: menu.name.en,
              },
              {
                required: true,
                type: 'hidden',
                fieldType: 'text',
                fieldName: 'id',
                fieldLabel: 'id',
                fieldValue: menu.id,
              },
              {
                required: true,
                type: 'hidden',
                fieldType: 'text',
                fieldName: 'langId',
                fieldLabel: 'langId',
                fieldValue: menu.name.id,
              },
            ]
          : [
              {
                required: true,
                type: 'text',
                fieldType: 'text',
                fieldName: 'name',
                fieldLabel: user.translate('title'),
                fieldValue: menu.name[user.lang],
              },
              {
                required: true,
                type: 'hidden',
                fieldType: 'text',
                fieldName: 'id',
                fieldLabel: 'id',
                fieldValue: menu.id,
              },
              {
                required: true,
                type: 'hidden',
                fieldType: 'text',
                fieldName: 'langId',
                fieldLabel: 'langId',
                fieldValue: menu.name.id,
              },
            ],
    })
  }

  function onDeletelick() {
    setManipulateState({
      isDelete: true,
      menu,
    })
    setOpenDeleteBox(true)
  }

  return (
    <div className={classes.root}>
      <AppBar className={classes.tabs} position="static" color="default">
        <Tabs
          value={selectedMenu}
          onChange={handleChangeMenu}
          indicatorColor="secondary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {menus.map((menu, index) => (
            <Tab
              key={menu.id}
              label={menu.name[user.lang]}
              style={{ backgroundColor: menu.active === 0 ? 'rgba(0, 0, 0, 0.05)' : 'transparent' }}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>

      {menus.map((menu, index) => (
        <MenuTabPanel value={selectedMenu} index={index} className={classes.gridList} style={{ padding: 24 }} key={'tab_panel_' + menu.id}>
          <Grid container item spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start" key={'menu_' + menu.id}>
            <Grid item xs={4} className={classes.gridItem}>
              <MenuCategories
                key={'menu_categories_' + menu.id + menuCategoryId}
                menuId={menu.id}
                menuCategoryId={menuCategoryId}
                setMenuCategoryId={setMenuCategoryId}
                setMenuItemId={setMenuItemId}
              />
            </Grid>
            <Grid item xs={4} className={classes.gridItem}>
              <MenuItems
                key={'menu_items_' + menu.id + menuCategoryId + menuItemId}
                menuCategoryId={menuCategoryId}
                menuItemId={menuItemId}
                setMenuItemId={setMenuItemId}
              />
            </Grid>
            <Grid item xs={4} className={classes.gridItem}>
              <MenuItemProducts key={'menu_item_products_' + menu.id + menuItemId} menuItemId={menuItemId} setMenuItemId={setMenuItemId} />
            </Grid>
          </Grid>
        </MenuTabPanel>
      ))}

      <SpeedDial
        ariaLabel="Edit Menu"
        id={'menu_edit_' + selectedMenu}
        className={classes.speedDialAction}
        icon={<SpeedDialIcon openIcon={<IconClose />} />}
        onClose={handleCloseSpeedDial}
        onOpen={handleOpenSpeedDial}
        open={openSpeedDial}
        key={'menu_edit_' + selectedMenu}
      >
        <SpeedDialAction
          key={user.translate('add_menu')}
          icon={<IconAdd />}
          tooltipTitle={user.translate('add_menu')}
          onClick={onAddClick}
        />
        <SpeedDialAction
          key={user.translate('edit_menu')}
          icon={<IconEdit />}
          tooltipTitle={user.translate('edit_menu')}
          onClick={onEditClick}
        />
        <SpeedDialAction
          key={user.translate('delete_menu')}
          icon={<IconDelete />}
          tooltipTitle={user.translate('delete_menu')}
          onClick={onDeletelick}
        />
      </SpeedDial>

      {manipulateState && manipulateState.isAdd && (
        <Add
          fieldList={manipulateState.fieldList}
          openManipulateBox={setManipulateState}
          actionType={manipulateState.actionType}
          name={typeof manipulateState.menu.name !== 'undefined' ? manipulateState.menu.name[user.lang] : user.translate('menu')}
          restaurant={restaurant}
          manipulateItem={manipulateState.editItem}
          deactivate={!!manipulateState.menu.active}
          deactivateItem={DEACTIVATE_ITEM}
          unDeactivateItem={UNDEACTIVATE_ITEM}
          onSuccess={refetch}
        />
      )}

      {openDeleteBox && (
        <Delete
          name={manipulateState.menu.name[user.lang]}
          variables={{ id: manipulateState.menu.id }}
          setOpenSnackBar={setOpenSnackBar}
          setOpenDeleteBox={setOpenDeleteBox}
          deleteItem={DELETE_ITEM}
          onSuccess={refetch}
        />
      )}

      {openSnackBar && (
        <SnackBarDelete
          message={manipulateState.menu.name[user.lang] + ` ${user.translate('deleted')}!`}
          variables={{ id: manipulateState.menu.id }}
          openSnackBar={openSnackBar}
          setOpenSnackBar={setOpenSnackBar}
          unDeleteItem={UNDELETE_ITEM}
          onSuccess={refetch}
        />
      )}
    </div>
  )
}
