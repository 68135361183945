import React, { useContext, useState } from 'react'

import { useParams, useNavigate } from 'react-router-dom'

import { useMutation, gql } from '@apollo/client'

import { CircularProgress, Button, Dialog, DialogTitle, DialogActions } from '@mui/material'

import { UserContext } from '../App'


export default function ChangeEmail(props) {
  const { code } = useParams()

  const user = useContext(UserContext)

  const EDIT_ITEM = gql`
    mutation ($code: String!) {
      auth_verifyEmail(codeWithVerifier: $code) {
        message
        ok
      }
    }
  `

  const [editMail, { loading: loading_editMail, data: data_editMail }] = useMutation(EDIT_ITEM)

  const navigate = useNavigate()

  if (loading_editMail) {
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    )
  }

  if (!loading_editMail && code && !data_editMail) {
    editMail({ variables: { code } })
  }

  let status = '';
    let message = ''

  if (data_editMail && code) {
    status = data_editMail.auth_verifyEmail.ok
    message = data_editMail.auth_verifyEmail.message
  }

  const goHome = () => {
    props.refetch()
    navigate('/home')
  }

  return (
    <Dialog open={data_editMail && code} onClose={goHome} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
      <DialogActions>
        <Button onClick={goHome} color="primary">
          {user.translate('home')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
