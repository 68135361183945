import { useContext, useState } from 'react'

import { addDays, startOfISOWeek, endOfISOWeek, subHours } from 'date-fns'
import { useNavigate } from 'react-router-dom'

import makeStyles from '@mui/styles/makeStyles'

import { UserContext } from '../../App'
import Report from '../common/Report'

const useStyles = makeStyles((theme) => ({
  root: {
    // '& #table-title': {
    //     maxHeight: 34
    // }
  },
}))
export default function TurnoverReport(props) {
  const classes = useStyles()
  const user = useContext(UserContext)
  const [page, setPage] = useState(0)

  const navigate = useNavigate()

  const startDate = startOfISOWeek(subHours(new Date(), 6))
  const endDate = endOfISOWeek(startDate, 1)

  const params = {
    query: {
      name: 'report_revenueExpenditureReport',
      dateInit: '$dateFrom: Long!, $dateTo: Long!',
      date: 'dateFrom: $dateFrom, dateTo: $dateTo',
      dateVars: {
        dateFrom: startDate.getTime(),
        dateTo: endDate.getTime(),
      },
      results: `
                id
                timeFrom
                timeTo
                fromTo
                fiscal
                marker
                invoice
                staff
                lost
                meters
                supply
                overhead
                misc
                totalRevenue
                totalExpenses
                totalProfit
            `,
      columnsPush: ['id'],
    },
    title: user.translate('turnover_report'),
    titleName: user.translate('turnoverreport'),
    key: 'turnover_report_',
    page,
    setPage,
    datetime: false,
    header: true,
    headerNumbers: true,
    hideHeaderNumbers: true,
    exportOrientation: 'landscape',
    handleSelectAccept: (item) => navigate(`./${new Date(parseInt((item.timeFrom + item.timeTo) / 2)).toISODate()}`),
    rowStyle: (row) => {
      if (row.isTotal) {
        return {
          background: '#CCC',
          fontWeight: 600,
          height: 24,
          borderTop: '2px solid #333',
          borderBottom: '2px solid #333',
        }
      }

      return undefined
    },
  }

  return (
    <div className={classes.root}>
      <Report {...params} />
    </div>
  )
}
