import React, { useContext, useState } from 'react'

import { gql, useQuery } from '@apollo/client'

import IconAdd from '@mui/icons-material/Add'
import { CircularProgress, Grid } from '@mui/material'
import SpeedDial from '@mui/material/SpeedDial'
import makeStyles from '@mui/styles/makeStyles'

import InventoryCounterItem from './InventoryCounterItem'
import { RestaurantContext, UserContext } from '../../App'
import Add from '../common/Add'
import Delete from '../common/Delete'
import SnackBarDelete from '../common/SnackBarDelete'
import SnackBarWarning from '../common/SnackBarWarning'
import { useEntityRelationQuery } from '../common/useEntityQuery'

// CSS
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    padding: theme.spacing(3),
  },
  gridItem: {
    maxWidth: 480,
    minWidth: 320,
    flexGrow: 1,
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

// React Function Component
export default function MeterList() {
  const classes = useStyles()

  // Get current restaurant from context
  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const [openDeleteBox, setOpenDeleteBox] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [openSnackBarWarning, setOpenSnackBarWarning] = useState(false)

  const [showCounter, setShowCounter] = useState()

  const [deleteState, setDeleteState] = useState({
    name: '',
    variables: {},
    hideUndo: false,
  })

  const warehousesQuery = useEntityRelationQuery(
    'Warehouse',
    [
      { name: 'id', gql: 'String!' },
      { name: 'name', gql: 'String!', subSelection: { [user.lang]: true } },
    ],
    'restaurantId',
    restaurant.id,
  )
  const warehouseOptions =
    warehousesQuery.items?.map((it) => ({
      val: it.id,
      label: it.name[user.lang],
    })) ?? []

  const prodDepsQuery = useEntityRelationQuery(
    'ProductionDepartment',
    [
      { name: 'id', gql: 'String!' },
      { name: 'name', gql: 'String!', subSelection: { [user.lang]: true } },
    ],
    'restaurantId',
    restaurant.id,
  )
  const prodDepOptions =
    prodDepsQuery.items?.map((it) => ({
      val: it.id,
      label: it.name[user.lang],
    })) ?? []

  const possQuery = useEntityRelationQuery(
    'Pos',
    [
      { name: 'id', gql: 'String!' },
      { name: 'name', gql: 'String!', subSelection: { [user.lang]: true } },
    ],
    'restaurantId',
    restaurant.id,
  )
  const posOptions =
    possQuery.items?.map((it) => ({
      val: it.id,
      label: it.name[user.lang],
    })) ?? []

  const stockTypesQuery = useEntityRelationQuery(
    'StockType',
    [
      { name: 'id', gql: 'String!' },
      { name: 'name', gql: 'String!', subSelection: { [user.lang]: true } },
      { name: 'uom', gql: 'Long!' },
    ],
    'restaurantId',
    restaurant.id,
  )

  const unitOfMeasures = {
    0: user.translate('liter'),
    1: user.translate('kilogram'),
    2: user.translate('piece'),
  }

  const stockTypeOptions =
    stockTypesQuery.items?.map((it) => ({
      val: it.id,
      label: it.name[user.lang] + ' (' + unitOfMeasures[it.uom] + ')',
    })) ?? []

  const fieldList = [
    {
      required: false,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'id',
      fieldLabel: 'id',
      fieldValue: showCounter?.id,
    },
    {
      required: false,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'langId',
      fieldLabel: 'langId',
      fieldValue: showCounter?.name?.id,
    },
    {
      required: true,
      type: 'text',
      fieldType: 'text',
      fieldName: 'name',
      fieldLabel: user.translate('title'),
      fieldValue: (showCounter?.name ? showCounter.name[user.lang] : '') ?? '',
    },
    {
      required: false,
      type: 'autocomplete',
      fieldType: 'autocomplete',
      fieldName: 'warehouseId',
      fieldLabel: user.translate('warehouse'),
      fieldValue: warehouseOptions.find((it) => it.val === showCounter?.warehouseId),
      fieldOptions: warehouseOptions,
      nullValue: 'null',
    },
    {
      required: false,
      type: 'autocomplete',
      fieldType: 'autocomplete',
      fieldName: 'productionDepartmentId',
      fieldLabel: user.translate('production_department'),
      fieldValue: prodDepOptions.find((it) => it.val === showCounter?.productionDepartmentId),
      fieldOptions: prodDepOptions,
      nullValue: 'null',
    },
    {
      required: false,
      type: 'autocomplete',
      fieldType: 'autocomplete',
      fieldName: 'posId',
      fieldLabel: user.translate('pos'),
      fieldValue: posOptions.find((it) => it.val === showCounter?.posId),
      fieldOptions: posOptions,
      nullValue: 'null',
    },
    {
      required: true,
      type: 'autocomplete',
      fieldType: 'autocomplete',
      fieldName: 'stockTypeId',
      fieldLabel: user.translate('stock_type'),
      fieldValue: stockTypeOptions.find((it) => it.val === showCounter?.stockTypeId) ?? stockTypeOptions[0] ?? {},
      fieldOptions: stockTypeOptions,
    },
    {
      required: true,
      type: 'number',
      fieldType: 'text',
      fieldName: 'quantity',
      fieldLabel: user.translate('quantity'),
      fieldValue: showCounter?.quantity || 1,
    },
  ]

  const DELETE_ITEM = `
    mutation ($id: String!) {
        deleteInventoryCounter(id: $id)
    }`

  const UNDELETE_ITEM = `
    mutation ($id: String!) {
        undeleteInventoryCounter(id: $id)
    }`

  const ADD_ITEM = `mutation ($restaurantId: String!, $warehouseId: String, $productionDepartmentId: String, $posId: String, $name: String!, $stockTypeId: String!, $quantity: Float!) {
        createInventoryCounter(input: {
            ${user.gqlCreateName('$name')}
            restaurantId: $restaurantId
            warehouseId: $warehouseId
            productionDepartmentId: $productionDepartmentId
            posId: $posId
            stockTypeId: $stockTypeId
            quantity: $quantity
        }) {
            id
        }
    }`

  const EDIT_ITEM = `mutation ($restaurantId: String!, $warehouseId: String, $productionDepartmentId: String, $posId: String, $id: String, $langId: String!, $name: String!, $stockTypeId: String!, $quantity: Float!) {
        createInventoryCounter(input: {
            id: $id,
            restaurantId: $restaurantId
            ${user.gqlEditName('$name', '$langId')}
            warehouseId: $warehouseId
            productionDepartmentId: $productionDepartmentId
            posId: $posId
            stockTypeId: $stockTypeId
            quantity: $quantity
        }) {
            id
        }
    }`

  // GraphQL API request definition (local variables: restaurantId)
  const GET_INVENTORY_COUNTER_BY_RESTAURANT = gql`
    query ($restaurantId: String!) {
		getInventoryCountersByContextRestaurantId(restaurantId: $restaurantId) {
			id
			name {
				${user.gqlFetchName()}
            }
            active
            stockTypeId
            warehouseId
            warehouse { name { ${user.lang} } }
            productionDepartmentId
            productionDepartment { name { ${user.lang} } }
            posId
            pos { name { ${user.lang} } }
            quantity
            inventoryCounterItemsCount
		}
  }`

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const { data, loading, error, refetch } = useQuery(GET_INVENTORY_COUNTER_BY_RESTAURANT, {
    variables: { restaurantId: restaurant.id },
    pollInterval: 3000,
  })

  // If it is loading, show progress bar
  // if (loading) return <CircularProgress />
  if (loading) {
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    )
  }

  // In case there is an error, just show it for now
  if (!data) {
    user.consoleLog(error)
    return <p>Error</p>
  }

  const alertMessage = `${user.translate('cant_delete_inventory_counter')} `;
    const autoHideDuration = 3000;
    const linkTo = ``;
    const linkText = ''

  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction="row" justifyContent="center" alignItems="start">
        {data.getInventoryCountersByContextRestaurantId.map((inventoryCounter) => (
          <Grid item xs={4} key={inventoryCounter.id} className={classes.gridItem}>
            <InventoryCounterItem
              inventoryCounter={inventoryCounter}
              onEditClick={setShowCounter}
              setOpenDeleteBox={setOpenDeleteBox}
              setDeleteState={setDeleteState}
              setOpenSnackBarWarning={setOpenSnackBarWarning}
              refetch={refetch}
            />
          </Grid>
        ))}
      </Grid>
      <SpeedDial
        ariaLabel="Add InventoryCounter"
        className={classes.speedDial}
        open={false}
        onClick={() => setShowCounter(true)}
        icon={<IconAdd />}
      />

      {openDeleteBox && (
        <Delete
          name={deleteState.name}
          variables={deleteState.variables}
          setOpenSnackBar={setOpenSnackBar}
          setOpenDeleteBox={setOpenDeleteBox}
          deleteItem={DELETE_ITEM}
          onSuccess={refetch}
        />
      )}

      {openSnackBar && (
        <SnackBarDelete
          hideUndo={deleteState.hideUndo}
          message={deleteState.name + ` ${user.translate('deleted')}!`}
          variables={deleteState.variables}
          openSnackBar={openSnackBar}
          setOpenSnackBar={setOpenSnackBar}
          unDeleteItem={UNDELETE_ITEM}
          onSuccess={refetch}
        />
      )}

      {openSnackBarWarning && (
        <SnackBarWarning
          message={alertMessage}
          autoHideDuration={autoHideDuration}
          linkTo={linkTo}
          linkText={linkText}
          openSnackBar={openSnackBarWarning}
          setOpenSnackBar={setOpenSnackBarWarning}
        />
      )}

      {Boolean(showCounter) && (
        <Add
          fieldList={fieldList}
          openManipulateBox={setShowCounter}
          actionType={user.translate(showCounter === true ? 'add' : 'edit')}
          name={user.translate('meter')}
          restaurant={restaurant}
          manipulateItem={typeof showCounter === 'object' ? EDIT_ITEM : ADD_ITEM}
          onSuccess={refetch}
        />
      )}
    </div>
  )
}
