import React, { useContext, useEffect, useRef, useState } from 'react'

import makeStyles from '@mui/styles/makeStyles'

import { PaymentDetails } from './PaymentDetails'
import { RestaurantContext, UserContext } from '../../App'
import GqlSimpleTable from '../common/GqlSimpleTable'
import SnackBarWarning from '../common/SnackBarWarning'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#EEE',
    paddingBottom: theme.spacing(2),
    padding: theme.spacing(1),
    '& .MuiTableCell-root .MuiIconButton-root': {
      padding: theme.spacing(1),
    },
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexFlow: 'wrap',
  },
  actionsButton: {
    flex: 1,
    width: '100%',
    minWidth: 230,
    maxWidth: 320,
    margin: 'auto',
    marginTop: theme.spacing(2),
  },
  splitButton: {
    flex: 1,
  },
  actionsDivider: {
    [theme.breakpoints.up(480)]: {
      width: theme.spacing(1),
    },
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
}))

export default function Payments(props) {
  const item = props.item
  const selectedPaymentId = props.selectedPaymentId

  const classes = useStyles()

  const user = useContext(UserContext)
  const restaurant = useContext(RestaurantContext)

  const [openSnackBarWarning, setOpenSnackBarWarning] = useState(false)
  const selectedRowEl = useRef()

  useEffect(() => {
    const timer = setTimeout(() => {
      const node = selectedRowEl.current?.parentNode?.parentNode
      if (node === undefined) return

      node.click()

      setTimeout(() => {
        node.style.transition = 'none'
        node.style['background-color'] = '#cacaea'

        setTimeout(() => {
          node.style.transition = 'background-color 1s ease'
          node.style['background-color'] = '#FFF'
        }, 50)
      }, 100)
    }, 700)

    return () => {
      clearTimeout(timer)
    }
  }, [selectedPaymentId])

  const statementCurrency = restaurant.currencies.find((opt) => opt.val === item.currency).label
  const titlePostfix = <span style={{ fontSize: 10 }}> ({statementCurrency})</span>

  const FIELDS = [
    { name: 'id', gql: 'String!', id: true, hidden: true },
    { name: 'statementId', gql: 'String!', hidden: true, value: item?.id },
    {
      name: 'payerId',
      title: 'komitent',
      gql: 'String!',
      edit: 'KomitentAutoComplete',
      width: 4,
      editName: 'payerName',
      render: (item) => item.payer.name[user.lang],
    },
    { name: 'date', gql: 'String!', width: 2, edit: 'DateMilis', value: item.date, mobile: false },
    {
      name: 'number',
      gql: 'String!',
      render: (item) => (item.id === selectedPaymentId ? <span ref={selectedRowEl}>{item.number}</span> : item.number),
    },
    { name: 'purpose', gql: 'String!', value: '', mobile: false, width: 2 },
    { name: 'direction', title: 'type', gql: 'String!', edit: 'SelectPaymentDirection' },
    { name: 'currency', gql: 'String!', edit: 'SelectCurrency', value: item.currency },
    { name: 'amount', gql: 'Float!', titlePostfix, width: 0.7, nowrap: true, edit: 'Numeric' },
    { name: 'paymentCode', gql: 'Long!', value: 0, hidden: true },
    { name: 'payerAccount', gql: 'String!', value: '', hidden: true },
    { name: 'createdById', gql: 'String', default: user.me.id, hidden: true },
    { name: 'updatedById', gql: 'String', value: user.me.id, hidden: true },
    { name: 'created', gql: 'Long!', default: new Date().getTime(), hidden: true },
    { name: 'updated', gql: 'Long!', default: new Date().getTime(), hidden: true },
    { name: 'seen', gql: 'Long', hidden: true },
    { name: 'payer', gql: 'Restaurant', hidden: true, subSelection: { name: { [user.lang]: true } }, readOnly: true },
  ]

  const details = [
    { name: 'bank', value: item.bank },
    { name: 'bankAccount', value: item.bankAccount },
    { name: 'date', value: user.formatDate(parseInt(item.date)) },
  ]

  return (
    <div className={classes.root}>
      <GqlSimpleTable
        pathName="payments"
        entityName="Payment"
        relFieldName="statementId"
        relFieldValue={props.item.id}
        fields={FIELDS}
        details={details}
        detailPanel={(item) => <PaymentDetails item={{ ...item, payeeId: item.payerId }} />}
        createdBy={item.createdBy}
        updatedBy={item.updatedBy}
        nowrap
        paging={false}
      />
      {openSnackBarWarning && (
        <SnackBarWarning
          message={openSnackBarWarning.alertMessage}
          linkTo={openSnackBarWarning.linkTo}
          linkText={openSnackBarWarning.linkText}
          autoHideDuration={openSnackBarWarning.autoHideDuration || 3000}
          openSnackBar
          setOpenSnackBar={setOpenSnackBarWarning}
        />
      )}
    </div>
  )
}
