import React, { useContext, useState } from 'react'

import makeStyles from '@mui/styles/makeStyles'

import { UserContext } from '../../../App'
import FormInvoice from '../../common/FormInvoice'
import helper from '../../common/Helper'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    flexDirection: 'column',
    position: 'relative',
    top: theme.spacing(2),
    alignItems: 'end',
  },
  itemName: {
    position: 'absolute',
    right: 0,
    top: -32,
  },
  billingStyles: {
    float: `right`,
  },
}))

export default function InvoiceTitle(props) {
  const classes = useStyles()
  // Get current user preferences (language)
  const user = useContext(UserContext)
  const invoice = props.invoice

  const [fieldListTitle, setFieldListTitle] = useState([
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'id',
      fieldLabel: 'id',
      fieldValue: invoice.id,
    },
    {
      required: true,
      type: 'text',
      fieldType: 'text',
      fieldName: 'invoiceNumber',
      fieldLabel: invoice.isCash ? user.translate('cash_invoice') : user.translate('invoice'),
      fieldValue: invoice.invoiceNumber ? invoice.invoiceNumber : '',
    },
    {
      required: true,
      type: 'date',
      fieldType: 'date',
      fieldName: 'issuingDate',
      fieldLabel: user.translate('issuing_date'),
      fieldValue: invoice.issuingDate ? invoice.issuingDate : '',
    },
    {
      required: true,
      type: 'date',
      fieldType: 'date',
      fieldName: 'dueToDate',
      fieldLabel: user.translate('due_to_date'),
      fieldValue: invoice.dueToDate ? invoice.dueToDate : '',
    },
  ])

  const setItems = (event, fieldName, fieldValue) => {
    let items = {};
      let field = {}
    const langId = fieldListTitle.find((c) => c.fieldName === `${fieldName}LangId`)

    field = {
      fieldType: `$${fieldName}: String!`,
      field: fieldName,
    }

    if (typeof langId !== 'undefined') {
      items = {
        id: langId.fieldValue,
        [fieldName]: fieldValue,
      }
      props.onEditInvoice(items, field, true)
    } else {
      if (fieldName === 'issuingDate' || fieldName === 'dueToDate') {
        field = {
          fieldType: `$${fieldName}: Long!`,
          field: fieldName,
        }
      }

      items = {
        id: invoice.id,
        [fieldName]: fieldValue,
      }
      props.onEditInvoice(items, field, false)
    }

    user.consoleLog(items, user)
  }

  // styles={classes.billingStyles}
  const tabIndex = 22

  return (
    <div className={classes.root}>
      {fieldListTitle.map((item, index) => (
        <FormInvoice
          key={'invoice_billing_' + item.fieldValue + index}
          fieldList={fieldListTitle}
          item={index}
          tabIndex={tabIndex}
          setFieldList={setFieldListTitle}
          setItems={setItems}
          left
        />
      ))}
    </div>
  )
}
