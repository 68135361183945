import React, { useContext } from 'react'

import Routes, { Case } from 'react-switch-case'

import AssignmentTurnedIn from '@mui/icons-material/AssignmentTurnedIn'
import Cancel from '@mui/icons-material/Cancel'
import Done from '@mui/icons-material/Done'
import Mail from '@mui/icons-material/Mail'
import Send from '@mui/icons-material/Send'
import { Button, IconButton } from '@mui/material'

import { UserContext } from '../../App'
import useConfirmDialog from '../common/ConfirmDialog'

export default function StockSupplyAction(props) {
  const item = props.item
  const manageSupply = props.manageSupply

  const user = useContext(UserContext)

  const confirmDialog = useConfirmDialog()

  const finishOrder = () => {
    confirmDialog
      .show({
        title: user.translate('finish'),
        message: user.translate('finish_supply_accept'),

        positiveAction: {
          title: user.translate('finish'),
        },
        cancelButton: true,
      })
      .then(() => {
        return manageSupply.saveSupply({ ...item, state: 'CLOSED' })
      })
      .catch(() => console.info('canceled'))
  }

  const sendOrder = () => {
    confirmDialog
      .show({
        title: user.translate('send'),
        message: user.translate('send_supply_accept'),

        positiveAction: {
          title: user.translate('send'),
        },
        cancelButton: true,
      })
      .then(() => {
        return manageSupply.saveSupply({ ...item, state: 'SENT' })
      })
      .catch(() => console.info('canceled'))
  }
  const receiviedOrder = () => {
    confirmDialog
      .show({
        title: user.translate('receiving'),
        message: user.translate('receive_supply_accept'),

        positiveAction: {
          title: user.translate('receive'),
        },
        cancelButton: true,
      })
      .then(() => {
        return manageSupply.saveSupply({ ...item, state: 'RECEIVED' })
      })
      .catch(() => console.info('canceled'))
  }
  const cancelOrder = () => {
    confirmDialog
      .show({
        title: user.translate('cancel'),
        message: user.translate('cancel_supply_accept'),

        positiveAction: {
          title: user.translate('cancel'),
        },
        cancelButton: true,
      })
      .then(() => {
        return manageSupply.saveSupply({ ...item, state: 'CANCELED' })
      })
      .catch(() => console.info('canceled'))
  }

  const iconButtonProps = {
    className: props.className,
    size: props.size || 'small',
    color: props.color || 'primary',
  }

  if (props.noText) {
    return (
      <>
        <Routes condition={item.state}>
          <Case value="OPEN">
            <IconButton {...iconButtonProps} onClick={finishOrder} size="large">
              <Done color="secondary" />
            </IconButton>
          </Case>
          <Case value="CLOSED">
            <IconButton {...iconButtonProps} onClick={sendOrder} size="large">
              <Send color="secondary" />
            </IconButton>
          </Case>
          <Case value="SENT">
            <IconButton {...iconButtonProps} onClick={receiviedOrder} size="large">
              <Mail color="secondary" />
            </IconButton>
          </Case>
          <Case value="RECEIVED">
            <IconButton {...iconButtonProps} onClick={cancelOrder} size="large">
              <Cancel style={{ opacity: 0.7 }} />
            </IconButton>
          </Case>
        </Routes>

        {confirmDialog.render()}
      </>
    )
  } else {
    const buttonProps = { ...iconButtonProps, variant: props.outlined ? 'outlined' : 'text' }
    return (
      <>
        <Routes condition={item.state}>
          <Case value="OPEN">
            <Button startIcon={<Done />} {...buttonProps} onClick={finishOrder}>
              {user.translate('ready')}
            </Button>
          </Case>
          <Case value="CLOSED">
            <Button startIcon={<Send />} {...buttonProps} onClick={sendOrder}>
              {user.translate('send')}
            </Button>
          </Case>
          <Case value="SENT">
            <Button startIcon={<Mail />} {...buttonProps} onClick={receiviedOrder}>
              {user.translate('receive')}
            </Button>
          </Case>
          <Case value="RECEIVED">
            <Button startIcon={<Cancel />} {...buttonProps} onClick={cancelOrder}>
              {user.translate('cancel')}
            </Button>
          </Case>
        </Routes>

        {confirmDialog.render()}
      </>
    )
  }
}
