import { useContext, useEffect, useState } from 'react'

import { gql, useMutation } from '@apollo/client'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material'

import { UserContext } from '../App'
import Show from '../restaurant/common/Show'

export default function GenerateOTP(props) {
  const user = useContext(UserContext)

  const [email, setEmail] = useState('')
  const [otpTime, setOtpTime] = useState()

  const REQUEST = gql`
    mutation ($employeeEmail: String!) {
      extras_generateEmployeeOTP(employeeEmail: $employeeEmail) {
        ok
        message
      }
    }
  `

  const [requestGenerateOTP, { loading, data }] = useMutation(REQUEST)

  const response = data?.extras_generateEmployeeOTP

  useEffect(() => {
    if (!data || otpTime === undefined) return
    console.log('udpate', otpTime)

    const timer = setTimeout(() => {
      console.log('udpate tmr')
      const now = new Date().getTime()
      const remains = 100 - (now - otpTime.craeted) / 1000

      setOtpTime({ ...otpTime, remains: Math.max(remains, 0) })
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [data, otpTime?.created, otpTime?.remains])

  const handleClose = () => {
    props.onClose()
  }

  const handleRequestAccept = () => {
    requestGenerateOTP({
      variables: {
        employeeEmail: email,
      },
    }).then(() => {
      setOtpTime({ craeted: new Date().getTime(), remains: 100 })
    })
  }

  return (
    <>
      <Dialog open aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{props.message}</DialogTitle>
        <DialogContent>
          <TextField
            required
            style={{ minWidth: 260 }}
            id="outlined-required"
            name="email"
            label={user.translate('email')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Show if={response !== undefined && response.ok && !loading}>
            <DialogContentText id="alert-dialog-description">
              <br />
              <Typography>{user.translate('otp_code')}</Typography>
              <Typography variant="h4" color="textPrimary" style={{ fontWeight: 'bold', color: otpTime?.remains === 0 ? '#900' : '#040' }}>
                {response?.message}
              </Typography>
              <Show if={otpTime?.remains && otpTime?.remains > 0}>
                <Typography variant="caption" color="textSecondary">
                  {user.translate('otp_expires_in')} {Math.round(otpTime?.remains || 0)} {user.translate('seconds')}
                </Typography>
              </Show>
              <Show if={otpTime?.remains === 0}>
                <Typography variant="caption" color="textSecondary">
                  {user.translate('otp_expired')}
                </Typography>
              </Show>
            </DialogContentText>
          </Show>
          <Show if={response !== undefined && !response.ok && !loading}>
            <DialogContentText id="alert-dialog-description">
              <br />
              <Typography variant="caption" color="textSecondary">
                {response?.message}
              </Typography>
            </DialogContentText>
          </Show>
          <Show if={loading}>
            <br />
            <LinearProgress />
          </Show>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {user.translate('close')}
          </Button>
          <Button onClick={handleRequestAccept} color="primary" disabled={loading} autoFocus>
            {user.translate('generate_new')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
