import React, { useContext, useState } from 'react'

import { UserContext } from '../../App'
import Report from '../common/Report'

// React Function Component
export default function ReceiptsReport(props) {
  const user = useContext(UserContext)

  const [page, setPage] = useState(0)
  const [refetchIt, setRefetchIt] = useState(false)
  const [editIt, setEditIt] = useState(false)
  // const [openReceipt, setOpenReceipt] = useState(false);

  const startDate = new Date()
  const endDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000)
  startDate.setHours(5, 0, 0, 0)
  endDate.setHours(5, 0, 0, 0)

  // const EDIT_ITEM = gql`
  // mutation ($receiptId: String!, $isCanceled: Long!) {
  //     editReceipt(input: {
  //         id: $receiptId,
  //         status: $isCanceled
  //     }) {
  //         id
  //     }
  // }`;
  // const [editItem, {loading: loading_edit}] = useMutation(EDIT_ITEM);

  const onEditItem = (rowData, columnDef, newValue, state) => {
    // let items = {...rowData, [columnDef]: newValue ? 2 : 1}
    // editItem({variables: items});
    // setEditIt(true);
  }

  if (editIt) {
    setEditIt(false)
    setRefetchIt(true)
  }

  const params = {
    query: {
      name: 'report_receipts',
      dateInit: '$dateFrom: Long!, $dateTo: Long!',
      date: 'dateFrom: $dateFrom, dateTo: $dateTo',
      dateVars: {
        dateFrom: startDate.getTime(),
        dateTo: endDate.getTime(),
      },
      results: `
                billing
                billingId
                createdTime
                customer
                customerId
                customersCount
                employee
                employeeId
                isCanceled
                payment
                pos
                posId
                price
                receiptNum
                table
                tableId
                receiptId
                invoiceId
                status
                discount
                statusVal
                closedTime
                orderType
            `,
      columnsPush: ['billingId', 'customerId', 'employeeId', 'tableId', 'receiptId', 'invoiceId', 'statusVal'],
    },
    title: user.translate('receipts_report'),
    titleName: user.translate('receiptsreport'),
    handleSelectAccept: props.handleSelectAccept,
    receiptList: user.role > 2,
    invoiceId: props.invoiceId,
    onEditItem,
    notEditItem: props.notEditItem,
    page,
    setPage,
    datetime: true,
    refetchIt,
    setRefetchIt,
    key: 'receipts_report_' + refetchIt,
    converters: {
      price: (item) => (item.isCanceled ? 0 : item.price),
    },
  }

  return (
    <>
      <Report {...params} />

      {/* {!loading_edit && openReceipt && (
                <InvoiceItemReceiptList invoice={invoice} itemReceipts={invoiceItem.invoiceItemReceipts} openReceiptView={openReceiptView} setOpenReceiptView={setOpenReceiptView}/>
            )} */}
    </>
  )
}
