import React, { useContext, useEffect, useState } from 'react'

import { CircularProgress, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import makeStyles from '@mui/styles/makeStyles'

import { callOrGet, ifNull, preventPropagation } from './utils'
import { UserContext } from '../../../App'
import { NumberFormatCustom } from '../Form'
import { useCustomQuery, useEntityContextQuery } from '../useEntityQuery'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-grid',
    margin: theme.spacing(1),
    minWidth: '20ch',
    flexGrow: 1,
    '& > .MuiTextField-root': {
      display: 'grid',
      width: '-webkit-fill-available',
    },
  },
}))

export default function StockTypeCostInput(props) {
  const classes = useStyles()

  const user = useContext(UserContext)

  const query = useCustomQuery('extras_stockTypeCost', [{ name: 'cost', gql: 'Float!' }], 'stockTypeId', props.rowData.stockType, {
    skip: typeof props.rowData.stockType !== 'string',
    single: true,
    pollInterval: 0,
    onLoad: (item) => {
      props.onChange(item?.extras_stockTypeCost?.cost || 0)
    },
  })

  const defaultValue = props.rowData[props.name] || query.item?.cost

  return (
    <TextField
      id={props.name}
      fullWidth
      name={props.name}
      value={defaultValue}
      onChange={(e) => props.onChange(e.target.value)}
      InputProps={{
        inputComponent: NumberFormatCustom,
        autoComplete: 'off',
      }}
      inputProps={{ decimalScale: 2 }}
    />
  )
}
StockTypeCostInput.gql = 'String'
