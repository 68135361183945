import React, { useContext, useState } from 'react'

import { gql, useMutation, useQuery } from '@apollo/client'

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormHelperText,
  TextField,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import makeStyles from '@mui/styles/makeStyles'

import tableComponents from './TableComponents'
import { UserContext } from '../../App'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    flexGrow: 1,
  },
  formRow: {
    marginBottom: 25,
    marginTop: 25,
    fontSize: 14,
    justifyContent: `center`,
  },
  formControl: {
    minWidth: '100%',
    width: `auto`,
    margin: `-14px ${theme.spacing(3)} 0`,
    float: 'left',
    paddingLeft: theme.spacing(3),
  },
}))

export default function KomitentBillingSearch(props) {
  const classes = useStyles()

  const user = useContext(UserContext)
  const [vars, setVars] = useState({
    name: '',
    url: '',
  })
  const [searchVars, setSearchVars] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [onSuccess, setOnSuccess] = useState(false)

  const SEARCH_KOMITENT_BILLLING = gql`
    query ($query: String!) {
      extras_searchPublicCompanies(query: $query) {
        name
        url
      }
    }
  `

  const { data: data_search, loading: loading_search } = useQuery(SEARCH_KOMITENT_BILLLING, {
    skip: !searchVars,
    variables: searchVars,
    fetchPolicy: 'no-cache',
  })

  const ADD_KOMITENT_BILLLING = gql`
    mutation ($companyId: String!, $url: String!) {
      extras_createPublicCompanyBilling(companyId: $companyId, url: $url)
    }
  `

  const [addKomitentBilling, { data: data_add_billing, loading: loading_add_billing }] = useMutation(ADD_KOMITENT_BILLLING, {
    skip: !onSuccess,
  })

  // Adjust Dialog
  const handleClose = () => {
    props.setSearchBilling(false)
  }

  if (!loading_add_billing && !loading_search && data_search && searchVars) {
    // setVars(data_search.extras_searchPublicCompanies)
    setSearchResults(data_search.extras_searchPublicCompanies)
    user.consoleLog(data_search)
    setSearchVars(false)
  }

  if (!loading_add_billing && !loading_search && onSuccess) {
    user.consoleLog('Add done!')
    props.onSuccess()
    setOnSuccess(false)
    handleClose()
  }

  const handleSearch = (search) => {
    if (search !== '' && !searchVars) {
      const items = {
        query: search,
      }
      setSearchVars(items)
    }
  }

  const handleAccept = () => {
    const items = {
      companyId: props.companyId,
      url: vars.url,
    }
    addKomitentBilling({
      variables: items,
    })

    setOnSuccess(true)
    user.consoleLog(items)
  }

  return (
    <>
      <Dialog
        open
        fullWidth
        maxWidth="sm"
        scroll="paper"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`${user.translate('add')} ${user.translate('komitent')} ${user.translate(
          'billing',
        )}`}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <FormGroup row className={classes.formRow}>
            <Autocomplete
              className={classes.formControl}
              options={searchResults}
              autoHighlight
              selectOnFocus
              autoFocus
              handleHomeEndKeys
              isOptionEqualToValue={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              value={vars}
              filterOptions={tableComponents.FilterOptions}
              onChange={(event, value) => {
                if (value != null) {
                  setVars(value)
                } else
                  setVars({
                    name: '',
                    url: '',
                  })
              }}
              onKeyDown={(event) => {
                switch (event.key) {
                  case 'Enter':
                    handleSearch(event.target.value)
                    break
                  case 'Escape':
                    // closeField();
                    break
                  default:
                    break
                }
              }}
              renderInput={(params) => {
                params.inputProps.autoComplete = 'off'
                return (
                  <TextField
                    {...params}
                    label={user.translate('search_company')}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <React.Fragment>
                          {loading_search ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )
              }}
            />
            <FormHelperText>{user.translate('enter_text_press_enter')}</FormHelperText>
          </FormGroup>

          {/* <DialogContentText id="alert-dialog-slide-description">
                    <Typography
                        variant="body1"
                        style={{ whiteSpace: 'pre-line' }}
                    >
                        {data_search && data_search.searchKomitentBilling}
                    </Typography>
                </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {user.translate('cancel')}
          </Button>
          <Button onClick={handleAccept} color="primary" disabled={vars.name === ''}>
            {user.translate('add')} {user.translate('billing')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
