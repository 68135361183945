import React, { useContext } from 'react'

import { useNavigate } from 'react-router-dom'

import IconDelete from '@mui/icons-material/Delete'
import { Card, CardActionArea, CardContent, CardMedia, IconButton, Typography } from '@mui/material/'
import makeStyles from '@mui/styles/makeStyles'

import config from '../../config'
import { UserContext } from '../App'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    flex: 1,
    maxWidth: 345,
    position: 'relative',
  },
  action: {
    zIndex: 1,
  },
  disableAction: {
    cursor: 'default',
  },
  media: {
    width: '100%',
    height: 140,
    marginLeft: 'auto',
    marginRight: 'auto',
    objectFit: 'contain',
  },
  delete: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 999,
    color: '#CCC',
    '&:hover': {
      color: '#AAA',
    },
  },
}))

export default function RestaurantView(props) {
  const navigate = useNavigate()
  const restaurant = props.restaurant
  const classes = useStyles()

  const user = useContext(UserContext)

  const restaurantName = restaurant.name[user.lang]

  const onDeleteClick = () => {
    props.setDeleteState({
      name: restaurant.name[user.lang],
      variables: { id: restaurant.id },
    })
    props.setOpenDeleteBox(true)
  }

  const openRestaurant = () => {
    if (user.role > 1) {
      navigate(`/restaurant/${restaurant._id}`)
    }
  }

  return (
    <Card className={classes.root}>
      <CardActionArea className={user.role > 1 ? classes.action : classes.disableAction} onClick={openRestaurant}>
        <CardMedia
          className={classes.media}
          component="img"
          style={{ backgroundColor: `${restaurant.imageLogoBg}` }}
          alt={restaurantName}
          image={
            restaurant.imageLogo != null && restaurant.imageLogo.indexOf('http') > -1
              ? restaurant.imageLogo
              : `${config.apiImagesUrl}/${restaurant.imageLogo}`
          }
          title={restaurantName}
        />
        <CardContent sx={{ display: 'grid' }}>
          <Typography gutterBottom variant="h6" component="h2">
            {restaurantName}
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            component="p"
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {restaurant.address}
          </Typography>
        </CardContent>
      </CardActionArea>
      {user.me.profile.isAdmin === 1 && (
        <IconButton className={classes.delete} color="primary" onClick={onDeleteClick} size="large">
          <IconDelete />
        </IconButton>
      )}
    </Card>
  )
}
