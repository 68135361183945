import React, { useContext } from 'react'

import { CircularProgress, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { callOrGet } from './form/utils'
import { UserContext } from '../../App'
import { DynamicTable } from '../common/DynamicTable'
import Show from '../common/Show'
import { useCustomQuery, useEntityQuery } from '../common/useEntityQuery'

const useStyles = makeStyles((theme) => ({
  detail: {
    paddingBottom: theme.spacing(2),
  },
  footerCreators: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      flexDirection: 'column',
    },
    flexWrap: 'wrap',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontStyle: 'italic',
  },
  creator: {
    fontWeight: 600,
    color: '#4e6e6f',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export function useGqlSimpleTable(props) {
  const entityName = props.entityName
  const customQuery = props.customQuery
  const entityRelFieldName = props.relFieldName
  const entityRelFieldValue = props.relFieldValue
  const fields = props.fields
  const isLoading = props.isLoading

  const factory = customQuery ? useCustomQuery : useEntityQuery
  const gqlEntity = factory(customQuery || entityName, fields, entityRelFieldName, entityRelFieldValue, {
    skip: isLoading,
    dateRangeFilter: props.dateRangeFilter,
    dateRangeField: props.dateRangeField,
    filter: props.filter,
    args: props.args,
    onLoad: props.onLoad,
  })

  return {
    gql: gqlEntity,
    items: gqlEntity.items,
    isLoading: isLoading || gqlEntity.isLoading,
  }
}

export function GqlSimpleTableView(props) {
  const controler = props.controler
  const title = props.title
  const pathName = props.pathName
  const fields = props.fields
  const details = props.details || []

  const classes = useStyles()

  const user = useContext(UserContext)

  if (controler.isLoading)
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    )

  let items = props.items || controler.items || []

  if (props.preRows) {
    items = callOrGet(props.preRows, items).plus(...items)
  }

  if (props.extraRows) {
    items = items.plus(...callOrGet(props.extraRows, items))
  }

  if (props.itemsAdapter) {
    items = props.itemsAdapter(items)
  }

  return (
    <div id="print_content" style={{ maxWidth: props.maxWidth, margin: 'auto' }}>
      <Show if={details.length > 0}>
        <div style={{ display: 'table' }} className={classes.detail}>
          {details.map((item) => (
            <div key={item.value} style={{ display: 'table-row' }}>
              <Typography noWrap variant="caption" style={{ display: 'table-cell' }}>
                {user.translate(item.name)}
              </Typography>
              <Typography noWrap variant="overline" color="textSecondary" style={{ display: 'table-cell', lineHeight: 1.9 }}>
                :&nbsp;{item.value}
              </Typography>
            </div>
          ))}
        </div>
      </Show>
      <DynamicTable
        name={title || pathName}
        nameSufix={props.nameSufix}
        path={pathName}
        items={items}
        columns={fields}
        query={controler.gql}
        search={false}
        detailPanel={props.detailPanel}
        nowrap={props.nowrap}
        onRowClick={props.onRowClick}
        paging={props.paging}
        header={props.header || props.dateRangeFilter?.renderHeader}
        exportAllData={props.exportAllData}
        headlines={props.headlines}
        columnParents={props.columnParents}
        columnNumbers={props.columnNumbers}
        showDetailPanel={props.showDetailPanel}
        noEdit={props.noEdit}
      />
      <Show if={props.createdBy || props.updatedBy}>
        <div className={classes.footerCreators}>
          <Typography noWrap variant="caption" color="textSecondary" style={{ display: props.createdBy === props.updatedBy && 'none' }}>
            {user.translate('created_by')} <span className={classes.creator}>{props.createdBy?.name}</span>
          </Typography>
          <Typography noWrap variant="caption" color="textSecondary">
            {user.translate('last_modified_by')} <span className={classes.creator}>{props.updatedBy?.name}</span>
          </Typography>
        </div>
      </Show>
    </div>
  )
}

export default function GqlSimpleTable(props) {
  const controler = useGqlSimpleTable(props)

  if (props.apiRef) {
    props.apiRef.current = controler
  }

  return <GqlSimpleTableView controler={controler} {...props} />
}
