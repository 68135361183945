import React, { useContext, useState } from 'react'

import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'
import { Card, Typography, IconButton, CardActionArea, CardContent, CardActions } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { RestaurantContext, UserContext } from '../../App'
import Add from '../common/Add'

const useStyles = makeStyles((theme) => ({
  itemCard: {
    display: 'flex',
  },
  itemCardDisabled: {
    display: 'flex',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  itemCardContent: {
    margin: 0,
    height: 42,
    padding: `${theme.spacing(2)} 0 ${theme.spacing(1)} 0`,
  },
  itemName: {
    margin: theme.spacing(1),
    flexGrow: 1,
  },
  code: {
    clear: `both`,
    display: `block`,
    fontSize: 10,
  },
}))

export default function TableItem(props) {
  const classes = useStyles()
  // Get current user preferences (language)
  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)
  const table = props.table

  const [openEditSnackBarWarning, setOpenEditSnackBarWarning] = useState(false)

  // Get onSuccess={props.refetch} name localized
  const tableName = table.name
  const itemName = user.translate('table') + ' ' + tableName

  const fieldList = [
    {
      required: true,
      type: 'text',
      fieldType: 'text',
      fieldName: 'name',
      fieldLabel: user.translate('title'),
      fieldValue: tableName,
    },
    {
      required: false,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'code',
      fieldLabel: user.translate('code'),
      fieldValue: table.code,
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'posId',
      fieldLabel: user.translate('pos'),
      fieldValue: '' + table.posId,
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'floor',
      fieldLabel: user.translate('floor'),
      fieldValue: '' + table.floor,
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'group',
      fieldLabel: user.translate('group'),
      fieldValue: table.group,
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'userId',
      fieldLabel: 'userId',
      fieldValue: '' + table.userId,
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'id',
      fieldLabel: 'id',
      fieldValue: '' + table.id,
    },
  ]
  const DELETE_ITEM = `
    mutation ($id: String!, $posId: String!) {
        deleteRestaurantTable(id: $id, posId: $posId)
    }`

  const UNDELETE_ITEM = `
    mutation ($id: String!, $posId: String!) {
        undeleteRestaurantTable(id: $id, posId: $posId)
    }`

  const DEACTIVATE_ITEM = `
    mutation ($id: String!) {
        editRestaurantTable(input: {
            id: $id,
            active: 0
        }) {
            id
        }
    }`
  const UNDEACTIVATE_ITEM = `
    mutation ($id: String!) {
        editRestaurantTable(input: {
            id: $id,
            active: 1
        }) {
            id
        }
    }`

  const EDIT_ITEM = `
    mutation ($id: String!, $posId: String!, $userId: String, $code: String!, $name: String!, $group: String!, $floor: Long!) {
        createRestaurantTable(input: {
			id: $id
			posId: $posId
			userId: $userId
			code: $code
			name: $name
			group: $group
          	floor: $floor
        }) {
            id
        }
      }`

  const [openManipulateBox, setOpenManipulateBox] = useState(false)

  const onManipulateClick = (table) => {
    // console.log(table.tableReceiptsCount)

    if (table.tableReceiptsCount !== 0) {
      setOpenEditSnackBarWarning(true)
    }
    setOpenManipulateBox(true)
  }

  const onDeleteClick = (table) => {
    // console.log(table.tableReceiptsCount)
    if (table.tableReceiptsCount === 0) {
      props.setDeleteState({
        name: table.name,
        variables: {
          id: table.id,
          posId: '' + table.posId,
        },
        hideUndo: false,
        deleteItem: DELETE_ITEM,
        unDeleteItem: UNDELETE_ITEM,
      })
      props.setOpenDeleteBox(true)
    } else {
      props.setOpenSnackBarWarning(true)
    }
  }

  const alertMessageEdit = user.translate('cant_edit_table')

  return (
    <React.Fragment>
      <Card className={table.active ? classes.itemCard : classes.itemCardDisabled}>
        <CardActionArea>
          <CardContent onClick={(e) => onManipulateClick(table)} className={classes.itemCardContent}>
            <Typography variant="subtitle1" component="subtitle1" className={classes.itemName}>
              {tableName}{' '}
              {table.code !== '' && (
                <span className={classes.code}>
                  <em>{table.code}</em>
                </span>
              )}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <IconButton size="small" color="primary" onClick={(e) => onManipulateClick(table)}>
            <IconEdit />
          </IconButton>
          <IconButton size="small" color="primary" onClick={(e) => onDeleteClick(table)}>
            <IconDelete />
          </IconButton>
        </CardActions>
      </Card>

      {openManipulateBox && (
        <Add
          fieldList={fieldList}
          openManipulateBox={setOpenManipulateBox}
          actionType={user.translate('edit')}
          restaurant={restaurant}
          name={itemName}
          manipulateItem={EDIT_ITEM}
          deactivate={!!table.active}
          deactivateItem={DEACTIVATE_ITEM}
          unDeactivateItem={UNDEACTIVATE_ITEM}
          onSuccess={props.refetch}
          alertMessageEdit={alertMessageEdit}
          openSnackBarWarning={openEditSnackBarWarning}
          setOpenSnackBarWarning={setOpenEditSnackBarWarning}
        />
      )}
    </React.Fragment>
  )
}
