import React, { useContext, useState } from 'react'

import { UserContext } from '../../../App'
import Report from '../../common/Report'

// React Function Component
export default function InventoryReport(props) {
  const user = useContext(UserContext)
  const [page, setPage] = useState(0)

  const startDate = new Date()
  startDate.setFullYear(2020, 0, 1)
  startDate.setHours(0, 0, 0, 0)

  const endDate = new Date()
  endDate.setHours('23', 59, 59, 999)

  const params = {
    query: {
      name: 'report_inventoryState',
      dateInit: '$dateFrom: Long!, $dateTo: Long!',
      date: 'dateFrom: $dateFrom, dateTo: $dateTo',
      dateVars: {
        dateFrom: startDate.getTime(),
        dateTo: endDate.getTime(),
      },
      results: `
                stockTypeId
                stockType
                warehouseId
                warehouse
                quantity
                ordersQuantity
                receivingQuantity
                internalTransferQuantity
                inventoryQuantity
                invoiceQuantity
                uom
                avgPrice
                avgNetPrice
                avgTotalPrice
                avgSellPrice
            `,
      columnsPush: ['stockTypeId', 'warehouseId'],
    },
    title: user.translate('inventory_report'),
    titleName: user.translate('inventoryreport'),
    InventoryAdjustments: user.role > 2,
    key: 'inventory_report_',
    page,
    setPage,
    datetime: true,
  }

  return <Report {...params} />
}
