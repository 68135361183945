import { useCallback, useContext } from 'react'

import { gql, useMutation } from '@apollo/client'

import { UserContext } from '../App'

export default function useLoginWithGoogleToken(onLogin) {
  const CONNECT_GOOGLE = gql`
    mutation ($idToken: String!) {
      auth_loginGoogle(idToken: $idToken) {
        ok
        message
        profile {
          name
          email
          id
        }
      }
    }
  `

  const [loginWithGoogle, { loading }] = useMutation(CONNECT_GOOGLE)

  const user = useContext(UserContext)

  const loginWithGoogleToken = useCallback(
    async (idToken) => {
      if (idToken) {
        const {
          data: { auth_loginGoogle: loginResult },
          errors,
        } = await loginWithGoogle({
          variables: { idToken },
        })
        if (loginResult?.ok) {
          if (onLogin) onLogin()
        } else if (loginResult.message) {
          alert(loginResult.message)
        } else {
          alert(errors ?? user.translate('login_error'))
        }
      }
    },
    [loginWithGoogle, onLogin, user],
  )

  return { loginWithGoogleToken, loading }
}
