import React, { useContext, useState } from 'react'

import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'
import { Box, Card, CardActionArea, CardActions, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'


import { RestaurantContext, UserContext } from '../../App'
import Add from '../common/Add'

const useStyles = makeStyles((theme) => ({
  itemCard: {
    display: 'flex',
  },
  itemName: {
    margin: 0,
    float: `left`,
    width: `80%`,
    padding: 12,
    flexGrow: 1,
  },
}))

export default function ProductionDepartment(props) {
  const classes = useStyles()
  // Get current user preferences (language)
  const user = useContext(UserContext)
  const restaurant = useContext(RestaurantContext)
  const productionDepartment = props.productionDepartment

  // Get ProductionDepartment name localized
  const productionDepartmentName = productionDepartment.name[user.lang]

  let fieldList = [
    {
      required: true,
      type: 'text',
      fieldType: 'text',
      fieldName: 'name',
      fieldLabel: user.translate('title'),
      fieldValue: productionDepartmentName,
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'langId',
      fieldLabel: 'langId',
      fieldValue: productionDepartment.name.id,
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'id',
      fieldLabel: 'id',
      fieldValue: productionDepartment.id,
    },
    {
      required: true,
      type: 'select',
      fieldType: 'select',
      fieldName: 'productionCategoryId',
      fieldLabel: user.translate('production_category'),
      fieldValue: '' + productionDepartment.productionCategory.id,
      fieldOptions: props.productionCategorysFieldOptions,
      noNone: true,
    },
    {
      required: true,
      type: 'select',
      fieldType: 'select',
      fieldName: 'warehouseId',
      fieldLabel: user.translate('warehouse'),
      fieldValue: '' + productionDepartment.warehouse.id,
      fieldOptions: props.warehousesFieldOptions,
      noNone: true,
    },
    {
      required: true,
      type: 'checkbox',
      fieldType: 'checkbox',
      fieldName: 'preparing',
      fieldLabel: user.translate('preparing'),
      fieldValue: productionDepartment.preparing,
      line: true,
      noNone: true,
    },
    {
      required: true,
      type: 'checkbox',
      fieldType: 'checkbox',
      fieldName: 'production',
      fieldLabel: user.translate('production'),
      fieldValue: productionDepartment.production,
      line: true,
      noNone: true,
    },
    {
      required: true,
      type: 'checkbox',
      fieldType: 'checkbox',
      fieldName: 'delivery',
      fieldLabel: user.translate('delivery'),
      fieldValue: productionDepartment.delivery,
      line: true,
      noNone: true,
    },
  ]

  if (user.me.profile && user.me.profile.isAdmin) {
    fieldList = [
      {
        required: true,
        type: 'text',
        fieldType: 'text',
        fieldName: 'nameMk',
        fieldLabel: user.translate('titleMk'),
        fieldValue: productionDepartment.name.mk,
      },
      {
        required: true,
        type: 'text',
        fieldType: 'text',
        fieldName: 'nameEn',
        fieldLabel: user.translate('titleEn'),
        fieldValue: productionDepartment.name.en,
      },
      {
        required: true,
        type: 'hidden',
        fieldType: 'text',
        fieldName: 'langId',
        fieldLabel: 'langId',
        fieldValue: productionDepartment.name.id,
      },
      {
        required: true,
        type: 'hidden',
        fieldType: 'text',
        fieldName: 'id',
        fieldLabel: 'id',
        fieldValue: productionDepartment.id,
      },
      {
        required: true,
        type: 'select',
        fieldType: 'select',
        fieldName: 'productionCategoryId',
        fieldLabel: user.translate('production_category'),
        fieldValue: '' + productionDepartment.productionCategory.id,
        fieldOptions: props.productionCategorysFieldOptions,
        noNone: true,
      },
      {
        required: true,
        type: 'select',
        fieldType: 'select',
        fieldName: 'warehouseId',
        fieldLabel: user.translate('warehouse'),
        fieldValue: '' + productionDepartment.warehouse.id,
        fieldOptions: props.warehousesFieldOptions,
        noNone: true,
      },
      {
        required: true,
        type: 'checkbox',
        fieldType: 'checkbox',
        fieldName: 'preparing',
        fieldLabel: user.translate('preparing'),
        fieldValue: productionDepartment.preparing,
        line: true,
        noNone: true,
      },
      {
        required: true,
        type: 'checkbox',
        fieldType: 'checkbox',
        fieldName: 'production',
        fieldLabel: user.translate('production'),
        fieldValue: productionDepartment.production,
        line: true,
        noNone: true,
      },
      {
        required: true,
        type: 'checkbox',
        fieldType: 'checkbox',
        fieldName: 'delivery',
        fieldLabel: user.translate('delivery'),
        fieldValue: productionDepartment.delivery,
        line: true,
        noNone: true,
      },
    ]
  }

  const EDIT_ITEM = `
	mutation ($restaurantId: String!, $id: String!, $langId: String!, ${
    user.me.profile && user.me.profile.isAdmin ? '$nameMk: String!, $nameEn: String!' : '$name: String!'
  }, $productionCategoryId: String!, $warehouseId: String!, $production: Long!, $delivery: Long!, $preparing: Long!) {
		createProductionDepartment(input: {
			id: $id,
			${user.me.profile && user.me.profile.isAdmin ? user.gqlEditNameTr('$nameMk', '$nameEn', '$langId') : user.gqlEditName('$name', '$langId')}
			restaurantId: $restaurantId
			productionCategoryId: $productionCategoryId
			warehouseId: $warehouseId
			production: $production
			delivery: $delivery
			preparing: $preparing
		}) {
			id
		}
	}`

  const [openManipulateBox, setOpenManipulateBox] = useState(false)

  const onManipulateClick = (e) => {
    setOpenManipulateBox(true)
  }

  const onDeleteClick = (e) => {
    if (productionDepartment.productionDepartmentPossCount === 0) {
      props.setDeleteState({
        name: productionDepartmentName,
        variables: { id: productionDepartment.id },
        hideUndo: true,
      })
      props.setOpenDeleteBox(true)
    } else {
      props.setOpenSnackBarWarning(true)
    }
  }

  return (
    <React.Fragment>
      <Card className={classes.itemCard}>
        <CardActionArea onClick={onManipulateClick}>
          <Box component="h4" bgcolor="background.paper" className={classes.itemName}>
            <b>{productionDepartmentName}</b>
          </Box>
          <Box p={1} bgcolor="background.paper" className={classes.itemName}>
            {user.translate('production_category')}:<br />
            <b>
              <em>{productionDepartment.productionCategory.name[user.lang]}</em>
            </b>
          </Box>
          <Box p={1} bgcolor="background.paper" className={classes.itemName}>
            {user.translate('warehouse')}:<br />
            <b>
              <em>{productionDepartment.warehouse.name[user.lang]}</em>
            </b>
          </Box>
        </CardActionArea>
        <CardActions>
          <IconButton size="small" color="primary" onClick={onManipulateClick}>
            <IconEdit />
          </IconButton>
          <IconButton size="small" color="primary" onClick={onDeleteClick}>
            <IconDelete />
          </IconButton>
        </CardActions>
      </Card>

      {openManipulateBox && (
        <Add
          fieldList={fieldList}
          openManipulateBox={setOpenManipulateBox}
          actionType={user.translate('edit')}
          restaurant={restaurant}
          name={productionDepartmentName}
          manipulateItem={EDIT_ITEM}
          onSuccess={props.refetch}
        />
      )}
    </React.Fragment>
  )
}
