export function cleanJson(obj) {
  const cleaned = JSON.stringify(obj, null, 2)

  return cleaned.replace(/^[\t ]*"[^:\n\r]+":/gm, function (match) {
    return match.replace(/"/g, '')
  })
}
export function keyOnlyJson(obj) {
  const cleaned = JSON.stringify(obj, null, 2)

  return cleaned.replace(/^[\t ]*"[^:\n\r]+":\s*([^:\n\r]+)/gm, function (match) {
    const keyVal = match.split(':')
    if (keyVal.length > 1 && keyVal[1].trim().indexOf('{') === 0) {
      return match.replace(/"/g, '').replace(/:\s*/, ' ')
    }
    return match.split(':')[0].replace(/"/g, '')
  })
}

export const extractArguments = (args, outArgs) => {
  if (args === null || args === undefined) return null
  if (args instanceof Array) {
    return args.map((arg) => extractArguments(arg, outArgs))
  }
  if (typeof args !== 'object') {
    return args
  }

  const newArgs = {}
  for (const key in args) {
    const value = args[key]
    if (key.startsWith('$')) {
      if (value?.alias) {
        outArgs[value.alias] = value.type
        newArgs[key.substring(1)] = `%%${value.alias}%%`
      } else {
        outArgs[key] = value
        newArgs[key.substring(1)] = `%%${key}%%`
      }
    } else if (key.endsWith('$')) {
      newArgs[key.substring(0, key.length - 1)] = `%@${value}@%`
    } else {
      newArgs[key] = extractArguments(value, outArgs)
    }
  }
  return newArgs
}

export default function generateGql(queries, type = 'query') {
  const builder = []

  const args = {}
  const queriesWithArgs = Object.keys(queries).map((name) => {
    const query = queries[name]
    return {
      name,
      args: extractArguments(query.args, args),
      select: query.select,
    }
  })

  builder.push(
    type +
      '(' +
      Object.keys(args)
        .map((arg) => arg + ': ' + args[arg])
        .join(', ') +
      ') {',
  )

  queriesWithArgs.forEach((query) => {
    const argv = cleanJson(query.args)
      .replace(/"%%\$(.+?)%%"/gm, '$$$1')
      .replace(/"%@(.+?)@%"/gm, '$1')
    builder.push(`\t${query.name}(${argv.substring(1, argv.length - 2).trim()}) `)
    if (query.select) {
      builder.push(keyOnlyJson(query.select).trim())
    }
  })

  builder.push('}')
  return builder.join('\n')
}
