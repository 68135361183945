const dfs = {
  am_pm: ['претпладне', 'попладне'],
  day_name: ['недела', 'понеделник', 'вторник', 'среда', 'четврток', 'петок', 'сабота'],
  day_short: ['нед', 'пон', 'вто', 'сре', 'чет', 'пет', 'саб'],
  era: ['пр.н.е.', 'н.е.'],
  era_name: ['пред наша ера', 'наша ера'],
  month_name: ['јануари', 'фебруари', 'март', 'април', 'мај', 'јуни', 'јули', 'август', 'септември', 'октомври', 'ноември', 'декември'],
  month_short: ['јан', 'феб', 'мар', 'апр', 'мај', 'јун', 'јул', 'авг', 'сеп', 'окт', 'нов', 'дек'],
  order_full: 'DMY',
  order_long: 'DMY',
  order_medium: 'DMY',
  order_short: 'DMY',
}
const nfs = {
  decimal_separator: ',',
  grouping_separator: '.',
  minus: '-',
}
const df = {
  SHORT_PADDED_CENTURY: function (d) {
    if (d) {
      return (d.getDate() + 101 + '').substring(1) + '/' + (d.getMonth() + 101 + '').substring(1) + '/' + d.getFullYear()
    }
  },
  SHORT: function (d) {
    if (d) {
      return (d.getDate() + 101 + '').substring(1) + '/' + (d.getMonth() + 101 + '').substring(1) + '/' + d.getFullYear()
    }
  },
  SHORT_NOYEAR: function (d) {
    if (d) {
      return (d.getDate() + 101 + '').substring(1) + '/' + (d.getMonth() + 101 + '').substring(1) + '/' + d.getFullYear()
    }
  },
  SHORT_NODAY: function (d) {
    if (d) {
      return (d.getMonth() + 101 + '').substring(1) + '/' + d.getFullYear()
    }
  },
  MEDIUM: function (d) {
    if (d) {
      return d.getDate() + ' ' + dfs.month_short[d.getMonth()] + ' ' + d.getFullYear()
    }
  },
  MEDIUM_NOYEAR: function (d) {
    if (d) {
      return d.getDate() + ' ' + dfs.month_short[d.getMonth()] + ' ' + d.getFullYear()
    }
  },
  MEDIUM_WEEKDAY_NOYEAR: function (d) {
    if (d) {
      return dfs.day_short[d.getDay()] + ' ' + d.getDate() + ' ' + dfs.month_short[d.getMonth()] + ' ' + d.getFullYear()
    }
  },
  LONG_NODAY: function (d) {
    if (d) {
      return dfs.month_name[d.getMonth()] + ' ' + d.getFullYear()
    }
  },
  LONG: function (d) {
    if (d) {
      return d.getDate() + ' ' + dfs.month_name[d.getMonth()] + ' ' + d.getFullYear()
    }
  },
  FULL: function (d) {
    if (d) {
      return dfs.day_name[d.getDay()] + ',' + ' ' + d.getDate() + ' ' + dfs.month_name[d.getMonth()] + ' ' + d.getFullYear()
    }
  },
}

export default {
  getCountry: function () {
    return 'MK'
  },
  getCountryName: function () {
    return 'Macedonia'
  },
  getDateFormat: function (formatCode) {
    const retVal = {}
    retVal.format = df[formatCode]
    return retVal
  },
  getDateFormats: function () {
    return df
  },
  getDateFormatSymbols: function () {
    return dfs
  },
  getDecimalFormat: function (places) {
    const retVal = {}
    retVal.format = function (n, quantity = false, zeros = false) {
      const ns = n < 0 ? Math.abs(n).toFixed(places) : n.toFixed(places)
      const ns2 = ns.split('.')
      let s = ns2[0]
      let d = ''
      if (quantity)
        if (zeros) d = ns2[1] === '0'.repeat(places) ? '' : ns2[1]
        else d = ns2[1] === '0'.repeat(places) ? '' : ns2[1].replace(/0*$/g, '')
      else d = s === 0 ? '' : ns2[1]
      const rgx = /(\d+)(\d{3})/
      while (rgx.test(s)) {
        s = s.replace(rgx, '$1' + nfs.grouping_separator + '$2')
      }
      return (n < 0 ? nfs.minus : '') + s + (d ? nfs.decimal_separator : '') + (d || '')
    }
    return retVal
  },
  getDecimalFormatSymbols: function () {
    return nfs
  },
  getIntegerFormat: function () {
    const retVal = {}
    retVal.format = function (i) {
      let s = i < 0 ? Math.abs(i).toString() : i.toString()
      const rgx = /(\d+)(\d{3})/
      while (rgx.test(s)) {
        s = s.replace(rgx, '$1' + nfs.grouping_separator + '$2')
      }
      return i < 0 ? nfs.minus + s : s
    }
    return retVal
  },
  getLanguage: function () {
    return 'mk'
  },
  getLanguageName: function () {
    return 'Macedonian'
  },
  getLocale: function () {
    return 'mk-MK'
  },
  getLocaleName: function () {
    return 'Macedonian (Macedonia)'
  },
  getCurrencySymbol: function () {
    return 'ден '
  },
}
