const transitionCyrLat = {
  а: 'a',
  б: 'b',
  в: 'v',
  г: 'g',
  д: 'd',
  ѓ: 'g',
  е: 'e',
  ж: 'z',
  з: 'z',
  ѕ: 'z',
  и: 'i',
  ј: 'j',
  к: 'k',
  л: 'l',
  љ: 'l',
  м: 'm',
  н: 'n',
  њ: 'n',
  о: 'o',
  п: 'p',
  р: 'r',
  с: 's',
  т: 't',
  ќ: 'k',
  у: 'u',
  ф: 'f',
  х: 'h',
  ц: 'c',
  ч: 'c',
  џ: 'dz',
  ш: 's',
  lj: 'l',
  sh: 's',
  nj: 'n',
  gj: 'g',
}

const Str = String
Str.prototype.cirilicToLatin = function () {
  let self = this.toLowerCase()
  for (const letter in transitionCyrLat) {
    self = self.replace(new RegExp(letter, 'g'), transitionCyrLat[letter])
  }
  return self
}
Str.prototype.cirilicLatinCompare = function (lang) {
  let self = this.toLowerCase()
  if (lang !== 'mk') {
    for (const letter in transitionCyrLat) {
      self = self.replace(new RegExp(letter, 'g'), transitionCyrLat[letter])
    }
  } else {
    for (const letter in transitionCyrLat) {
      self = self.replace(new RegExp(transitionCyrLat[letter], 'g'), letter)
    }
  }

  return self
}

const Num = Number
Num.prototype.switch = function (options) {
  return options[this] || options.default
}

Num.prototype.round = function (decimals, defNaN) {
  const multiplier = Math.pow(10, decimals)
  const value = Math.round(this * multiplier) / multiplier
  return isNaN(value) ? defNaN || value : value
}

Num.prototype.roundUpBy = function (step) {
  return Math.ceil(this / step) * step
}
Num.prototype.roundDownBy = function (step) {
  return Math.floor(this / step) * step
}

const Arr = Array

Arr.prototype.plus = function (...arrays) {
  if (arrays === undefined || arrays.length === 0 || (arrays.length === 1 && arrays[0] === undefined)) return this
  const result = [...this]
  arrays.forEach((array) => array && result.push(array))
  return result
}

Arr.prototype.get = function (key, defValue) {
  return this[key] || defValue
}
Arr.prototype.groupBy = function (keyFunction) {
  const groups = {}
  this.forEach(function (el) {
    const key = keyFunction(el)
    const keyArr = groups[key]
    if (keyArr === undefined) {
      groups[key] = [el]
    } else {
      keyArr.push(el)
    }
  })
  return groups
}

Arr.prototype.toMapBy = function (keyFunction, valFunction) {
  const result = {}
  this.forEach(function (el) {
    result[keyFunction(el)] = valFunction ? valFunction(el) : el
  })
  return result
}

Arr.prototype.orderBy = function (keyFunction) {
  return [...this].sort((a, b) => keyFunction(a) - keyFunction(b))
}

Arr.prototype.orderByDesc = function (keyFunction) {
  return [...this].sort((a, b) => keyFunction(b) - keyFunction(a))
}

Arr.prototype.sumOf = function (valFunction) {
  let sum = 0
  this.forEach((item) => {
    sum = sum + valFunction(item)
  })
  return sum
}

Arr.prototype.maxOf = function (valFunction, initialMax) {
  let max = initialMax
  this.forEach((item) => {
    const val = valFunction(item)
    if (max === undefined || val > max) {
      max = val
    }
  })
  return max
}

Arr.prototype.maxBy = function (valFunction) {
  let max
  let maxItem
  this.forEach((item) => {
    const val = valFunction(item)
    if (max === undefined || val > max) {
      max = val
      maxItem = item
    }
  })
  return maxItem
}

Arr.prototype.minOf = function (valFunction, initialMax) {
  let max = initialMax
  this.forEach((item) => {
    const val = valFunction(item)
    if (max === undefined || val < max) {
      max = val
    }
  })
  return max
}

Arr.prototype.minBy = function (valFunction) {
  let max
  let maxItem
  this.forEach((item) => {
    const val = valFunction(item)
    if (max === undefined || val < max) {
      max = val
      maxItem = item
    }
  })
  return maxItem
}

Arr.prototype.mapRange = function (start, end, valFunction) {
  if (start < 0) throw Error('start should be greater or equal to zero', start)
  if (end > this.length) throw Error('end should be less or equal to the length', start)
  const results = []
  for (let i = start; i < end; i++) {
    results.push(valFunction(this[i], i))
  }
  return results
}

Arr.prototype.reduceRange = function (start, end, callback, initialVal) {
  if (start < 0) throw Error('start should be greater or equal to zero', start)
  if (end > this.length) throw Error('end should be less or equal to the length', start)
  let acc = initialVal
  for (let i = start; i < end; i++) {
    acc = callback(acc, this[i], i)
  }
  return acc
}

Arr.prototype.reducePromise = function (callback, start = 0, end) {
  if (start < 0) throw Error('start should be greater or equal to zero', start)
  if (end === undefined) end = this.length
  if (end > this.length) throw Error('end should be less or equal to the length', start)
  let acc
  for (var i = start; i < end; i++) {
    const item = this[i]
    if (acc === undefined) {
      acc = callback(item, i)
      continue
    }
    acc = acc.then(() => callback(item, i))
  }
  return acc
}

Arr.prototype.removeBy = function (predicate) {
  const index = this.findIndex(predicate)
  if (index < 0) return undefined
  return this.splice(index, 1)[0]
}

Arr.prototype.distinct = function () {
  return this.filter((v, i, self) => self.indexOf(v) === i)
}

Arr.prototype.distinctBy = function (keyFunction) {
  const set = new Set()
  return this.filter((v) => {
    const key = keyFunction(v)
    if (set.has(key)) return false
    set.add(key)
    return true
  })
}

Arr.prototype.move = function (from, to) {
  if (from < 0 || from >= this.length) return this
  if (to < 0 || to >= this.length) return this
  this.splice(to, 0, this.splice(from, 1)[0])
  return this
}
Arr.prototype.count = function (predicate) {
  if (this.length === 0) return 0
  let count = 0
  this.forEach((item) => {
    if (predicate(item)) count++
  })
  return count
}

const Dat = Date
Dat.prototype.toISODate = function () {
  return this.toISOString().split('T')[0]
}

if (String.prototype.replaceAll === undefined) {
  Str.prototype.replaceAll = function (search, replace) {
    return this.replace(new RegExp(search.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace)
  }
}

Str.prototype.capitalize = function (forceCamelCase = false) {
  if (this.length <= 1) return this
  if (forceCamelCase) {
    return this.charAt(0).toLocaleUpperCase() + this.slice(1).toLocaleLowerCase()
  } else {
    return this.charAt(0).toLocaleUpperCase() + this.slice(1)
  }
}
Str.prototype.capitalizeAll = function () {
  return this.split(/(\W+)/)
    .map((w) => w.capitalize())
    .join('')
}
Str.prototype.capitalizeSentence = function () {
  return this.split(/([.?!]\s*)/)
    .map((w) => w.capitalize())
    .join('')
}

Str.prototype.wordsUpTo = function (length) {
  if (this.length <= length) return this
  let result = ''
  const words = this.split(/([.?!\s]+)/)
    .map((w) => w.capitalize())
    .forEach((word) => {
      if (result.length + word.length <= length) {
        result += word
      }
    })
  return result.trim()
}

if (!Str.prototype.padStart) {
  Str.prototype.padStart = function padStart(targetLength, padString) {
    targetLength = targetLength >> 0 // truncate if number or convert non-number to 0;
    padString = String(typeof padString !== 'undefined' ? padString : ' ')
    if (this.length > targetLength) {
      return String(this)
    } else {
      targetLength = targetLength - this.length
      if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length) // append to original to ensure we are longer than needed
      }
      return padString.slice(0, targetLength) + String(this)
    }
  }
}

if (!Str.prototype.repeat) {
  Str.prototype.repeat = function (count) {
    if (this === null) throw new TypeError("can't convert " + this + ' to object')

    let str = '' + this
    // To convert string to integer.
    count = +count
    // Check NaN
    if (Number.isNaN(count)) count = 0

    if (count < 0) throw new RangeError('repeat count must be non-negative')

    if (count === Infinity) throw new RangeError('repeat count must be less than infinity')

    count = Math.floor(count)
    if (str.length === 0 || count === 0) return ''

    // Ensuring count is a 31-bit integer allows us to heavily optimize the
    // main part. But anyway, most current (August 2014) browsers can't handle
    // strings 1 << 28 chars or longer, so:
    if (str.length * count >= 1 << 28) throw new RangeError('repeat count must not overflow maximum string size')

    const maxCount = str.length * count
    count = Math.floor(Math.log(count) / Math.log(2))
    while (count) {
      str += str
      count--
    }
    str += str.substring(0, maxCount - str.length)
    return str
  }
}
