import React, { useContext, useState } from 'react'

import { gql, useQuery } from '@apollo/client'

import IconAdd from '@mui/icons-material/Add'
import { CircularProgress, Grid } from '@mui/material'
import SpeedDial from '@mui/material/SpeedDial'
import makeStyles from '@mui/styles/makeStyles'

import WarehouseItem from './WarehouseItem'
import { RestaurantContext, UserContext } from '../../App'
import Add from '../common/Add'
import Delete from '../common/Delete'
import SnackBarDelete from '../common/SnackBarDelete'
import SnackBarWarning from '../common/SnackBarWarning'

// CSS
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    padding: theme.spacing(3),
  },
  gridItem: {
    maxWidth: 480,
    minWidth: 320,
    flexGrow: 1,
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

// React Function Component
export default function WarehouseList() {
  const classes = useStyles()

  // Get current restaurant from context
  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const [openManipulateBox, setOpenManipulateBox] = useState(false)
  const [openDeleteBox, setOpenDeleteBox] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [openSnackBarWarning, setOpenSnackBarWarning] = useState(false)

  const [deleteState, setDeleteState] = useState({
    name: '',
    variables: {},
    hideUndo: false,
  })

  const onManipulateClick = (e) => {
    setOpenManipulateBox(true)
  }

  let fieldList = [
    {
      required: true,
      type: 'text',
      fieldType: 'text',
      fieldName: 'name',
      fieldLabel: user.translate('title'),
      fieldValue: '',
    },
  ]

  if (user.me.profile && user.me.profile.isAdmin) {
    fieldList = [
      {
        required: true,
        type: 'text',
        fieldType: 'text',
        fieldName: 'nameMk',
        fieldLabel: user.translate('titleMk'),
        fieldValue: '',
      },
      {
        required: true,
        type: 'text',
        fieldType: 'text',
        fieldName: 'nameEn',
        fieldLabel: user.translate('titleEn'),
        fieldValue: '',
      },
    ]
  }

  const DELETE_ITEM = `
    mutation ($id: String!) {
        deleteWarehouse(id: $id)
    }`

  const UNDELETE_ITEM = `
    mutation ($id: String!) {
        undeleteWarehouse(id: $id)
    }`

  const ADD_ITEM = `
    mutation ($restaurantId: String!, ${
      user.me.profile && user.me.profile.isAdmin ? '$nameMk: String!, $nameEn: String!' : '$name: String!'
    }) {
		createWarehouse(input: {
			${user.me.profile && user.me.profile.isAdmin ? user.gqlCreateNameTr('$nameMk', '$nameEn') : user.gqlCreateName('$name')}
			restaurantId: $restaurantId
		}) {
			id
		}
    }`

  // GraphQL API request definition (local variables: restaurantId)
  const GET_WAREHOUSE_BY_RESTAURANT = gql`
    query ($restaurantId: String!) {
		getWarehousesByContextRestaurantId(restaurantId: $restaurantId) {
			id
			name {
				${user.gqlFetchName()}
            }
            active
            productionDepartmentsCount
            orderItemsCount
            inventorysCount
            receivingStockItemsCount
            invoiceItemProductsCount
            receivingWarehouseStockTransfersCount
            sendingWarehouseStockTransfersCount
		}
  }`

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const { data, loading, error, refetch } = useQuery(GET_WAREHOUSE_BY_RESTAURANT, {
    variables: { restaurantId: restaurant.id },
    pollInterval: 3000,
  })

  // If it is loading, show progress bar
  // if (loading) return <CircularProgress />
  if (loading) {
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    )
  }

  // In case there is an error, just show it for now
  if (!data) {
    user.consoleLog(error)
    return <p>Error</p>
  }

  const alertMessage = `${user.translate('cant_delete_warehouse')} `;
    const autoHideDuration = 3000;
    const linkTo = ``;
    const linkText = ''

  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction="row" justifyContent="center" alignItems="start">
        {data.getWarehousesByContextRestaurantId.map((warehouse) => (
          <Grid item xs={4} key={warehouse.id} className={classes.gridItem}>
            <WarehouseItem
              warehouse={warehouse}
              setOpenDeleteBox={setOpenDeleteBox}
              setDeleteState={setDeleteState}
              setOpenSnackBarWarning={setOpenSnackBarWarning}
              refetch={refetch}
            />
          </Grid>
        ))}
      </Grid>
      <SpeedDial ariaLabel="Add Warehouse" className={classes.speedDial} open={false} onClick={onManipulateClick} icon={<IconAdd />} />

      {openDeleteBox && (
        <Delete
          name={deleteState.name}
          variables={deleteState.variables}
          setOpenSnackBar={setOpenSnackBar}
          setOpenDeleteBox={setOpenDeleteBox}
          deleteItem={DELETE_ITEM}
          onSuccess={refetch}
        />
      )}

      {openSnackBar && (
        <SnackBarDelete
          hideUndo={deleteState.hideUndo}
          message={deleteState.name + ` ${user.translate('deleted')}!`}
          variables={deleteState.variables}
          openSnackBar={openSnackBar}
          setOpenSnackBar={setOpenSnackBar}
          unDeleteItem={UNDELETE_ITEM}
          onSuccess={refetch}
        />
      )}

      {openSnackBarWarning && (
        <SnackBarWarning
          message={alertMessage}
          autoHideDuration={autoHideDuration}
          linkTo={linkTo}
          linkText={linkText}
          openSnackBar={openSnackBarWarning}
          setOpenSnackBar={setOpenSnackBarWarning}
        />
      )}

      {openManipulateBox && (
        <Add
          fieldList={fieldList}
          openManipulateBox={setOpenManipulateBox}
          actionType={user.translate('add')}
          name={user.translate('warehouse')}
          restaurant={restaurant}
          manipulateItem={ADD_ITEM}
          onSuccess={refetch}
        />
      )}
    </div>
  )
}
