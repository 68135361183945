import { useContext, useEffect, useState } from 'react'

import Moment from 'react-moment'
import { useParams } from 'react-router-dom'

import PrintIcon from '@mui/icons-material/Print'
import { Button, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import DateKdfiReport from './turnover/DateKdfiReport'
import GenericReport from './turnover/GenericReport'
import { UserContext } from '../../App'
import helper from '../common/Helper'
import Show from '../common/Show'

const useStyles = makeStyles((theme) => ({
  root: {
    // '& #table-title': {
    //     maxHeight: 34
    // }
    padding: theme.spacing(1),
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& table': {
      borderCollapse: 'collapse',
      width: '100%',
    },
    '& td, th': {
      textAlign: 'left',
      paddingRight: 8,
      fontSize: 12,
    },
  },
}))
export default function TurnoverReportDate(props) {
  const classes = useStyles()
  const user = useContext(UserContext)

  const { date: dateStr } = useParams()

  const date = new Date(dateStr)

  const [isLoading, setIsLoading] = useState({})
  const [documentHeaders, setDocumentHeaders] = useState()
  const [print, setPrint] = useState()

  const getIsLoadingSetter = (key) => (val) => {
    setIsLoading((prevState) => ({ ...prevState, [key]: val }))
  }

  useEffect(() => {
    if (!print) return
    setTimeout(() => {
      helper.printdiv('printDiv', 'no_print')
    }, 500)
  }, [print])

  return (
    <div className={classes.root} id="printDiv">
      <div className={classes.header}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <Typography variant="h6">
            {user.translate('daily_turnover')}{' '}
            <Moment format="ll" locale={user.lang}>
              {date}
            </Moment>
          </Typography>
          <Show if={print}>
            <br />
            <table>
              {documentHeaders?.map((item) => (
                <tr key={item.title}>
                  <td>{item.title}</td>
                  <td>{item.value}</td>
                </tr>
              ))}
            </table>
          </Show>
        </div>
        <Button
          variant="contained"
          color="primary"
          disabled={Object.keys(isLoading).length === 0 || Object.values(isLoading).reduce((p, c) => p || c, false)}
          component="span"
          size="small"
          style={{ visibility: print ? 'hidden' : 'visible' }}
          onClick={(e) => setPrint(Date.now())}
          startIcon={<PrintIcon />}
        >
          {user.translate('printing')}
        </Button>
      </div>
      <br />
      <DateKdfiReport date={date} setIsLoading={getIsLoadingSetter('kdfi')} setDocumentHeaders={setDocumentHeaders} />
      <br />
      <GenericReport
        date={date}
        setIsLoading={getIsLoadingSetter('mir')}
        name="report_markerInvoiceReport"
        columns={['fromTo', 'timeTo', 'komitent', 'customer', 'amount']}
        totalBy={['amount']}
      />
      <br />
      <GenericReport
        date={date}
        setIsLoading={getIsLoadingSetter('semr')}
        name="report_staffExpenseMarkerReport"
        columns={['fromTo', 'timeTo', 'customer', 'amount']}
        totalBy={['amount']}
      />
      <br />
      <GenericReport
        date={date}
        setIsLoading={getIsLoadingSetter('isr')}
        name="report_inventoryShrinkageReport"
        columns={['fromTo', 'timeTo', 'type', 'quantity', 'cost', 'unit', 'stockType', 'amount']}
        totalBy={['amount']}
      />
      <br />
      <GenericReport
        date={date}
        setIsLoading={getIsLoadingSetter('icr')}
        name="report_inventoryCounterReport"
        columns={['name', 'info', 'counterDiff', 'orderQuantity', 'diff', 'amount']}
        totalBy={['diff', 'amount']}
      />
    </div>
  )
}
