import React, { useContext, useState } from 'react'

import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'
import { Card, Typography, IconButton, CardActionArea, CardContent, CardActions } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { UserContext , RestaurantContext } from '../../App'
import Add from '../common/Add'
import helper from '../common/Helper'

const useStyles = makeStyles((theme) => ({
  itemCard: {
    display: 'flex',
  },
  itemName: {
    margin: theme.spacing(1),
    flexGrow: 1,
  },
}))

export default function Staff(props) {
  const classes = useStyles()
  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const staff = props.staff
  const employee = staff.employee

  let employeeRole = staff.role

  if (employeeRole === 0) {
    employeeRole = user.translate('deactivated')
  } else if (employeeRole === 1) {
    employeeRole = user.translate('employee')
  } else if (employeeRole === 2) {
    employeeRole = user.translate('shift_manager')
  } else if (employeeRole === 3) {
    employeeRole = user.translate('manager')
  } else if (employeeRole === 4) {
    employeeRole = user.translate('owner')
  }

  const access = user.role > staff.role || user.role === 4

  const fieldList = [
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'employeeId',
      fieldLabel: 'employeeId',
      fieldValue: employee.id,
      error: false,
      helperText: '',
    },
    {
      required: true,
      type: 'text',
      fieldType: 'text',
      fieldName: 'name',
      fieldLabel: user.translate('name'),
      fieldValue: employee.profile ? employee.profile.name : '',
      error: false,
      helperText: '',
    },
    {
      required: false,
      type: access ? 'email' : 'disabled',
      fieldType: 'text',
      fieldName: 'email',
      fieldLabel: user.translate('email'),
      fieldValue: employee.profile ? employee.profile.email : '',
      error: false,
      helperText: '',
    },
    {
      required: false,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'code',
      fieldLabel: user.translate('code'),
      fieldValue: staff.code != null ? '' + staff.code : '',
      error: false,
      helperText: '',
      hidden: true,
    },
    {
      required: false,
      type: access ? 'password_show' : 'hidden',
      fieldType: 'text',
      fieldName: 'pinCode',
      fieldLabel: user.translate('pin_code'),
      fieldValue: staff.pinCode != null ? '' + staff.pinCode : '',
      error: false,
      helperText: '',
    },
    {
      required: false,
      type: access ? 'password_show' : 'hidden',
      fieldType: 'text',
      fieldName: 'patternCode',
      fieldLabel: user.translate('pattern_code'),
      fieldValue: staff.patternCode != null ? '' + staff.patternCode : '',
      error: false,
      helperText: '',
    },
    {
      required: false,
      type: access ? 'password_show' : 'hidden',
      fieldType: 'text',
      fieldName: 'nfcTag',
      fieldLabel: user.translate('nfc_tag'),
      fieldValue: staff.nfcTag != null ? '' + staff.nfcTag : '',
      error: false,
      helperText: '',
    },
    {
      required: true,
      type: access ? 'radio' : 'hidden',
      fieldType: access ? 'radio' : 'text',
      fieldName: 'role',
      fieldLabel: user.translate('role'),
      fieldValue: '' + staff.role,
      fieldOptions: [
        { val: '0', label: user.translate('deactivated') },
        { val: '1', label: user.translate('employee') },
        { val: '2', label: user.translate('shift_manager') },
        { val: '3', label: user.translate('manager') },
        { val: '4', label: user.translate('owner') },
      ].filter((c) => c.val < user.role || user.role === 4),
      error: false,
      helperText: '',
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'lang',
      fieldLabel: user.lang,
      fieldValue: user.lang,
    },
  ]

  const EDIT_ITEM = `
    mutation ($restaurantId: String!, $employeeId: String!, $code: String, $pinCode: String, $patternCode: String, $nfcTag: String, $role: Long!, $name: String!, $email: String, $lang: String!) {
        createProfile(input: {
          userId: $employeeId
          name: $name
          email: $email
        }){
          userId
        }
        createRestaurantEmployee(input: {
          employeeId: $employeeId
          restaurantId: $restaurantId
          role: $role
          code: $code
          pinCode: $pinCode
          patternCode: $patternCode
          nfcTag: $nfcTag
          lang: $lang
        }){
          employeeId
        }
    }`

  const [openManipulateBox, setOpenManipulateBox] = useState(false)

  const onManipulateClick = (e) => {
    setOpenManipulateBox(true)
  }

  const onDeleteClick = (employee) => {
    props.setDeleteState({
      name: employee.profile ? employee.profile.name : '',
      variables: {
        employeeId: employee.id,
        restaurantId: restaurant.id,
      },
      hideUndo: true,
    })
    props.setOpenDeleteBox(true)
  }

  return (
    <React.Fragment>
      <Card className={classes.itemCard}>
        <CardActionArea>
          <CardContent onClick={(e) => onManipulateClick(employee)}>
            <Typography variant="subtitle1" className={classes.itemName}>
              {employee.profile ? employee.profile.name : ''}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" className={classes.itemName}>
              {employeeRole}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <IconButton size="small" color="primary" onClick={(e) => onManipulateClick(employee)}>
            <IconEdit />
          </IconButton>
          <IconButton
            size="small"
            color="primary"
            disabled={user.role <= staff.role && user.me.id !== employee.id ? true : undefined}
            onClick={(e) => onDeleteClick(employee)}
          >
            <IconDelete />
          </IconButton>
        </CardActions>
      </Card>

      {openManipulateBox && (
        <Add
          fieldList={fieldList}
          openManipulateBox={setOpenManipulateBox}
          actionType={user.translate('edit')}
          restaurant={restaurant}
          name={employee.profile ? employee.profile.name : ''}
          manipulateItem={EDIT_ITEM}
          onSuccess={props.refetch}
        />
      )}
    </React.Fragment>
  )
}
