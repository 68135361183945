import React, { useContext, useRef, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { MenuItem, Select } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { RestaurantContext, UserContext } from '../../App'
import SnackBarDelete from '../common/action/SnackBarDelete'
import useDateRangeFilter from '../common/DateRangeHeader'
import KomitentAutoComplete from '../common/form/KomitentAutoComplete'
import SelectPaymentDirection from '../common/form/SelectPaymentDirection'
import GqlSimpleTable from '../common/GqlSimpleTable'
import Show from '../common/Show'
import useWindowSize from '../common/useWindowSize'

const useStyles = makeStyles((theme) => ({
  roots: {
    '& .MuiTableCell-root .MuiIconButton-root': {
      padding: theme.spacing(1),
    },
  },
  root: {},
  checkedLabel: {
    marginRight: 0,
    '& .MuiFormControlLabel-label': {
      fontSize: 12,
    },
  },
  distOk: {
    color: '#069a87',
    fontSize: '1.1em',
    verticalAlign: 'middle',
    paddingBottom: 1,
  },
  options: {
    display: 'flex',
    flexFlow: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2),
  },
  tableCell: {
    textAlign: 'left',
    paddingTop: 0,
  },
}))

const directions = {
  all: 'all',
  debit: 'DEBIT',
  credit: 'CREDIT',
}

export default function OpenItemsStatement() {
  const classes = useStyles()

  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const size = useWindowSize()

  const navigate = useNavigate()

  const { komitentId: selectedKomitentId } = useParams()

  const urlQuery = new URLSearchParams(window.location.search)

  const selectedDirection = directions[(urlQuery.get('type') || 'all').toLowerCase()] || 'all'

  const komitentsRef = useRef()

  const [deletedItem, setDeletedItem] = useState()

  const selectedKomitentItem = komitentsRef.current?.find((item) => item.commissioner.id === selectedKomitentId)?.commissioner

  const selectedKomitent = selectedKomitentId && {
    id: selectedKomitentId,
    name: selectedKomitentItem && selectedKomitentItem.name[user.lang],
  }

  const typeMaxLen = 16
  const types = {
    compensation: user.translate('compensation').substring(0, typeMaxLen),
    COMPENSATION: user.translate('compensation').substring(0, typeMaxLen),
    cession: user.translate('cession').substring(0, typeMaxLen),
    CESSION: user.translate('cession').substring(0, typeMaxLen),
    BANK_ACCOUNT: user.translate('bank_statement').substring(0, typeMaxLen),
    INVOICE: user.translate('invoice').substring(0, typeMaxLen),
    NONE: user.translate('not_dist').substring(0, typeMaxLen),
    OTHER: user.translate('other').substring(0, typeMaxLen),
    INITIAL: user.translate('initialo').substring(0, typeMaxLen),
    TURNOVER: user.translate('turnover').substring(0, typeMaxLen),
    BALANCE: user.translate('balance').substring(0, typeMaxLen),
    BALANCE_SUM: user.translate('balance_sum').substring(0, typeMaxLen),
    CANCELED: <span style={{ color: '#F00' }}>{user.translate('storna').substring(0, typeMaxLen)}</span>,
  }

  const renderAmount = (item, value, formated) => {
    const type = item.typeVal

    if (type === 'INITIAL' || type === 'TURNOVER' || type === 'BALANCE' || type === 'BALANCE_SUM') {
      return (
        <i>
          <b style={{ whiteSpace: 'nowrap' }}>{formated}</b>
        </i>
      )
    } else if (item.targetDocType === 'NONE' && value > 0) {
      return <span style={{ color: '#08753e', fontWeight: 600, whiteSpace: 'nowrap' }}>{formated}</span>
    } else if (value === 0) {
      return <span style={{ color: '#6d6d6d', whiteSpace: 'nowrap' }}>{formated}</span>
    }
    return formated
  }

  const FIELDS = [
    { name: 'id', gql: 'String!', id: true, hidden: true },
    { name: 'issuingDate', gql: 'String!', width: 0.7 },
    { name: 'komitent', gql: 'String!', width: 2, nowrap: true, maxWidth: '12vw', fontSize: 10, hidden: selectedKomitent?.id !== undefined },
    {
      name: 'type',
      gql: 'String!',
      render: (item) => <span style={{ fontWeight: item.typeVal.startsWith('BALANCE') ? 700 : 400 }}>{item.type}</span>,
    },
    { name: 'invoiceNumber', gql: 'String!', width: 0.7 },
    { name: 'dueToDate', gql: 'String!', width: 0.7 },
    { name: 'debit', gql: 'Float!', minWidth: 110, render: (item) => renderAmount(item, item.debitVal, item.debit), align: 'right' },
    { name: 'credit', gql: 'Float!', minWidth: 110, render: (item) => renderAmount(item, item.creditVal, item.credit), align: 'right' },
    { name: 'currency', gql: 'String!', hidden: true },
    { name: 'createdById', gql: 'String', default: user.me.id, hidden: true },
    { name: 'updatedById', gql: 'String', value: user.me.id, hidden: true },
    { name: 'created', gql: 'Long!', default: new Date().getTime(), hidden: true },
    { name: 'updated', gql: 'Long!', default: new Date().getTime(), hidden: true },
  ]
  const dateRangeFilter = useDateRangeFilter('this_year')

  const apiControler = useRef()

  const itemsAdapter = (items) =>
    items?.map((item) => {
      return {
        ...item,
        issuingDate: user.formatDate(item.issuingDate),
        dueToDate: item.dueToDate > 0 ? user.formatDate(item.dueToDate) : '',
        type: types[item.type],
        typeVal: item.type,
        debitVal: item.debit,
        creditVal: item.credit,
        debit: user.formatQuantity(item.debit) + ' ' + item.currency,
        credit: user.formatQuantity(item.credit) + ' ' + item.currency,
      }
    })

  const onRowClick = (item) => {
    if (item.typeVal === 'INVOICE') {
      if (item.debit > 0) {
        navigate(`/restaurant/${restaurant._id}/invoice_output/${item.id}`)
      } else {
        navigate(`/restaurant/${restaurant._id}/invoice_input/${item.id}`)
      }
    } else if (item.typeVal === 'COMPENSATION') {
      navigate(`/restaurant/${restaurant._id}/document/compensation/${item.sourceId}`)
    } else if (item.typeVal === 'BANK_ACCOUNT') {
      navigate(`/restaurant/${restaurant._id}/bank_statement/${item.sourceParentId}/payment/${item.sourceId}`)
    }
  }

  const setSelectedDirection = (direction) => {
    if (selectedKomitentId) {
      navigate(`/restaurant/${restaurant._id}/open_items_statement/${selectedKomitentId}?type=${direction.toLowerCase()}`)
    } else {
      navigate(`/restaurant/${restaurant._id}/open_items_statement?type=${direction.toLowerCase()}`)
    }
  }

  const titleDirection =
    selectedDirection === 'CREDIT' ? ' / ' + user.translate('outputs') : selectedDirection === 'DEBIT' ? ' / ' + user.translate('inputs') : ''

  const titleSufix = (
    <>
      {' '}
      / {user.formatDate(dateRangeFilter.startDate)} - {user.formatDate(dateRangeFilter.endDate)}
      {titleDirection}
    </>
  )

  const columnParents = []

  if (selectedKomitent?.name) {
    columnParents.push({ title: selectedKomitent?.name, colSpan: 8 })
  }

  const setSelectedKomitent = (id) => {
    if (id) {
      navigate(`/restaurant/${restaurant._id}/open_items_statement/${id}?type=${selectedDirection}`)
    } else {
      navigate(`/restaurant/${restaurant._id}/open_items_statement?type=${selectedDirection}`)
    }
  }

  const onLoadKomitents = (data, fieldName) => {
    const komitents = data[fieldName]
    komitentsRef.current = komitents

    if (selectedKomitentId) {
      setSelectedKomitent(selectedKomitentId)
    }
  }

  return (
    <div className={classes.roots}>
      <div className={classes.options}>
        <KomitentAutoComplete
          name="komitent"
          label="komitent"
          value={selectedKomitent?.id}
          onChange={(id, name) => {
            setSelectedKomitent(id)
          }}
          onClear={() => setSelectedKomitent()}
          onLoad={onLoadKomitents}
        />

        <SelectPaymentDirection
          name="document_type_io"
          style={{ width: size.isMobile ? '100%' : 150, marginLeft: 8, marginRight: 8 }}
          value={selectedDirection}
          onChange={(value) => setSelectedDirection(value)}
          options={[
            { val: 'all', name: user.translate('all') },
            { val: 'DEBIT', name: user.translate('inputs') },
            { val: 'CREDIT', name: user.translate('outputs') },
          ]}
          showLabel
          shrink
        />
        <div style={{ width: '100%' }}>{dateRangeFilter.renderHeader()}</div>
      </div>
      <GqlSimpleTable
        apiRef={apiControler}
        customQuery="finance_getOpenItemsStatement"
        relFieldName="restaurantId"
        relFieldValue={restaurant.id}
        pathName={(item) => `document/${item.type.toLowerCase()}`}
        fields={FIELDS}
        itemsAdapter={itemsAdapter}
        header={() => titleSufix}
        args={{
          komitentIds: selectedKomitent?.id ? [selectedKomitent?.id] : [],
          direction$: selectedDirection === 'all' ? null : selectedDirection,
          restaurantId: '1',
          dateFrom: dateRangeFilter.startDate.getTime(),
          dateTo: dateRangeFilter.endDate.getTime(),
        }}
        title="open_items_statement"
        paging
        exportAllData
        columnParents={columnParents}
        onRowClick={onRowClick}
      />

      <Show if={Boolean(deletedItem)}>
        <SnackBarDelete {...deletedItem} refresh={apiControler.current?.gql?.refresh} onClose={setDeletedItem} />
      </Show>
    </div>
  )
}
