import React, { useContext, useRef, useState } from 'react'

import { useNavigate, useLocation, useParams } from 'react-router-dom'

import { gql } from '@apollo/client'

import Print from '@mui/icons-material/Print'
import { CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import Payments from './Payments'
import { RestaurantContext, UserContext } from '../../App'
import useDateRangeFilter from '../common/DateRangeHeader'
import GqlSimpleTable from '../common/GqlSimpleTable'
import MobileDialog from '../common/MobileDialog'
import Show from '../common/Show'
import SnackBarWarning from '../common/SnackBarWarning'
import { useEntityRelationQuery } from '../common/useEntityQuery'

const useStyles = makeStyles((theme) => ({
  roots: {
    '& .MuiTableCell-root .MuiIconButton-root': {
      padding: theme.spacing(1),
    },
  },
  root: {},
  checkedLabel: {
    marginRight: 0,
    '& .MuiFormControlLabel-label': {
      fontSize: 12,
    },
  },
}))

const GQL_GET_KOMITENTS_AND_EMPLOYEES = function (user) {
  return gql`
        query ($restaurantId: String!) {
            getCommissionersByRestaurantId(restaurantId: $restaurantId) {
                commissioner {
                    id
                    name {
                        ${user.gqlFetchName()}
                    }
                }
            }
            getRestaurantEmployeesByContextRestaurantId(restaurantId: $restaurantId) {
                employee {
                    id
                    profile {
                        _id
                        name
                    }
                }
                role
                lang
            }
        }
    `
}

// React Function Component
export default function BankStatementList() {
  const classes = useStyles()

  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const navigate = useNavigate()
  const location = useLocation()

  const { statementId: selectedStatementId } = useParams()

  const [openSnackBarWarning, setOpenSnackBarWarning] = useState(false)

  const defaultBilling = restaurant.restaurantBillings[0]

  const FIELDS = [
    { name: 'id', gql: 'String!', id: true, hidden: true },
    { name: 'restaurantId', gql: 'String!', hidden: true, default: restaurant.id },
    { name: 'year', gql: 'Long!', width: 0.7, default: new Date().getFullYear() },
    { name: 'number', gql: 'String!', width: 0.5 },
    { name: 'currency', gql: 'String!', width: 0.5, edit: 'SelectCurrency', default: 'MKD' },
    { name: 'bankAccount', gql: 'String!', default: defaultBilling?.bankAccount?.wordsUpTo(32), mobile: false },
    { name: 'bank', gql: 'String!', width: 2, default: defaultBilling?.bankName?.wordsUpTo(32), mobile: false },
    { name: 'date', gql: 'String!', edit: 'DateMilis' },
    { name: 'createdById', gql: 'String', default: user.me.id, hidden: true },
    { name: 'updatedById', gql: 'String', value: user.me.id, hidden: true },
    { name: 'created', gql: 'Long!', default: new Date().getTime(), hidden: true },
    { name: 'updated', gql: 'Long!', default: new Date().getTime(), hidden: true },
    { name: 'seen', gql: 'Long', hidden: true },
  ]

  const dateRangeFilter = useDateRangeFilter('this_year')

  const apiControler = useRef()

  const closeItem = () => {
    if (location.state?.from === 'list') {
      navigate(-1)
    } else {
      navigate(`/restaurant/${restaurant._id}/bank_statement`)
    }
  }

  return (
    <div className={classes.roots}>
      <GqlSimpleTable
        apiRef={apiControler}
        entityName="BankStatement"
        pathName="bank_statement"
        fields={FIELDS}
        dateRangeFilter={dateRangeFilter}
        dateRangeField="date"
        title="bank_statements"
      />

      {openSnackBarWarning && (
        <SnackBarWarning
          message={openSnackBarWarning.alertMessage}
          linkTo={openSnackBarWarning.linkTo}
          linkText={openSnackBarWarning.linkText}
          autoHideDuration={openSnackBarWarning.autoHideDuration || 3000}
          openSnackBar
          setOpenSnackBar={setOpenSnackBarWarning}
        />
      )}

      <Show if={selectedStatementId}>
        <BankStatement itemId={selectedStatementId} fields={FIELDS} refetch={apiControler.current?.gql?.refresh} onClose={closeItem} />
      </Show>
    </div>
  )
}

export function BankStatement(props) {
  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const { paymentId: selectedPaymentId } = useParams()

  const defaultBilling = restaurant.restaurantBillings[0]

  const FIELDS = props.fields || [
    { name: 'id', gql: 'String!', id: true, hidden: true },
    { name: 'restaurantId', gql: 'String!', hidden: true, default: restaurant.id },
    { name: 'year', gql: 'Long!', width: 0.7, default: new Date().getFullYear() },
    { name: 'number', gql: 'String!', width: 0.5 },
    { name: 'currency', gql: 'String!', width: 0.5, edit: 'SelectCurrency', default: 'MKD' },
    { name: 'bankAccount', gql: 'String!', default: defaultBilling?.bankAccount?.wordsUpTo(32), mobile: false },
    { name: 'bank', gql: 'String!', width: 2, default: defaultBilling?.bankName?.wordsUpTo(32), mobile: false },
    { name: 'date', gql: 'String!', edit: 'DateMilis' },
    { name: 'createdById', gql: 'String', default: user.me.id, hidden: true },
    { name: 'updatedById', gql: 'String', value: user.me.id, hidden: true },
    { name: 'created', gql: 'Long!', default: new Date().getTime(), hidden: true },
    { name: 'updated', gql: 'Long!', default: new Date().getTime(), hidden: true },
    { name: 'seen', gql: 'Long', hidden: true },
  ]

  const query = useEntityRelationQuery('BankStatement', FIELDS, 'id', props.itemId, {
    single: true,
  })

  const item = query.item
  return (
    <MobileDialog maxWidth="md" open onClose={props.onClose} title={`${item?.number} / ${item?.year}`} actionIcon={<Print />}>
      <Show if={Boolean(item)}>
        <Payments item={item} selectedPaymentId={selectedPaymentId || props.subItemId} refetch={props.refetch} />
      </Show>
      <Show if={item === undefined || item === null}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </div>
      </Show>
    </MobileDialog>
  )
}
