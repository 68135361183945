import React, { useContext, useState } from 'react'

import { gql, useQuery } from '@apollo/client'

import IconAdd from '@mui/icons-material/Add'
import { CircularProgress, Grid } from '@mui/material'
import SpeedDial from '@mui/material/SpeedDial'
import makeStyles from '@mui/styles/makeStyles'

import ProductionDepartmentItem from './ProductionDepartmentItem'
import { RestaurantContext, UserContext } from '../../App'
import Add from '../common/Add'
import Delete from '../common/Delete'
import helper from '../common/Helper'
import SnackBarDelete from '../common/SnackBarDelete'
import SnackBarWarning from '../common/SnackBarWarning'

// CSS
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    padding: theme.spacing(3),
  },
  gridItem: {
    maxWidth: 350,
    minWidth: 350,
    flexGrow: 1,
    textAlign: `left`,
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

// React Function Component
export default function ProductionDepartmentList() {
  const classes = useStyles()

  // Get current restaurant from context
  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)
  const [openManipulateBox, setOpenManipulateBox] = useState(false)
  const [openDeleteBox, setOpenDeleteBox] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [openSnackBarWarning, setOpenSnackBarWarning] = useState(false)

  const [deleteState, setDeleteState] = useState({
    name: '',
    variables: {},
    hideUndo: false,
  })

  const onManipulateClick = (e) => {
    setOpenManipulateBox(true)
  }

  const DELETE_ITEM = `
    mutation ($id: String!) {
        deleteProductionDepartment(id: $id)
    }`

  const UNDELETE_ITEM = `
    mutation ($id: String!) {
        undeleteProductionDepartment(id: $id)
    }`

  const ADD_ITEM = `
      mutation ($restaurantId: String!, ${
        user.me.profile && user.me.profile.isAdmin ? '$nameMk: String!, $nameEn: String!' : '$name: String!'
      }, $productionCategoryId: String!, $warehouseId: String!) {
        createProductionDepartment(input: {
            ${user.me.profile && user.me.profile.isAdmin ? user.gqlCreateNameTr('$nameMk', '$nameEn') : user.gqlCreateName('$name')}
            restaurantId: $restaurantId
            productionCategoryId: $productionCategoryId
            warehouseId: $warehouseId
        }) {
            id
        }
    }`

  // GraphQL API request definition (local variables: restaurantId)
  const GET_WAREHOUSE_BY_RESTAURANT = gql`
        query ($restaurantId: String!) {
            getProductionDepartmentsByContextRestaurantId(restaurantId: $restaurantId) {
                id
                name {
                    ${user.gqlFetchName()}
                }
                productionCategory {
                    id
                    name {
                        ${user.gqlFetchName()}
                    }
                }
                warehouse {
                    id
                    name {
                        ${user.gqlFetchName()}
                    }
                }
                production
                delivery
                preparing
                deviceUUID
                productionDepartmentPossCount
        }
        getProductionCategorysByContextRestaurantId(restaurantId: $restaurantId) {
            id
            name {
                ${user.gqlFetchName()}
            }
        }
        getWarehousesByContextRestaurantId(restaurantId: $restaurantId) {
            id
            name {
                ${user.gqlFetchName()}
            }
        }
    }`

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const { data, loading, error, refetch } = useQuery(GET_WAREHOUSE_BY_RESTAURANT, {
    variables: { restaurantId: restaurant.id },
    pollInterval: 3000,
  })

  // If it is loading, show progress bar
  // if (loading) return <CircularProgress />
  if (loading) {
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    )
  }

  // In case there is an error, just show it for now
  if (!data) {
    user.consoleLog(error)
    return <p>Error</p>
  }

  let warehouses;
    let productionCategorys;
    const warehousesFieldOptions = [];
    const tempWarehouses = [];
    let firstWarehouseId;
    const productionCategorysFieldOptions = [];
    let firstProductionCategoryId
  let alertMessage = `${user.translate('cant_delete_production_department')} `;
    let autoHideDuration = 3000;
    let linkTo = ``;
    let linkText = ''

  if (data) {
    warehouses = data.getWarehousesByContextRestaurantId
    productionCategorys = data.getProductionCategorysByContextRestaurantId
  }

  if (productionCategorys.length > 0) {
    productionCategorys.forEach((prodCategory) => {
      productionCategorysFieldOptions.push({
        val: prodCategory.id,
        label: prodCategory.name[user.lang],
      })
    })
    firstProductionCategoryId = productionCategorysFieldOptions[0].id
  } else {
    alertMessage = `${user.translate('please_create')} `
    linkTo = `/restaurant/${restaurant._id}/production_category/`
    linkText = user.translate('production_category')
    autoHideDuration = undefined
    if (!openSnackBarWarning) setOpenSnackBarWarning(true)
  }

  if (warehouses.length > 0) {
    warehouses.forEach((warehouse) => {
      warehousesFieldOptions.push({
        val: warehouse.id,
        label: warehouse.name[user.lang],
      })
    })

    firstWarehouseId = warehousesFieldOptions[0].id
  } else {
    alertMessage = `${user.translate('please_create')} `
    linkTo = `/restaurant/${restaurant._id}/warehouse/`
    linkText = user.translate('warehouse')
    autoHideDuration = undefined
    if (!openSnackBarWarning) setOpenSnackBarWarning(true)
  }

  let fieldList = [
    {
      required: true,
      type: 'text',
      fieldType: 'text',
      fieldName: 'name',
      fieldLabel: user.translate('title'),
      fieldValue: '',
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'id',
      fieldLabel: 'id',
      fieldValue: helper.uid(),
    },
    {
      required: true,
      type: 'select',
      fieldType: 'select',
      fieldName: 'productionCategoryId',
      fieldLabel: user.translate('production_category'),
      fieldValue: '' + firstProductionCategoryId,
      fieldOptions: productionCategorysFieldOptions,
      noNone: true,
    },
    {
      required: true,
      type: 'select',
      fieldType: 'select',
      fieldName: 'warehouseId',
      fieldLabel: user.translate('warehouse'),
      fieldValue: '' + firstWarehouseId,
      fieldOptions: warehousesFieldOptions,
      noNone: true,
    },
  ]

  if (user.me.profile && user.me.profile.isAdmin) {
    fieldList = [
      {
        required: true,
        type: 'text',
        fieldType: 'text',
        fieldName: 'nameMk',
        fieldLabel: user.translate('titleMk'),
        fieldValue: '',
      },
      {
        required: true,
        type: 'text',
        fieldType: 'text',
        fieldName: 'nameEn',
        fieldLabel: user.translate('titleEn'),
        fieldValue: '',
      },
      {
        required: true,
        type: 'hidden',
        fieldType: 'text',
        fieldName: 'id',
        fieldLabel: 'id',
        fieldValue: helper.uid(),
      },
      {
        required: true,
        type: 'select',
        fieldType: 'select',
        fieldName: 'productionCategoryId',
        fieldLabel: user.translate('production_category'),
        fieldValue: '' + firstProductionCategoryId,
        fieldOptions: productionCategorysFieldOptions,
        noNone: true,
      },
      {
        required: true,
        type: 'select',
        fieldType: 'select',
        fieldName: 'warehouseId',
        fieldLabel: user.translate('warehouse'),
        fieldValue: '' + firstWarehouseId,
        fieldOptions: warehousesFieldOptions,
        noNone: true,
      },
    ]
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
        {data.getProductionDepartmentsByContextRestaurantId.map((productionDepartment) => (
          <Grid item xs={4} key={productionDepartment.id} className={classes.gridItem}>
            <ProductionDepartmentItem
              productionDepartment={productionDepartment}
              warehousesFieldOptions={warehousesFieldOptions}
              productionCategorysFieldOptions={productionCategorysFieldOptions}
              setOpenDeleteBox={setOpenDeleteBox}
              setDeleteState={setDeleteState}
              setOpenSnackBarWarning={setOpenSnackBarWarning}
              refetch={refetch}
            />
          </Grid>
        ))}
      </Grid>
      <SpeedDial
        ariaLabel="Add ProductionDepartment"
        className={classes.speedDial}
        open={false}
        onClick={onManipulateClick}
        icon={<IconAdd />}
      />

      {openDeleteBox && (
        <Delete
          name={deleteState.name}
          variables={deleteState.variables}
          setOpenSnackBar={setOpenSnackBar}
          setOpenDeleteBox={setOpenDeleteBox}
          deleteItem={DELETE_ITEM}
          onSuccess={refetch}
        />
      )}

      {openSnackBar && (
        <SnackBarDelete
          hideUndo={deleteState.hideUndo}
          message={deleteState.name + ` ${user.translate('deleted')}!`}
          variables={deleteState.variables}
          openSnackBar={openSnackBar}
          setOpenSnackBar={setOpenSnackBar}
          unDeleteItem={UNDELETE_ITEM}
          onSuccess={refetch}
        />
      )}

      {openManipulateBox && (
        <Add
          fieldList={fieldList}
          openManipulateBox={setOpenManipulateBox}
          actionType={user.translate('add')}
          name={user.translate('production_department')}
          restaurant={restaurant}
          manipulateItem={ADD_ITEM}
          onSuccess={refetch}
        />
      )}

      {openSnackBarWarning && (
        <SnackBarWarning
          message={alertMessage}
          autoHideDuration={autoHideDuration}
          linkTo={linkTo}
          linkText={linkText}
          openSnackBar={openSnackBarWarning}
          setOpenSnackBar={setOpenSnackBarWarning}
        />
      )}
    </div>
  )
}
