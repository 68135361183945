import React, { useState, useContext, useEffect } from 'react'

import { useMutation, gql } from '@apollo/client'

import { CircularProgress, Dialog, DialogTitle, DialogActions, Button } from '@mui/material'

import helper from './Helper'
import { UserContext } from '../../App'

export default function Deactivate(props) {
  const user = useContext(UserContext)

  const [deactivateIt, setDeactivateIt] = useState(false)
  const [onSuccess, setOnSuccess] = useState(props.onSuccess !== undefined)

  const DEACTIVATE_ITEM = gql`
    ${props.deactivateItem}
  `

  const [deactivateItem, { loading }] = useMutation(DEACTIVATE_ITEM)

  if (loading === false && deactivateIt && onSuccess) {
    user.consoleLog('Deactivate done!')
    props.onSuccess()
    setOnSuccess(false)
    props.setDeactivate(!props.deactivate)
    props.setOpenDeactivateSnackBar(true)
  }

  useEffect(() => {
    if (deactivateIt && loading === false) {
      props.setOpenDeactivateBox(false)
    }
  })

  // Deactivate Dialog
  const handleClose = () => {
    props.setOpenDeactivateBox(false)
  }

  if (loading) {
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    )
  }

  const handleDeactivateAccept = () => {
    setDeactivateIt(true)
    deactivateItem({
      variables: props.variables,
    })
    if (props.setUpdatedAmount) props.setUpdatedAmount(false)
    user.consoleLog(props.variables)
  }

  return (
    <>
      <Dialog open={!deactivateIt} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {props.deactivateType} {props.name}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {user.translate('cancel')}
          </Button>
          <Button onClick={handleDeactivateAccept} color="primary" autoFocus>
            {user.translate('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
