import { useContext, useState } from 'react'

import { endOfISOWeek, startOfISOWeek, subMonths } from 'date-fns'
import { useNavigate } from 'react-router-dom'

import { ExportCsv } from '@material-table/exporters'
import {
  CurrencyAmountField,
  EditText,
  EditTextFloat,
  EditTextInt,
  EditTextPercent,
  GqlReport,
  HiddenField,
} from '@zeroapi-io/zeroapireact'

import { Box } from '@mui/material'

import ExportPdf from './ExportPdf'
import { UserContext } from '../../App'
import { useExportOptions } from '../common/useExportOptions'

const exportOptions = [
  {
    label: 'Export PDF',
    exportFunc: (cols, datas) =>
      ExportPdf(
        cols.map((it) => ({
          title: it.title?.toString() ?? it?.field?.toString() ?? '-',
        })),
        datas,
        'myPdfFileName',
      ),
  },
  {
    label: 'Export CSV',
    exportFunc: (cols, datas) => ExportCsv(cols, datas, 'myCsvFileName'),
  },
]

export default function InventoryTurnoverReport(props) {
  const user = useContext(UserContext)

  const startDate = startOfISOWeek(subMonths(new Date(), 6))
  const endDate = endOfISOWeek(startDate, 1)

  const exportOptions = useExportOptions(`${user.translate('Извештај за состојба на залиха')} ${startDate.toISODate()} - ${endDate.toISODate()}`)

  return (
    <Box m={1}>
      <GqlReport
        entity="report_inventoryRevenueReport"
        dateFrom={startDate}
        dense="dense"
        fontSize="small"
        pageSize={200}
        exportOptions={exportOptions}
      >
        <HiddenField name="id" />
        <EditText name="categoryName" />
        <EditText name="stockType" />
        <EditText name="uom" />
        <EditText name="tax" />

        <EditTextFloat name="initialCountingQuantity" decimals={3} />
        <CurrencyAmountField name="initialTotalValue" currency="MKD" />

        <EditTextFloat name="receivingQuantity" />
        <CurrencyAmountField name="receivingTotalValue" currency="MKD" />

        <EditTextFloat name="closingCountingQuantity" decimals={3} />
        <CurrencyAmountField name="closingTotalValue" currency="MKD" />

        <EditTextFloat name="expenditureQuantity" decimals={3} />
        <CurrencyAmountField name="expenditurePrice" currency="MKD" />

        <EditTextFloat name="usedQuantity" decimals={3} />
        <CurrencyAmountField name="usedQuantityPrice" currency="MKD" />

        <EditTextFloat name="ordersQuantity" decimals={3} />
        <CurrencyAmountField name="unitSalePrice" currency="MKD" />
        <CurrencyAmountField name="totalSalePrice" currency="MKD" />

        <CurrencyAmountField name="profitAmount" currency="MKD" />
        <EditTextPercent name="profitPercent" />

        {/* <FormSwitch name="error" /> */}
      </GqlReport>
    </Box>
  )
}
