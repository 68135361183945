import { useContext, useMemo } from 'react'

import { ExportCsv } from '@material-table/exporters'

import { UserContext } from '../../App'
import ExportPdf from '../report/ExportPdf'
import { ExportExcel } from '../report/ExportXLSX'

type Orientation = 'portrait' | 'landscape'

export const useExportOptions = (exportFileName: string, orientation: Orientation) => {
  const user = useContext(UserContext)

  return useMemo(() => {
    const fileName = `${exportFileName} ${user.formatDate(new Date())}`
    return [
      {
        label: user.translate('export_pdf_name'),
        exportFunc: (cols: any[], datas: any[]) =>
          ExportPdf(
            cols.map((it) => ({
              title: it.title?.toString() ?? it?.field?.toString() ?? '-',
            })),
            datas,
            `${fileName}.pdf`,
            orientation,
          ),
      },
      {
        label: user.translate('export_csv_name'),
        exportFunc: (cols, datas) => ExportCsv(cols, datas, `${fileName}.csv`),
      },
      {
        label: user.translate('export_excel_name'),
        exportFunc: (cols, datas) => ExportExcel(cols, datas, `${fileName}.xlsx`),
      },
    ]
  }, [exportFileName, orientation, user])
}
