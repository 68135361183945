import React, { useContext, useMemo, useState } from 'react'

import { gql, useQuery } from '@apollo/client'

import IconAdd from '@mui/icons-material/Add'
import { Box, Chip, CircularProgress, Divider, Grid, Stack } from '@mui/material'
import SpeedDial from '@mui/material/SpeedDial'
import makeStyles from '@mui/styles/makeStyles'

import RestaurantView from './RestaurantItem'
import { UserContext } from '../App'
import Add from '../restaurant/common/Add'
import Delete from '../restaurant/common/Delete'
import helper from '../restaurant/common/Helper'
import { If } from '../restaurant/common/If'
import SnackBarDelete from '../restaurant/common/SnackBarDelete'
import useWindowSize from '../restaurant/common/useWindowSize'

const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

export default function RestaurantList(props) {
  // const classes = useStyles();

  const classes = useStyles()

  const user = useContext(UserContext)

  const [openAddBox, setOpenAddBox] = useState(false)
  const [openDeleteBox, setOpenDeleteBox] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false)

  const [deleteState, setDeleteState] = useState({
    name: '',
    variables: {},
    hideUndo: false,
  })

  const DELETE_ITEM = `
    mutation ($id: String!) {
        editRestaurant(input:{
            id: $id, 
            active: 0
        }){
            id
        }
    }`

  const UNDELETE_ITEM = `
    mutation ($id: String!) {
        undeleteRestaurant(id: $id)
    }`

  const ADD_ITEM = `
	mutation (
			$id: String!, 
			$employeeId: String!, 
			$lang: String!, 
			$name: String!, 
			$cityId: String!, 
			$imageLogo: String!, 
			$imageLogoBg: String!, 
			$locationLat: Float!, 
			$locationLng: Float!, 
			$locationRadius: Float!, 
			$address: String!, 
			$phone: String!, 
			$website: String!, 
            $workingHours: String!,
            $role: Long! 
			) {
            createRestaurant(input:{
                id: $id
                ${user.gqlCreateName('$name')}
                city: $cityId
                imageLogo: $imageLogo
                imageLogoBg: $imageLogoBg
                locationLat: $locationLat
                locationLng: $locationLng
                locationRadius: $locationRadius
                address: $address
                phone: $phone
                website: $website
                workingHours: $workingHours
                version: 0
            }){
                id
            }
            createRestaurantEmployee(input:{
                restaurantId: $id
                employeeId: $employeeId
                role: $role
                lang: $lang
            }){
                employeeId
            }
            createCommissioner(input: {
                restaurantId: $id,
                commissionerId: $id
            }){
                commissionerId
            }
    }`

  const ADD_CITY_ITEM = `
	mutation (
			$id: String!, 
			$employeeId: String!, 
			$lang: String!, 
			$name: String!, 
			$cityId: String!, 
			$cityName: String!, 
			$imageLogo: String!, 
			$imageLogoBg: String!, 
			$locationLat: Float!, 
			$locationLng: Float!, 
			$locationRadius: Float!, 
			$address: String!, 
			$phone: String!, 
			$website: String!, 
            $workingHours: String!,
            $role: Long! 
			) {
            createCity(input:{
                id: $cityId
                ${user.gqlCreateName('$cityName')}
                countryId: "1"
            }){
                id
            }
            createRestaurant(input:{
                id: $id
                ${user.gqlCreateName('$name')}
                city: $cityId
                imageLogo: $imageLogo
                imageLogoBg: $imageLogoBg
                locationLat: $locationLat
                locationLng: $locationLng
                locationRadius: $locationRadius
                address: $address
                phone: $phone
                website: $website
                workingHours: $workingHours
                version: 0
            }){
                id
            }
            createRestaurantEmployee(input:{
                restaurantId: $id
                employeeId: $employeeId
                role: $role
                lang: $lang
            }){
                employeeId
            }
            createCommissioner(input: {
                restaurantId: $id,
                commissionerId: $id
            }){
                commissionerId
            }
    }`

  // GraphQL API request definition (local variables: restaurantId)
  const GET_ORDER_BY_RESTAURANT = gql`
    query {
		getCitysByCountryId(countryId: "1") {
            id
            name {
                ${user.gqlFetchName()}
            }
        }
  }`

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const { data, loading, error } = useQuery(GET_ORDER_BY_RESTAURANT, {
    pollInterval: 3000,
  })

  const size = useWindowSize(false)

  // If it is loading, show progress bar
  // if (loading) return <CircularProgress />
  if (loading) {
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    )
  }

  // In case there is an error, just show it for now
  if (!data) {
    user.consoleLog(error)
    return <p>Error</p>
  }

  const countrysOptions = [
    {
      val: '1',
      label: user.translate('macedonia'),
    },
  ]

  const cities = data.getCitysByCountryId || []

  const citiesOptions = cities
    .distinctBy((city) => city.id)
    .map((city) => ({
      val: city.id,
      label: city.name[user.lang],
    }))

  const firstCityId = citiesOptions[0]

  const fieldList = [
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'id',
      fieldLabel: 'id',
      fieldValue: helper.uid(),
    },
    {
      required: false,
      type: 'file',
      fieldType: 'upload',
      fieldName: 'imageLogo',
      fieldLabel: user.translate('image'),
      fieldValue: '',
    },
    {
      required: false,
      type: 'text',
      fieldType: 'colorPicker',
      fieldName: 'imageLogoBg',
      fieldLabel: user.translate('image_logo_bg'),
      fieldValue: '#FFFFFF00',
    },
    {
      required: true,
      type: 'text',
      fieldType: 'text',
      fieldName: 'name',
      fieldLabel: user.translate('name'),
      fieldValue: '',
    },
    {
      required: true,
      type: 'text',
      fieldType: 'text',
      fieldName: 'address',
      fieldLabel: user.translate('address'),
      fieldValue: '',
    },
    {
      required: true,
      type: 'autocompleteAdd',
      fieldType: 'autocompleteAdd',
      fieldName: 'cityId',
      fieldLabel: user.translate('city'),
      fieldValue: firstCityId,
      fieldOptions: citiesOptions,
      wholeValueId: true,
    },
    {
      required: true,
      type: 'select',
      fieldType: 'select',
      fieldName: 'country',
      fieldLabel: user.translate('country'),
      fieldValue: '1',
      fieldOptions: countrysOptions,
      noNone: true,
    },
    {
      required: true,
      type: 'text',
      fieldType: 'text',
      fieldName: 'phone',
      fieldLabel: user.translate('phone'),
      fieldValue: '',
    },
    {
      required: false,
      type: 'text',
      fieldType: 'text',
      fieldName: 'website',
      fieldLabel: user.translate('website'),
      fieldValue: '',
    },
    {
      required: false,
      type: 'text',
      fieldType: 'text',
      fieldName: 'workingHours',
      fieldLabel: user.translate('workingHours'),
      fieldValue: '',
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'locationLat',
      fieldLabel: 'locationLat',
      fieldValue: '0',
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'locationLng',
      fieldLabel: 'locationLng',
      fieldValue: '0',
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'locationRadius',
      fieldLabel: 'locationRadius',
      fieldValue: '0',
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'employeeId',
      fieldLabel: 'employeeId',
      fieldValue: user.me.id,
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'lang',
      fieldLabel: 'lang',
      fieldValue: user.lang,
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'role',
      fieldLabel: 'role',
      fieldValue: user.role ? user.role : 0,
    },
  ]

  const onAddClick = (e) => {
    setOpenAddBox(true)
  }

  const subscriptionOrder = ['PREMIUM', 'CUSTOM', 'BASIC', 'FREE', 'NONE']

  const restaurantsBySubscription = Object.entries(props.restaurants.groupBy(({ restaurant }) => restaurant.subscription)).sort(
    ([subscription]) => subscriptionOrder.indexOf(subscription),
  )

  const restaurantsList = user.me.profile.isAdmin ? (
    <Stack spacing={2} direction="column" justifyContent="center" alignItems="stretch" sx={{ p: 2, m: 0 }}>
      {restaurantsBySubscription.map(([subscription, restaurants]) => {
        const restaurantViews = restaurants.map((employeeRestaurant) => {
          const restaurant = employeeRestaurant.restaurant
          return (
            <Grid
              item
              key={restaurant._id}
              style={{ display: 'flex', justifyContent: 'center' }}
              xs={If(size.isMobile, 12).else(Math.round(Math.max((240 * 12) / size.width, 1)))}
            >
              <RestaurantView
                restaurant={restaurant}
                employeeId={employeeRestaurant.employeeId}
                setOpenDeleteBox={setOpenDeleteBox}
                setDeleteState={setDeleteState}
                refetch={props.refetch}
              />
            </Grid>
          )
        })

        return (
          <React.Fragment key={`frag_${subscription}`}>
            <Divider key={`divider_${subscription}`}>
              {subscription ? (
                <Chip
                  label={
                    (subscription === 'NONE' ? user.translate('deactivated') : user.translate(subscription)) + ` (${restaurants.length})`
                  }
                  size="small"
                />
              ) : null}
            </Divider>
            <Grid key={`items_${subscription}`} item sx={{ filter: subscription === 'NONE' ? 'grayscale(0.9)' : 'none' }}>
              <Grid
                key={`items_${subscription}`}
                container
                xs={12}
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                sx={{ ml: -1, filter: subscription === 'NONE' ? 'grayscale(0.9)' : 'none' }}
              >
                {restaurantViews}
              </Grid>
            </Grid>
          </React.Fragment>
        )
      })}
    </Stack>
  ) : (
    <Grid container item xs={12} spacing={2} direction="row" justifyContent="center" alignItems="stretch" sx={{ pl: 2, pr: 2 }}>
      {props.restaurants.map((employeeRestaurant) => {
        const restaurant = employeeRestaurant.restaurant

        return (
          <Grid
            item
            key={restaurant._id}
            style={{ display: 'flex', justifyContent: 'center' }}
            xs={If(size.isMobile, 12).else(Math.round(Math.max((240 * 12) / size.width, 1)))}
          >
            <RestaurantView
              restaurant={restaurant}
              employeeId={employeeRestaurant.employeeId}
              setOpenDeleteBox={setOpenDeleteBox}
              setDeleteState={setDeleteState}
              refetch={props.refetch}
            />
          </Grid>
        )
      })}
    </Grid>
  )

  return (
    <>
      {restaurantsList}
      {user.role > 2 && (
        <SpeedDial ariaLabel="Add Warehouse" className={classes.speedDial} open={false} onClick={onAddClick} icon={<IconAdd />} />
      )}

      {openDeleteBox && (
        <Delete
          name={deleteState.name}
          variables={deleteState.variables}
          setOpenSnackBar={setOpenSnackBar}
          setOpenDeleteBox={setOpenDeleteBox}
          deleteItem={DELETE_ITEM}
          onSuccess={props.refetch}
        />
      )}

      {openSnackBar && (
        <SnackBarDelete
          hideUndo
          message={deleteState.name + ` ${user.translate('deleted')}!`}
          variables={deleteState.variables}
          openSnackBar={openSnackBar}
          setOpenSnackBar={setOpenSnackBar}
          unDeleteItem={UNDELETE_ITEM}
          onSuccess={props.refetch}
        />
      )}

      {openAddBox && (
        <Add
          fieldList={fieldList}
          openManipulateBox={setOpenAddBox}
          actionType={user.translate('add')}
          name={user.translate('restaurant')}
          manipulateItem={ADD_ITEM}
          manipulateItem1={ADD_CITY_ITEM}
          onSuccess={props.refetch}
        />
      )}
    </>
  )
}
