import React, { useContext, useState, useLayoutEffect, useEffect } from 'react'

import $ from 'jquery'

import { useQuery, useMutation, gql } from '@apollo/client'

import IconAdd from '@mui/icons-material/Add'
import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'
import { CircularProgress, Card, CardContent, IconButton, Button, Tooltip, Fab } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { RestaurantContext, UserContext } from '../../App'

// import DragHandleIcon from '@mui/icons-material/DragHandle';
import Add from '../common/Add'
import Delete from '../common/Delete'
import SnackBarDelete from '../common/SnackBarDelete'

import 'jquery-ui-bundle'
import 'jquery-ui-bundle/jquery-ui.min.css'
import './style.menu.css'
import ProductDialog from '../common/ProductDialog'
import helper from '../common/Helper'

// CSS
const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  speedDialGroup: {
    position: 'absolute',
    right: 0,
    top: `60px`,
  },
  addGroupItem: {
    float: 'right',
    padding: '3px',
    marginTop: '-3px',
  },
  itemCard: {
    display: 'block',
    overflow: 'unset',
    textAlign: 'left',
  },
  cardContent: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  cardHeader: {
    width: `inherit`,
    padding: `30px`,
    marginTop: '-30px',
    borderRadius: `10px`,
    boxShadow: `0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)`,
    backgroundColor: `#3f51b5`,
    color: `#fff`,
  },
  cardHeaderTitle: {
    width: `inherit`,
    padding: `30px`,
    margin: '-30px',
  },
  menuTitle: {
    padding: `5px 0`,
    fontSize: 12,
    display: 'block',
  },
  menuTitleWidth: {
    width: `77%`,
    textTransform: 'initial',
  },
  dragIcon: {
    float: `left`,
    paddingRight: theme.spacing(1),
  },
  cardHeaderIcons: {
    float: `right`,
    padding: '10px',
    marginTop: '-10px',
    width: '40px',
  },
  cardMenuIcons1: {
    right: `29px`,
    position: `absolute`,
    zIndex: '99',
    padding: '3px',
    marginTop: '-33px',
    width: '33px',
  },
  cardMenuIcons2: {
    right: `0`,
    position: `absolute`,
    zIndex: '99',
    padding: '3px',
    marginTop: '-33px',
    width: '33px',
  },
  itemName: {
    margin: 0,
    position: 'relative',
    flexGrow: 1,
  },
  titleName: {
    fontVariant: 'all-small-caps',
    fontSize: 18,
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
  },
  titleNameQuantity: {
    fontVariant: 'all-small-caps',
    fontSize: 14,
    marginBottom: 0,
    marginLeft: 15,
    fontStyle: 'italic',
  },
  group: {
    background: `#eee`,
    marginTop: theme.spacing(1),
    borderRadius: '10px',
    padding: theme.spacing(2),
    cursor: 'pointer',
  },
  mainProduct: {
    color: `#674ea7`,
  },
}))

// React Function Component
export default function MenuItemProducts(props) {
  const classes = useStyles()

  // Get current restaurant from context
  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const [openAddBox, setOpenAddBox] = useState(false)
  const [editGroup, setEditGroup] = useState(false)
  const [addGroup, setAddGroup] = useState(false)
  const [openEditBox, setOpenEditBox] = useState(false)
  const [openDeleteBox, setOpenDeleteBox] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [openSelectBox, setOpenSelectBox] = useState(false)
  const [selectedItem, setSelectedItem] = useState({
    product: {},
  })

  const [deleteState, setDeleteState] = useState({
    name: '',
    deleteItem: ``,
    undeleteItem: ``,
    variables: {},
  })

  const [editState, setEditState] = useState({
    groupItem: {},
    group: {},
    editItem: ``,
  })

  const ADD_GROUP = `
        mutation ($id: String!, $menuItemId: String!, $langId: String!, $name: String!, $displayIndex: Long!, $minQuantity: Float!, $maxQuantity: Float!,) {
            createMenuItemGroup(input:{
                id: $id
                ${user.gqlCreateName('$name', '$langId')}
                menuItemId: $menuItemId
                displayIndex: $displayIndex
                minQuantity: $minQuantity
                maxQuantity: $maxQuantity
            }){
                id
            }
        }`
  const EDIT_GROUP = `
        mutation ($id: String!, $menuItemId: String!, $langId: String!, $name: String!, $displayIndex: Long!, $minQuantity: Float!, $maxQuantity: Float!,) {
            createMenuItemGroup(input:{
                id: $id
                ${user.gqlEditName('$name', '$langId')}
                menuItemId: $menuItemId
                displayIndex: $displayIndex
                minQuantity: $minQuantity
                maxQuantity: $maxQuantity
            }){
                id
            }
        }`
  const EDIT_GROUP_ITEM = `
        mutation ($id: String!, $groupId: String!, $productId: String!, $displayIndex: Long!, $quantity: Float!, $mainProduct: Long!, $image: String) {
            createMenuItemGroupItem(input:{
                id: $id
                groupId: $groupId
                productId: $productId
                displayIndex: $displayIndex
                quantity: $quantity
                mainProduct: $mainProduct
                image: $image
            }){
                id
            }
        }`
  const UPDATE_GROUPS_DISPLAY_INDEX = gql`
    mutation ($indices: [InputDisplayIndex!]!) {
      extras_updateMenuItemGroupsDisplayIndex(indices: $indices)
    }
  `
  const [bundleEditGroup] = useMutation(UPDATE_GROUPS_DISPLAY_INDEX)

  const UPDATE_GROUP_ITEMS_DISPLAY_INDEX = gql`
    mutation ($indices: [InputDisplayIndex!]!) {
      extras_updateMenuItemGroupItemsDisplayIndex(indices: $indices)
    }
  `
  const [bundleEditGroupItem] = useMutation(UPDATE_GROUP_ITEMS_DISPLAY_INDEX)

  const DELETE_GROUP = `
        mutation ($id: String!) {
            deleteMenuItemGroup(id: $id)
        }`

  const DELETE_GROUP_ITEM = `
        mutation ($id: String!) {
            deleteMenuItemGroupItem(id: $id)
        }`

  const UNDELETE_GROUP = `
        mutation ($id: String!) {
            undeleteMenuItemGroup(id: $id)
        }`
  const UNDELETE_GROUP_ITEM = `
        mutation ($id: String!) {
            undeleteMenuItemGroupItem(id: $id)
        }`

  // @TODO: check all queries here for delete

  const EDIT_MENU_ITEM_PRICE = gql`
    mutation ($id: String!) {
      extras_updateMenuItemPrice(menuItemId: $id)
    }
  `
  const [editMenuItemPrice, { data: data_menu_price }] = useMutation(EDIT_MENU_ITEM_PRICE)

  // GraphQL API request definition (local variables: restaurantId)
  const GET_MENU_ITEM_PRODUCTS_BY_RESTAURANT = gql`
    query($menuItemId: String!) {
        getMenuItemById(id: $menuItemId) {
            name {
                ${user.gqlFetchName()}
            }
            price
            menuItemGroups {
                id
                menuItemId
                name {
                    ${user.gqlFetchName()}
                }
                displayIndex
                minQuantity
                maxQuantity
                groupMenuItemGroupItems {
                    id
                    product {
                        id
                        name
                        price
                    }
                    displayIndex
                    quantity
                    mainProduct
                    image
                }
            }
        }
    }`

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const { data, loading, refetch } = useQuery(GET_MENU_ITEM_PRODUCTS_BY_RESTAURANT, {
    variables: { menuItemId: props.menuItemId },
    pollInterval: 3000,
    errorPolicy: 'ignore',
  })

  const onAddClick = (e, group) => {
    let maxDisplayIndexGroupItem = 0
    if (group.groupMenuItemGroupItems !== 'undefined' && group.groupMenuItemGroupItems.length > 0) {
      maxDisplayIndexGroupItem = group.groupMenuItemGroupItems[group.groupMenuItemGroupItems.length - 1].displayIndex + 1
    }

    setEditGroup(false)
    setEditState({
      groupItem: {},
      group: {},
      groupId: group.id,
      maxDisplayIndexGroupItem,
      editItem: EDIT_GROUP_ITEM,
    })

    setOpenDeleteBox(false)
    setOpenEditBox(false)
    setOpenAddBox(false)
    setOpenSelectBox(true)
  }

  const onAddGroupClick = () => {
    setEditGroup(true)

    setEditState({
      groupItem: {},
      group: {},
      editItem: ADD_GROUP,
    })

    setOpenSelectBox(false)
    setOpenDeleteBox(false)
    setOpenEditBox(false)
    setOpenAddBox(true)
  }

  const onEditGroupClick = (e, group) => {
    setEditGroup(true)
    setEditState({
      groupItem: {},
      group,
      editItem: group.name === null ? ADD_GROUP : EDIT_GROUP,
    })

    setOpenSelectBox(false)
    setOpenAddBox(false)
    setOpenDeleteBox(false)
    setOpenEditBox(true)
  }

  const onDeleteGroupClick = (e, group) => {
    setDeleteState({
      name: group.name != null && group.name[user.lang] !== '' ? group.name[user.lang] : 'Group ' + group.displayIndex,
      variables: { id: group.id },
      deleteItem: DELETE_GROUP,
      undeleteItem: UNDELETE_GROUP,
    })

    setOpenSelectBox(false)
    setOpenAddBox(false)
    setOpenEditBox(false)
    setOpenDeleteBox(true)
  }

  const onEditGroupItemClick = (event, groupItem, groupId) => {
    setEditGroup(false)
    setEditState({
      groupItem,
      group: {},
      groupId,
      editItem: EDIT_GROUP_ITEM,
    })

    setOpenSelectBox(false)
    setOpenAddBox(false)
    setOpenDeleteBox(false)
    setOpenEditBox(true)
  }
  const onDeleteGroupItemClick = (event, groupItem) => {
    setDeleteState({
      name: groupItem.product.name,
      variables: { id: groupItem.id },
      deleteItem: DELETE_GROUP_ITEM,
      undeleteItem: UNDELETE_GROUP_ITEM,
    })

    setOpenSelectBox(false)
    setOpenAddBox(false)
    setOpenEditBox(false)
    setOpenDeleteBox(true)
  }

  useLayoutEffect(() => {
    let isMounted = true // note this flag denote mount status

    let placeholderHeight, placeholderAnimatorHeight

    const slides = $('.slides.slidesGroupItems')
    if (isMounted) {
      $(slides).sortable({
        placeholder: 'slide-placeholder',
        axis: 'y',
        revert: 150,
        start: function (event, ui) {
          placeholderHeight = ui.item.outerHeight()
          ui.placeholder.height(placeholderHeight + 15)
          $('<div class="slide-placeholder-animator" data-height="' + placeholderHeight + '"></div>').insertAfter(ui.placeholder)
        },
        change: function (event, ui) {
          ui.placeholder
            .stop()
            .height(0)
            .animate(
              {
                height: ui.item.outerHeight() + 15,
              },
              300,
            )

          placeholderAnimatorHeight = parseInt($('.slide-placeholder-animator').attr('data-height'))

          $('.slide-placeholder-animator')
            .stop()
            .height(placeholderAnimatorHeight + 15)
            .animate(
              {
                height: 0,
              },
              300,
              function () {
                $(this).remove()
                placeholderHeight = ui.item.outerHeight()
                $('<div class="slide-placeholder-animator" data-height="' + placeholderHeight + '"></div>').insertAfter(ui.placeholder)
              },
            )
        },
        stop: function (event, ui) {
          const indices = []
          // let sortable = $('.slides .groupItems');
          const sortable = $(event.target).find('.groupItems')
          const count = sortable.length

          let min = 0
          for (let i = 0; i <= count - 1; i++) {
            min = min > i ? min : i
            sortable[i].setAttribute('displayIndex', min)

            indices.push({
              id: sortable[i].getAttribute('id'),
              index: min,
            })
          }
          bundleEditGroupItem({ variables: { indices } })

          $('.slide-placeholder-animator').remove()
        },
      })
    }

    const slidesGroup = $('.slides.slidesGroup')
    if (isMounted) {
      $(slidesGroup).sortable({
        placeholder: 'slide-placeholder',
        axis: 'y',
        revert: 150,
        start: function (event, ui) {
          placeholderHeight = ui.item.outerHeight()
          ui.placeholder.height(placeholderHeight + 15)
          $('<div class="slide-placeholder-animator" data-height="' + placeholderHeight + '"></div>').insertAfter(ui.placeholder)
        },
        change: function (event, ui) {
          ui.placeholder
            .stop()
            .height(0)
            .animate(
              {
                height: ui.item.outerHeight() + 15,
              },
              300,
            )

          placeholderAnimatorHeight = parseInt($('.slide-placeholder-animator').attr('data-height'))

          $('.slide-placeholder-animator')
            .stop()
            .height(placeholderAnimatorHeight + 15)
            .animate(
              {
                height: 0,
              },
              300,
              function () {
                $(this).remove()
                placeholderHeight = ui.item.outerHeight()
                $('<div class="slide-placeholder-animator" data-height="' + placeholderHeight + '"></div>').insertAfter(ui.placeholder)
              },
            )
        },
        stop: function (event, ui) {
          const indices = []
          const sortable = $('.slides .group')
          const count = sortable.length

          let min = 0
          for (let i = 0; i <= count - 1; i++) {
            min = min > i ? min : i
            sortable[i].setAttribute('displayIndex', min)

            indices.push({
              id: sortable[i].getAttribute('id'),
              index: min,
            })
          }
          bundleEditGroup({ variables: { indices } })

          $('.slide-placeholder-animator').remove()
        },
      })
    }

    return () => {
      isMounted = false
    } // use effect cleanup to set flag false, if unmounted
  })

  useEffect(() => {
    if (loading) return

    const menuItem = data?.getMenuItemById
    if (!menuItem) return

    const menuItemGroups = menuItem ? menuItem.menuItemGroups.slice().sort((a, b) => a.displayIndex - b.displayIndex) : []

    let total = 0.0
    menuItemGroups.forEach((group) => {
      const groupItems = group.groupMenuItemGroupItems.orderBy((it) => it.product.price)
      let quantity = group.minQuantity

      while (quantity > 0 && groupItems.length > 0) {
        const groupItem = groupItems.find((it) => it.quantity > 0) || groupItems.find((it) => it.mainProduct) || groupItems[0]
        if (groupItem === undefined) break

        const productQuantity = Math.min(Math.max(groupItem.quantity, 1), quantity)
        total += productQuantity * (groupItem.product?.price ?? 0)
        quantity -= productQuantity
        groupItems.splice(groupItems.indexOf(groupItem), 1)
      }
    })

    const price = data_menu_price && data_menu_price.editMenuItem ? data_menu_price.editMenuItem.price : menuItem.price

    if (total && total !== price) {
      const items = {
        id: props.menuItemId,
        price: total,
      }

      console.log('newPrice', total)

      editMenuItemPrice({ variables: items }).then(() => {
        refetch()
      })
    }
  }, [data?.getMenuItemById, data_menu_price, editMenuItemPrice, loading, props.menuItemId, refetch])

  // If it is loading, show progress bar
  // if (loading) return <CircularProgress />
  // if (loading) {
  //     return (<div className="App AppLoading"><CircularProgress /></div>);
  // }

  // In case there is an error, just show it for now
  if (!data) {
    return <p>&nbsp;</p>
  }

  const menuItem = data.getMenuItemById
  const menuItemGroups = menuItem ? menuItem.menuItemGroups.slice().sort((a, b) => a.displayIndex - b.displayIndex) : []
  const maxDisplayIndexGroup = menuItemGroups.length > 0 ? menuItemGroups[menuItemGroups.length - 1].displayIndex + 1 : 0
  let groupOptions = []
  let firstGroup = []
  if (menuItemGroups.length > 0) {
    groupOptions = menuItemGroups.map((group) => ({
      val: group.id,
      label:
        group.name != null && typeof group.name[user.lang] !== 'undefined' && group.name[user.lang] !== ''
          ? group.name[user.lang]
          : 'Group ' + group.displayIndex,
    }))
    firstGroup = groupOptions.slice(0, 1)
  }

  const minQuantityOptions = []
  const maxQuantityOptions = []
  for (let i = 0; i <= 100; i++) {
    minQuantityOptions.push({
      val: i,
      label: '' + i,
    })
    maxQuantityOptions.push({
      val: i,
      label: '' + i,
    })
  }

  const groupFieldList = [
    {
      required: false,
      type: 'text',
      fieldType: 'text',
      fieldName: 'name',
      fieldLabel: user.translate('title'),
      fieldValue: typeof editState.group.name !== 'undefined' && editState.group.name != null ? editState.group.name[user.lang] : '',
      null: true,
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'langId',
      fieldLabel: 'langId',
      fieldValue: typeof editState.group.name !== 'undefined' && editState.group.name != null ? editState.group.name.id : helper.uid(),
      nullLangId: true,
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'menuItemId',
      fieldLabel: 'menuItemId',
      fieldValue: props.menuItemId,
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'displayIndex',
      fieldLabel: 'displayIndex',
      fieldValue: typeof editState.group.displayIndex !== 'undefined' ? parseInt(editState.group.displayIndex) : 0,
    },
    {
      required: true,
      type: 'number',
      fieldType: 'text',
      fieldName: 'minQuantity',
      fieldLabel: user.translate('min_quantity'),
      fieldValue: typeof editState.group.minQuantity !== 'undefined' ? '' + editState.group.minQuantity : '1',
      decimalScale: 3,
    },
    {
      required: true,
      type: 'number',
      fieldType: 'text',
      fieldName: 'maxQuantity',
      fieldLabel: user.translate('max_quantity'),
      fieldValue: typeof editState.group.maxQuantity !== 'undefined' ? '' + editState.group.maxQuantity : '1',
      decimalScale: 3,
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'id',
      fieldLabel: 'id',
      fieldValue: typeof editState.group.id !== 'undefined' ? editState.group.id : helper.uid(),
    },
  ]
  const groupItemFieldList = [
    {
      required: false,
      type: 'file',
      fieldType: 'upload',
      fieldName: 'image',
      fieldLabel: user.translate('image'),
      fieldValue: typeof editState.groupItem.id !== 'undefined' ? editState.groupItem.image : '',
    },
    {
      required: true,
      type: 'text',
      fieldType: 'text',
      fieldName: 'name',
      fieldLabel: user.translate('title'),
      fieldValue: typeof editState.groupItem.product !== 'undefined' ? editState.groupItem.product.name : '',
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'productId',
      fieldLabel: 'productId',
      fieldValue: typeof editState.groupItem.product !== 'undefined' ? editState.groupItem.product.id : '',
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'select',
      fieldName: 'groupId',
      fieldLabel: user.translate('group'),
      fieldValue:
        typeof editState.groupId !== 'undefined'
          ? editState.groupId
          : typeof firstGroup[0] !== 'undefined'
            ? firstGroup[0].val.toString()
            : '',
      fieldOptions: groupOptions,
      noNone: true,
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'displayIndex',
      fieldLabel: 'displayIndex',
      fieldValue: typeof editState.groupItem.displayIndex !== 'undefined' ? parseInt(editState.groupItem.displayIndex) : 1,
    },
    {
      required: true,
      type: 'number',
      fieldType: 'text',
      fieldName: 'quantity',
      fieldLabel: user.translate('quantity'),
      fieldValue: typeof editState.groupItem.quantity !== 'undefined' ? '' + editState.groupItem.quantity : '1',
      decimalScale: 3,
    },
    {
      required: true,
      type: 'radio',
      fieldType: 'radio',
      fieldName: 'mainProduct',
      fieldLabel: user.translate('main_product'),
      fieldValue: typeof editState.groupItem.mainProduct !== 'undefined' ? '' + editState.groupItem.mainProduct : '0',
      fieldOptions: [
        { val: '0', label: user.translate('no') },
        { val: '1', label: user.translate('yes') },
      ],
    },
    {
      required: true,
      type: 'hidden',
      fieldType: 'text',
      fieldName: 'id',
      fieldLabel: 'id',
      fieldValue: typeof editState.groupItem.id !== 'undefined' ? editState.groupItem.id : helper.uid(),
    },
  ]

  const renderAdd = (fieldList, maxDisplayIndexGroup) => {
    if (!editGroup) {
      fieldList[1].fieldValue = selectedItem.product.name
      fieldList[2].fieldValue = selectedItem.product.id
      fieldList[4].fieldValue = editState.maxDisplayIndexGroupItem
    } else {
      fieldList[3].fieldValue = maxDisplayIndexGroup
    }

    const itemName = editGroup ? `${user.translate('new')} ${user.translate('group')}` : user.translate('menu_item_product')

    const params = {
      fieldList,
      openManipulateBox: setOpenAddBox,
      actionType: user.translate('add'),
      name: itemName,
      restaurant,
      manipulateItem: editState.editItem,
      onSuccess: refetch,
      line: true,
    }

    if (editGroup) return <Add {...params} />
    else return <Add {...params} product />
  }

  const renderEdit = (fieldList) => {
    const params = {
      fieldList,
      openManipulateBox: setOpenEditBox,
      actionType: user.translate('edit'),
      restaurant,
      manipulateItem: addGroup ? ADD_GROUP : editState.editItem,
      onSuccess: refetch,
      line: true,
      setAddGroup,
      key: addGroup,
    }

    if (editGroup)
      return <Add {...params} name={editState.group.name != null ? editState.group.name[user.lang] : editState.group.displayIndex} />
    else return <Add {...params} name={editState.groupItem.product.name} product />
  }

  return (
    <React.Fragment>
      <Card className={classes.itemCard}>
        <CardContent className={classes.cardContent}>
          <div className={classes.titleName}>
            {user.translate('menu_item_products')} <u>{menuItem && typeof menuItem.name !== 'undefined' ? menuItem.name[user.lang] : ''}</u>
            :
            <Fab ariaLabel="Add Menu Item Group" color="primary" className={classes.speedDial} onClick={onAddGroupClick}>
              <IconAdd />
            </Fab>
          </div>

          <div className="slides slidesGroup">
            {/* {loading && (
                            <div className="App AppLoading"><CircularProgress /></div>
                        )} */}
            {!loading &&
              menuItemGroups.length > 0 &&
              menuItemGroups.map((group, index) => (
                <div className={`${classes.group} group`} id={group.id} key={group.id + index + props.menuItemId}>
                  <div className={classes.titleName}>
                    {(group.name && group.name[user.lang]) || `Group ${group.displayIndex}`}
                    <span className={classes.titleNameQuantity}>{`${user.translate('quantity')}: ${group.minQuantity} - ${
                      group.maxQuantity
                    }`}</span>
                    <IconButton size="small" color="primary" className={classes.addGroupItem} onClick={(e) => onDeleteGroupClick(e, group)}>
                      <IconDelete />
                    </IconButton>
                    <IconButton size="small" color="primary" className={classes.addGroupItem} onClick={(e) => onEditGroupClick(e, group)}>
                      <IconEdit />
                    </IconButton>
                    <IconButton size="small" color="primary" className={classes.addGroupItem} onClick={(e) => onAddClick(e, group)}>
                      <IconAdd />
                    </IconButton>
                  </div>

                  <div className="slides slidesGroupItems">
                    {group.groupMenuItemGroupItems
                      .slice()
                      .sort((a, b) => a.displayIndex - b.displayIndex)
                      .map((groupItem, index) => {
                        return (
                          <div
                            className={`${classes.itemName} groupItems`}
                            // displayIndex={groupItem.displayIndex}
                            id={groupItem.id}
                            key={group.id + index + groupItem.id}
                          >
                            <Tooltip
                              title={`${user.translate('drag_to_reorder')} ` + (groupItem.product?.name ?? 'n/a')}
                              placement="bottom-start"
                              className={classes.menuTitle}
                            >
                              <Button color="inherit" component="subtitle1">
                                {/* <DragHandleIcon className={`${classes.dragIcon}`} /> */}
                                <div className={`${classes.menuTitleWidth} ${groupItem.mainProduct ? classes.mainProduct : ''}`}>
                                  {groupItem.product?.name ?? 'n/a'}
                                  <span className={classes.titleNameQuantity}>{`${user.translate('quantity')}: ${
                                    groupItem.quantity
                                  }`}</span>
                                </div>
                              </Button>
                            </Tooltip>

                            <Tooltip
                              title={`${user.translate('edit')} ` + (groupItem.product?.name ?? 'n/a')}
                              placement="bottom-start"
                              className={classes.cardMenuIcons1}
                            >
                              <IconButton size="small" color="primary" onClick={(e) => onEditGroupItemClick(e, groupItem, group.id)}>
                                <IconEdit />
                              </IconButton>
                            </Tooltip>

                            <Tooltip
                              title={`${user.translate('delete')} ` + (groupItem.product?.name ?? 'n/a')}
                              placement="bottom-start"
                              className={classes.cardMenuIcons2}
                            >
                              <IconButton size="small" color="primary" onClick={(e) => onDeleteGroupItemClick(e, groupItem)}>
                                <IconDelete />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )
                      })}
                  </div>
                </div>
              ))}
          </div>
        </CardContent>
      </Card>

      {openDeleteBox && (
        <Delete
          name={deleteState.name}
          variables={deleteState.variables}
          setOpenSnackBar={setOpenSnackBar}
          setOpenDeleteBox={setOpenDeleteBox}
          deleteItem={deleteState.deleteItem}
          onSuccess={refetch}
        />
      )}

      {openSnackBar && (
        <SnackBarDelete
          message={deleteState.name + ` ${user.translate('deleted')}!`}
          variables={deleteState.variables}
          openSnackBar={openSnackBar}
          setOpenSnackBar={setOpenSnackBar}
          unDeleteItem={deleteState.undeleteItem}
          onSuccess={refetch}
        />
      )}

      {openAddBox && renderAdd(editGroup ? groupFieldList : groupItemFieldList, maxDisplayIndexGroup)}

      {openEditBox && renderEdit(editGroup ? groupFieldList : groupItemFieldList)}

      {openSelectBox && (
        <ProductDialog
          restaurant={restaurant}
          setOpenSelectBox={setOpenSelectBox}
          setSelectedItem={setSelectedItem}
          action={setOpenAddBox}
        />
      )}
    </React.Fragment>
  )
}
