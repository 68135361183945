import React, { useContext, useState } from 'react'

import { gql, useMutation, useQuery } from '@apollo/client'

import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Avatar,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Snackbar,
} from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'

import { RestaurantContext, UserContext } from '../../App'
import Add from '../common/Add'
import helper from '../common/Helper'

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    '& .MuiTableCell-root .MuiIconButton-root': {
      padding: 2,
    },
  },
  tabs: {
    display: `grid`,
  },
  gridList: {
    flexGrow: 1,
    width: `100%`,
  },
  chipRoot: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
  },
  configContent: {
    paddingRight: theme.spacing(4),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
  },
  details: {
    alignItems: 'center',
  },
  columnLeft: {
    flexGrow: 1,
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'left',
  },
  columnRight: {
    flexGrow: 2,
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'left',
  },
}))

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    justifyContent: 'flex-end',
    flex: 1,
  },
}))(MuiAccordionDetails)

export default function RestaurantConfigTxt(props) {
  const classes = useStyles()

  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const [openSnackBarDelete, setOpenSnackBarDelete] = useState(false)
  const [expanded, setExpanded] = React.useState('')

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState()
  const [openEditDialog, setOpenEditDialog] = React.useState()

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const EDIT_ITEM = gql`
    mutation ($id: String!, $restaurantId: String!, $config: String!, $lang: String!, $value: String!) {
      createRestaurantConfigTxt(input: { id: $id, restaurantId: $restaurantId, config: $config, value: $value, lang: $lang }) {
        id
      }
    }
  `
  const [editItem, { loading: loading_edit }] = useMutation(EDIT_ITEM)

  const DELETE_ITEM = gql`
    mutation ($id: String!) {
      deleteRestaurantConfigTxt(id: $id)
    }
  `
  const [deleteItem, { loading: loading_delete }] = useMutation(DELETE_ITEM)

  // GraphQL API request definition (local variables: restaurantId)
  const GET_CONFIG_BY_RESTAURANT = gql`
    query ($restaurantId: String!) {
      getRestaurantConfigTxtsByContextRestaurantId(restaurantId: $restaurantId) {
        id
        value
        config
        lang
      }
    }
  `

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const { data, loading, error, refetch } = useQuery(GET_CONFIG_BY_RESTAURANT, {
    variables: { restaurantId: restaurant.id },
    pollInterval: 10000,
  })

  if (loading) {
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    )
  }

  // In case there is an error, just show it for now
  // if (!data) { user.consoleLog(error); return <p>Error</p> }

  const defaultConfigs = [
    { id: helper.uid(), title: user.translate('INVOICE_FOOTER'), config: 'INVOICE_FOOTER' },
    { id: helper.uid(), title: user.translate('APP_ARTICLE_SEARCH_HINT'), config: 'APP_ARTICLE_SEARCH_HINT' },
  ]

  const results = data.getRestaurantConfigTxtsByContextRestaurantId
  user.consoleLog(results)

  const onItemEditClick = (config, availableLang) => (e) => {
    e.stopPropagation()
    console.log(config)
    if (config.lang === undefined) {
      config.lang = availableLang[0].val
    }
    setOpenEditDialog(config)
  }

  const onItemDeleteClick = (config) => () => {
    setOpenDeleteDialog(config)
  }

  const handleDelete = () => {
    if (openDeleteDialog === undefined || openDeleteDialog === false) return
    deleteItem({
      variables: {
        id: openDeleteDialog.id,
      },
    }).then((res) => {
      setOpenDeleteDialog(false)
      setOpenSnackBarDelete(openDeleteDialog)
      refetch()
    })
  }

  const handleCloseDelete = () => {
    setOpenDeleteDialog(false)
  }

  const langFieldOptions = [
    { val: 'MK', label: 'Македонски' },
    { val: 'EN', label: 'English' },
  ]

  const fieldListConfig = openEditDialog
    ? [
        {
          required: true,
          type: 'hidden',
          fieldType: 'text',
          fieldName: 'id',
          fieldLabel: 'id',
          fieldValue: openEditDialog?.id || helper.uid(),
        },
        {
          required: true,
          type: 'hidden',
          fieldType: 'text',
          fieldName: 'config',
          fieldLabel: 'config',
          fieldValue: openEditDialog?.config,
        },
        {
          required: true,
          type: 'text',
          fieldType: 'select',
          fieldName: 'lang',
          fieldLabel: user.translate('lang'),
          fieldValue: openEditDialog?.lang || 'MK',
          fieldOptions: langFieldOptions,
          noNone: true,
        },
        {
          required: true,
          type: 'text',
          fieldType: 'textarea',
          fieldName: 'value',
          fieldLabel: user.translate('value'),
          multiline: true,
          fieldValue: openEditDialog?.value || user.translate(openEditDialog?.config),
        },
      ]
    : []

  return (
    <div>
      {defaultConfigs.map((config) => {
        const configs = results.filter((c) => c.config === config.config)

        const availableLang = langFieldOptions.filter((lang) => configs.findIndex((c) => c.lang === lang.val) === -1)

        return (
          <Accordion
            square
            expanded={expanded === config.config}
            key={'config_text+' + config.config}
            onChange={handleChange(config.config)}
          >
            <AccordionSummary aria-controls={`panel1d-${config.config}`} id={config.config} expandIcon={<ExpandMoreIcon />}>
              <div className={classes.columnLeft}>
                <Typography className={classes.heading}>{config.title}</Typography>
              </div>
              <div className={classes.columnRight}>
                <ul className={classes.chipRoot}>
                  {configs.map((config) => (
                    <li key={config.lang}>
                      <Chip label={config.lang} onDelete={onItemDeleteClick(config)} className={classes.chip} />
                    </li>
                  ))}
                </ul>
              </div>
              <IconButton
                edge="end"
                aria-label="delete"
                style={{ marginRight: 10 }}
                onClick={onItemEditClick(config, availableLang)}
                disabled={availableLang.length === 0}
                size="large"
              >
                <AddIcon />
              </IconButton>
            </AccordionSummary>
            <AccordionDetails>
              <List style={{ flex: 1 }}>
                {configs.map((langConfig) => (
                  <ListItem key={'config_text' + langConfig.config + langConfig.lang}>
                    <ListItemAvatar>
                      <Avatar>
                        <Typography>{langConfig.lang}</Typography>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText className={classes.configContent} primary={langConfig.value} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" onClick={onItemEditClick(langConfig)} size="large">
                        <EditIcon />
                      </IconButton>
                      <IconButton edge="end" aria-label="delete" onClick={onItemDeleteClick(langConfig)} size="large">
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        )
      })}

      <Dialog
        open={Boolean(openDeleteDialog)}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{user.translate('delete')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {openDeleteDialog
              ? `${user.translate('delete_accept')} '${user.translate(openDeleteDialog.config)}' (${openDeleteDialog.lang})`
              : ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} color="primary">
            {user.translate('delete')}
          </Button>
          <Button onClick={handleCloseDelete} color="primary" autoFocus>
            {user.translate('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      {Boolean(openEditDialog) && (
        <Add
          fieldList={fieldListConfig}
          openManipulateBox={setOpenEditDialog}
          actionType={user.translate('add')}
          restaurant={restaurant}
          name={user.translate('invoice_item_product')}
          manipulateItem={EDIT_ITEM}
          onSuccess={refetch}
        />
      )}

      {Boolean(openSnackBarDelete) && (
        <Snackbar
          key={`delete_snack_${openSnackBarDelete.config}`}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(openSnackBarDelete)}
          autoHideDuration={3000}
          onClose={() => setOpenSnackBarDelete(false)}
          message={`'${user.translate(openSnackBarDelete.config)}' (${openSnackBarDelete.lang}) ${user.translate('deleted')}!`}
          action={
            <>
              <IconButton
                aria-label="close"
                color="inherit"
                className={classes.close}
                onClick={() => setOpenSnackBarDelete(false)}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </>
          }
          TransitionProps={{
            onExited: () => setOpenSnackBarDelete(false),
          }}
        />
      )}
    </div>
  )
}
