import { useCallback, useContext, useState } from 'react'

import { gql, useMutation, useQuery } from '@apollo/client'

import { RestaurantContext, UserContext } from '../../App'
import helper from '../common/Helper'

const GQL_GET_SUPPLY_NUMBERS = gql`
  query ($restaurantId: String!, $match: String!) {
    getStockSupplysByContextRestaurantId(restaurantId: $restaurantId, filter: { by: "supplyingNumber", like: $match }) {
      supplyingNumber
    }
  }
`

export default function useSupplyNumberQuery() {
  const restaurant = useContext(RestaurantContext)

  const { refetch: fetchSupplyNumbers } = useQuery(GQL_GET_SUPPLY_NUMBERS, {
    skip: true,
    variables: {
      restaurantId: restaurant.id,
      match: `%/${new Date().getFullYear()}`,
    },
  })

  const getNextSupplyNumber = useCallback(
    (itemData) =>
      new Promise((resolve, reject) => {
        if (itemData.supplyingNumber) return resolve(itemData.supplyingNumber)
        return fetchSupplyNumbers()
          .then(({ data }) => {
            let maxNumber = 0
            data.getStockSupplysByContextRestaurantId.forEach((item) => {
              const supNumber = parseInt(item.supplyingNumber.split('/')[0])
              if (supNumber && !isNaN(supNumber) && supNumber > maxNumber) {
                maxNumber = supNumber
              }
            })

            resolve(`${maxNumber + 1}/${new Date().getFullYear()}`)
          })
          .catch(reject)
      }),
    [restaurant.id],
  )

  return { getNextSupplyNumber }
}

const GQL_SAVE_ITEM = gql`
  mutation (
    $id: String!
    $restaurantId: String!
    $supplierId: String!
    $employeeId: String!
    $supplyingNumber: String!
    $date: String!
    $comment: String!
    $state: String
    $receivingId: String
    $updated: Long!
  ) {
    createStockSupply(
      input: {
        id: $id
        restaurantId: $restaurantId
        supplierId: $supplierId
        employeeId: $employeeId
        supplyingNumber: $supplyingNumber
        date: $date
        comment: $comment
        state: $state
        updated: $updated
        receivingId: $receivingId
      }
    ) {
      id
    }
  }
`

const DELETE_ITEM = gql`
  mutation ($id: String!) {
    deleteStockSupply(id: $id)
  }
`

const GQL_SAVE_STOCK_SUPPLY_ITEM = gql`
  mutation (
    $id: String!
    $stockSupplyId: String!
    $warehouseId: String
    $stockTypeId: String!
    $quantity: Float!
    $uomOpts: Long!
    $comment: String!
    $updated: Long!
  ) {
    createStockSupplyItem(
      input: {
        id: $id
        stockSupplyId: $stockSupplyId
        warehouseId: $warehouseId
        stockTypeId: $stockTypeId
        quantity: $quantity
        uomOpts: $uomOpts
        comment: $comment
        updated: $updated
      }
    ) {
      _id
      id
    }
  }
`

const GQL_DELETE_STOCK_SUPPLY_ITEM = gql`
  mutation ($id: String!) {
    deleteStockSupplyItem(id: $id)
  }
`

export function useSupply(props) {
  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const [openSnackBarWarning, setOpenSnackBarWarning] = useState(false)

  const { getNextSupplyNumber } = useSupplyNumberQuery()

  const [saveSupply, { loading: loadingSave }] = useMutation(GQL_SAVE_ITEM)
  const [deleteSupply, { loading: loadingDelete }] = useMutation(DELETE_ITEM)

  const [saveSupplyItem, { loading: loadingSaveItem }] = useMutation(GQL_SAVE_STOCK_SUPPLY_ITEM)
  const [deleteSupplyItem, { loading: loadingDeleteItem }] = useMutation(GQL_DELETE_STOCK_SUPPLY_ITEM)

  const refetchSupplies =
    props?.refetch || (() => new Promise((resolve) => console.log('props.refetch not implemented in `useSupply`') || resolve()))

  const onSaveSupply = (itemData, autoRefetch = true) =>
    new Promise((resolve, reject) => {
      getNextSupplyNumber(itemData)
        .then((supplyingNumber) => {
          const data = {
            id: itemData.id || helper.uid(),
            restaurantId: restaurant.id,
            supplierId: itemData.supplierId,
            employeeId: user.me.id,
            supplyingNumber,
            receivingId: itemData.receivingId || null,
            date: itemData.date || new Date().toISODate(),
            comment: '',
            updated: new Date().getTime(),
            state: itemData.state || 'OPEN',
          }
          saveSupply({ variables: data })
            .then((e) => {
              if (autoRefetch) {
                refetchSupplies()
                  .then(() => resolve(data))
                  .catch(reject)
              } else {
                resolve(data)
              }
            })
            .catch((e) => {
              setOpenSnackBarWarning({
                alertMessage: e.networkError.result.errors.map((x) => x.message).join(),
                autoHideDuration: 3000,
                linkTo: ``,
                linkText: '',
              })
              reject(e)
            })
        })
        .catch(reject)
    })

  const onSaveSupplyItem = (itemData) =>
    new Promise((resolve, reject) => {
      return saveSupplyItem({
        variables: {
          id: itemData.id || helper.uid(),
          stockSupplyId: itemData.stockSupplyId,
          warehouseId: itemData.warehouseId,
          stockTypeId: itemData.stockTypeId,
          quantity: itemData.quantity,
          uomOpts: itemData.uomOpts || 0,
          comment: itemData.comment || '',
          updated: new Date().getTime(),
        },
      })
        .then(() => resolve(itemData))
        .catch(reject)
    })

  const onDeleteSupply = (itemData, autoRefetch = true) => {
    return deleteSupply({ variables: { id: itemData.id } })
      .then(() => {
        if (autoRefetch) {
          refetchSupplies()
        }
      })
      .catch((e) => {
        setOpenSnackBarWarning({
          alertMessage: e.networkError.result.errors.map((x) => x.message).join(),
          autoHideDuration: 3000,
          linkTo: ``,
          linkText: '',
        })
        throw e
      })
  }

  const onDeleteSupplyItem = (itemId, autoRefetch = true) =>
    deleteSupplyItem({ variables: { id: itemId } }).then(() => {
      if (autoRefetch) {
        refetchSupplies()
      }
    })

  const getOrCreateSupply = (supplierId) => {
    const supply = props.orders?.find((item) => item.state === 'OPEN' && item.supplierId === supplierId)

    if (supply) return new Promise((resolve) => resolve(supply))

    return onSaveSupply({ supplierId }, false)
  }

  const onSetItemSupply = (supplierId, stockTypeId, quantity, uomOpts) =>
    new Promise((resolve, reject) => {
      getOrCreateSupply(supplierId)
        .then((supply) => {
          const existing = (supply.stockSupplyItems && supply.stockSupplyItems.filter((item) => item.stockTypeId === stockTypeId)) || []
          if (quantity === 0) {
            if (existing.length > 0) {
              return existing
                .reducePromise((item) => onDeleteSupplyItem(item.id, false))
                .then(refetchSupplies)
                .then(resolve)
                .catch(reject)
            }
            return resolve()
          }

          let promise = existing.reducePromise((item) => onDeleteSupplyItem(item.id, false), 1)

          const item = {
            ...(existing[0] || {}),
            stockSupplyId: supply.id,
            stockTypeId,
            uomOpts,
            quantity,
          }

          promise = promise ? promise.then(() => onSaveSupplyItem(item)) : onSaveSupplyItem(item)

          promise.then(refetchSupplies).then(resolve).catch(reject)
        })
        .catch(reject)
    })

  return {
    isLoading: loadingSave || loadingDelete || loadingSaveItem || loadingDeleteItem,
    warning: openSnackBarWarning,
    saveSupply: onSaveSupply,
    deleteSupply: onDeleteSupply,
    saveSupplyItem: onSaveSupplyItem,
    deleteSupplyItem: onDeleteSupplyItem,
    setItemSupply: onSetItemSupply,
  }
}
