import React, { useContext, useRef, useState } from 'react'

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import Button from '@mui/material/Button'

import { UserContext } from '../../App'

export default function useConfirmDialog() {
  const user = useContext(UserContext)

  const promiseRef = useRef()
  const [showDialog, setShowDialog] = useState(false)

  const onClose = () => {
    setShowDialog(false)
    if (promiseRef.current) {
      promiseRef.current.reject()
      promiseRef.current = undefined
    }
  }

  const onShow = (props) => {
    return new Promise((resolve, reject) => {
      promiseRef.current = { resolve, reject }
      setShowDialog(props)
    })
  }

  const onShowConfirm = (action, context, callback, ...params) =>
    onShow({
      title: user.translate(action),
      message: user.translate(`${action}_accept_${context}`, ...params),

      positiveAction: {
        title: user.translate(action),
      },
      cancelButton: true,
    })
      .then(() => callback())
      .catch((e) => console.error('canceled', e))

  const handleAction = (action) => (e) => {
    if (action.onClick) {
      action.onClick()
    }
    if (promiseRef.current === undefined) return
    if (action === 'cancel') {
      promiseRef.current.reject()
    } else if (action === showDialog.positiveAction) {
      promiseRef.current.resolve(true)
    } else if (action === showDialog.negativeAction) {
      promiseRef.current.resolve(false)
    } else {
      promiseRef.current.reject()
    }
    promiseRef.current = undefined
    setShowDialog(false)
  }

  const render = () =>
    showDialog && (
      <Dialog open={Boolean(showDialog)} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{showDialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{showDialog.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {showDialog.negativeAction && (
            <Button onClick={handleAction(showDialog.negativeAction)} color="secondary">
              {showDialog.negativeAction.title}
            </Button>
          )}
          {showDialog.neutralAction && <Button onClick={handleAction(showDialog.neutralAction)}>{showDialog.neutralAction.title}</Button>}
          {showDialog.cancelButton && <Button onClick={handleAction('cancel')}>{user.translate('cancel')}</Button>}
          {showDialog.positiveAction && (
            <Button onClick={handleAction(showDialog.positiveAction)}>{showDialog.positiveAction.title}</Button>
          )}
        </DialogActions>
      </Dialog>
    )

  return {
    render,
    show: onShow,
    showConfirm: onShowConfirm,
  }
}
