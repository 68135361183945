import { useContext, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { gql, useMutation, useQuery } from '@apollo/client'

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, MenuItem } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import makeStyles from '@mui/styles/makeStyles'
import { DatePicker } from '@mui/x-date-pickers'

import { RestaurantContext, UserContext } from '../../App'
import helper from '../common/Helper'

const useStyles = makeStyles((theme) => ({
  roots: {
    '& .MuiTableCell-root .MuiIconButton-root': {
      padding: theme.spacing(1),
    },
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'wrap',
    padding: 0,
    justifyContent: 'space-evenly',
  },
  item: {
    width: '100%',
    maxWidth: 320,
    minWidth: 280,
    margin: theme.spacing(1),
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '& > *': {
      height: 22,
      display: 'flex',
      alignItems: 'flex-end',
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  iconInactive: {
    marginRight: theme.spacing(1),
    opacity: 0.4,
  },
  checkedLabel: {
    marginRight: 0,
    '& .MuiFormControlLabel-label': {
      fontSize: 12,
    },
  },
  progress: {
    // paddingLeft: theme.spacing(1),
  },
  stateSection: {
    marginLeft: theme.spacing(2),
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  loading: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    margin: theme.spacing(2),
  },
  dialogContent: {
    minWidth: 330,
    [theme.breakpoints.down('md')]: {
      minWidth: 220,
    },
  },
}))

const GQL_INVENTORY_COUNTING_CREATE = gql`
  mutation ($id: String!, $warehouseId: String!, $employeeId: String!, $date: String!) {
    createInventoryCounting(input: { id: $id, warehouseId: $warehouseId, employeeId: $employeeId, date: $date }) {
      id
    }
  }
`

// React Function Component
export default function InventoryCountingDialog(props) {
  const classes = useStyles()

  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const navigate = useNavigate()

  const [warehouseId, setWarehouseId] = useState(props.item?.warehouseId)
  const [date, setDate] = useState(parseInt(props.item?.date) || new Date().getTime())

  const { data: dataWarehouses, loading: loadingWarehouses } = useQuery(
    gql`
        query($restaurantId: String!){
            getWarehousesByContextRestaurantId(restaurantId: $restaurantId) {
                id
                name { 
                    ${user.lang}
                }
            }
        }  
    `,
    {
      variables: {
        restaurantId: restaurant.id,
      },
    },
  )
  const [saveItem, { loading: loadingSave }] = useMutation(GQL_INVENTORY_COUNTING_CREATE)

  if (!dataWarehouses || loadingWarehouses) return <></>

  const warehouses = dataWarehouses.getWarehousesByContextRestaurantId

  if (!warehouseId && warehouses.length > 0) {
    setWarehouseId(warehouses[0].id)
    return <></>
  }

  const handleClose = () => {
    props.onClose(false)
  }

  const onSaveClick = () => {
    saveItem({
      variables: {
        id: props.item?.id || helper.uid(),
        warehouseId,
        employeeId: user.me.id,
        date: new Date(date - new Date().getTimezoneOffset() * 60000).toISOString().split('T')[0],
      },
    }).then((e) => {
      navigate(`${e.data.createInventoryCounting.id}`)
      props.onSuccess()
      props.onClose(false)
    })
  }

  return (
    <Dialog open onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {user.translate('new')} {user.translate('inventory_counting')}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container justifyContent="space-around" style={{ flexDirection: 'column' }}>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">{user.translate('warehouse')}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={warehouseId}
              onChange={(e, value) => setWarehouseId(e.target.value)}
            >
              {warehouses.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name[user.lang]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Date picker dialog"
            format="dd/MM/yyyy"
            value={new Date(date)}
            onChange={(e) => setDate(e.getTime())}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        {Boolean(loadingSave) && <CircularProgress size={24} className={classes.loading} />}
        <Button onClick={handleClose} color="primary" disabled={loadingSave}>
          {user.translate('cancel')}
        </Button>
        <Button onClick={onSaveClick} color="primary" autoFocus disabled={loadingSave}>
          {user.translate('save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
