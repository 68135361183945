import React, { useContext, useState } from 'react'

import Add from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import Storage from '@mui/icons-material/Storage'
import VerticalAlignBottom from '@mui/icons-material/VerticalAlignBottom'
import VerticalAlignTop from '@mui/icons-material/VerticalAlignTop'
import { CircularProgress } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionActions from '@mui/material/AccordionActions'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import { green, red } from '@mui/material/colors'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'


import { useSupply } from './useSupply'
import { UserContext } from '../../App'
import CustomTheme from '../common/CustomTheme'
import ItemsChooserDialog from '../common/ItemsChooserDialog'
import { QuantityInputFieldToggle } from '../common/QuantityInputFieldToggle'
import Show from '../common/Show'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '0.9em',
    margin: 'auto 0px',
  },
  secondaryHeading1: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    minWidth: 36,
  },
  secondaryHeading2: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
    textAlign: 'right',
    whiteSpace: 'nowrap',
    minWidth: 36,
    flex: 1,
  },
  secondaryHeading3: {
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.text.secondary,
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  secondaryHeading4: {
    fontSize: theme.typography.pxToRem(11),
    color: 'rgb(15 85 93 / 68%)',
    textAlign: 'left',
    paddingTop: 2,
    fontWeight: 'bold',
    fontStyle: 'oblique',
  },
  quantityNegative: {
    color: '#af5454',
    fontWeight: 'bold',
  },
  quantityPositive: {
    color: '#3fa794',
  },

  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  summary: {
    margin: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '& > *': {
      margin: '0px !important',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  detailsRow: {
    width: '100%',
  },
  supplierOptions: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  supplier: {
    width: '100%',
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    // alignSelf: 'stretch'
  },
  orderOptions: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  columnHalf: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    // paddingTop: theme.spacing(1),
    // paddingBottom: 1,
    flexBasis: '50%',
    textAlign: 'left',
  },
  half: {
    flexBasis: '50%',
    textAlign: 'left',
  },
  quater: {
    flexBasis: '25%',
    textAlign: 'left',
  },
  stretch: {
    flex: 1,
    flexGrow: 1,
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  spacerLine: {
    flex: 1,
    flexGrow: 1,
    height: 1,
    marginTop: 'auto',
    marginBottom: 'auto',
    borderTop: '1px dashed #000',
  },
  columnQuater: {
    alignSelf: 'center',
    flexBasis: '25%',
    textAlign: 'left',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  quantity: {
    marginLeft: 4,
    width: 'auto !important',
    borderRadius: 14,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#76dedb !important',
    color: '#236d6c !important',
    fontWeight: 'bold',
  },
  inputOtherQuantity: {
    maxWidth: 160,
    marginLeft: 'auto',
    marginTop: theme.spacing(1),
  },
  actions: {
    justifyContent: 'space-between',
  },
  quantityIcon: {
    width: 16,
    height: 16,
    marginRight: 4,
    marginLeft: 'auto',
    color: '#6f6f6f',
  },
  uomIcon: {
    width: 16,
    height: 14,
    marginLeft: 4,
    marginTop: 'auto',
    marginBottom: 'auto',
    color: '#6f6f6f',
  },
  inventory: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
  },
  row: {
    display: 'flex',
    width: '100%',
  },
  loading: {
    position: 'absolute',
    right: theme.spacing(1),
  },
}))
const uomUnit = ['L', 'Kg', 'p']

export default function NewStockSupplingStockItem(props) {
  const classes = useStyles()

  const item = props.item
  const bestOption = item.bestCandidate || { neededQuantity: 0 }

  const user = useContext(UserContext)

  const [customSuppliers, setCustomSuppliers] = useState([])
  const [showSupplierChooser, setShowSupplierChooser] = useState(false)

  const manageSupply = useSupply({
    orders: props.supplies?.getStockSupplysByContextRestaurantId,
    refetch: props.refresh,
  })

  const neededQuantityAbs = Math.abs(bestOption.neededQuantity)
  const neededQuantity =
    bestOption.minStock !== undefined ? user.formatQuantity(item.unit === 2 ? neededQuantityAbs : neededQuantityAbs / 1000, 1) : '-'

  const missingQuantity =
    bestOption.minStock !== undefined ? user.formatQuantity(item.unit === 2 ? neededQuantityAbs : neededQuantityAbs / 1000, 1) : '-'

  const minStock = bestOption.minStock
    ? `(${user.translate(bestOption.neededQuantity > 0 ? 'for' : 'until')} ${user.formatQuantity(
        item.unit === 2 ? bestOption.minStock : bestOption.minStock / 1000,
      )})`
    : ''

  const totalOrderedQuantity = item.supplyOrders.sumOf((order) => order.quantity)
  const totalOrdered =
    totalOrderedQuantity > 0 ? (
      <span style={{ color: '#595b98', fontWeight: 'bold', fontSize: 12 }}>{user.formatQuantitySign(totalOrderedQuantity)}</span>
    ) : (
      ''
    )

  const orderSuppliers = item.supplyOrders.map((item) => item.order.supplierId).distinct()

  const orderSupplierNames = orderSuppliers.map((supplierId) => props.komitents[supplierId]?.name).join(', ')

  const otherSuppliers = orderSuppliers
    .filter((supplierId) => item.orderOptions.findIndex((option) => option.supplierId === supplierId) === -1)
    .map((supplierId) => ({ supplierId, quantities: [] }))

  const inventoryQuantity = `${user.formatQuantity(item.quantity, 1)} ${uomUnit[item.unit]}`

  const onCustomSupplier = (supplier) => {
    setCustomSuppliers([...customSuppliers, { supplierId: supplier.id, quantities: [] }])
  }

  const buttonTheme = {
    primary: {
      main: green[500],
      contrastText: '#fff',
      dark: green[900],
    },
    secondary: {
      main: red[500],
      contrastText: '#fff',
    },
  }

  const quantityClass = bestOption.neededQuantity > 0 ? classes.quantityNegative : classes.quantityPositive

  return (
    <div className={(classes.root, props.className)}>
      <Accordion defaultExpanded={props.expanded} onChange={(e, expand) => props.setExpanded(expand)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.summary}
          style={{ margin: 0, paddingTop: 8, paddingBottom: 8 }}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.row}>
            <Typography className={[classes.half, classes.heading].join(' ')}>{item.stockType}</Typography>
            <div className={[classes.quater, classes.inventory].join(' ')}>
              <Storage className={classes.quantityIcon} />
              <Typography className={classes.secondaryHeading1}>{inventoryQuantity}</Typography>
            </div>
            <div className={[classes.quater, classes.inventory].join(' ')}>
              <Typography className={[classes.secondaryHeading2, quantityClass].join(' ')}>{neededQuantity}</Typography>
              {bestOption.neededQuantity > 0 ? (
                <VerticalAlignTop className={classes.uomIcon} />
              ) : (
                <VerticalAlignBottom className={classes.uomIcon} />
              )}
            </div>
          </div>
          <Show if={totalOrdered !== ''}>
            <div className={classes.row} style={{ height: 8 }} />
            <div className={classes.row}>
              <Typography className={classes.secondaryHeading4} color="textSecondary">
                {orderSupplierNames}
              </Typography>
              <Typography className={[classes.stretch, classes.secondaryHeading1].join(' ')}>{totalOrdered}</Typography>
              <ShoppingCartIcon className={classes.uomIcon} style={{ color: '#595b98', width: 12, paddingRight: 2 }} />
            </div>
          </Show>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.details}>
          {manageSupply.isLoading && <CircularProgress className={classes.loading} size={20} />}
          {[...item.orderOptions, ...customSuppliers, ...otherSuppliers]
            .distinctBy((item) => item.supplierId)
            .map((option, index) => {
              const supplierOrders = item.supplyOrders.filter((item) => item.order.supplierId === option.supplierId)

              return (
                <div key={`item_${option.supplierId}`} className={classes.detailsRow}>
                  {index > 0 && <Divider key={`divider_${option.supplierId}`} style={{ width: '100%', height: 1 }} />}
                  <div key={`item_${option.supplierId}`} className={classes.supplierOptions}>
                    <Typography key={`title_${option.supplierId}`} variant="caption" color="secondary">
                      {props.komitents[option.supplierId]?.name || '...'}
                    </Typography>
                    <CustomTheme {...buttonTheme}>
                      <div key={`item_${option.supplierId}`} className={classes.supplier}>
                        {option.quantities.map((quantityOption) => {
                          const order = supplierOrders.removeBy((x) => x.quantity === quantityOption.quantity)
                          return (
                            <Chip
                              key={`chip_${option.supplierId}_${quantityOption.quantity}`}
                              className={classes.orderOptions}
                              variant={order ? 'default' : 'outlined'}
                              color={order?.order?.state !== 'CLOSED' ? 'primary' : 'default'}
                              label={`${quantityOption.label} ${uomUnit[item.unit]}`}
                              deleteIcon={order === undefined ? <DoneIcon /> : <DeleteIcon />}
                              onDelete={order?.order?.state === 'OPEN' ? () => manageSupply.deleteSupplyItem(order.id) : undefined}
                              onClick={
                                order?.order?.state !== 'CLOSED'
                                  ? () =>
                                      manageSupply.setItemSupply(
                                        option.supplierId,
                                        item.stockTypeId,
                                        quantityOption.quantity,
                                        option.uomOpts,
                                      )
                                  : undefined
                              }
                              clickable
                            />
                          )
                        })}
                        <div style={{ flexGrow: 1 }} />
                        <QuantityInputFieldToggle
                          key={`quantity_${option.supplierId}_${item.quantity}_${item.uomOpts}`}
                          label={user.translate('quantity')}
                          value={supplierOrders.sumOf((item) => item.quantity)}
                          uomOpts={supplierOrders.length > 1 ? 0 : supplierOrders[0]?.uomOpts || 0}
                          stockTypeId={item.stockTypeId}
                          className={classes.inputOtherQuantity}
                          onSubmit={(quantity, uomOpts) =>
                            manageSupply.setItemSupply(option.supplierId, item.stockTypeId, quantity, uomOpts)
                          }
                          enableClear
                          enableAccept
                        />
                      </div>
                    </CustomTheme>
                  </div>
                </div>
              )
            })}
        </AccordionDetails>
        <Divider />
        <AccordionActions className={classes.actions}>
          <Button size="small" color="secondary" startIcon={<Add />} onClick={() => setShowSupplierChooser(true)}>
            {user.translate('addSupplier')}
          </Button>
          <Button size="small" color="primary" disabled>
            {user.translate('supply')}
          </Button>
        </AccordionActions>
      </Accordion>

      {showSupplierChooser !== undefined && (
        <ItemsChooserDialog
          show={showSupplierChooser}
          items={Object.values(props.komitents)}
          onSelect={onCustomSupplier}
          onClose={setShowSupplierChooser}
        />
      )}
    </div>
  )
}
