import React, { useContext, useEffect, useMemo, useState } from 'react'

import { gql, useQuery } from '@apollo/client'

import SubtitlesIcon from '@mui/icons-material/Subtitles'
import { Button, CircularProgress, FormControl, FormGroup, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import makeStyles from '@mui/styles/makeStyles'

import { DateRangePickerComponent } from './DateRangePickerComponent'
import KomitentListDialog from './KomitentListDialog'
import { RestaurantContext, UserContext } from '../../App'

const useStyles = makeStyles((theme) => ({
  gridItem: {
    flexGrow: 0,
    marginBottom: 25,
    textAlign: `center`,
    fontSize: 14,
    justifyContent: `center`,
  },
  formRow: {
    flexGrow: 0,
    marginBottom: 25,
    textAlign: `center`,
    marginTop: 25,
    fontSize: 14,
    justifyContent: `center`,
  },
  formControlKomitents: {
    minWidth: 150,
    width: `auto`,
    margin: `15px ${theme.spacing(2)} 0`,
    float: 'left',
  },
  formControl: {
    minWidth: 150,
    width: `auto`,
    margin: `10px ${theme.spacing(2)} 0`,
    float: 'left',
  },
  formControlDate: {
    minWidth: 150,
    width: `auto`,
    margin: `10px ${theme.spacing(2)} 0`,
    float: 'left',
    paddingLeft: theme.spacing(3),
  },
  formControlAutocomplete: {
    minWidth: 250,
    width: `auto`,
    margin: `10px ${theme.spacing(2)} 0`,
  },
  formControlPrint: {
    minWidth: 250,
    width: `auto`,
    margin: `0`,
  },
}))

export default function InvoiceHeader(props) {
  const classes = useStyles()
  const user = useContext(UserContext)
  const restaurant = useContext(RestaurantContext)

  const statusOptions = [
    {
      status: 0,
      title: user.translate('created'),
    },
    {
      status: 1,
      title: user.translate('open'),
    },
    {
      status: 2,
      title: user.translate('paid'),
    },
    {
      status: 3,
      title: user.translate('storna'),
    },
  ]
  const typeOptions = [
    {
      type: 0,
      title: user.translate('none'),
    },
    {
      type: 1,
      title: user.translate('material'),
    },
    {
      type: 2,
      title: user.translate('immaterial'),
    },
    {
      type: 3,
      title: user.translate('both_group'),
    },
  ]

  const [commissioners, setCommissioners] = useState([])
  const [open, setOpen] = useState(false)
  const [openSelectBox, setOpenSelectBox] = useState(false)
  const [gotIt, setGotIt] = useState(false)

  // GraphQL API request definition (local variables: restaurantId)
  const GET_COMMISSIONERS_BY_RESTAURANT = gql`
    query ($restaurantId: String!){
        getCommissionersByRestaurantId(restaurantId: $restaurantId) {
            commissioner {
                id
                name {
                    ${user.gqlFetchName()}
                }
            }
        }
    }`

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const { data: data_commissioners, loading: loading_commissioners } = useQuery(GET_COMMISSIONERS_BY_RESTAURANT, {
    variables: { restaurantId: restaurant.id },
    skip: !open && !gotIt,
  })

  if (data_commissioners && open && commissioners.length === 0 && !gotIt) {
    const _commissioners = data_commissioners.getCommissionersByRestaurantId.map((item) => ({
      ...item.commissioner,
      name: item.commissioner.name[user.lang],
    }))
    setCommissioners(_commissioners)
    setGotIt(true)
  }

  const [filterState, setFilterState] = useState({ ...props.filterState })

  useEffect(() => {
    if (props.filterState && JSON.stringify(filterState) !== JSON.stringify(props.filterState)) {
      setFilterState(props.filterState)
      props.setQuery(props.filterState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterState])

  const dateRange = useMemo(
    () => ({
      startDate: filterState.selectedDateFrom,
      endDate:
        filterState.selectedDateTo &&
        filterState.selectedDateFrom &&
        new Date(Math.max(filterState.selectedDateTo.getTime(), filterState.selectedDateFrom.getTime())),
    }),
    [filterState.selectedDateFrom, filterState.selectedDateTo],
  )

  // const filterOptions = createFilterOptions({
  //     matchFrom: 'any',
  //     stringify: option => option.name.cirilicToLatin(),
  // });

  const filterOptions = (options, params) =>
    options.filter((option) => option.name && option.name.cirilicToLatin().indexOf(params.inputValue.cirilicToLatin()) > -1)

  const handleChangeFilter = (event, value) => {
    const _filters = filterState.filters
    if (typeof value !== 'undefined') {
      _filters.company = value
      setFilterState({ ...filterState, filters: _filters })
    } else {
      _filters[event.target.name] = event.target.value
      setFilterState({ ...filterState, filters: _filters })
    }
    props.setQuery(filterState, commissioners)
  }

  const handleDateRangeAccept = (range) => {
    const filState = Object.assign(filterState)
    const dateTo = new Date(range.endDate)
    dateTo.setHours('23')
    dateTo.setMinutes('59')
    dateTo.setSeconds('59')
    filState.selectedDateFrom = range.startDate
    filState.selectedDateTo = dateTo
    setFilterState(filState)
    // setDateRange({
    //     startDate: range.startDate,
    //     endDate: dateTo
    // });
    props.setQuery(filState)
  }

  const onKomissionersClick = () => {
    setOpenSelectBox(true)
  }

  const company =
    typeof filterState.filters.company !== 'undefined' && filterState.filters.company != null ? filterState.filters.company : ''
  const status = typeof filterState.filters.status !== 'undefined' && filterState.filters.status != null ? filterState.filters.status : ''
  const type = typeof filterState.filters.type !== 'undefined' && filterState.filters.type != null ? filterState.filters.type : ''

  return (
    <>
      <FormGroup row className={classes.formRow}>
        <div className={classes.formControlKomitents}>
          <Button
            style={{ margin: '12px auto' }}
            color="primary"
            component="span"
            onClick={onKomissionersClick}
            startIcon={<SubtitlesIcon />}
          >
            {user.translate('komitents')}
          </Button>
        </div>

        <FormControl className={classes.formControlAutocomplete}>
          <Autocomplete
            id="filter_company"
            name="companyId"
            size="small"
            open={open}
            onOpen={() => {
              setOpen(true)
            }}
            onClose={() => {
              setOpen(false)
            }}
            loading={loading_commissioners}
            selectOnFocus
            handleHomeEndKeys
            options={commissioners}
            filterOptions={filterOptions}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option?.name ?? ''}
            onChange={(event, value) => handleChangeFilter(event, value)}
            value={company}
            renderInput={(params) => {
              params.inputProps.autoComplete = 'off'
              return (
                <TextField
                  {...params}
                  variant="standard"
                  size="small"
                  label={user.translate('komitents')}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading_commissioners ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="filter_status_label">{user.translate('status')}</InputLabel>
          <Select
            id="filter_status"
            labelId="filter_status_label"
            name="status"
            fullWidth
            variant="standard"
            onChange={(event) => handleChangeFilter(event)}
            value={status}
          >
            <MenuItem key="none" value={null}>
              <em>None</em>
            </MenuItem>
            {statusOptions.map((option) => (
              <MenuItem key={option.status} value={option.status}>
                {option.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="standard" className={classes.formControl}>
          <InputLabel id="filter_type_label">{user.translate('invoice_type_filter')}</InputLabel>
          <Select
            id="filter_type"
            labelId="filter_type_label"
            name="type"
            onChange={(event) => handleChangeFilter(event)}
            value={type}
            variant="standard"
          >
            {typeOptions.map((option) => (
              <MenuItem key={option.type} value={option.type}>
                {option.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div className={classes.dateRange}>
          <DateRangePickerComponent initialDateRange={dateRange} onChange={(range) => handleDateRangeAccept(range)} />
        </div>
      </FormGroup>

      {openSelectBox && <KomitentListDialog setOpenSelectBox={setOpenSelectBox} openSelectBox={openSelectBox} />}
    </>
  )
}
