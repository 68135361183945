import { useCallback, useMemo } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { FormGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { DateRangePickerComponent } from './DateRangePickerComponent'

const useStyles = makeStyles((theme) => ({
  formRow: {
    flexGrow: 0,
    textAlign: `center`,
    fontSize: 14,
    justifyContent: `center`,
  },
  inputContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  formControlDate: {
    maxWidth: 160,
    width: `auto`,
    margin: theme.spacing(1),
  },
  dateRange: {
    position: `absolute`,
    marginTop: `60px`,
    zIndex: 999999,
  },
  datePickerRoot: {
    zIndex: 999999,
  },
}))

export function DateRangeHeader({ filter, setFilter }) {
  const classes = useStyles()

  const handleDateRangeAccept = useCallback(
    ({ startDate, endDate }) => {
      if (!startDate || !endDate) return

      const dateTo = new Date(endDate.getTime() + 24 * 60 * 60 * 1000 - 1)
      dateTo.setHours(5, 0, 0, 0)

      setFilter({ startDate, endDate: dateTo })
    },
    [setFilter],
  )

  return (
    <FormGroup row className={classes.formRow} sx={{ pl: 2 }}>
      <DateRangePickerComponent initialDateRange={filter} onChange={handleDateRangeAccept} />
    </FormGroup>
  )
}

export default function useDateRangeFilter(defaultFilter = 'today' || 'yesterday' || 'this_month' || 'last_and_this_month' || 'this_year') {
  const location = useLocation()
  const navigate = useNavigate()

  const [startDate, endDate] = useMemo(() => {
    const startDate = new Date()
    const endDate = new Date()
    if (defaultFilter === 'yesterday') {
      startDate.setDate(startDate.getDate() - 1)
    } else if (defaultFilter === 'past_week') {
      startDate.setDate(startDate.getDate() - 7)
    } else if (defaultFilter === 'this_month') {
      startDate.setMonth(startDate.getMonth(), 1)
    } else if (defaultFilter === 'last_and_this_month') {
      startDate.setMonth(startDate.getMonth() - 1, 1)
    } else if (defaultFilter === 'this_year') {
      startDate.setMonth(0, 1)
    }

    startDate.setHours(5, 0, 0, 0)

    endDate.setTime(endDate.getTime() + 24 * 60 * 60 * 1000)
    endDate.setHours(5, 0, 0, 0)

    return [startDate, endDate]
  }, [defaultFilter])

  const filterState = useMemo(
    () => ({
      startDate: location.state?.startDate || startDate,
      endDate: location.state?.endDate || endDate,
    }),
    [endDate, location.state?.endDate, location.state?.startDate, startDate],
  )

  const setFilterState = useCallback(
    (state) => {
      navigate('', { ...location.state, state })
    },
    [location.state, navigate],
  )

  return {
    ...filterState,
    renderHeader: () => <DateRangeHeader filter={filterState} setFilter={setFilterState} />,
  }
}
