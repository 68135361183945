import React, { useContext } from 'react'

import Add from '@mui/icons-material/Add'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import { Fab } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'


import MenuCategoriesList from './MenuCategoriesList'
import { RestaurantContext, UserContext } from '../../App'
import Show from '../common/Show'
import { useEntityRelationQuery } from '../common/useEntityQuery'

// CSS
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    textAlign: 'left',
    paddingBottom: 80,
  },
  fabAdd: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    margin: theme.spacing(2),
  },
  fabReorder: {
    position: 'fixed',
    bottom: 0,
    margin: theme.spacing(2),
  },
}))

export default function RestaurantMenu(props) {
  const classes = useStyles()

  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const FIELDS = [
    { name: 'id', gql: 'String!', id: true, hidden: true },
    { name: 'name', gql: 'String!', subSelection: { [user.gqlFetchName()]: true } },
    { name: 'displayIndex', gql: 'Long!', hidden: true },
    { name: 'icon', gql: 'String!', hidden: true },
    { name: 'active', gql: 'Boolean!', hidden: true },
  ]

  const query = useEntityRelationQuery('MenuCategory', FIELDS, 'menuId', props.menuId)

  const onAddCategoryClick = () => {}

  const onReorderCategoriesClick = () => {}

  return (
    <div className={classes.root}>
      <MenuCategoriesList menuId={props.menuId} items={query.items} isLoading={query.isLoading} />

      <Fab className={classes.fabAdd} ariaLabel="Add Menu" color="primary" onClick={onAddCategoryClick}>
        <Add />
      </Fab>

      <Show if={query.items && query.items.length > 0}>
        <Fab className={classes.fabReorder} ariaLabel="Add Menu" color="primary" onClick={onReorderCategoriesClick}>
          <LowPriorityIcon />
        </Fab>
      </Show>
    </div>
  )
}
