import { useState, useContext } from 'react'

import FacebookLogin from 'react-facebook-login'

import { useMutation, gql } from '@apollo/client'

import makeStyles from '@mui/styles/makeStyles'

import config from '../../config'
import { UserContext } from '../App'

const useStyles = makeStyles((theme) => ({
  fb: {
    display: 'inline-flex',
    color: 'inherit',
    border: 'none',
    textTransform: 'uppercase',
    padding: '6px 16px',
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    fontSize: 14,
    whiteSpace: 'nowrap',
    lineHeight: '1.75',
    backgroundColor: '#194ea5',
    cursor: 'pointer',
  },
}))

// React Function Component
export default function ConnectFb(props) {
  const classes = useStyles()

  const width = typeof window !== 'undefined' ? window.innerWidth : null

  const user = useContext(UserContext)

  const [editIt, setEditIt] = useState(false)

  const CONNECT_FB = gql`
    mutation ($accessToken: String!) {
      auth_loginFacebook(accessToken: $accessToken) {
        message
        ok
        profile {
          name
          email
          id
        }
      }
    }
  `

  const [connectFb, { loading: loading_connectFb }] = useMutation(CONNECT_FB)

  const responseFacebook = (response) => {
    user.consoleLog(response)

    if (typeof response.accessToken !== 'undefined') {
      const items = {
        accessToken: response.accessToken,
      }

      connectFb({ variables: items })
      setEditIt(true)
    }
  }

  if (!loading_connectFb && editIt && props.refetch) {
    props.refetch()
    setEditIt(false)
  }

  return (
    <FacebookLogin
      appId={config.fbAppId}
      autoLoad={false}
      fields="name,email,picture"
      scope="email"
      callback={responseFacebook}
      isMobile={width < 768}
      disableMobileRedirect={!(width < 768)}
      redirectUri={width < 768 ? window.location.href : undefined}
      textButton={user.translate('connect_fb')}
      cssClass={classes.fb}
    />
  )
}
