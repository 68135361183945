import React, { useContext, useState } from 'react'

import { useLazyQuery, gql } from '@apollo/client'

import CloseIcon from '@mui/icons-material/Close'
import { Dialog, AppBar, Toolbar, IconButton, DialogContent, DialogActions, Button, Slide, alpha, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import helper from './Helper'
import { UserContext } from '../../App'
import KomitentList from '../komitent/KomitentList'

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'inline-flex',
  },
  appBar: {
    position: 'relative',
  },
  gridItem: {
    maxWidth: 290,
    minWidth: 240,
    flexGrow: 1,
  },
  itemName: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.05),
      cursor: `pointer`,
    },
  },
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 600,
    height: 40,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function KomitentListDialog(props) {
  const classes = useStyles()
  // Get current user preferences (language)
  const user = useContext(UserContext)
  let billing = false

  const [openApply, setOpenApply] = useState(props.openSelectBox)
  const [getIt, setGetIt] = useState(false)

  const onToggleClose = () => {
    setOpenApply(!openApply)
    props.setOpenSelectBox(!props.openSelectBox)
  }

  // GraphQL API request definition (local variables: restaurantId)
  const GET_BILLING_BY_ID = gql`
    query ($id: String){
        getRestaurantBillingById(id: $id) {
            id
            name
            address
            restaurantId
            restaurant{
                name {
                    ${user.gqlFetchName()}
                }
            }
            city {
                id
                name {
                  ${user.gqlFetchName()}
                }
                country {
                  id
                  name {
                      ${user.gqlFetchName()}
                  }
                }
            }
            
        }
    }`

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const [getBilling, { data, loading }] = useLazyQuery(GET_BILLING_BY_ID, {
    skip: !getIt,
  })

  if (data && getIt && !loading) {
    billing = data.getRestaurantBillingById
    if (billing) {
      const items = {
        val: billing.restaurantId,
        label: billing.restaurant.name[user.lang],
      }

      setGetIt(false)
      if (props.action) props.action(items)
      onToggleClose()
    }
  }

  const handleSelectAccept = (rowData) => {
    getBilling({ variables: { id: rowData.id } })
    setGetIt(true)
  }

  return (
    <div className={`${classes.root}`}>
      {openApply && (
        <Dialog
          open={openApply}
          fullScreen
          onClose={onToggleClose}
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          aria-describedby="form-dialog-description"
          fullWidth
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={onToggleClose} aria-label="close" size="large">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                {user.translate('komitents')}
              </Typography>
            </Toolbar>
          </AppBar>

          <DialogContent>
            <KomitentList
              handleSelectAccept={handleSelectAccept}
              action={props.action}
              setOpenSelectBox={props.setOpenSelectBox}
              openSelectBox={props.openSelectBox}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onToggleClose} color="primary">
              {user.translate('cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}
