import { useContext, useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

import { gql, useQuery } from '@apollo/client'

import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import { Box, CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import helper from './Helper'
import ReportHeader from './ReportHeader'
import TableReport from './TableReport'
import { RestaurantContext, UserContext } from '../../App'
import InvoiceItemReceiptList from '../invoice/item/InvoiceItemReceiptList'

const useStyles = makeStyles((theme) => ({
  roots: {
    // display: 'inline-flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checkIcon: {
    fontSize: 12,
  },
  itemName: {
    float: `left`,
    clear: `both`,
    textAlign: 'left',
    fontStyle: 'normal',
  },
  fTitle: {
    float: `left`,
    clear: `both`,
    textAlign: 'left',
    fontStyle: 'normal',
  },
  itemTableName: {
    float: `left`,
    clear: `both`,
    textAlign: 'left',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: 2,
  },
  pltTitleBox: {
    width: '100%',
    display: 'inline-flex',
    textAlign: 'center',
    margin: '16px auto 0 auto',
  },
  pltTitle: {
    width: '100%',
    display: 'block',
    textAlign: 'center',
  },
  pltHeadersTitleBox: {
    width: '100%',
    display: 'inline-block',
    paddingLeft: 24,
    paddingRight: 24,
  },
  pltHeaders: {
    width: 'auto',
    display: 'inline-flex',
    textAlign: 'left',
    float: 'left',
    margin: '16px auto 0 auto',
  },
  pltHeadersItem: {
    width: 'auto',
    display: 'block',
    textAlign: 'left',
  },
  pltInvoice: {
    width: 'auto',
    display: 'inline-flex',
    textAlign: 'left',
    float: 'right',
    margin: '16px auto 0 auto',
  },
}))

// React Function Component
export default function Report(props) {
  const classes = useStyles()

  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const preloadParams = new URLSearchParams(useLocation().search)
  let preloadFilters = []
  let preloadPlt = ''
  let preloadGroup = ''
  const preloadSubtotals = []
  let _preload = false

  if (JSON.stringify(preloadParams) !== '') {
    _preload = true
    // Log the values
    preloadParams.forEach(function (value, key) {
      if (key === 'plt') preloadPlt = value
      else if (key === 'group') preloadGroup = value
      else if (key.indexOf('subtotal') > -1) preloadSubtotals[key] = value
      else preloadFilters[key] = value
    })
  }

  if (props.filter && Object.keys(props.filter).length > 0) {
    // _preload = true
    preloadFilters = { ...preloadFilters, ...props.filter }
  }

  props.query.receivingIdInit = props.query.receivingIdInit || ''
  props.query.receivingId = props.query.receivingId || ''
  props.query.receivingIdVars = props.query.receivingIdVars || {}

  const [vars, setVars] = useState({
    ...props.query.dateVars,
    ...(props.query.receivingIdVars || {}),
    restaurantId: restaurant.id,
    lang: user.lang, // user[lang]
  })

  const [preload, setPreload] = useState(_preload)

  const receivingId = props.receivingId || false
  const [sender, setSender] = useState(false)
  const [invoice, setInvoice] = useState(false)
  const [sendingNumber, setSendingNumber] = useState(false)
  const [receivingNumber, setReceivingNumber] = useState(false)

  const [group, setGroup] = useState('')
  const [subTotal, setSubTotal] = useState('')
  const [plt, setPlt] = useState(receivingId ? 'tax' : undefined)
  const [filters, setFilters] = useState(false)
  const [filterTableTitle, setFilterTableTitle] = useState(false)
  const [openReceiptView, setOpenReceiptView] = useState(false)

  let fieldReport = {}
  const pltReport = {}
  let groupTitle = ''
  let pltTitle = ''

  let filtersString = `` // {name: "sender", selection: "94", options: [{id:"1", title: "Главен"}]}

  const get1 = `${props.query.name}(restaurantId: $restaurantId,${props.query.receivingId} ${props.query.date}, lang: $lang,  filters: [`
  let get2 = `${filtersString}`
  const get3 = `]) {
        title
        documentHeaders {
            title
            value
        }
        headers {
            name
            title
            hidden
            children {
                name
                title
                hidden
            }
        }
        subTotals {
            name
            title
            hidden
        }
        results {
            ${props.query.results}
        }
        group {
            name
            title
        }
        totalsGroup {
            totals {
                hidden
                title
                name
            }
            groupBy {
                name
                title
                headerTitle
                values {
                    title
                    value
                }
            }
            defaultSubTotals
        }
        filters {
            name
            title
            selection
            options{
                _id
                id
                title
            }
        }
    }`

  const [get, setGet] = useState(
    `query ($restaurantId: String!, ${props.query.receivingIdInit} ${props.query.dateInit}, $lang: String!){${get1}${get2}${get3}}`,
  )

  const { data, loading, error, refetch } = useQuery(
    gql`
      ${get}
    `,
    {
      variables: vars,
      pollInterval: 3000,
    },
  )

  useEffect(() => {
    const initialTitle = document.title
    return () => {
      document.title = initialTitle
    }
  }, [])

  useEffect(() => {
    let documentTitle = user.translate(props.pageTitle ?? props.title ?? 'ПЛТ')

    if (receivingNumber) {
      documentTitle += ' ' + receivingNumber
    }

    if (props.query.dateVars.dateFrom && props.query.dateVars.dateTo) {
      const dateStr =
        ' ' +
        new Date(props.query.dateVars.dateFrom).toLocaleDateString() +
        ' - ' +
        new Date(props.query.dateVars.dateTo).toLocaleDateString()
      documentTitle += dateStr.replaceAll('/', '_')
    }

    document.title = documentTitle
  }, [props.pageTitle, props.query.dateVars.dateFrom, props.query.dateVars.dateTo, props.title, receivingNumber, user])

  if (!data) {
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    )
  }

  // In case there is an error, just show it for now
  if (!data && error) {
    user.consoleLog(error)
    return <p>Error: {error}</p>
  }

  let returnData = []
  let results = []
  const tableFilters = {}
  let _filters = {}
  const lookupFilters = {}
  let _children = []
  let children = []
  let _totalHeaders = 0
  const totalHeaders = []
  if (data) {
    returnData = data[props.query.name]
    results = returnData.results

    returnData.filters.forEach((filter) => {
      lookupFilters[filter.name] = filter
      _filters[filter.name] =
        filter.selection !== undefined ? filter.selection.map((sel) => filter.options.find((opt) => opt.id === sel)) : []
    })

    if (props.header) {
      _totalHeaders = returnData.headers.length
      _children = returnData.headers.filter((header) => header.children.length !== 0).map((header) => header.children)
      _children.forEach((child) => {
        _totalHeaders--
        children = children.concat(
          child.map((_child) => {
            _totalHeaders++
            return _child
          }),
        )
      })
    }
    if (props.header && props.headerNumbers && _totalHeaders > 0) {
      while (_totalHeaders >= 0) {
        totalHeaders[_totalHeaders] = _totalHeaders + 1
        _totalHeaders--
      }
    }
  }

  fieldReport.columns = []
  fieldReport.columnParents = []
  fieldReport.columnNumbers = totalHeaders
  fieldReport.data = []

  const checkHidden = (name, hidden) => {
    if (name === group || name === plt) return true
    if (typeof filters[name] !== 'undefined' && Object.keys(filters[name]).length === 1) return true
    return hidden
  }

  fieldReport.columns = [
    {
      title: '',
      field: 'count',
      editable: 'never',
      cellStyle: { width: '1%', padding: `0 5px 0 5px`, fontSize: 12, whiteSpace: 'pre-line' },
      sorting: false,
      hidden: props.noRowNum,
    },
  ]
  fieldReport.columnParents = [
    {
      title: '',
      field: 'count',
      editable: 'never',
      cellStyle: { width: '1%', padding: `0 5px 0 5px`, fontSize: 12, whiteSpace: 'pre-line' },
      sorting: false,
      hidden: props.noRowNum,
    },
  ]

  const formatNumberColumns = [
    'totalPrice',
    'totalPriceWithTax',
    'totalTax',
    'totalTaxDiff',
    'totalFee',
    'amount',
    'dealerCost',
    'totalSaleValue',
    'totalSalesAmount',
    'price',
    'taxAmount',
    'totalAmount',
    'itemPrice',
    'itemSellPrice',
    'totalSellPrice',
    'totalSellTax',
    'totalFee',
    'avgPrice',
    'avgNetPrice',
    'avgTotalPrice',
    'avgSellPrice',
    'taxAmountDomesticA',
    'taxAmountDomesticB',
    'taxAmountDomesticC',
    'taxAmountDomesticD',
    'taxAmountDomesticE',
    'totalIncome',
    'totalIncomeByTaxA',
    'totalIncomeByTaxB',
    'totalIncomeByTaxC',
    'totalIncomeByTaxD',
    'totalIncomeByTaxE',
    'totalIncomeDomestic',
    'totalTax',
    'totalTaxByRateA',
    'totalTaxByRateB',
    'totalTaxByRateC',
    'totalTaxByRateD',
    'totalTaxByRateE',
    'totalTaxDomestic',
    'fiscal',
    'marker',
    'invoice',
    'staff',
    'lost',
    'meters',
    'supply',
    'overhead',
    'misc',
    'totalRevenue',
    'totalExpenses',
    'totalProfit',
  ]

  const formatQuantityColumns = [
    'quantity',
    'ordersQuantity',
    'invoiceQuantity',
    'receivingQuantity',
    'internalTransferQuantity',
    'inventoryQuantity',
  ]

  const formatDateTimeColumns = [
    'itemCreated',
    'receivingCreated',
    'firstOrderTime',
    'lastOrderTime',
    'createdTime',
    'created',
    'updated',
    'closedTime',
  ]

  const formatDateColumns = ['bookDate', 'documentDate']

  ;(returnData?.headers || []).forEach((header) => {
    if (!checkHidden(header.name, header.hidden)) {
      if (header.children.length === 0) {
        if (header.name === 'isCanceled') {
          fieldReport.columns.push({
            title: header.title,
            field: header.name,
            hidden: header.hidden,
            export: false,
            editable: 'never', // props.notEditItem || subTotal || user.role < 3 ? 'never' : 'always',
            type: 'boolean',
            align: 'right',
            render: (rowData) => {
              if (rowData.isCanceled === true) return <CheckIcon className={classes.checkIcon} />
              else if (rowData.isCanceled === false) return <ClearIcon className={classes.checkIcon} />
              else return rowData.isCanceled
            },
          })
        } else {
          fieldReport.columns.push({
            title: header.title,
            field: header.name,
            hidden: false,
            editable: 'never',
            align: 'right',
            customSort: (a, b) => {
              const numberString = formatNumberColumns.find((c) => c === header.name)
              if (typeof numberString === 'string' && typeof a[header.name] === 'string') {
                return user.parseStringNumber(a[header.name]) - user.parseStringNumber(b[header.name])
              } else if (typeof a[header.name] === 'string') {
                return a[header.name].cirilicLatinCompare(user.lang).localeCompare(b[header.name].cirilicLatinCompare(user.lang), user.lang)
              }
            },
          })
        }

        if (props.header && props.headerNumbers) {
          fieldReport.columnParents.push({
            title: '',
            colSpan: 1,
          })
        }
      } else {
        header.children.forEach((child) => {
          if (!checkHidden(child.name, child.hidden)) {
            fieldReport.columns.push({
              title: child.title,
              field: child.name,
              hidden: false,
              editable: 'never',
              align: 'right',
              child: true,
              customSort: (a, b) => {
                const numberString = formatNumberColumns.find((c) => c === child.name)
                if (typeof numberString === 'string' && typeof a[child.name] === 'string') {
                  return user.parseStringNumber(a[child.name]) - user.parseStringNumber(b[child.name])
                } else if (typeof a[child.name] === 'string') {
                  return a[child.name].cirilicLatinCompare(user.lang).localeCompare(b[child.name].cirilicLatinCompare(user.lang), user.lang)
                }
              },
            })
          }
        })
        if (props.header && props.headerNumbers) {
          fieldReport.columnParents.push({
            title: header.title,
            colSpan: header.children.count((it) => !it.hidden),
          })
        }
      }
    } else {
      fieldReport.columns.push({
        title: header.title,
        field: header.name,
        hidden: true,
        editable: 'never',
      })
    }
  })

  props.query.columnsPush.forEach((value) => {
    fieldReport.columns.push({
      width: '1%',
      cellStyle: { whiteSpace: 'nowrap' },
      title: value,
      field: value,
      hidden: true,
    })
  })

  window.columnParents = props.header ? fieldReport.columnParents : []
  window.columnNumbers = props.headerNumbers ? fieldReport.columnNumbers : []

  const columnIndex = fieldReport.columns.findIndex((c) => c.hidden === false)

  fieldReport.columns[columnIndex] = {
    ...fieldReport.columns[columnIndex],
    customFilterAndSearch: (filterValue, rowData) => {
      let find = false
      const i = ''

      console.log(rowData)
      for (const key in rowData) {
        const row = rowData[key]
        const numberString = formatNumberColumns.find((c) => c === i)
        if (
          typeof numberString === 'string' &&
          typeof row === 'string' &&
          user.parseStringNumber(row).toString().indexOf(filterValue) > -1
        ) {
          find = true
        } else if (
          row &&
          i !== '__typename' &&
          i !== 'tableData' &&
          typeof row === 'string' &&
          row.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1
        ) {
          find = true
        }
      }
      return find
    },
    align: 'left',
  }

  const converters = props.converters || {}

  let rowIndex = 1

  fieldReport.data = results.map((result) => {
    const _result = {}
    Object.keys(result).forEach((key) => {
      const converter = converters[key]
      const value = converter ? converter(result) : result[key]

      if (formatNumberColumns.indexOf(key) > -1) {
        _result[key] = user.formatNumber(value)
      } else if (formatQuantityColumns.indexOf(key) > -1) {
        _result[key] = user.formatQuantityReport(value)
      } else if (formatDateTimeColumns.indexOf(key) > -1) {
        if (value === 0) {
          _result[key] = '-'
        } else {
          _result[key] = user.formatDate(value, true)
        }
      } else if (formatDateColumns.indexOf(key) > -1) {
        if (value === 0) {
          _result[key] = '-'
        } else {
          _result[key] = user.formatDate(value)
        }
      } else if (key === 'tax') {
        _result[key] = user.formatNumber(value, true)
      }
    })

    const item = {
      ...result,
      ..._result,
      count: props.skipRowNum && props.skipRowNum(result) ? '' : `${rowIndex++}.`,
    }
    if (props.onItem) props.onItem(item)
    return item
  })

  if (props.mapData) {
    fieldReport.data = props.mapData(fieldReport.data)
  }

  // user.consoleLog(fieldReport['data'])

  const setQuery = (filterState, type = 'filters', value = null) => {
    if (preload) {
      setPreload(false)
    }

    filtersString = ``
    let _filterTableTitle = []

    if (type === 'filters') {
      setFilters({ ...filterState.filters })

      let selected = false
      filtersString = ``
      for (const _filter in filterState.filters) {
        let _fTitle = ``

        if (Object.keys(filterState.filters[_filter]).length > 0) {
          filtersString += `{name: "${_filter}", selection: ["`
          const filter = returnData.filters.find((c) => c.name === _filter)
          _fTitle = filter.title + ':'

          filterState.filters[_filter].forEach(function (value, key) {
            if (typeof value.id !== 'undefined') {
              selected = true
              filtersString += `${value.id}","`
              _fTitle += ' ' + value.title + ', '
            }
          })

          filtersString = filtersString.substring(0, filtersString.length - 2) + ']},'
          _fTitle = _fTitle.substring(0, _fTitle.length - 2)
          _filterTableTitle.push(_fTitle)
        }
      }

      if (selected) {
        filtersString = filtersString.substring(0, filtersString.length - 1)
      } else {
        filtersString = ``
        _filterTableTitle = false
      }

      setFilterTableTitle(_filterTableTitle)

      get2 = `${filtersString}`
      setGet(
        `query ($restaurantId: String!, ${props.query.receivingIdInit} ${props.query.dateInit}, $lang: String!){${get1}${get2}${get3}}`,
      )
    }

    if (type === 'dateRange') {
      setVars({
        ...vars,
        dateFrom: filterState.selectedDateFrom.getTime(),
        dateTo: filterState.selectedDateTo.getTime(),
      })
    }

    if (type === 'date') {
      setVars({ ...vars, date: filterState.selectedDate.getTime() })
    }

    if (type === 'plt') {
      setPlt(value)

      const subTotals = []
      if (value !== '') {
        returnData.totalsGroup.defaultSubTotals.forEach((defaultSubTotal) => {
          const item = returnData.headers.find((c) => c.name === defaultSubTotal)
          if (item) subTotals.push(item)
        })
      }
      setSubTotal(subTotals)
    }

    if (type === 'group') {
      setGroup(value)
    }

    if (type === 'subTotal') {
      setSubTotal(value)
    }
  }

  let selected = false
  const subTotals = []
  const _filterTableTitle = []
  const _preloadFilters = []

  if (preload) {
    if (Object.keys(preloadFilters).length > 0) {
      filtersString = ``
      for (const key in preloadFilters) {
        let _fTitle = ``
        let item = false
        let item_option = false

        item = returnData.filters.find((c) => c.name === key)

        if (item) {
          item_option = item.options.find((c) => c.id === preloadFilters[key])

          if (item_option) {
            _preloadFilters[key] = []
            _preloadFilters[key].push(item_option)

            _fTitle = item.title + ': ' + item_option.title
          }

          selected = true
          _filterTableTitle.push(_fTitle)
          filtersString = filtersString + `{name: "${key}", selection: "${preloadFilters[key]}"},`
        }
      }
      if (selected) {
        setFilters({ ..._preloadFilters })
        setFilterTableTitle(_filterTableTitle)
        get2 = filtersString.substring(0, filtersString.length - 1)
        setGet(
          `query ($restaurantId: String!, ${props.query.receivingIdInit} ${props.query.dateInit}, $lang: String!){${get1}${get2}${get3}}`,
        )
      }
    }

    if (preloadPlt !== '') {
      setPlt(preloadPlt)
      returnData.totalsGroup.defaultSubTotals.forEach((defaultSubTotal) => {
        const item = returnData.headers.find((c) => c.name === defaultSubTotal)
        if (item) subTotals.push(item)
      })
      setSubTotal(subTotals)
    }

    if (preloadGroup !== '') {
      setGroup(preloadGroup)
    }

    if (Object.keys(preloadSubtotals).length > 0) {
      preloadSubtotals.forEach((preloadSubtotal) => {
        const item = returnData.headers.find((c) => c.name === preloadSubtotal)
        if (item) subTotals.push(item)
      })
      setSubTotal(subTotals)
    }

    setPreload(false)
  }

  const renderGroup = (groups, columns, _group) => {
    let newGroups = []
    const firstColumn = columns.find((c) => c.hidden === false)

    for (const groupName in groups) {
      const group = groups[groupName]
      const groupTitleRow = {}

      groupTitleRow[firstColumn.field] = groupName // <b>{groupName}</b>;
      group.unshift(groupTitleRow)

      if (subTotal) {
        const subTotalRow = {}
        for (const i in subTotal) {
          let subTotalSum = 0

          group.forEach((subGroup) => {
            if (typeof subGroup[subTotal[i].name] !== 'undefined') {
              const val = subGroup[subTotal[i].name]
              if (typeof val === 'boolean') subTotalSum += val ? 1 : 0
              else if (typeof val === 'string') subTotalSum += user.parseStringNumber(val)
              else subTotalSum += val
            }
          })

          subTotalRow[subTotal[i].name] = user.formatNumber(subTotalSum) // <b>{subTotalSum}</b>;
        }

        group.push(subTotalRow)
      }

      newGroups = newGroups.concat(group)
    }

    fieldReport.data = Object.assign(newGroups)

    return fieldReport
  }

  const renderSubTotal = (groups, _subTotal) => {
    let newGroups = []

    const subTotalRow = {}
    ;(_subTotal || []).forEach((item) => {
      let subTotalSum = 0

      for (const _group in groups) {
        if (typeof groups[_group][item.name] !== 'undefined') {
          const val = groups[_group][item.name]
          if (typeof val === 'boolean') subTotalSum += val ? 1 : 0
          else if (typeof val === 'string') subTotalSum += user.parseStringNumber(val)
          else subTotalSum += val
        }
      }

      subTotalRow[item.name] = user.formatNumber(subTotalSum) // <b>{subTotalSum}</b>;
    })

    let add = false
    for (const j in subTotalRow) {
      if (subTotalRow[j]) add = true
    }
    if (add) groups.push(subTotalRow)

    newGroups = groups

    fieldReport.data = Object.assign(newGroups)

    return fieldReport
  }

  if (receivingId && Object.keys(results).length > 0 && !receivingNumber && !sender && !invoice && !sendingNumber) {
    filtersString = ``
    for (const key in returnData.filters) {
      let item = false
      let id = false
      for (const _key in returnData.filters[key].selection) {
        let _fTitle = ``
        id = returnData.filters[key].selection[_key]
        item = returnData.filters[key].options.find((c) => c.id === id)

        if (item) {
          _preloadFilters[returnData.filters[key].name] = []
          _preloadFilters[returnData.filters[key].name].push(item)

          if (returnData.filters[key].name === 'sender') setSender(user.translate('senderd') + ': ' + item.title)
          if (returnData.filters[key].name === 'invoice') setInvoice(user.translate('invoice') + ': ' + item.title)
          if (returnData.filters[key].name === 'sendingNumber') setSendingNumber(user.translate('sending_no') + ': ' + item.title)
          if (returnData.filters[key].name === 'receivingNumber') setReceivingNumber(item.title)

          _fTitle = returnData.filters[key].title + ': ' + item.title
          _filterTableTitle.push(_fTitle)
        }
      }
    }

    setFilters({ ..._preloadFilters })
    setFilterTableTitle(_filterTableTitle)
  }

  if (receivingNumber && sender && invoice && sendingNumber && !subTotal) {
    for (const i in returnData.totalsGroup.defaultSubTotals) {
      let item = false
      item = returnData.headers.find((c) => c.name === returnData.totalsGroup.defaultSubTotals[i])
      if (item) subTotals.push(item)
    }
    setSubTotal(subTotals)
  }

  if (plt) {
    const pltObj = returnData.totalsGroup.groupBy.find((c) => c.name === plt)
    pltTitle = pltObj.title

    // let plts = helper.groupBy(fieldReport['data'], plt, plt);
    const plts = Object.assign(fieldReport.data)
    if (subTotal) fieldReport = renderSubTotal(plts, subTotal)

    // Create second table field data
    pltReport.columns = []
    pltReport.data = []

    const totals = returnData.totalsGroup.totals

    pltReport.columns.push({
      title: pltObj.headerTitle,
      field: pltObj.name,
      hidden: pltObj.hidden,
      editable: 'never',
    })

    totals.forEach((header) => {
      pltReport.columns.push({
        title: header.title,
        field: header.name,
        hidden: header.hidden,
        editable: 'never',
      })
    })

    const secondPlts = helper.groupBy(results, plt, plt)
    const dataRows = pltObj.values
    const newSecondPlts = []

    const lastTotalRow = {}
    lastTotalRow[pltObj.name] = user.translate('total') // <b>{user.translate("total")}</b>;

    dataRows.forEach((dataRow) => {
      const pltName = dataRow.value

      const subTotalRow = {}
      totals.forEach((total) => {
        let subTotalSum = 0
        if (typeof secondPlts[pltName] !== 'undefined') {
          subTotalRow[pltObj.name] = dataRow.title
          for (const _plt in secondPlts[pltName]) {
            if (typeof secondPlts[pltName][_plt][total.name] !== 'undefined') {
              subTotalSum += secondPlts[pltName][_plt][total.name]
            }
          }
          subTotalRow[total.name] = subTotalSum
        } else {
          subTotalRow[pltObj.name] = dataRow.title
          subTotalRow[total.name] = 0
        }
      })

      newSecondPlts.push(subTotalRow)
    })

    totals.forEach((total) => {
      let totalSum = 0
      newSecondPlts.forEach((newSecondPlt) => {
        totalSum += newSecondPlt[total.name]
      })
      lastTotalRow[total.name] = totalSum // <b>{totalSum}</b>;
    })

    newSecondPlts.push(lastTotalRow)
    pltReport.data = Object.assign(newSecondPlts)
  }

  if (group) {
    groupTitle = returnData.group.find((c) => c.name === group)
    if (!groupTitle) {
      setGroup('')
    } else {
      groupTitle = groupTitle.title

      const groups = helper.groupBy(fieldReport.data, group, group + 'Id')
      fieldReport = renderGroup(groups, fieldReport.columns, group)
    }
  }

  if (subTotal && !plt && !group) {
    const groups = Object.assign(fieldReport.data)
    fieldReport = renderSubTotal(groups, subTotal)
  }

  if (filters) {
    _filters = Object.assign(filters)
  }

  if (!loading && !props.loading_edit && props.refetchIt) {
    props.setRefetchIt(false)
    refetch()
    user.consoleLog('Refetch Done!')
  }

  const onReceiptClick = (rowData) => {
    setOpenReceiptView(rowData)
  }

  const _tableName =
    !group && !plt
      ? props.title
      : `${props.title}: ${groupTitle !== '' ? (pltTitle !== '' ? pltTitle + ', ' + groupTitle : groupTitle) : pltTitle}`

  // index !== 0 &&

  const tableName = (
    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
      <Grid item key="documentHeaders">
        <Box component="span" className={classes.itemTableName}>
          <b>{_tableName}</b>
        </Box>
        <Box component="span" className={classes.itemName}>
          {user.translate('document_from')} {vars.date ? user.formatDate(vars.date) : user.formatDate(vars.dateFrom)}{' '}
          {vars.dateTo ? user.translate('tos') + ' ' + user.formatDate(vars.dateTo) : ''}
        </Box>
        {returnData.documentHeaders?.map((documentHeader, index) => (
          <Box key={documentHeader.title} component="span" className={classes.itemName}>
            {documentHeader.title}: {documentHeader.value}
          </Box>
        ))}
      </Grid>
      <Grid item key="documentFilters" style={{ maxWidth: 400 }}>
        <Box component="span" className={classes.itemTableName}>
          &nbsp;
        </Box>
        {filterTableTitle &&
          filterTableTitle.map((_title) => (
            <Box key={_title} component="span" className={classes.fTitle}>
              {_title}
            </Box>
          ))}
      </Grid>
    </Grid>
  )

  const reportParams = {
    pageSize: '50',
    pageSizeOptions: [50, 100, 200],
    paging: true,
    page: props.page,
    setPage: props.setPage,
    dataLimit: props.dataLimit,
    editable: props.editable,
    onEditItem: props.onEditItem,
    onDeleteItem: props.onDeleteItem,
    onRowClick: props.receiptList && !props.handleSelectAccept ? onReceiptClick : props.handleSelectAccept,
    invoiceId: props.invoiceId,
    exportFileName: _tableName.replaceAll(' ', '').replaceAll(':', '_'),
    tableName: '',
    printTitle: receivingId ? '' : tableName,
    plt,
    export: !receivingId,
    header: props.header,
    headerNumbers: !props.hideHeaderNumbers && props.headerNumbers,
    headers: returnData.headers,
    exportOrientation: props.exportOrientation,
    rowStyle: props.rowStyle,
  }
  return (
    <div className={classes.roots}>
      <ReportHeader
        key={helper.hash('report_header' + group + subTotal + get + filters + receivingId)}
        tableInfo={returnData}
        filters={_filters}
        group={group}
        subTotal={subTotal}
        plt={plt}
        setQuery={setQuery}
        vars={vars}
        datetime={props.datetime}
        onPrintPlt={props.onPrintPlt}
        displayPrint={!!receivingId}
        receivingId={receivingId}
      />

      {receivingId && (
        <div className={classes.pltTitleBox}>
          <div className={classes.pltHeadersTitleBox}>
            <div className={classes.pltTitle}>
              {user.translate(props.pageTitle ?? 'plt_report_title')} {receivingNumber}
            </div>

            <Grid
              className={classes.pltHeaders}
              container
              spacing={0}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              alignContent="flex-start"
            >
              <Grid item key="documentHeaders">
                {returnData.documentHeaders.map((documentHeader) => (
                  <Box key={documentHeader.title} component="span" className={classes.pltHeadersItem}>
                    {documentHeader.title}: {documentHeader.value}
                  </Box>
                ))}
              </Grid>
            </Grid>

            <div className={classes.pltInvoice}>
              {sender}
              <br />
              {invoice}
              <br />
              {sendingNumber}
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <div className="App AppLoading">
          <CircularProgress />
        </div>
      ) : (
        <TableReport
          // key={helper.hash(group+subTotal+get+JSON.stringify(fieldReport['data'])+props.page+openReceiptView)}
          fieldReport={fieldReport}
          {...reportParams}
        />
      )}

      {!loading && !props.noTax && (plt || receivingId) && (
        <TableReport
          // key={helper.hash(plt+get+JSON.stringify(pltReport["data"])+props.page+openReceiptView)}
          fieldReport={pltReport}
          {...reportParams}
          tableTitleId="table-title1"
          tableName=""
        />
      )}

      {/* {props.InventoryAdjustments && <InventoryAdjustments onSuccess={refetch} />} */}

      {openReceiptView !== false && (
        <InvoiceItemReceiptList
          receiptId={openReceiptView.receiptId}
          receipt={openReceiptView}
          openReceiptView={openReceiptView}
          setOpenReceiptView={setOpenReceiptView}
        />
      )}

      <div id="table-footer" />
    </div>
  )
}
