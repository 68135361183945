import React, { useContext, useState } from 'react'

import SubtitlesIcon from '@mui/icons-material/Subtitles'
import { Button, alpha } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { UserContext } from '../../../App'
import KomitentListDialog from '../../common/KomitentListDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
  },
}))

export default function InvoiceApplyBillTo(props) {
  const classes = useStyles()
  // Get current user preferences (language)
  const user = useContext(UserContext)
  const invoice = props.invoice

  const [openApply, setOpenApply] = useState(false)

  const onOpenApply = () => {
    setOpenApply(true)
  }

  const onToggleClose = () => {
    setOpenApply(false)
  }

  const handleSelectAccept = (item) => {
    console.log(item)

    if (item) {
      const items = {
        id: invoice.id,
        payerCompanyId: item.commissionerId,
        billTo: item.name,
        billToLangId: invoice.billTo.id,
        billToRegNumber: item.regNumber,
        billToVat: item.vat,
        billToAddress: item.address ? item.address + ', ' + item.city + ', ' + item.country : '',
        billToAddressLangId: invoice.billToAddress.id,
        billToContactPerson: item.contactPerson,
        billToContactPersonLangId: invoice.billToContactPerson.id,
        billToContactPhone: item.contactPhone,
        billToContactEmail: item.contactEmail,
      }

      const fieldList = [
        {
          required: true,
          type: 'hidden',
          fieldType: 'text',
          fieldName: 'id',
          fieldLabel: 'id',
          fieldValue: invoice.id,
        },
        {
          required: true,
          type: 'text',
          fieldType: 'text',
          fieldName: 'billTo',
          fieldLabel: user.translate('to'),
          fieldValue: item.name,
        },
        {
          required: true,
          type: 'hidden',
          fieldType: 'text',
          fieldName: 'billingToLangId',
          fieldLabel: 'billingToLangId',
          fieldValue: invoice.billTo.id,
        },
        {
          required: true,
          type: 'hidden',
          fieldType: 'text',
          fieldName: 'billToRegNumber',
          fieldLabel: user.translate('billing_reg_no'),
          fieldValue: item.regNumber,
        },
        {
          required: true,
          type: 'text',
          fieldType: 'text',
          fieldName: 'billToAddress',
          fieldLabel: user.translate('address'),
          fieldValue: items.billToAddress,
        },
        {
          required: true,
          type: 'hidden',
          fieldType: 'text',
          fieldName: 'billToAddressLangId',
          fieldLabel: 'billToAddressLangId',
          fieldValue: invoice.billToAddress.id,
        },
      ]

      props.setFieldListBillTo([...fieldList])

      props.onEditInvoiceBillTo(items)
    }

    onToggleClose()
  }

  return (
    <div className={`${classes.root} no_print`}>
      {!openApply && (
        <Button color="primary" component="span" onClick={onOpenApply} startIcon={<SubtitlesIcon />}>
          {user.translate('komitents')}
        </Button>
      )}

      {openApply && <KomitentListDialog setOpenSelectBox={setOpenApply} openSelectBox={openApply} action={handleSelectAccept} />}
    </div>
  )
}
