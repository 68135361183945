import React, { useContext, useEffect, useState } from 'react'

import { CircularProgress, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import makeStyles from '@mui/styles/makeStyles'

import { callOrGet, ifNull, preventPropagation } from './utils'
import { UserContext } from '../../../App'
import { useEntityContextQuery } from '../useEntityQuery'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-grid',
    margin: theme.spacing(1),
    minWidth: '20ch',
    flexGrow: 1,
    '& > .MuiTextField-root': {
      display: 'grid',
      width: '-webkit-fill-available',
    },
  },
}))

const filterOptions = (options, params) => {
  return options.filter((option) => option.name && option.name.cirilicToLatin().indexOf(params.inputValue.cirilicToLatin()) > -1)
}

export default function EntityAutoComplete(props) {
  const classes = useStyles()

  const user = useContext(UserContext)

  const [isOpen, setIsOpen] = useState(false)

  const fields = [
    { name: 'id', gql: 'String!' },
    { name: 'name', gql: 'Translation!', subSelection: { [user.lang]: true } },
    // TODO select aditional fields (StockType.price)
  ]
  if (props.subSelection) {
    Object.keys(props.subSelection).forEach((prop) => {
      if (prop === 'id' || prop === 'name') return
      fields.push({ name: prop, gql: 'String' })
    })
  }
  const query = useEntityContextQuery(props.entity, fields, {
    readOnly: true,
    pollInterval: 30000,
    skip: props.hidden,
    onLoad: props.onLoad,
  })

  const propsValue = props.editField && typeof props.value === 'object' ? props.value[props.editField] : props.value
  const value = ifNull(propsValue, callOrGet(props.default, props.item))
  let selected = null

  const exclude = callOrGet(props.exclude, value, props.item)

  const filteredItems = exclude ? query.items?.filter((item) => exclude.indexOf(item.id) === -1) : query.items
  const komitentsOptions =
    filteredItems?.map((item) => {
      const option = { ...item, name: item.name[user.lang] }
      if (option.id === value) {
        selected = option
      }
      return option
    }) || []

  useEffect(() => {
    if (props.editField && selected && typeof props.value === 'object') {
      props.rowData[props.editField + 'Val'] = props.value
      props.onChange(selected.id, selected.name)
    }
  }, [typeof props.value === 'object'])

  if (selected && props.fieldName && props.rowData['_' + props.fieldName] !== selected) {
    props.rowData['_' + props.fieldName] = selected
  }

  return (
    <Autocomplete
      className={classes.root}
      onOpen={() => {
        setIsOpen(true)
      }}
      onClose={() => {
        setIsOpen(false)
      }}
      autoHighlight
      selectOnFocus
      fullWidth={props.fullWidth}
      handleHomeEndKeys
      options={komitentsOptions}
      filterOptions={filterOptions}
      value={selected}
      onChange={props.onChange && ((event, value) => props.onChange(value?.id, value?.name))}
      onInputChange={(e, value) => value.length === 0 && props.onClear && props.onClear()}
      onSubmit={props.onSubmit}
      disabled={props.disabled}
      size={props.size}
      onKeyDown={(event) => preventPropagation(event, isOpen)}
      isOptionEqualToValue={(option, value) => value && option.id === value.id}
      getOptionLabel={(option) => option?.name || ''}
      renderInput={(params) => {
        params.inputProps.autoComplete = 'off'
        params.inputProps.style = { fontSize: props.size === 'small' ? 12 : 14 }
        return (
          <TextField
            {...params}
            label={user.translate(props.label || props.name)}
            error={props.error}
            variant={props.variant || 'outlined'}
            size={props.size}
            fullWidth={props.fullWidth}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <React.Fragment>
                  {query.isLoading ? (
                    <CircularProgress color="inherit" size={props.size === 'small' ? 14 : 18} style={{ marginLeft: 4 }} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )
      }}
    />
  )
}
EntityAutoComplete.gql = 'String'
