import React, { useContext, useRef, useState } from 'react'

import { useNavigate, useLocation, useParams } from 'react-router-dom'

import Check from '@mui/icons-material/Check'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows'
import Print from '@mui/icons-material/Print'
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty'
import makeStyles from '@mui/styles/makeStyles'

import Compensation from './Compensation'
import { RestaurantContext, UserContext } from '../../App'
import SnackBarDelete from '../common/action/SnackBarDelete'
import useDateRangeFilter from '../common/DateRangeHeader'
import GqlSimpleTable from '../common/GqlSimpleTable'
import MobileDialog from '../common/MobileDialog'
import Show from '../common/Show'
import SpeedDialButton from '../common/SpeedDialButton'
import useWindowSize from '../common/useWindowSize'

const useStyles = makeStyles((theme) => ({
  roots: {
    '& .MuiTableCell-root .MuiIconButton-root': {
      padding: theme.spacing(1),
    },
  },
  root: {},
  checkedLabel: {
    marginRight: 0,
    '& .MuiFormControlLabel-label': {
      fontSize: 12,
    },
  },
  distOk: {
    color: '#069a87',
    fontSize: '1.1em',
    verticalAlign: 'middle',
    paddingBottom: 1,
  },
}))

// React Function Component
export default function FinancialDocumentList() {
  const classes = useStyles()

  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const size = useWindowSize()

  const navigate = useNavigate()
  const location = useLocation()

  const { documentId: selectedDocumentId, documentType } = useParams()

  const [deletedItem, setDeletedItem] = useState()

  const green = '#069a87'
  const red = '#644'

  const renderDist = (item) => {
    if (item.amount === item.distExpense && item.amount === item.distIncome) return <Check className={classes.distOk} />
    const expense =
      item.distExpense === item.amount ? <Check className={classes.distOk} /> : <span style={{ color: '#800' }}>{item.distExpense}</span>
    const income =
      item.distIncome === item.amount ? <Check className={classes.distOk} /> : <span style={{ color: '#800' }}>{item.distIncome}</span>

    return (
      <span>
        {expense} / {income}
      </span>
    )
  }

  const types = {
    compensation: user.translate('compensation').substring(0, 6),
    COMPENSATION: user.translate('compensation').substring(0, 6),
    cession: user.translate('cession').substring(0, 6),
    CESSION: user.translate('cession').substring(0, 6),
  }

  const FIELDS = [
    { name: 'id', gql: 'String!', id: true, hidden: true },
    { name: 'type', gql: 'String!', render: (item) => types[item.type] },
    { name: 'year', gql: 'Long!', width: 0.7, default: new Date().getFullYear() },
    { name: 'document', title: size.isMobile ? 'doc' : 'document', gql: 'String!', width: 0.3 },
    { name: 'currency', gql: 'String!', hidden: true },
    { name: 'distExpense', gql: 'Float', hidden: true },
    { name: 'distIncome', gql: 'Float', hidden: true },
    { name: 'dist', title: size.isMobile ? 'dist' : 'distributed', render: renderDist, align: 'center', mobile: false },
    { name: 'amount', gql: 'Float!', width: 2, render: (item) => `${item.amount} ${item.currency}`, align: 'right', mobile: false },
    { name: 'date', gql: 'String!', edit: 'DateMilis' },
    { name: 'createdById', gql: 'String', default: user.me.id, hidden: true },
    { name: 'updatedById', gql: 'String', value: user.me.id, hidden: true },
    { name: 'created', gql: 'Long!', default: new Date().getTime(), hidden: true },
    { name: 'updated', gql: 'Long!', default: new Date().getTime(), hidden: true },
    { name: 'seen', gql: 'Long', hidden: true },
  ]

  const dateRangeFilter = useDateRangeFilter('this_year')

  const apiControler = useRef()

  const closeItem = () => {
    if (location.state?.from === 'list-document') {
      navigate(-1)
    } else {
      navigate(`/restaurant/${restaurant._id}/document`)
    }
  }

  const openCompensation = (id = 'new') => {
    const url = `/restaurant/${restaurant._id}/document/compensation/${id}`
    if (id === 'new') {
      navigate(url, { ...location.state, from: `list-document` })
    } else {
      navigate(url, { ...location.state, from: `list-document` })
    }
  }

  const openCession = (id) => {
    const url = `/restaurant/${restaurant._id}/document/cession/${id}`
    if (id === 'new') {
      navigate(url, { ...location.state, from: `list-document` })
    } else {
      navigate(url, { ...location.state, from: `list-document` })
    }
  }

  const onCreateCompensationClick = () => openCompensation('new')
  const onCreateCessionClick = () => openCession('new')

  const onDeleteDocument = (item) => {
    closeItem()
    setDeletedItem({ entityName: documentType.capitalize(true), itemId: item.id })
  }

  const showCompensation = documentType?.toUpperCase() === 'COMPENSATION'
  const showCession = documentType?.toUpperCase() === 'CESSION'

  const isNew = selectedDocumentId?.toLowerCase() === 'new'
  const selectedDocument = !isNew && selectedDocumentId

  return (
    <div className={classes.roots}>
      <GqlSimpleTable
        apiRef={apiControler}
        customQuery="finance_getAllDocuments"
        relFieldName="restaurantId"
        relFieldValue={restaurant.id}
        pathName={(item) => `document/${item.type.toLowerCase()}`}
        fields={FIELDS}
        header={dateRangeFilter.renderHeader}
        args={{
          dateFrom: dateRangeFilter.startDate.getTime(),
          dateTo: dateRangeFilter.endDate.getTime(),
          types: [],
        }}
        title="finance_documents"
      />

      <SpeedDialButton
        actions={[
          { name: 'compensation', icon: <CompareArrowsIcon /> },
          { name: 'cession', icon: <ThreeSixtyIcon /> },
        ]}
        onCessionClick={onCreateCessionClick}
        onCompensationClick={onCreateCompensationClick}
      />

      <Show if={Boolean(deletedItem)}>
        <SnackBarDelete {...deletedItem} refresh={apiControler.current?.gql?.refresh} onClose={setDeletedItem} />
      </Show>

      <MobileDialog
        open={Boolean(showCompensation)}
        onClose={closeItem}
        title={user.translate(isNew ? 'new_compensation' : 'compensation')}
        actionIcon={<Print />}
      >
        <Show if={showCompensation}>
          <Compensation
            itemId={selectedDocument}
            onCreate={(item) => openCompensation(item.id)}
            onClose={closeItem}
            onDelete={onDeleteDocument}
            refresh={apiControler.current?.gql?.refresh}
          />
        </Show>
      </MobileDialog>

      <MobileDialog
        open={Boolean(showCession)}
        onClose={closeItem}
        title={user.translate(isNew ? 'new_cession' : 'cession')}
        actionIcon={<Print />}
      >
        <Show if={showCession}>
          <Compensation
            itemId={selectedDocument}
            onCreate={(item) => openCession(item.id)}
            onClose={closeItem}
            onDelete={onDeleteDocument}
            refresh={apiControler.current?.gql?.refresh}
          />
        </Show>
      </MobileDialog>
    </div>
  )
}
