import ReactDOM from 'react-dom'

import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { TranslationProvider } from '@zeroapi-io/zeroapireact'

import { ThemeProvider, createTheme } from '@mui/material'
import { ThemeProvider as ThemeProviderLegacy } from '@mui/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LicenseInfo } from '@mui/x-license-pro'

import 'fontsource-roboto'

import config from './config'
import './ext'
import './index.css'
import * as serviceWorker from './serviceWorker'
import App from './views/App'

LicenseInfo.setLicenseKey('f866a90f0188a4170f74f16de543c28fTz04MTMyMCxFPTE3MzU1MTM2MzAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=')

const theme = createTheme({
  palette: {
    primary: {
      light: 'rgba(8, 46, 45, 0.2)', // will be calculated from palette.primary.main,
      medium: 'rgba(8, 46, 45, 0.7)',
      main: 'rgba(8, 46, 45, 0.9)', // '#003366'//'#970297' //"rgb(151, 2, 151)" //'rgb(105, 1, 105, 0.9)', //#690169
      dark: 'rgba(8, 46, 45, 1)', // will be calculated from palette.primary.main,
      background: 'rgba(0,0,0,0.05)', // will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#ffc6ff',
      main: '#b75cb7', // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    tertiary: {
      light: '#fff2cc',
      main_light: 'rgba(171, 153, 101, 0.5)',
      main: '#ff0000',
      main_dark: '#ab9965',
      dark: '#4a3a0a', // dark: will be calculated from palette.secondary.main,
      contrastText: '#b75cb7',
    }, // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3, // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
})

const cache = new InMemoryCache({
  typePolicies: {
    ReportFilterOptions: {
      keyFields: ['_id'],
    },
    ProfileByEmail: {
      keyFields: ['_id'],
    },
  },
})
const link = new HttpLink({
  uri: `${config.apiUrl}/graphql/restaurant`,
  credentials: 'include',
  withCredentials: true,
  fetchOptions: {
    credentials: 'include',
  },
})

const client = new ApolloClient({
  cache,
  link,
})

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ThemeProviderLegacy theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <GoogleOAuthProvider clientId={config.googleClientId}>
          <ApolloProvider client={client}>
            <TranslationProvider defaultLocale="mk" defaultTranslations={{}} translationsFactory={(locale) => {}}>
              <App theme={theme} />
            </TranslationProvider>
          </ApolloProvider>
        </GoogleOAuthProvider>
      </LocalizationProvider>
    </ThemeProviderLegacy>
  </ThemeProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
