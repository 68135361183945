import React, { useContext, useState } from 'react'

import { useQuery, gql } from '@apollo/client'

import IconAdd from '@mui/icons-material/Add'
import { CircularProgress, Grid } from '@mui/material'
import SpeedDial from '@mui/material/SpeedDial'
import makeStyles from '@mui/styles/makeStyles'

import ProductionCategoryItem from './ProductionCategoryItem'
import { RestaurantContext, UserContext } from '../../App'
import Add from '../common/Add'
import Delete from '../common/Delete'
import helper from '../common/Helper'
import SnackBarDelete from '../common/SnackBarDelete'
import SnackBarWarning from '../common/SnackBarWarning'

// CSS
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    padding: theme.spacing(3),
  },
  gridList: {
    justifyContent: 'right',
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  gridItem: {
    maxWidth: 350,
    minWidth: 350,
    flexGrow: 1,
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

// React Function Component
export default function ProductionCategoryList() {
  const classes = useStyles()

  // Get current restaurant from context
  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)
  const [openManipulateBox, setOpenManipulateBox] = useState(false)
  const [openDeleteBox, setOpenDeleteBox] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [openSnackBarWarning, setOpenSnackBarWarning] = useState(false)

  const [deleteState, setDeleteState] = useState({
    name: '',
    variables: {},
    hideUndo: false,
  })

  const onManipulateClick = (e) => {
    setOpenManipulateBox(true)
  }

  const DELETE_ITEM = `
    mutation ($id: String!) {
        deleteProductionCategory(id: $id)
    }`

  const UNDELETE_ITEM = `
    mutation ($id: String!) {
        undeleteProductionCategory(id: $id)
    }`

  const ADD_ITEM = `
    mutation (
        $restaurantId: String!, 
        ${user.me.profile && user.me.profile.isAdmin ? '$nameMk: String!, $nameEn: String!' : '$name: String!'},
        $irTaxTypeId: String!,
        $taTaxTypeId: String!,
        $ctTaxTypeId: String!,
    ) {
		createProductionCategory(input: {
			${user.me.profile && user.me.profile.isAdmin ? user.gqlCreateNameTr('$nameMk', '$nameEn') : user.gqlCreateName('$name')}
            restaurantId: $restaurantId
            irTaxTypeId: $irTaxTypeId
            taTaxTypeId: $taTaxTypeId
            ctTaxTypeId: $ctTaxTypeId
		}) {
			id
		}
    }`

  // GraphQL API request definition (local variables: restaurantId)
  const GET_PRODUCTION_CATEGORY_BY_RESTAURANT = gql`
    query ($restaurantId: String!) {
		getProductionCategorysByContextRestaurantId(restaurantId: $restaurantId) {
			id
			name {
				${user.gqlFetchName()}
            }
            productsCount
            productionDepartmentsCount
            irTaxType {
                id
                rate
                name
            }
            taTaxType {
                id
                rate
                name
            }
            ctTaxType {
                id
                rate
                name
            }
        }
        
        getTaxTypesByContextRestaurantId(restaurantId: $restaurantId) {
            id
            name
            code
            rate
        }
	}`

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const { data, loading, error, refetch } = useQuery(GET_PRODUCTION_CATEGORY_BY_RESTAURANT, {
    variables: { restaurantId: restaurant.id },
    pollInterval: 3000,
  })

  // If it is loading, show progress bar
  // if (loading) return <CircularProgress />
  if (loading) {
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    )
  }

  // In case there is an error, just show it for now
  if (!data) {
    user.consoleLog(error)
    return <p>Error</p>
  }

  const alertMessage = `${user.translate('cant_delete_production_category')} `;
    const autoHideDuration = 3000;
    const linkTo = ``;
    const linkText = ''
  let _taxs;
    let taxTypes = [];
    let firstTaxType

  if (data) {
    _taxs = data.getTaxTypesByContextRestaurantId

    if (_taxs.length > 0) {
      const taxs = Object.assign([], _taxs)
      taxs.sort((a, b) => b.rate - a.rate)

      taxTypes = taxs.map((_tax) => {
        return {
          val: _tax.id,
          label: user.translate(_tax.name) + ' ' + _tax.rate * 100,
          name: _tax.name,
        }
      })

      firstTaxType = taxTypes.slice(0, 1)[0]
    }
  }

  let fieldList = [
    {
      required: true,
      type: 'text',
      fieldType: 'text',
      fieldName: 'name',
      fieldLabel: user.translate('title'),
      fieldValue: '',
    },
    {
      required: true,
      type: 'select',
      fieldType: 'select',
      fieldName: 'irTaxTypeId',
      fieldLabel: user.translate('irTaxType'),
      fieldValue: firstTaxType.val,
      fieldOptions: taxTypes,
    },
    {
      required: true,
      type: 'select',
      fieldType: 'select',
      fieldName: 'taTaxTypeId',
      fieldLabel: user.translate('taTaxType'),
      fieldValue: firstTaxType.val,
      fieldOptions: taxTypes,
    },
    {
      required: true,
      type: 'select',
      fieldType: 'select',
      fieldName: 'ctTaxTypeId',
      fieldLabel: user.translate('ctTaxType'),
      fieldValue: firstTaxType.val,
      fieldOptions: taxTypes,
    },
  ]

  if (user.me.profile && user.me.profile.isAdmin) {
    fieldList = [
      {
        required: true,
        type: 'text',
        fieldType: 'text',
        fieldName: 'nameMk',
        fieldLabel: user.translate('titleMk'),
        fieldValue: '',
      },
      {
        required: true,
        type: 'text',
        fieldType: 'text',
        fieldName: 'nameEn',
        fieldLabel: user.translate('titleEn'),
        fieldValue: '',
      },
      {
        required: true,
        type: 'select',
        fieldType: 'select',
        fieldName: 'irTaxTypeId',
        fieldLabel: user.translate('irTaxType'),
        fieldValue: firstTaxType.val,
        fieldOptions: taxTypes,
      },
      {
        required: true,
        type: 'select',
        fieldType: 'select',
        fieldName: 'taTaxTypeId',
        fieldLabel: user.translate('taTaxType'),
        fieldValue: firstTaxType.val,
        fieldOptions: taxTypes,
      },
      {
        required: true,
        type: 'select',
        fieldType: 'select',
        fieldName: 'ctTaxTypeId',
        fieldLabel: user.translate('ctTaxType'),
        fieldValue: firstTaxType.val,
        fieldOptions: taxTypes,
      },
    ]
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
        {data.getProductionCategorysByContextRestaurantId.map((productionCategory) => (
          <Grid item xs={4} key={productionCategory.id} className={classes.gridItem}>
            <ProductionCategoryItem
              productionCategory={productionCategory}
              taxTypes={taxTypes}
              setOpenDeleteBox={setOpenDeleteBox}
              setDeleteState={setDeleteState}
              setOpenSnackBarWarning={setOpenSnackBarWarning}
              refetch={refetch}
            />
          </Grid>
        ))}
      </Grid>
      <SpeedDial
        ariaLabel="Add ProductionCategory"
        className={classes.speedDial}
        open={false}
        onClick={onManipulateClick}
        icon={<IconAdd />}
      />

      {openDeleteBox && (
        <Delete
          name={deleteState.name}
          variables={deleteState.variables}
          setOpenSnackBar={setOpenSnackBar}
          setOpenDeleteBox={setOpenDeleteBox}
          deleteItem={DELETE_ITEM}
          onSuccess={refetch}
        />
      )}

      {openSnackBar && (
        <SnackBarDelete
          hideUndo={deleteState.hideUndo}
          message={deleteState.name + ` ${user.translate('deleted')}!`}
          variables={deleteState.variables}
          openSnackBar={openSnackBar}
          setOpenSnackBar={setOpenSnackBar}
          unDeleteItem={UNDELETE_ITEM}
          onSuccess={refetch}
        />
      )}

      {openManipulateBox && (
        <Add
          fieldList={fieldList}
          openManipulateBox={setOpenManipulateBox}
          actionType={user.translate('add')}
          name={user.translate('production_category')}
          restaurant={restaurant}
          manipulateItem={ADD_ITEM}
          onSuccess={refetch}
        />
      )}

      {openSnackBarWarning && (
        <SnackBarWarning
          message={alertMessage}
          autoHideDuration={autoHideDuration}
          linkTo={linkTo}
          linkText={linkText}
          openSnackBar={openSnackBarWarning}
          setOpenSnackBar={setOpenSnackBarWarning}
        />
      )}
    </div>
  )
}
