import React from 'react'

import { useNavigate } from 'react-router-dom'

import { Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  deleted: {
    width: 140,
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
      bottom: 44,
      left: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}))

export default function TableBottomLink(props) {
  const classes = useStyles()

  const navigate = useNavigate()

  const onClick = () => {
    navigate(`../${props.path}`)
  }

  return (
    <Button startIcon={props.icon} className={classes.deleted} onClick={onClick}>
      {props.title}
    </Button>
  )
}
