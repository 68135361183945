import React, { useState, useContext, useEffect } from 'react'

import { useMutation, gql } from '@apollo/client'

import CloseIcon from '@mui/icons-material/Close'
import { CircularProgress, Button, Snackbar, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import helper from './Helper'
import { UserContext } from '../../App'

const useStyles = makeStyles((theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
  displayNone: {
    display: `none`,
  },
}))

export default function SnackBarDelete(props) {
  const classes = useStyles()

  const user = useContext(UserContext)

  const message = props.message
  const [messageInfo, setMessageInfo] = useState({ message })
  const [onSuccess, setOnSuccess] = useState(props.onSuccess !== undefined)
  const [undeleteIt, setUndeleteIt] = useState(false)

  const UNDELETE_ITEM = gql`
    ${props.unDeleteItem}
  `

  const [undeleteItem, { loading, error }] = useMutation(UNDELETE_ITEM)

  if (loading === false && undeleteIt && onSuccess) {
    user.consoleLog('Undelete done!')
    props.onSuccess()
    setOnSuccess(false)
  }

  useEffect(() => {
    if (undeleteIt && loading === false) {
      props.setOpenSnackBar(false)
    }
  })

  const hideUndo = typeof props.hideUndo !== 'undefined' && props.hideUndo ? 'displayNone' : ''

  if (error) {
    return <p>error</p>
  }

  if (loading) {
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    )
  }

  const handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //     return;
    // }
    props.setOpenSnackBar(false)
  }

  const handleExited = () => {
    setMessageInfo(undefined)
    handleClose()
  }

  const handleUndelete = (event) => {
    if (typeof props.setUnDeleteIt !== 'undefined') {
      // for table views
      props.setUnDeleteIt(true)
    }

    setUndeleteIt(true)

    undeleteItem({
      variables: props.variables,
    })

    user.consoleLog(props.variables)
  }

  return (
    <>
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={props.openSnackBar}
        autoHideDuration={3000}
        onClose={handleClose}
        message={messageInfo ? messageInfo.message : undefined}
        action={
          <>
            <Button color="secondary" size="small" onClick={handleUndelete} className={classes[hideUndo]}>
              {user.translate('undo')}
            </Button>
            <IconButton aria-label="close" color="inherit" className={classes.close} onClick={handleClose} size="large">
              <CloseIcon />
            </IconButton>
          </>
        }
        TransitionProps={{
          onExited: handleExited,
        }}
      />
    </>
  )
}
