import { forwardRef, useContext } from 'react'

import MaterialTable, { MTableHeader } from '@material-table/core'

import AddBox from '@mui/icons-material/AddBox'
import ArrowDownward from '@mui/icons-material/ArrowDownward'
import Check from '@mui/icons-material/Check'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import Clear from '@mui/icons-material/Clear'
import DeleteOutline from '@mui/icons-material/DeleteOutline'
import Edit from '@mui/icons-material/Edit'
import FilterList from '@mui/icons-material/FilterList'
import FirstPage from '@mui/icons-material/FirstPage'
import LastPage from '@mui/icons-material/LastPage'
import Remove from '@mui/icons-material/Remove'
import SaveAlt from '@mui/icons-material/SaveAlt'
import Search from '@mui/icons-material/Search'
import ViewColumn from '@mui/icons-material/ViewColumn'

import { UserContext } from '../../App'
import { useExportOptions } from './useExportOptions'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

export default function TableInvoice(props) {
  const user = useContext(UserContext)

  const tableData = { ...props.fieldList }

  const pageSize = parseInt(props.pageSize)
  const pageSizeOptions = props.pageSizeOptions
  const tableLayout = 'auto'
  let fixedColumns = {}

  if (typeof props.fixedColumns !== 'undefined') {
    fixedColumns = props.fixedColumns
  }

  const exportFileName = `${props.exportFileName}_${user.formatDate(new Date())}`

  const exportOptions = useExportOptions(props.exportFileName)

  const onPageChange = (page) => {}

  // useLayoutEffect(() => {

  //     let add = document.querySelector(`[title=${user.translate("add")}]`);
  //     let edit = document.querySelectorAll(`[title=${user.translate("edit")}]`);

  //     if(props.commissioners.length === 0 && !props.gotIt)
  //     {
  //         if(add!== null && edit != null)
  //         {
  //             add.addEventListener("click", function(event)
  //             {
  //                 props.setOpenCommissioners(true);
  //             });
  //             for(var i in edit)
  //             {
  //                 if (typeof edit[i] === "object")
  //                 {
  //                     edit[i].addEventListener("click", function(event)
  //                     {
  //                         props.setOpenCommissioners(this);
  //                     });
  //                 }
  //             }
  //         }
  //     }

  // });

  // useLayoutEffect(() => {

  //     // let editRow = document.querySelector("[mode='update']");
  //     // if(props.commissioners.length !== 0 && !props.openCommissioners && props.gotIt !== true && props.gotIt !== false && editRow != null)
  //     // {

  //     //     setTimeout(() => {
  //     //         props.gotIt.click();
  //     //         props.setGotIt(true);
  //     //     }, 3000)
  //     // }

  // });

  const countColumns = tableData.columns.filter((x) => !x.hidden).length - 1
  const paddingColumns = props.paddingColumns || 0

  window.columnParents = []
  window.columnNumbers = []

  return (
    <>
      <MaterialTable
        style={{ display: 'grid' }}
        icons={tableIcons}
        title={
          <div
            id={props.tableTitleId ? props.tableTitleId : 'table-title'}
            style={{
              lineHeight: 1,
              fontSize: '14px',
              fontWeight: 'bold',
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              textAlign: 'left',
              minWidth: 250,
            }}
          >
            {props.tableName}
          </div>
        }
        columns={tableData.columns}
        data={tableData.data}
        localization={{
          header: {
            actions: user.translate('actions'),
          },
          body: {
            emptyDataSourceMessage: user.translate('no_records_display'),
            addTooltip: user.translate('add'),
            deleteTooltip: user.translate('delete'),
            editTooltip: user.translate('edit'),
            editRow: {
              deleteText: user.translate('delete_text_row'),
              cancelTooltip: user.translate('cancel'),
              saveTooltip: user.translate('save'),
            },
          },
          toolbar: {
            page: user.translate('page'),
            of: user.translate('of'),
            seal: user.translate('seal'),
            authorized_person_signature: user.translate('authorized_person_signature'),
            exportCSVName: user.translate('export_csv_name'),
            exportPDFName: user.translate('export_pdf_name'),
            exportTitle: user.translate('export'),
            searchAriaLabel: user.translate('search'),
            searchTooltip: user.translate('search'),
            searchPlaceholder: user.translate('search'),
          },
          pagination: {
            firstTooltip: user.translate('first_page'),
            lastTooltip: user.translate('last_page'),
            previousTooltip: user.translate('previous_page'),
            nextTooltip: user.translate('next_page'),
          },
        }}
        options={{
          addRowPosition: 'first',
          tableLayout,
          fixedColumns,
          emptyRowsWhenPaging: false,
          initialPage: props.page,
          paging: !(props.print || tableData.data.length < 50),
          pageSize,
          pageSizeOptions,
          exportMenu: exportOptions,
          exportButton: typeof props.export !== 'undefined' ? (!!props.export) : true,
          exportFileName,
          exportAllData: true,
          exportFontName: 'Roboto',
          exportFontSize: 7,
          exportFontOptions: {
            styles: { font: 'Roboto', fontStyle: 'normal', fontSize: 8 },
            headStyles: {
              font: 'RobotoBold',
              fontStyle: 'normal',
              fontSize: 8,
              fillColor: 227,
              textColor: 32,
              lineWidth: 1,
            },
          },
          exportOrientation: 'portrait',
          cellStyle: (rowData) => {
            return {
              padding: `${paddingColumns}`,
              fontSize: 12,
              width: `${100 / countColumns}%`,
              whiteSpace: 'pre-line',
            }
          },
          headerStyle: {
            padding: `5px 0`,
            lineHeight: 1.2,
            position: 'sticky',
            top: 0,
            backgroundColor: '#e3e3e3',
          },
          actionsCellStyle: {
            padding: 0,
            width: '5%',
          },
          rowStyle: (rowData) => {
            // if(rowData.status === 0) {//created
            //     return {backgroundColor: 'white'};
            // }
            if (rowData.status === 1) {
              // sent
              if (rowData.dueToDate > new Date().getTime()) {
                // overdue
                return { backgroundColor: '#F59A90' }
              }
              return { backgroundColor: '#CDC9F5' }
            }
            if (rowData.status === 2) {
              // paid
              return { backgroundColor: '#A9F5CC' }
            }
            if (rowData.status === 3) {
              // canceled
              return { backgroundColor: '#898989', color: '#FFF' }
            }

            return {}
          },
        }}
        // onChangePage={(page, pageSize) => onPageChange(page)}
        onRowClick={props.onRowClick ? (event, rowData) => props.onRowClick(rowData) : undefined}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              resolve()

              props.onAddItem(newData)

              // setState((prevState) => {
              //     const data = [...prevState.data];
              //     data.push(newNewData);
              //     return { ...prevState, data };
              // });
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              resolve()

              if (oldData) {
                props.onEditItem(newData, tableData)

                // setState((prevState) => {
                //     const data = [...prevState.data];
                //     data.push(newNewData);
                //     return { ...prevState, data };
                // });
              }
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              resolve()

              props.onDeleteItem(oldData, tableData)

              // setState((prevState) => {
              //     const data = [...prevState.data];
              //     data.splice(data.indexOf(oldData), 1);
              //     return { ...prevState, data };
              // });
            }),
        }}
        components={{
          Header: (_props) => <MTableHeader {..._props} />,
        }}
      />
    </>
  )
}
