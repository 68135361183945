import { useContext } from 'react'

import Moment from 'react-moment'

import { Button, CircularProgress, Tooltip } from '@mui/material'

import { UserContext } from '../../App'
import { useEntityRelationQuery } from '../common/useEntityQuery'

export const InventoryCounterState = (props) => {
  const user = useContext(UserContext)

  const warehouseCountersQuery = useEntityRelationQuery(
    'InventoryCounterItem',
    [
      { name: 'id', gql: 'String!', readOnly: true },
      { name: 'inventoryCounterId', gql: 'String!' },
      { name: 'time', gql: 'String!' },
      { name: 'updated', gql: 'String!' },
      { name: 'state', gql: 'Float!' },
      { name: 'employeeId', gql: 'String' },
      { name: 'employee', gql: 'Employee!', readOnly: true, subSelection: { profile: { name: true } } },
    ],
    'inventoryCounterId',
    props.counterId,
    {
      orderByDesc: 'time',
      limit: 1,
    },
  )

  const item = warehouseCountersQuery.items && warehouseCountersQuery.items[0]

  const onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const newValue = prompt(user.translate('enter_new_state', item?.state?.toString() ?? ''))
    try {
      const newState = parseFloat(newValue)
      warehouseCountersQuery.saveItem({
        employeeId: user.me.id,
        state: newState,
        time: Date.now(),
        updated: Date.now(),
        inventoryCounterId: props.counterId,
      })
    } catch (e) {}
  }

  const style = { minWidth: 48 }

  if (warehouseCountersQuery.isLoading) {
    return (
      <Button style={style} onClick={onClick}>
        <CircularProgress size={16} />
      </Button>
    )
  }
  if (!item) {
    return (
      <Button style={style} onClick={onClick}>
        0
      </Button>
    )
  }
  return (
    <Tooltip
      title={
        <span>
          <Moment format="llll" locale={user.lang}>
            {parseInt(item.time)}
          </Moment>{' '}
          - {item.employee?.profile?.name}
        </span>
      }
    >
      <Button style={style} onClick={onClick}>
        {item.state}
      </Button>
    </Tooltip>
  )
}
