import { useContext, useState } from 'react'

import SubtitlesIcon from '@mui/icons-material/Subtitles'
import { Box, Button, FormGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { DateRangePickerComponent } from './DateRangePickerComponent'
import KomitentListDialog from './KomitentListDialog'
import { UserContext } from '../../App'

const useStyles = makeStyles((theme) => ({
  formRow: {
    flexGrow: 0,
    marginBottom: 25,
    textAlign: `center`,
    marginTop: 25,
    fontSize: 14,
    justifyContent: `center`,
  },
}))

export default function ReceivingHeader(props) {
  const classes = useStyles()
  const user = useContext(UserContext)

  const [filterState, setFilterState] = useState({ ...props.filterState })

  const dateRange = {
    startDate: filterState.selectedDateFrom,
    endDate:
      filterState.selectedDateTo &&
      filterState.selectedDateFrom &&
      new Date(Math.max(filterState.selectedDateTo.getTime(), filterState.selectedDateFrom.getTime())),
  }

  const [openSelectBox, setOpenSelectBox] = useState(false)

  const handleDateRangeAccept = (range) => {
    const filState = Object.assign(filterState)
    const dateTo = new Date(range.endDate)
    dateTo.setHours('23')
    dateTo.setMinutes('59')
    dateTo.setSeconds('59')
    filState.selectedDateFrom = range.startDate
    filState.selectedDateTo = dateTo
    setFilterState(filState)

    if (props.setQuery) {
      props.setQuery(filState)
    }
  }

  const onKomissionersClick = () => {
    setOpenSelectBox(true)
  }

  return (
    <>
      <FormGroup row sx={{ justifyContent: 'space-between', p: 2 }}>
        <Box>
          <Button sx={{ mt: 1 }} color="primary" component="span" onClick={onKomissionersClick} startIcon={<SubtitlesIcon />}>
            {user.translate('komitents')}
          </Button>
        </Box>

        <DateRangePickerComponent initialDateRange={dateRange} onChange={(range) => handleDateRangeAccept(range)} />
      </FormGroup>

      {openSelectBox && <KomitentListDialog setOpenSelectBox={setOpenSelectBox} openSelectBox={openSelectBox} />}
    </>
  )
}
