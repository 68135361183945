import React, { useContext } from 'react'

import { Backdrop } from '@mui/material'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import makeStyles from '@mui/styles/makeStyles'

import { UserContext } from '../../App'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 380,
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

export default function SpeedDialButton(props) {
  const classes = useStyles()

  const user = useContext(UserContext)

  const [open, setOpen] = React.useState(false)

  return (
    <>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
      >
        {props.actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={user.translate(action.name)}
            tooltipOpen
            onClick={props[`on${action.name.capitalize(true)}Click`]}
          />
        ))}
      </SpeedDial>
    </>
  )
}
