import { useMemo } from 'react'

import parser from 'ua-parser-js'

import { Box, Chip, Divider, List, ListItem, Tooltip } from '@mui/material'

export const DateRangeShortcuts = (props) => {
  const {
    items,
    onChange,
    isValid,
    value,
    ownerState: { isLandscape },
  } = props

  const [selectedStart, selectedEnd] = useMemo(() => {
    const [start, end] = value

    return [new Date(start.setHours(0, 0, 0, 0)), new Date(end.setHours(0, 0, 0, 0) - 1)]
  }, [value])

  const resolvedItems = useMemo(() => {
    if (!items?.length) {
      return null
    }

    return items
      .map((item) => {
        const newValue = item.getValue({ isValid })

        const [start, end] = newValue

        const startNormalized = new Date(start.setHours(0, 0, 0, 0))
        const endNormalized = new Date(end.setHours(0, 0, 0, 0) - 1)

        return {
          label: item.label,
          help: item.help,
          start,
          end,
          onClick: () => {
            onChange(newValue)
          },
          disabled: !isValid(newValue),
          isSelected: selectedStart.getTime() === startNormalized.getTime() && selectedEnd.getTime() === endNormalized.getTime(),
        }
      })
      .sort((a, b) => a.start.getTime() - b.end.getTime())
  }, [isValid, items, onChange, selectedEnd, selectedStart])

  if (!resolvedItems?.length) {
    return null
  }

  const isMobile = parser(window.navigator.userAgent).device.type === 'mobile'

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gridColumn: isLandscape ? 1 : 2,
        gridColumnStart: 1,
        gridColumnEnd: 3,
        gridRowStart: 0,
        gridRowEnd: 1,
      }}
    >
      <List
        dense
        sx={{
          display: 'flex',
          width: isMobile ? (!isLandscape ? '310px' : '500px') : 'auto',
          overflow: 'scroll',
          justifyContent: 'space-between',
          pl: 1,
          '& .MuiListItem-root': {
            pt: 0,
            pb: 0,
            pl: 0,
            pr: 1,
          },
        }}
      >
        {resolvedItems.map((item) => {
          return (
            <ListItem key={item.label} sx={{ width: 'auto' }}>
              <Tooltip title={item.help ?? item.label}>
                <Chip {...item} size="small" color="primary" variant={item.isSelected ? 'filled' : 'outlined'} />
              </Tooltip>
            </ListItem>
          )
        })}
      </List>
      <Divider />
    </Box>
  )
}
